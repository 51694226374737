import useSWR from "swr";
import { API_NAME } from "../../constant";

import { getReportApi, getReportOverviewApi } from "../../reports";

export const useReport = (
  type: string,
  agentId: string,
  date: string,
  reportType: string
) => {
  return useSWR(
    [API_NAME.report_list, type, agentId, date, reportType],
    () => getReportApi(type, agentId, date, reportType),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useReportOverview = (
  type: string,
  startDate: string,
  endDate: string
) => {
  return useSWR(
    [API_NAME.report_overview_list, type, startDate, endDate],
    () => getReportOverviewApi(type, startDate, endDate),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
