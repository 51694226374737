import React, { useState } from "react";
import BackArrowButton from "../components/ui/buttons/BackArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import { RouteName } from "../app/constants/route-constant";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, TextField } from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "./common/Icon";
import { DevTool } from "@hookform/devtools";
import { useUpdateProfile } from "../api/mutation/profile";
import { useProfileById } from "../api/funs/profile";
import SpinnerLoading from "./common/SpinnerLoading";
const EditProfilePage = () => {
  const navigate = useNavigate();
  const [currentPassword, setcurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [retypePassword, setRetypePassword] = useState(false);
  const { id } = useParams();

  const { data: editProfile } = useProfileById(id as string);

  const { trigger } = useUpdateProfile(id as string);
  const formSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    // phoneNo:  yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid'),

    currentPassword: yup
      .string()
      .required("Current password is required")
      .min(4, "Current password length should be at least 4 characters")
      .max(12, "Current password cannot exceed more than 12 characters"),
    newPassword: yup
      .string()
      .required("New password is required")
      .min(4, "New password length should be at least 4 characters")
      .max(12, "New password cannot exceed more than 12 characters"),
    retypePassword: yup
      .string()
      .required("Retype password is required")
      .min(4, "Retype password length should be at least 4 characters")
      .max(12, "Retype password cannot exceed more than 12 characters")
      .oneOf([yup.ref("newPassword")], "New password do not match"),
  });
  const { control, handleSubmit, formState, reset } = useForm<any>({
    values: {
      username: editProfile?.data?.data?.name,
      phone: editProfile?.data?.data?.phone,
    },
    defaultValues: {
      username: editProfile?.data?.data?.name,
      phone: editProfile?.data?.data?.phone,
    },
    resolver: yupResolver(formSchema),
  });
  const { errors } = formState;
  const onSubmit = (data: any) => {
    trigger({
      password: data?.retypePassword,
      roleId: editProfile?.data?.data?.roleId?._id,
      ipAddress: editProfile?.data?.data?.loginIpAddresses?.[0],
      name: data?.name,
      email: data?.email,
    });
    reset();
  };
  return (
    <>
      {editProfile ? (
        <>
          <div className="pl-5">
            <BackArrowButton handleClick={() => navigate(RouteName.profile)} />
          </div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 px-5 gap-x-10">
              <Controller
                control={control}
                name="username"
                render={({ field, formState, fieldState }: any) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      fullWidth
                      {...field}
                      placeholder="Username (e.g.Ko Soe)"
                      error={!!errors?.username}
                      label="Username (e.g.Ko Soe)"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.username?.message && errors?.username?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field, formState, fieldState }: any) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      disabled
                      size="small"
                      fullWidth
                      {...field}
                      placeholder="Phone Number"
                      error={!!errors?.phone}
                      label="Phone Number"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.phone?.message && errors?.phone?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="px-5">
              <label className="mt-3 mb-3 text-[12px]">Change Password</label>
              <div className="grid grid-cols-2 gap-x-10">
                <Controller
                  control={control}
                  name="currentPassword"
                  rules={{
                    required: {
                      value: true,
                      message: "Current Password is required.",
                    },
                  }}
                  render={({ field, formState, fieldState }: any) => {
                    return (
                      <TextField
                        autoComplete="off"
                        color="error"
                        size="small"
                        {...field}
                        type={currentPassword ? "text" : "password"}
                        placeholder="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() => {
                                setcurrentPassword(!currentPassword);
                              }}
                            >
                              {currentPassword ? (
                                <EyeSlashIcon className="w-6 h-6" />
                              ) : (
                                <EyeIcon className="w-6 h-6" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.currentPassword}
                        label="Current Password"
                        sx={{
                          marginBottom: "1.5rem",
                        }}
                        helperText={
                          !!errors?.currentPassword?.message &&
                          errors?.currentPassword?.message
                        }
                      />
                    );
                  }}
                />
                <div></div>

                <Controller
                  control={control}
                  name="newPassword"
                  rules={{
                    required: {
                      value: true,
                      message: "Current Password is required.",
                    },
                  }}
                  render={({ field, formState, fieldState }: any) => {
                    return (
                      <TextField
                        autoComplete="off"
                        color="error"
                        size="small"
                        {...field}
                        type={newPassword ? "text" : "password"}
                        placeholder="New Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() => {
                                setNewPassword(!newPassword);
                              }}
                            >
                              {newPassword ? (
                                <EyeSlashIcon className="w-6 h-6" />
                              ) : (
                                <EyeIcon className="w-6 h-6" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.newPassword}
                        label="New Password"
                        sx={{
                          marginBottom: "1.5rem",
                        }}
                        helperText={
                          !!errors?.newPassword?.message &&
                          errors?.newPassword?.message
                        }
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="retypePassword"
                  rules={{
                    required: {
                      value: true,
                      message: "Retype Password is required.",
                    },
                  }}
                  render={({ field, formState, fieldState }: any) => {
                    return (
                      <TextField
                        autoComplete="off"
                        color="error"
                        size="small"
                        {...field}
                        type={retypePassword ? "text" : "password"}
                        placeholder="New Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() => {
                                setRetypePassword(!retypePassword);
                              }}
                            >
                              {retypePassword ? (
                                <EyeSlashIcon className="w-6 h-6" />
                              ) : (
                                <EyeIcon className="w-6 h-6" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.retypePassword}
                        label="Retype Password"
                        sx={{
                          marginBottom: "1.5rem",
                        }}
                        helperText={
                          !!errors?.retypePassword?.message &&
                          errors?.retypePassword?.message
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between flex-1 col-span-2">
              <div></div>
              <div>
                <button type="submit" className="btn-create-active">
                  Update
                </button>
              </div>
            </div>
            <DevTool control={control} />
          </form>
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default EditProfilePage;
