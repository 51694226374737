import useSWR from "swr";
import { API_NAME } from "../../constant";
import {
  getPolicesConditionApi,
  getPolicesConditionByLangApi,
} from "../../polices";

export const useGetPolicesCondition = () => {
  return useSWR(
    API_NAME.polices_api,
    getPolicesConditionApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetPoliciesByLang = (lang?: string) => {
  return useSWR(
    lang ? [API_NAME.polices_condition_by_lang_api, lang] : null,
    () => getPolicesConditionByLangApi(lang),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
