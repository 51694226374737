import React from "react";
import { CONTENT } from "../../content";
import { format } from "date-fns";
import { CheckCircleIcon, UnCheckCircleIcon } from "../../pages/common/Icon";
import { decryptHashedData } from "../../util/funs";
import ShowHideSecret from "../../components/ui/agent/ShowHideSecret";

const DetailAgentTable = ({ agentDetail }: any) => {
  return (
    <div className="mt-5">
      <table className="w-full table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.agent_detail.array.map((_d, i) => (
            <tr key={i} className="">
              <td className="py-3 text-xs border border-steam md:text-base">{_d}</td>
              <td className="py-3 text-xs font-semibold border border-steam md:text-base">
                {i === 0 ? (
                  agentDetail?.data?.data?.name
                ) : i === 2 ? (
                  <ShowHideSecret code={agentDetail?.data?.data?.secretCode} />
                ) : i === 1 ? (
                  agentDetail?.data?.data?.userCode
                ) : i === 3 ? (
                  <span>
                    {agentDetail?.data?.data?.is2DAvailable && "2D"}
                    {agentDetail?.data?.data?.is2DAvailable &&
                      agentDetail?.data?.data?.is3DAvailable &&
                      ","}
                    {agentDetail?.data?.data?.is3DAvailable && "3D"}
                  </span>
                ) : i === 4 ? (
                  <div>
                    {!agentDetail?.data?.data?.isRound1Available &&
                      !agentDetail?.data?.data?.isRound2Available &&
                      !agentDetail?.data?.data?.isRound3Available &&
                      !agentDetail?.data?.data?.isRound4Available &&
                      "---"}
                    {agentDetail?.data?.data?.is2DAvailable ? (
                      <div className="flex justify-center gap-x-2 ">
                        {agentDetail?.data?.data?.is2DAvailable &&
                          agentDetail?.data?.data?.isRound1Available && (
                            <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
                              R1
                            </div>
                          )}
                        {agentDetail?.data?.data?.is2DAvailable &&
                          agentDetail?.data?.data?.isRound2Available && (
                            <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
                              R2
                            </div>
                          )}
                        {agentDetail?.data?.data?.is2DAvailable &&
                          agentDetail?.data?.data?.isRound3Available && (
                            <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
                              R3
                            </div>
                          )}
                        {agentDetail?.data?.data?.is2DAvailable &&
                          agentDetail?.data?.data?.isRound4Available && (
                            <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
                              R4
                            </div>
                          )}
                      </div>
                    ) : (
                      "--"
                    )}
                  </div>
                ) : i === 5 ? (
                  <span>{`${agentDetail?.data?.data?.invoicePercentage}%`}</span>
                ) : i === 6 ? (
                  agentDetail?.data?.data?.phone
                ) : i === 7 ? (
                  agentDetail?.data?.data?.email
                ) : i === 8 ? (
                  agentDetail?.data?.data?.receivingMail
                ) : i === 9 ? (
                  `${format(
                    new Date(agentDetail?.data?.data?.liveDate),
                    "dd MMM  yyyy"
                  )} at ${format(
                    new Date(agentDetail?.data?.data?.liveDate),
                    "hh:mm:ss a"
                  )}`
                ) : i === 10 ? (
                  agentDetail?.data?.data?.status ? (
                    <span className="flex items-center justify-center gap-x-1">
                      <CheckCircleIcon />{" "}
                      <span className="font-normal">Verified</span>
                    </span>
                  ) : (
                    <span className="flex items-center justify-center gap-x-1">
                      <UnCheckCircleIcon />{" "}
                      <span className="font-normal">Blocked</span>
                    </span>
                  )
                ) : i === 11 ? (
                  `${format(
                    new Date(agentDetail?.data?.data?.createdAt),
                    "dd MMM  yyyy"
                  )} at ${format(
                    new Date(agentDetail?.data?.data?.createdAt),
                    "hh:mm:ss a"
                  )}`
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="w-full my-5 table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.agent_detail.array2.map((_d, i) => (
            <tr key={i} className="">
              <td className="py-3 text-xs border border-steam md:text-base">{_d}</td>
              <td
                className={`py-3 font-semibold  border border-steam ${
                  [2, 4].includes(i) ? "text-red-500" : "text-dynamic_black"
                } text-xs md:text-base`}
              >
                {i === 0 ? (
                  agentDetail?.data?.data?.encryptPassword &&
                  decryptHashedData(
                    agentDetail?.data?.data?.encryptPassword,
                    "AIO_PASSWORD_ENCRYPT"
                  )
                ) : i === 1 ? (
                  <div className="">
                    <div className="grid grid-cols-1 place-items-center gap-y-2">
                      {agentDetail?.data?.data?.loginIpAddresses?.map(
                        (_d: any, i: number) => (
                          <div
                            key={i}
                            className="px-2 md:px-0 md:w-[200px]  h-[33px] flex justify-center items-center rounded-md bg-goldenrod text-dynamic_black"
                          >
                            {_d}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : i === 2 ? (
                  agentDetail?.data?.data?.domainName
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailAgentTable;
