import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getFaqListApi = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  const axios_url = new URL(`${url}${API_NAME.faq_list_api}`);
  axios_url.searchParams.set("language", "");
  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());
  axios_url.searchParams.set("sort", "desc");

  return axiosInstance.get(axios_url);
};

export const getSystemMaintenanceApi = async () => {
  return axiosInstance.get(`${url}/api/admins/setting/system-maintenance`);
};

export const createMaintenanceApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      isEnable?: boolean;
      startDate?: string;
      startTime?: string;
      endDate?: string;
      endTime?: string;
    };
  }
) => {
  // const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${url}${agUrl}`, arg);
};
