import React from "react";

const ReportOverviewCard = ({ reportOverview }: any) => {
  return (
    <div className="bg-white rounded-[10px] py-3 px-3 mt-3 md:w-[70%]">
      <span className="font-bold text-dynamic_black text-[12px] mb-4 block">
        Revenue
      </span>
      <div className="flex justify-between flex-1 pr-5">
        <div className="w-full">
          <span className=" text-dim_grey font-normal text-[14px]  ">
            Total Profit
          </span>
          <span
            className={`block ${
              reportOverview?.data?.data?.profit < 0
                ? "text-vermilion_bird"
                : "text-grass_green"
            }  text-[30px] font-bold`}
          >
            {reportOverview
              ? reportOverview?.data?.data?.profit?.toLocaleString()
              : "--"}{" "}
          </span>
          <div className="flex justify-between flex-1 w-full pr-5 mt-3">
            <div>
              <span className="block text-[12px]">Total Betting</span>
              <span className="block font-bold text-grass_green text-[20px]">
                {reportOverview
                  ? reportOverview?.data?.data?.totalBetting?.toLocaleString()
                  : "--"}{" "}
              </span>
            </div>
            <div>
              <span className="block text-[12px]">Total Winning</span>
              <span className="block font-bold text-hailey_blue text-[20px]">
                {reportOverview
                  ? reportOverview?.data?.data?.totalWinning?.toLocaleString()
                  : "--"}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportOverviewCard;
