import {
  CheckCircleIcon,
  UnCheckCircleIcon,
} from "../../../pages/common/Icon";
import { useLocation,  useParams } from "react-router-dom";


import IOSSwitchAction from "../../../table/actions/IOSSwitchAction";
import { useProfileInfo } from "../../../api/funs/profile";
import { permissionValidate } from "../../../util/funs";
import { CONTENT } from "../../../content";

const AgentInformationOverview = ({ agentDetail }: any) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { data: profileInfo } = useProfileInfo();

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <span className="text-[14px] md:text-[18px] font-bold">{agentDetail?.data?.data?.name}</span>
        </div>
        {pathname === `/twod/betlist/user/${id}` ? null : (
          <div className="flex items-center">
            {agentDetail?.data?.data?.status ? (
              <span className="flex items-center p-r-10 gap-x-1">
                <CheckCircleIcon /> <span className="text-sm md:text-base">Verified</span>
              </span>
            ) : (
              <span className="flex items-center p-r-10 gap-x-1">
                <UnCheckCircleIcon /> <span className="text-sm md:text-base">Blocked</span>
              </span>
            )}

            {permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.AGENT_ACTIVE_BLOCK_TOGGLE
            ) && <IOSSwitchAction detail={agentDetail?.data?.data} />}

            {/* 
            <div className="flex gap-x-2 items-center px-2 py-1 rounded-[10px]  ml-3 border border-steam bg-white" onClick={()=>{

              navigate(`/admins/edit/${id}`)
            }}>
              <EditIcon /> <span>Edit</span>
            </div> */}
          </div>
        )}
      </div>
      <div className="my-2 d-flex">
      <span className="font-semibold text-[12px] md:text-[16px]">
          {agentDetail?.data?.data?.userCode}
        </span>
      </div>
      <div className="d-flex">
      <span className="font-semibold text-[12px] md:text-[16px]">
          {agentDetail?.data?.data?.phone}
        </span>
      </div>
    </>
  );
};

export default AgentInformationOverview;
