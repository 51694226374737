import { getAccessToken } from "./storage";
import { API_DOMAIN } from "../config";
import axios from "axios";

const api_url = API_DOMAIN;

const axiosClient = axios.create({
  baseURL: api_url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: "Bearer " + getAccessToken(),
  },
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response.ok = true;
    return response;
  },
  function (error) {
    let errorMessage = "Error Occur";
    if (error.response.data.msg) {
      errorMessage = error.response.data.msg;
      if (errorMessage === "jwt malformed") {
        localStorage.setItem("access_token", "");
      }
    } else {
      //eslint-disable-next-line
      errorMessage = error.message;
    }
    let errorStatus = "404";
    if (error.response.status) {
      errorStatus = error.response.status;
    } else {
      errorStatus = error.status;
    }
    if (errorStatus !== "401") {
      // user not found and user password 8 character
      if (errorStatus !== "422") {
        //storeErrorModal(errorMessage); //remove error dialog
      }
    }

    if (error.response.status === 401) {
      // history.push("/login");
    }
    return Promise.reject(error);
  }
);

export async function fetchData(param: any) {
  const { method = "get", url, data } = param;

  return await axiosClient({
    method,
    url,
    withCredentials: false,
    data,
    headers: {
      "x-ipAddress": "210.14.96.136",
      Authorization: "Bearer " + getAccessToken(),
    },
  });
}
