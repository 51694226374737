import React, { useState } from "react";

import BetTab from "../tabs/BetTab";
import WinningTransactionTwoD from "./WinningTransactionTwoD";
import WinningTransactionThreeD from "./WinningTransactionThreeD";

const WinningTransactionHistory = () => {
  const [betTabIndex, setBetTabIndex] = useState(0);

  return (
    <>
      <BetTab betTabIndex={betTabIndex} setBetTabIndex={setBetTabIndex} />

      {betTabIndex === 0 ? (
        <WinningTransactionTwoD betTabIndex={betTabIndex} />
      ) : betTabIndex === 1 ? (
          <WinningTransactionThreeD betTabIndex={betTabIndex } />
      ) : null}
    </>
  );
};

export default WinningTransactionHistory;
