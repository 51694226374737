import moment from "moment";
import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";

const LatestResultWinning3DCard = ({ winningResult, round }: any) => {
  return (
    <div className="w-full md:w-[55%] px-2  md:px-3 py-3  rounded-md">
      {" "}
      <div className="flex justify-between mb-4">
        <div className="text-[14px] font-bold">
          <span className="block">Latest Result</span>
          <span>
            3D, {moment(winningResult?.resultDate).format("DD MMM YYYY")}{" "}
          </span>
        </div>
      </div>
      {!winningResult && (
        <p className=" text-dim_grey text-[12px]">Coming soon!</p>
      )}
      <div className="grid items-center w-full grid-cols-2 mt-10 mb-5">
        <div className="flex gap-x-2">
          <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
            {winningResult?.winningNumber?.[0] || "-"}
          </div>
          <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
            {winningResult?.winningNumber?.[1] || "-"}
          </div>
          <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
            {winningResult?.winningNumber?.[2] || "-"}
          </div>
        </div>
      </div>
      <div>
        {winningResult ? (
          <span className={`flex items-center mb-2 gap-x-1 `}>
            <MdCheckCircle className="text-green-500 text-[12px]" />
            <span className="text-[12px] md:text-[14px]">
              Announce Result at{" "}
              {moment().format("DD MMM YYYY")} at{" "}
              {winningResult?.announceTime &&
                moment(winningResult?.announceTime, "HH:mm")?.format(
                  "hh:mm:ss a"
                )}
            </span>
          </span>
        ) : (
          <span className="flex items-center gap-x-1">
            <AiFillInfoCircle className="text-dim_grey text-[12px]" />
            <span className="text-[12px] md:text-[14px]">
              Bet will be closed at {moment().format("DD MMM YYYY")} at{" "}
              {round?.data?.data?.[0]?.endTime &&
                moment(round?.data?.data?.[0]?.endTime, "HH:mm")?.format(
                  "hh:mm:ss a"
                )}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default LatestResultWinning3DCard;
