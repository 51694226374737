// RichTextEditor.tsx
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import "./RichTextEditor.scss"; // Custom styles for the editor

interface RichTextEditorProps {
  value: string | undefined;
  onChange: (value: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // Toggle buttons for bold, italic, underline, and strike
      ["link", "image"], // Insert link and image
      [{ list: "ordered" }, { list: "bullet" }], // Ordered and unordered lists
      ["clean"], // Remove formatting
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "image",
    "list",
    "bullet",
  ];

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(content, _, source) => {
        if (source === "user") {
          onChange(content);
        }
      }}
      modules={modules}
      formats={formats}
    />
  );
};

export default RichTextEditor;
