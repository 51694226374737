import React from "react";
import NoticeCard from "../../components/ui/maintenance/NoticeCard";
import { Controller, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import {
  CalendarIcon,
  TimeClockIcon,
  TurnOffRedIcon,
  TurnOnWhiteIcon,
} from "../common/Icon";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { CircularProgress, TextField } from "@mui/material";
import { useGetSystemMaintenance } from "../../api/funs/maintenance";
import moment from "moment";
import { useCreateSystemMaintenance } from "../../api/mutation/maintenance";
import SpinnerLoading from "../common/SpinnerLoading";

const CreateMaintenancePage = () => {
  const { data: systemMaintenance, mutate } = useGetSystemMaintenance();

  const { trigger, isMutating } = useCreateSystemMaintenance(mutate);

  const { control, handleSubmit, formState } = useForm<{
    fromDate: Dayjs | null;
    fromTime: Dayjs | null;
    toDate: Dayjs | null;
    toTime: Dayjs | null;
    message: string;
  }>({
    values: {
      fromDate: dayjs(
        moment(systemMaintenance?.data?.data?.startDate, "DD-MM-YYYY").format(
          "YYYY-MM-DD hh:mm:ss A"
        )
      ),
      fromTime:
        systemMaintenance?.data?.data?.startTime &&
        dayjs(
          moment(systemMaintenance?.data?.data?.startTime, "HH:mm").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
      toDate:
        systemMaintenance?.data?.data?.endDate &&
        dayjs(
          moment(systemMaintenance?.data?.data?.endDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
      toTime:
        systemMaintenance?.data?.data?.endTime &&
        dayjs(
          moment(systemMaintenance?.data?.data?.endTime, "HH:mm").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
      message: systemMaintenance?.data?.data?.message,
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    const passData = {
      isEnable: !systemMaintenance?.data?.data?.isEnable,
      startDate: moment(
        dayjs(data?.fromDate).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      ).format("DD-MM-YYYY"),
      startTime: moment(
        dayjs(data?.fromTime).format("YYYY-MM-DD hh:mm:ss A")
      ).format("HH:mm A"),
      endDate: moment(
        dayjs(data?.toDate).format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      ).format("DD-MM-YYYY"),
      endTime: moment(
        dayjs(data?.toTime).format("YYYY-MM-DD hh:mm:ss A")
      ).format("HH:mm A"),
      message: data?.message,
    };

    trigger(passData);
  };

  return (
    <div className="mt-10 md:mt-0">
      {systemMaintenance ? (
        <div className="md:pl-5 md:w-[60%]">
          {systemMaintenance && (
            <NoticeCard
              status={systemMaintenance?.data?.data?.isEnable}
              endDate={systemMaintenance?.data?.data?.endDate}
              endTime={systemMaintenance?.data?.data?.endTime}
            />
          )}

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-x-4">
              <span className="block mb-2 font-bold">From</span> <div></div>
              <Controller
                control={control}
                name="fromDate"
                rules={{
                  required: {
                    value: true,
                    message: "Date is required",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <DesktopDatePicker
                      label="Select Date"
                      disabled={systemMaintenance?.data?.data?.isEnable}
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          color: "error",
                          helperText:
                            !!errors?.fromDate?.message &&
                            errors?.fromDate?.message,
                          error: !!errors?.fromDate?.message,
                        },
                        field: {
                        readOnly: true
                    }
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      format="DD MMM YYYY"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="fromTime"
                rules={{
                  required: {
                    value: true,
                    message: "Time is required",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <DesktopTimePicker
                      label="Select Time"
                      {...field}
                      disabled={systemMaintenance?.data?.data?.isEnable}
                      slotProps={{
                        textField: {
                          size: "small",
                          color: "error",
                          helperText:
                            !!errors?.fromTime?.message &&
                            errors?.fromTime?.message,
                          error: !!errors?.fromTime?.message,
                        },
                        field: {
                        readOnly: true
                    }
                      }}
                      slots={{
                        openPickerIcon: TimeClockIcon,
                      }}
                      format="hh:mm:ss A"
                    />
                  );
                }}
              />
              <span className="block mt-4 mb-2 font-bold">To</span> <div></div>
              <Controller
                control={control}
                name="toDate"
                rules={{
                  required: {
                    value: true,
                    message: "Date is required",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <DesktopDatePicker
                      label="Select Date"
                      disabled={systemMaintenance?.data?.data?.isEnable}
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          color: "error",
                          helperText:
                            !!errors?.toDate?.message &&
                            errors?.toDate?.message,
                          error: !!errors?.toDate?.message,
                        },
                        field: {
                        readOnly: true
                    }
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      format="DD MMM YYYY"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="toTime"
                rules={{
                  required: {
                    value: true,
                    message: "Time is required",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <DesktopTimePicker
                      label="Select Time"
                      disabled={systemMaintenance?.data?.data?.isEnable}
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          color: "error",
                          helperText:
                            !!errors?.toTime?.message &&
                            errors?.toTime?.message,
                          error: !!errors?.toTime?.message,
                        },
                        field: {
                        readOnly: true
                    }
                      }}
                      slots={{
                        openPickerIcon: TimeClockIcon,
                      }}
                      format="hh:mm:ss A"
                    />
                  );
                }}
              />
              <div className="col-span-2 mt-6">
                <Controller
                  control={control}
                  name="message"
                  rules={{
                    required: {
                      value: true,
                      message: "Answer is required.",
                    },
                  }}
                  render={({ field, formState, fieldState }) => {
                    return (
                      <TextField
                        autoComplete="off"
                        color="error"
                        {...field}
                        disabled={systemMaintenance?.data?.data?.isEnable}
                        error={!!errors?.message}
                        label="Description"
                        multiline
                        rows={3}
                        fullWidth
                        sx={{
                          marginBottom: "10px",
                        }}
                        helperText={
                          <span className="whitespace-nowrap">
                            {!!errors?.message?.message &&
                              errors?.message?.message}
                          </span>
                        }
                      />
                    );
                  }}
                />
              </div>
              <div className="col-span-2">
                <button
                  className={`cursor-pointer flex items-center justify-center w-full py-2 md:py-3 font-semibold  rounded-md  gap-x-2 ${
                    systemMaintenance?.data?.data?.isEnable
                      ? "text-vermilion_bird bg-pink_lemonade "
                      : "bg-vermilion_bird text-white"
                  } ${isMutating ? "opacity-50 cursor-not-allowed " : ""}`}
                  disabled={isMutating}
                >
                  {isMutating ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: systemMaintenance?.data?.data?.isEnable
                          ? "#F44336"
                          : "#fff",
                      }}
                    />
                  ) : (
                    <>
                      {" "}
                      {systemMaintenance?.data?.data?.isEnable ? (
                        <>
                          <TurnOffRedIcon /> Turn Off
                        </>
                      ) : (
                        <>
                          {" "}
                          <TurnOnWhiteIcon /> Turn On
                        </>
                      )}
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

export default CreateMaintenancePage;
