import { TimeFormatCell } from "../../cell/TimeFormatCell";
import { AmountMMKCell } from "../../cell/AmountMMKCell";
import BetNumbersCell from "../../cell/BetNumbersCell";
import { AvatarCell } from "../../cell/AvatarCell";

export const betListCol = [
  {
    accessorKey: "name", //simple recommended way to define a column
    header: "User",
    Cell:AvatarCell
  },
  {
    accessorKey: "agent_code", //simple recommended way to define a column
    header: "Agent Code",
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.bet_date),
    accessorKey: "bet_date", //simple recommended way to define a column
    header: "Bet Date",
    filterVariant: "date-range",
    Cell: TimeFormatCell,
  },
  {
    accessorKey: "round", //simple recommended way to define a column
    header: "Round",
  },
  {
    accessorKey: "bet_numbers", //simple recommended way to define a column
    header: "Bet Numbers",
    Cell: BetNumbersCell,
  },
  {
    accessorKey: "total_no_of_bet", //simple recommended way to define a column
    header: "Total No. of Bet",
  },
  {
    accessorKey: "bet_amount", //simple recommended way to define a column
    header: "Bet Amount",
    Cell: AmountMMKCell,
  },
  // {
  //   accessorKey: "profile_time", //simple recommended way to define a column
  //   header: "Profit Time",
  // },
];
