import {  useNavigate } from "react-router-dom";
import {
  setAccessToken,
  setPermissionIds,
  setSuperAdmin,
  setUser,
} from "../../../services/storage";
import { login } from "../../auth";
import useSWRMutation from "swr/mutation";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/authContext";

import { toast } from "react-toastify";

export function useLogin() {
  const navigate = useNavigate();
  const { setAuthenticated,  } = useContext(AuthContext);

  return useSWRMutation("/api/admins/auth/login", login, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      setAccessToken(d?.data?.data?.token);
      setUser(JSON.stringify(d?.data?.data?.user));
      if (d.data.data.user?.roleId?.name === "SUPERADMIN") {
        setSuperAdmin(true);
      }
      // let permissiondata = [];
      // for (
      //   let i = 0;
      //   i < d?.data?.data?.user?.permissionIds.length;
      //   i++
      // ) {
      //   permissiondata[i] = d?.data?.data?.user?.permissionIds[i]?.value;
      // }
      setAuthenticated(true);
      setPermissionIds(
        JSON.stringify(d?.data?.data?.user?.permissionIds || [])
      );
      toast.success("Successfully Logined.", {
        position: "bottom-right",
      });
      navigate("/dashboard");
    },
  });
}
