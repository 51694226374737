import moment from "moment";
import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { SettingWhiteIcon } from "../../../pages/common/Icon";

const NoticeCard = ({
  status,
  endDate,
  endTime,
}: {
  status?: boolean;
  endDate?: string;
  endTime?: string;
}) => {
  return (
    <>
      {status ? (
        <div className="flex items-center justify-center w-full px-3 py-3 mb-4 rounded-lg gap-x-3 bg-vermilion_bird">
          <SettingWhiteIcon />

          <span className="block text-[12px] text-white  font-bold">
            The system is under maintenance till{" "}
            {moment(endDate, "DD-MM-YYYY").format("DD MMM YYYY")}{" "}
            {moment(endTime, "hh:mm A").format("hh:mm:ss A")}.
          </span>
        </div>
      ) : (
        <div className="flex w-full px-3 py-3 mb-4 rounded-lg gap-x-3 bg-lynx_white">
          <AiFillInfoCircle className="text-vermilion_bird text-[16px]" />

          <span className="block text-[12px]">
            "Maintenance mode" usually refers to a state where a system or
            product is temporarily taken offline or limited in functionality for
            updates, repairs, or enhancements.
          </span>
        </div>
      )}
    </>
  );
};

export default NoticeCard;
