export function AmountGreenMMKCell({ renderedCellValue, row }: any) {
  return (
    <span
      className={`font-semibold  ${
        renderedCellValue < 0 ? "text-vermilion_bird" : "text-grass_green"
      }`}
    >
      {(+renderedCellValue).toLocaleString()}
      <span className="text-[8px] ml-1 "></span>
    </span>
  );
}
