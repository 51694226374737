import { DevTool } from "@hookform/devtools";
import TextField from "@mui/material/TextField";

import { Controller, useForm } from "react-hook-form";
import { useCreateNotiInApp } from "../api/mutation/notification";
import { CircularProgress } from "@mui/material";

const InAppNotiForm = () => {
  const { trigger, isMutating } = useCreateNotiInApp();

  const { control, handleSubmit, formState, reset } = useForm<{
    title: string;
    message: string;
  }>({
    defaultValues: {
      title: "",
      message: "",
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    trigger({
      title: data?.title,
      text: data?.message,
    });
    reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name="title"
          rules={{
            required: {
              value: true,
              message: "Title is required.",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                {...field}
                error={!!errors?.title}
                label="Title"
                fullWidth
                sx={{
                  marginBottom: "10px",
                }}
                helperText={!!errors?.title?.message && errors?.title?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="message"
          rules={{
            required: {
              value: true,
              message: "Message is required",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                {...field}
                error={!!errors?.message?.message}
                label="Message"
                multiline
                fullWidth
                minRows={2}
                maxRows={4}
                sx={{
                  marginBottom: "10px",
                }}
                helperText={
                  !!errors?.message?.message && errors?.message?.message
                }
              />
            );
          }}
        />

        <div className="flex items-center justify-between w-full">
          <div></div>
          <button
            type="submit"
            className={`btn-create-active ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Create"
            )}
          </button>
        </div>

        <DevTool control={control} />
      </form>
    </div>
  );
};

export default InAppNotiForm;
