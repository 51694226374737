import { useNavigate } from "react-router-dom";

import useSWRMutation from "swr/mutation";
import {  useState } from "react";

import {
  createNotificationEmailApi,
  createNotificationInAppApi,
  createNotificationTelegramApi,
} from "../../notification";
import { useNotificationLists } from "../../funs/notification";
import { RouteName } from "../../../app/constants/route-constant";
import { toast } from "react-toastify";

export function useCreateNotiTelegram() {
  const navigate = useNavigate();
  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useNotificationLists(pagination);

  return useSWRMutation(
    "/api/admins/notifications/telegram",
    createNotificationTelegramApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.notification_list);
      },
    }
  );
}
export function useCreateNotiInApp() {
  const navigate = useNavigate();
  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useNotificationLists(pagination);

  return useSWRMutation(
    "/api/admins/notifications/inApp",
    createNotificationInAppApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.notification_list);
      },
    }
  );
}
export function useCreateEmailApp() {
  const navigate = useNavigate();
  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useNotificationLists(pagination);

  return useSWRMutation(
    "/api/admins/notifications/email",
    createNotificationEmailApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.notification_list);
      },
    }
  );
}
