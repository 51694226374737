import useSWR from "swr";
import { API_NAME } from "../../constant";
import {
  getNotificationByIdApi,
  getNotificationForEmailApi,
  getNotificationListApi,
} from "../../notification";

export const useNotificationLists = ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  return useSWR(
    [API_NAME.notification_lists_api, pageIndex, pageSize],
    () => getNotificationListApi({ pageIndex, pageSize }),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
      // revalidateOnMount: false,
      // revalidateOnReconnect: false,
      // refreshWhenOffline: false,
      // refreshWhenHidden: false,
      // refreshInterval: 0,
    }
  );
};

export const useNotificationById = (id: string | null) => {
  return useSWR(
    `${API_NAME.notifications_by_id_api} ${id}`,
    () => getNotificationByIdApi(id)
    // getDashboardOverviewListApi,
    // {
    //   revalidateOnFocus: false,
    //   revalidateOnMount: false,
    //   revalidateOnReconnect: false,
    //   refreshWhenOffline: false,
    //   refreshWhenHidden: false,
    //   refreshInterval: 0,
    // }
  );
};
export const useNotificationForEmail = () => {
  return useSWR(
    `${API_NAME.notifications_for_email_api}`,
    getNotificationForEmailApi
    // getDashboardOverviewListApi,
    // {
    //   revalidateOnFocus: false,
    //   revalidateOnMount: false,
    //   revalidateOnReconnect: false,
    //   refreshWhenOffline: false,
    //   refreshWhenHidden: false,
    //   refreshInterval: 0,
    // }
  );
};
