import React, { useEffect, useMemo } from "react";

import { useAtom } from "jotai";
import { filterUserAtom, paginationUserAtom } from "../../store";
import CustomizeTable from "../../table/CustomizeTable";
import { userTableCol } from "../../table/columns/user";
import { useGetUserList } from "../../api/funs/user";

const UserList: React.FC = () => {
  const [globalFilter, setGlobalFilter] = useAtom(filterUserAtom);
  const [pagination, setPagination] = useAtom(paginationUserAtom);

  const {
    data: UserLists,
    isLoading,
    error,
  } = useGetUserList(pagination, globalFilter);

  useEffect(() => {
    setGlobalFilter("");
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [setGlobalFilter,setPagination]);

  const userMemorized = useMemo(() => {
    return UserLists?.data?.data?.map((d: any) => ({
      _id: d?._id,
      user_code: d?.userCode,
      name: d?.name,
      phone: d?.phone,
      imageUrl: d?.imageUrl,
      status: d?.status,
      agent_code: d?.agentId?.userCode,
      reg_date: d?.createdAt,
      last_login_date: d?.lastLogin,
      amount: "400000",
    }));
  }, [UserLists]);

  const mermorizedCol = useMemo(() => {
    return userTableCol;
  }, []);

  // const memorizedCol = useMemo<MRT_ColumnDef<any>[]>(() => userTableCol || [], []);

  return (
    <div className="w-full">
      <CustomizeTable
        column={mermorizedCol}
        data={userMemorized}
        totalCount={UserLists?.data?.totalCounts}
        isError={error}
        isRefetching={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        showGlobalFilter={true}
      />
    </div>
  );
};

export default UserList;
