import axios from "axios";
import { API_DOMAIN } from "../../config";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;

export const login = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      userCode: string;
      password: string;
      ipAddress?: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axios.post(`${axios_url}`, arg);
};
export const getIpAddressApi = async () => {
  return axios.get(`https://api.ipify.org?format=json`);
};
