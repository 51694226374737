import React, { useState, useMemo } from "react";

import CustomizeTable from "../../table/CustomizeTable";
import { privacyPolicyCol } from "../../table/columns/settings/privacyPolicyCol";
import { useGetPolicesCondition } from "../../api/funs/polices";

const List: React.FC = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  // const { permissionIds } = useContext(AuthContext);
  // const [totalCount, setTotalCount] = useState<number>(0);
  // const [selectCount, setSelectCount] = useState<number>(0);
  // const [pageLimit, setpageLimit] = useState<number>(10);
  // const headerdata = [
  //   {
  //     name: "Content",
  //   },
  //   {
  //     name: "",
  //   },
  // ];
  // const [bodydata, setBodydata] = useState<any[]>([]);
  // const [message, setMessage] = useState("");
  // const [id, setId] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const navigate = useNavigate();

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setMessage("");
  //   setId("");
  // };

  // const fetchtermData = async (pageCount: number, pageLimitAmount: number) => {
  //   pageCount = pageCount + 1;
  //   //let filter='?';
  //   //let pagenumber= pageCount?filter+'pageSize='+pageLimitAmount+'&pageNumber='+pageCount:filter;
  //   fetchData({
  //     url: "api/admins/privacies/lists",
  //     method: "get",
  //   })
  //     .then((res) => {
  //       if (res.data.isSuccess) {
  //         setBodydata(res?.data?.data);
  //         setTotalCount(res?.data?.data.length);
  //       }
  //     })
  //     .catch((err) => {})
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };
  // // Invoke when user click to request another page.
  // const handlePageClick = (pageNumber: number, pageLimitAmount: number) => {
  //   setIsLoading(true);
  //   setSelectCount(pageNumber);
  //   setpageLimit(pageLimitAmount);
  //   fetchtermData(pageNumber, pageLimitAmount);
  // };
  // useEffect(() => {
  //   fetchtermData(0, 10);
  // }, []);

  // const deleteConfirm = (id: string) => {
  //   setId(id);
  //   setMessage("Are you sure you want to delete?");
  //   setIsModalOpen(true);
  // };

  // const deleteClick = () => {
  //   fetchData({
  //     url: "api/admins/privacies/" + id,
  //     method: "delete",
  //   })
  //     .then((res) => {
  //       if (res.data.isSuccess) {
  //         setId("");
  //         setMessage(res.data.message);
  //         setIsModalOpen(false);
  //         fetchtermData(0, 10);
  //       } else {
  //         setId("");
  //         setMessage(res.data.message);
  //       }
  //     })
  //     .catch((err) => {})
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: polices, error, isLoading } = useGetPolicesCondition();

  const termsConditionMemorizedData = useMemo(() => {
    return polices?.data?.data?.map((d: any, i: number) => ({
      language:
        d?.language === "en"
          ? "English"
          : d?.language === "zh"
          ? "Chinese"
          : "Myanmar",
      lang: d?.language,

      // sr_no: `${pagination.pageIndex}${i}`,
      content: d?.policy,
      date: d?.createdAt,
      description: "anything",
    }));
  }, [polices]);

  return (
    <>
      <CustomizeTable
        column={privacyPolicyCol}
        data={termsConditionMemorizedData || []}
        totalCount={termsConditionMemorizedData?.length}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </>
  );
};

export default List;
