import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { RouteName } from "../../app/constants/route-constant";

const TitleTotalList = ({ total }: { total: number }) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const textType =
    pathname === RouteName.notification_list
      ? "Notifications"
      : pathname === RouteName.users_userlist
      ? "Users"
      : pathname === RouteName.admins_list
      ? "Admins"
      : pathname === RouteName.agents_list
      ? "Agents"
      : pathname === RouteName.twod_betlist
      ? "Bettings"
      : [
          RouteName.threed_betlist,
          `/twod/betlist/user/${id}`,
        ].includes(pathname)
      ? "Bettings"
      : [RouteName.twod_roundsetting,RouteName.three_roundsetting].includes(pathname)
      ? "Rounds"
      : [RouteName.setting_closedays_list].includes(pathname)
      ? "Close Days"
      : [RouteName.setting_termconditions_list].includes(pathname)
      ? "Term Conditions"
      : [RouteName.setting_privaypolicy_list].includes(pathname)
      ? "Privacy Policys"
      : [RouteName.setting_faqs_list].includes(pathname)
      ? "FAQ"
      : [RouteName.report_twod_daily_report,RouteName.report_twod_monthly_report,RouteName.report_twod_annual_report,RouteName.report_threed_monthly_report,RouteName.report_threed_annual_report].includes(pathname)
      ? "reports"
      : "";

  return (
    <p className="pt-2 whitespace-nowrap text-sm md:text-[16px] pl-2">
      Total of {total || "--"} {textType}
    </p>
  );
};

export default TitleTotalList;
