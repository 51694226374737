import React from "react";
import { LogoIcon } from "../../../pages/common/Icon";

interface Props {
  header: string;
  children: React.ReactNode;
  className?: string;
}

function AgentCredentialsCard({ children, header, className }: Props) {
  return (
    <div className="w-full overflow-hidden border-t-[1px] rounded-lg border-b-[1px] border-b-gray-500">
      <div className="flex h-[180px] w-full items-center justify-between bg-vermilion_bird px-4   text-white ">
        <div>
          <div className="text-2xl capitalize">All in one</div>
          <div className="text-5xl capitalize">{header} </div>
        </div>
        <LogoIcon className="w-[150px] h-[150px]" />
      </div>
      <div>{children}</div>
    </div>
  );
}

export default AgentCredentialsCard;
