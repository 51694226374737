import { useNavigate } from "react-router-dom";

import useSWRMutation from "swr/mutation";

import { RouteName } from "../../../app/constants/route-constant";
import { useGetHolidayList } from "../../funs/holidays";
import {
  createHolidayDayApi,
  deleteHolidayOneApi,
  updateHolidayDayApi,
} from "../../holidays";
import { toast } from "react-toastify";

export function useCreateHolidayDay() {
  const navigate = useNavigate();

  const { mutate } = useGetHolidayList();

  return useSWRMutation("/api/admins/holidays", createHolidayDayApi, {
    throwOnError: false,
    onError: () => {toast.error("Something wrong.", {
      position: "bottom-right",
    });},
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.setting_closedays_list);
    },
  });
}
export function useUpdateHolidayDay(id?: string) {
  const navigate = useNavigate();

  const { mutate } = useGetHolidayList();

  return useSWRMutation(
    id ? `/api/admins/holidays/${id}` : null,
    updateHolidayDayApi,
    {
      throwOnError: false,
      onError: () => {},
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.setting_closedays_list);
      },
    }
  );
}
export function useDeleteHolidayOne() {
  const { mutate } = useGetHolidayList();

  return useSWRMutation(`/api/admins/holidays`, deleteHolidayOneApi, {
    throwOnError: false,
    onError: () => {toast.error("Something wrong.", {
      position: "bottom-right",
    });},
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}
