import ReactHtmlParser from "react-html-parser";
import { decode } from "html-entities";
import { useState } from "react";

export function RenderHtmlCell({ renderedCellValue, row }: any) {
  const decodedHtml = decode(renderedCellValue);
  const [seeMoreShow, setSeeMoreShow] = useState(true);

  return (
    <>
      <div className={seeMoreShow ? "truncate-content" : ""}>
        {" "}
        {ReactHtmlParser(decodedHtml)}
      </div>

      {seeMoreShow ? (
        <span
          onClick={() => {
            setSeeMoreShow(false);
          }}
          className="text-vermilion_bird"
        >
          ... More
        </span>
      ) : (
        <span
          onClick={() => {
            setSeeMoreShow(true);
          }}
          className="text-vermilion_bird"
        >
          {" "}
          ... less
        </span>
      )}
    </>
  );
}
