import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const createAdsBannerApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: any;
  }
) => {
  // const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${url}${agUrl}`, arg, {
    headers: { "Content-type": "multipart/form-data" },
  });
};
export const getAdsBannersApi = async () => {
  // const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.get(`${url}/api/admins/banners/lists`);
};

export const deleteAdsBannerOneApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      id: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}/${arg.id}`);

  return axiosInstance.delete(`${axios_url}`);
};
