export function PRoundCell({ renderedCellValue, row }: any) {
  return (
    <div className="flex gap-x-2">
      {(!row?.original?.is2DAvailable ||
        (!row?.original?.isRound1Available &&
          !row?.original?.isRound2Available &&
          !row?.original?.isRound3Available &&
          !row?.original?.isRound4Available)) &&
        "---"}

      {row?.original?.is2DAvailable && row?.original?.isRound1Available && (
        <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
          R1
        </div>
      )}
      {row?.original?.is2DAvailable && row?.original?.isRound2Available && (
        <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
          R2
        </div>
      )}
      {row?.original?.is2DAvailable && row?.original?.isRound3Available && (
        <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
          R3
        </div>
      )}
      {row?.original?.is2DAvailable && row?.original?.isRound4Available && (
        <div className="inline-block px-1 py-1 rounded-md bg-goldenrod text-dynamic_black">
          R4
        </div>
      )}
      {/* {renderedCellValue?.length === 0 ? (
        "---"
      ) : (
        <div className="grid grid-cols-1 gap-x-2 gap-y-1">
          {renderedCellValue?.map((_e: any, i: number) => (
            <div
              key={i}
              className="inline-block py-1 mb-1 text-center rounded-md text-dynamic_black bg-goldenrod "
            >
              {_e}
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}
