import { useAtom } from "jotai";
import React from "react";
import { menuAtom } from "../../../store";
import UserDetailOverview from "./UserDetailOverview";
import BetHistory from "./BetHistory";
import UserOverview from "./UserOverview";
import WinningTransactionHistory from "./WinningTransactionHistory";
import { useProfileInfo } from "../../../api/funs/profile";
import { permissionValidate } from "../../../util/funs";
import { CONTENT } from "../../../content";

const UserDetailMenuItem = () => {
  const [menuIndex] = useAtom(menuAtom);
  const { data: profileInfo } = useProfileInfo();

  return (
    <div>
      {menuIndex === 0 &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.USER_DETAIL_INFORMATION
      ) ? (
        <UserDetailOverview />
      ) : menuIndex === 2 &&
        permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.USER_DETAIL_WINNING_HISTORY
        ) ? (
        <WinningTransactionHistory />
      ) : menuIndex === 1 &&
        permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.USER_DETAIL_BET_HISTORY
        ) ? (
        <BetHistory />
      ) : menuIndex === 3 &&
        permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.USER_DETAIL_OVERVIEW
        ) ? (
        <UserOverview />
      ) : (
        ""
      )}
    </div>
  );
};

export default UserDetailMenuItem;
