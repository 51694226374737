import React from 'react'
import AgentCredentialsCard from './AgentCredentialsCard'
import InfoCard from './InfoCard'
import { decryptHashedData } from '../../../util/funs'

const AgentCredentialShowCard = ({agentInfo}:any) => {
  return (
    <AgentCredentialsCard header="Agent Credentials" className="px-0">
      <InfoCard label="Name" info={ agentInfo?.name} />
    <InfoCard label="Password" info={  agentInfo?.encryptPassword && decryptHashedData(
          agentInfo?.encryptPassword,
          "AIO_PASSWORD_ENCRYPT"
        )} />
    <InfoCard
      label="Agent Code"
      info={ agentInfo?.userCode}
    />
    <InfoCard
      label="Secret Code"
      info={agentInfo?.secretCode}
    />
  </AgentCredentialsCard>
  )
}

export default AgentCredentialShowCard