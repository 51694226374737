import { useNavigate, useParams } from "react-router-dom";

import useSWRMutation from "swr/mutation";
import { useAtom } from "jotai";
import {
  filterAdminAtom,
  filterAgentAtom,
  filterUserAtom,
  modalAtom,
  paginatioAgentBetTabDAtom,
  paginatioAgentDateTabDAtom,
  paginatioAgentInvoiceDAtom,
  paginationAdminAtom,
  paginationAgentAtom,
  paginationUserAtom,
} from "../../../store";

import { RouteName } from "../../../app/constants/route-constant";

import { useGetAdminDetail, useGetAdminList } from "../../funs/admin";
import {
  useGetAgentDetail,
  useGetAgentInvoice,
  useGetAgentList,
} from "../../funs/agent";
import {
  createAgentApi,
  patchInvoiceStatusAgentApi,
  patchStatusAgentApi,
  updateAgentApi,
} from "../../agent";
import { useGetUserById, useGetUserList } from "../../funs/user";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";

export function useCreateAgent() {



  return useSWRMutation("/api/admins/agents/register", createAgentApi, {
    throwOnError: false
  });
}

export function usePatchUserStatus(id?: string, type?: string) {
  const [paginationUser] = useAtom(paginationUserAtom);
  const [globalFilter] = useAtom(filterUserAtom);
  const { mutate: mutateUser } = useGetUserList(paginationUser, globalFilter);
  const { mutate: mutateDetailUser } = useGetUserById(id || "0");

  const [, setIsOpenModal] = useAtom(modalAtom);

  const mutateUrl = `/api/admins/${type}/${id}/status-change`;

  return useSWRMutation(id || type ? mutateUrl : null, patchStatusAgentApi, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });

      mutateUser();
      mutateDetailUser();

      setIsOpenModal({ isOpen: false });
    },
  });
}
export function usePatchAgentStatus(id?: string, type?: string) {
  const [pagination] = useAtom(paginationAgentAtom);
  const [globalFilter] = useAtom(filterAgentAtom);

  const { mutate } = useGetAgentList(pagination, globalFilter);
  const { mutate: mutateDetailAgent } = useGetAgentDetail(id as string);

  const [, setIsOpenModal] = useAtom(modalAtom);

  const mutateUrl = `/api/admins/${type}/${id}/status-change`;

  return useSWRMutation(id || type ? mutateUrl : null, patchStatusAgentApi, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });

      mutate();
      mutateDetailAgent();

      setIsOpenModal({ isOpen: false });
    },
  });
}
export function usePatchAdminStatus(id?: string, type?: string) {
  const [paginationAdmin] = useAtom(paginationAdminAtom);
  const [globalFilter] = useAtom(filterAdminAtom);
  const { mutate: mutateAdmin } = useGetAdminList(
    paginationAdmin,
    globalFilter
  );
  const { mutate: mutateDetailAdmin } = useGetAdminDetail(id as string);
  const [, setIsOpenModal] = useAtom(modalAtom);

  const mutateUrl = `/api/${type}/${id}/status-change`;

  return useSWRMutation(id || type ? mutateUrl : null, patchStatusAgentApi, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });

      mutateAdmin();
      mutateDetailAdmin();

      setIsOpenModal({ isOpen: false });
    },
  });
}

export function useUpdateAgent(id: string) {
  const navigate = useNavigate();

  const [pagination] = useAtom(paginationAgentAtom);

  const { mutate } = useGetAgentList(pagination, "");

  return useSWRMutation(`/api/admins/agents/${id}`, updateAgentApi, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      // setIsOpenModal({
      //   isOpen: true,
      //   showData: {
      //     type: "credential",
      //     data: d?.data?.data,
      //   },
      // });
      toast.success("Successfully Updated.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.agents_list);
    },
  });
}

export function useUpdateAgentInvoiceStatus(invoiceId?: string) {
  const [pagination] = useAtom(paginatioAgentInvoiceDAtom);
  const {id}=useParams()

  const [globalFilter] = useState("");
  const [betTabIndex] = useAtom(paginatioAgentBetTabDAtom);
  const [date] = useAtom(paginatioAgentDateTabDAtom);

  const { mutate } = useGetAgentInvoice(
    pagination,
    globalFilter,
    betTabIndex === 0 ? "2d-invoice" : "3d-invoice",
    id as string,
    date ? `${dayjs(date).format("MMMM")}` : `${moment().format("MMMM")}`
  );

  const typeBet = betTabIndex === 0 ? "twod" : "threed";

  const mutateUrl = `/api/admins/invoices/${typeBet}/${invoiceId}/status-change`;

  return useSWRMutation(id ? mutateUrl : null, patchInvoiceStatusAgentApi, {
    throwOnError: false,
    onError: (e) => {
      toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Updated.", {
        position: "bottom-right",
      });

      mutate();
    },
  });
}

