import { atom } from "jotai";
// Standard interface and functions
export interface Todo {
  id: number;
  text: string;
  done: boolean;
}
export const todosAtom = atom<Todo[]>([
  {
    id: 1,
    text: "react",
    done: true,
  },
]);

export const menuAtom = atom<number>(0);
export const betTabAtom = atom<number>(0);
export const modalAtom = atom<{
  isOpen: boolean;
  showData?: null | any;
}>({ isOpen: false, showData: null });
export const roundInfoAtom = atom<{ value?: string; label?: string }>({
  value: "",
  label: "",
});
export const userDetailAtom = atom<any | null>(null);

export const blockVerifiedAtom = atom<{
  type?: string;
  id?: string;
  round_id?: string;
}>({
  type: "",
  id: "",
});

export const roundAtom = atom<{
  label: "";
  value: "";
}>({
  label: "",
  value: "",
});

export const paginationAgentAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const filterAgentAtom = atom<string>("");
export const filterUserAtom = atom<string>("");
export const filterAdminAtom = atom<string>("");
export const paginationAdminAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const paginationAdsBannerAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 100,
});
export const paginationUserAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const paginationBetHistoryTwoDAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const paginationBetHistoryThreeDAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const paginatioAgentInvoiceDAtom = atom<{
  pageIndex: number;
  pageSize: number;
}>({
  pageIndex: 0,
  pageSize: 10,
});
export const paginatioAgentBetTabDAtom = atom<number>(0);
export const paginatioAgentDateTabDAtom = atom<any>(null);
