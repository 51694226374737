import { ImageCell } from "../../cell/ImageCell";
import { TimeFormatCell } from "../../cell/TimeFormatCell";
export const appAdsCol = [
  {
    accessorKey: "image", //simple recommended way to define a column
    header: "Image",
    Cell: ImageCell,
  },
  
  {
    accessorKey: "redirect_link", //simple recommended way to define a column
    header: "Redirect Link",
  },
  {
    accessorKey: "uploaded_date", //simple recommended way to define a column
    header: "Updated Date",
    Cell:TimeFormatCell
  },
  // {
  //   accessorKey: "updated_by", //simple recommended way to define a column
  //   header: "Updated By",
  // },
];
