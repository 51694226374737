import React from "react";
import LatestResultWinningCard from "./LatestResultWinningCard";
import LatestResultWinningDetailCard from "./LatestResultWinningDetailCard";
import {
  useGetPreviousResultList,
  useGetRoundList,
} from "../../../api/funs/rounds";
import moment from "moment";

const LatestBetCard = () => {
  const { data: roundsList } = useGetRoundList("2d");

  const currentTime = moment().format("HH:mm");

  const previousResultRound =
    roundsList?.data?.data?.[1]?.endTime > currentTime
      ? roundsList?.data?.data?.[0]
      : roundsList?.data?.data?.[2]?.endTime > currentTime
      ? roundsList?.data?.data?.[1]
      : roundsList?.data?.data?.[3]?.endTime > currentTime
      ? roundsList?.data?.data?.[2]
      : roundsList?.data?.data?.[3];

  const { data: previousResult } = useGetPreviousResultList(
    "two-d",

    "",
    ""
  );

  const winningResult = previousResult?.data?.data?.[0];
  return (
    <div className="md:flex justify-between items-center w-full md:h-[364px] py-6 md:py-0  pr-5 mt-3 bg-white bg-center bg-no-repeat bg-cover rounded-md bg-dashboard_latest_result">
      <LatestResultWinningCard
        winningResult={winningResult}
        previousResultRound={previousResultRound}
      />

      {previousResult?.data?.data?.length > 0 && (
        <LatestResultWinningDetailCard
          resultId={winningResult?._id}
          winningNumber={winningResult?.winningNumber}
          set={winningResult?.set}
          value={winningResult?.value}
          resultDate={winningResult?.resultDate}
          roundType={previousResultRound?.roundName}
        />
      )}
    </div>
  );
};

export default LatestBetCard;
