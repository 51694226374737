import DateFormatCell from "../../cell/DateFormatCell";

export const betThreeDRoundCol = [
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.end_date),
    accessorKey: "end_date", //simple recommended way to define a column
    header: "End Date",
    filterVariant: "date-range",
    Cell: DateFormatCell,
  },
  {
    accessorKey: "bet_close_time", //simple recommended way to define a column
    header: "Bet Close Time",
  },
  {
    accessorKey: "result_announce_time", //simple recommended way to define a column
    header: "R.A Time",
  },
  {
    accessorKey: "extra_result_announce_time", //simple recommended way to define a column
    header: "Extra R.A Time",
  },
];
