import React, { useState, useMemo } from "react";

import CustomizeTable from "../../table/CustomizeTable";
import { betRoundCol } from "../../table/columns/bet/betRound";
import { useGetRoundList } from "../../api/funs/rounds";
import moment from "moment";

const RoundSetting: React.FC = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: roundsList, error, isLoading } = useGetRoundList("2d");
  const historyData = useMemo(() => {
    return roundsList?.data?.data?.map((d: any) => ({
      _id: d?._id,
      date: new Date(),
      status: d?.status,
      round: d?.roundName,
      bet_open_time: moment(moment(d?.openTime, "HH:mm")).format("hh:mm A"),
      bet_close_time: moment(moment(d?.endTime, "HH:mm")).format("hh:mm A"),
      result_announce_time: moment(moment(d?.announceTime, "HH:mm")).format(
        "hh:mm A"
      ),
      extra_result_announce_time: moment(
        moment(d?.untilEndTime, "HH:mm")
      ).format("hh:mm A"),
    }));
  }, [roundsList]);

  return (
    <CustomizeTable
      column={betRoundCol}
      data={historyData}
      totalCount={historyData?.length}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

export default RoundSetting;
