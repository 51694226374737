import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CalendarIcon } from "../../pages/common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DevTool } from "@hookform/devtools";
import {
  useCreateHolidayDay,
  useUpdateHolidayDay,
} from "../../api/mutation/holidays";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetHolidayById } from "../../api/funs/holidays";

const HolidayCreate: React.FC = () => {
  // const [name, setName] = useState("");
  // const [message, setMessage] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(new Date());

  const { id } = useParams();

  const { data: holidayDetail } = useGetHolidayById(id);
  const navigate = useNavigate();
  // const [errorArray, setErrorArray] = useState<any[]>([]);

  // const handleDateChange = (date: Date) => {
  //   setSelectedDate(date);
  // };
  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   fetchData({
  //     url: "api/admins/holidays",
  //     data: {
  //       holidayName: name,
  //       date: selectedDate,
  //     },
  //     method: "post",
  //   })
  //     .then((res) => {
  //       if (res.data.isSuccess) {
  //         setMessage(res.data.message);
  //         navigate("/setting/closedays/list");
  //       } else {
  //         setMessage(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       setErrorArray(err.response.data.errors);
  //     })
  //     .finally(() => {});
  // };
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };
  const { trigger: updateTrigger, isMutating: isMutatingUpdate } =
    useUpdateHolidayDay(id);
  const { trigger: createTrigger, isMutating: isMutatingCreate } =
    useCreateHolidayDay();

  const { control, handleSubmit, formState, reset } = useForm<{
    holiday_name: string;
    holiday_date: Dayjs | null;
  }>({
    values: {
      holiday_name: holidayDetail?.data?.data?.holidayName,
      holiday_date:
        holidayDetail?.data?.data?.date &&
        dayjs(holidayDetail?.data?.data?.date),
    },
    defaultValues: {
      holiday_name: "",
      holiday_date: null,
    },
  });
  const { errors } = formState;
  const onSubmit = (data: any) => {
    if (id) {
      updateTrigger({
        date: format(
          dayjs(data.holiday_date, "YYYY-MM-DD").toDate(),
          "yyyy-MM-dd"
        ),
        holidayName: data?.holiday_name,
      });
    } else {
      createTrigger({
        date: format(
          dayjs(data.holiday_date, "YYYY-MM-DD").toDate(),
          "yyyy-MM-dd"
        ),
        holidayName: data?.holiday_name,
      });
    }

    reset();
  };

  return (
    <>
      <div className="pl-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.setting_closedays_list)}
        />
      </div>

      <form
        className="w-[60%] pl-5"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex mb-4 gap-x-10">
          <Controller
            control={control}
            name="holiday_name"
            rules={{
              required: {
                value: true,
                message: "Holiday name is required.",
              },
            }}
            render={({ field, formState, fieldState }) => {
              return (
                <TextField
                  autoComplete="off"
                  color="error"
                  size="small"
                  {...field}
                  error={!!errors?.holiday_name}
                  label="Holiday_name"
                  fullWidth
                  sx={{
                    marginBottom: "10px",
                  }}
                  helperText={
                    <span className="whitespace-nowrap">
                      {!!errors?.holiday_name?.message &&
                        errors?.holiday_name?.message}
                    </span>
                  }
                />
              );
            }}
          />
          <Controller
            control={control}
            name="holiday_date"
            rules={{
              required: {
                value: true,
                message: "Holiday Date is required",
              },
            }}
            render={({ field, formState, fieldState }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Select Date"
                    {...field}
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",

                        helperText:
                          !!errors?.holiday_name?.message &&
                          errors?.holiday_name?.message,
                        error: !!errors?.holiday_name?.message,
                      },
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                    format="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              );
            }}
          />
        </div>
        <div className="flex items-center justify-between w-full">
          <div></div>
          <button
            type="submit"
            className={`btn-create-active ${
              isMutatingUpdate || isMutatingCreate
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            disabled={isMutatingUpdate || isMutatingCreate}
          >
            {isMutatingUpdate || isMutatingCreate ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              <>{id ? "Update" : "Create"}</>
            )}
          </button>
        </div>
        <DevTool control={control} />
      </form>
    </>
  );
};

export default HolidayCreate;
