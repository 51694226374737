import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AddCircle1Icon } from "../common/Icon";
import CustomizeTable from "../../table/CustomizeTable";
import { faqCol } from "../../table/columns/settings/faqCol";
import { useGetFaqList } from "../../api/funs/faq";
import { RouteName } from "../../app/constants/route-constant";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const List: React.FC = () => {
  const navigate = useNavigate();
  const { data: profileInfo } = useProfileInfo();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const { data: faqList, isLoading, error } = useGetFaqList(pagination);

  const faqData = useMemo(() => {
    return faqList?.data?.data?.faqs?.data?.map((d: any, i: number) => ({
      question: d?.question,
      _id: d?._id,
      // sr_no: `${pagination.pageIndex}${i}`,
      answer: d?.answer,
      language:
        d?.language === "en"
          ? "English"
          : d?.language === "mm"
          ? "Myanmar"
          : "Chinese",
      date: d?.createdAt,
    }));
  }, [faqList]);
  return (
    <>
      <div className="inline-block mb-3 gap-x-4">
        {permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.SETTING_FAQS_CREATE
        ) && (
          <div
            onClick={() => {
              navigate(RouteName.setting_faqs_create);
            }}
            className="flex items-center btn-create"
          >
            <AddCircle1Icon width={18} height={18} />
            <span className="p-l-5">Create</span>
          </div>
        )}
      </div>
      <CustomizeTable
        column={faqCol}
        data={faqData || []}
        totalCount={faqData?.length}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </>
  );
};

export default List;
