import { useAtom } from "jotai";
import { modalAtom } from "../../../../store";
import { ReleaseAlertIcon } from "../../../../pages/common/Icon";

import { useNavigate } from "react-router-dom";

const TokenExpiredModalContent = () => {
  const [, setIsOpenModal] = useAtom(modalAtom);

  const navigate = useNavigate();

  const handleClick = () => {
    setIsOpenModal({ isOpen: false });
    localStorage.removeItem("token_expired");

    navigate("/login");

    localStorage.removeItem("access_token");
    localStorage.removeItem("permission");
    localStorage.removeItem("super_admin");
    localStorage.removeItem("user");
  };

  return (
    <div className="relative  px-3 py-3 bg-white rounded-md w-[300px] md:w-[500px]">
      <div className="flex items-center justify-center mt-6 mb-3">
        <ReleaseAlertIcon />
      </div>

      <span className="block font-bold text-center">Token Expired!</span>
      <span className="block mt-2 mb-4 text-center text-[12px]">
        It seems your token has expired. Please login again to use this account.
      </span>
      <div className="grid grid-cols-1 place-items-center gap-x-5">
        <span
          className={`w-full bg-vermilion_bird  text-white rounded-[10px] text-center py-2 cursor-pointer font-bold`}
          onClick={handleClick}
        >
          Click to Login
        </span>
      </div>
    </div>
  );
};

export default TokenExpiredModalContent;
