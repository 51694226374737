import React from "react";
import {
  ArrowRightIcon,

} from "../common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import { useGetPreviousWinnersThreeDList } from "../../api/funs/rounds";
import { getPreviousResult } from "../../services/storage";
import SpinnerLoading from "../common/SpinnerLoading";

const ThreeDPreviousWinnerListPage = () => {
  const navigate = useNavigate();
  const winningValue = getPreviousResult();
  const { data: PreviousWinnings, isLoading } = useGetPreviousWinnersThreeDList(
    "three-d",
    JSON.parse(winningValue as any)?.resultId as string,
    0,
    100,
    JSON.parse(winningValue as any)?.tootDaeType as string
  );



  return (
    <>
      <BackArrowButton
        handleClick={() => navigate(RouteName.threed_previousreslts)}
      />
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="bg-white rounded-[10px] px-1 md:px-3 py-2 mt-3">
          <div className="md:flex gap-x-10">
            <div className="md:w-[299px] h-full py-3 bg-center bg-no-repeat bg-cover bg-round_image rounded-[10px] flex flex-col justify-center items-center mb-3 md:mb-0">
              <span className="text-[12px]">Result</span>
              <span className="text-[50px] font-bold -mt-3 mb-2">
                {JSON.parse(winningValue as any)?.winningNumber}
              </span>
              <span className="block">
                {JSON.parse(winningValue as any)?.tootNumbers?.map(
                  (_r: any, i: any) => (
                    <span
                      className="text-[30px] font-bold -mt-3 mb-2 block"
                      key={i}
                    >
                      {_r}
                    </span>
                  )
                )}
              </span>
            </div>
            <div className="flex flex-col items-start justify-start flex-auto">
              <span className="block mb-2">
                Total of{" "}
                {PreviousWinnings?.data?.data?.threedWinnerLists?.length}{" "}
                Winners
              </span>
              <table className="w-full text-dim_grey">
                <tbody>
                  {PreviousWinnings?.data?.data?.threedWinnerLists?.map(
                    (_d: any, i: number) => (
                      <tr
                        className="border-dashed cursor-pointer border-steam border-y"
                        onClick={() => {
                          navigate({
                            pathname: `/users/detail/${_d?.userId?._id}/winning/${_d?.threedResultId}`,
                            search: `?type=threed&tdType=${
                              (JSON.parse(winningValue as any)
                                ?.tootDaeType as string) === "box-winners"
                                ? "box"
                                : "straight"
                            }`,
                          });
                        }}
                      >
                        <td className="py-3">
                          {i < 3 && (
                           <span className="px-1 md:px-2 md:py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                           {i + 1}
                         </span>
                          )}
                        </td>
                        <td>
                            {" "}
                            <div className="flex items-center">
                              {/* <img
                        className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}

                              <div>
                                <div className=" w-16 text-[10px] md:text-[14px] font-medium  text-dynamic_black">
                                  {_d?.userId?.name}
                                </div>
                                <div className="text-[8px] md:text-[12px] text-dim_grey">
                                  {_d?.userId?.phone}{" "}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Agent Code <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.agentCode}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Bet <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.totalBetAmount?.toLocaleString()}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px] block">Won</span>
                            <span className="text-[12px] md:text-[16px] text-grass_green">
                              {_d?.totalWinningAmount?.toLocaleString()}
                            </span>
                          </td>
                        {/* <td>
                          <span className="text-[12px] block">Status</span>
                          {_d?.status === "UNCLAIMED" ? (
                            <div className="flex items-center">
                              <CheckCircleBrownIcon />
                              <span className=" text-[14px] ml-1 text-dim_grey">
                                UnClaimed
                              </span>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <CheckCircleIcon />
                              <span className=" text-[14px] ml-1 text-grass_green">
                                Claimed
                              </span>
                            </div>
                          )}
                        </td> */}
                        <td>
                          <ArrowRightIcon />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              <div className="flex justify-between w-full mt-4">
                  <p className="text-grass_green text-[12px] md:text-[14px]">Total Won</p>
                  <p className="text-grass_green font-bold text-[14px] md:text-[16px]">
                    {PreviousWinnings
                      ? PreviousWinnings?.data?.data?.totalAmount?.toLocaleString()
                      : "--"}{" "}
                    <span className="text-[14px] font-normal"></span>
                  </p>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThreeDPreviousWinnerListPage;
