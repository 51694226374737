import useSWR from "swr";

import { getAppAdsApi } from "../../app-ads";

export const useGetAppAdsList = () => {
  return useSWR(
    ["get-app-ads"],
    () => getAppAdsApi(),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
