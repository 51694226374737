import { useAtom } from "jotai";
import { blockVerifiedAtom, modalAtom } from "../../../../store";
import { FiX } from "react-icons/fi";
import {
  BlockCircleModalIcon,
  CheckCircleModalIcon,
} from "../../../../pages/common/Icon";

import { usePatchRoundStatus } from "../../../../api/mutation/rounds";
import { CircularProgress } from "@mui/material";

const RoundActiveUnActiveModalContent = () => {
  const [blockVerified] = useAtom(blockVerifiedAtom);
  const [, setIsOpenModal] = useAtom(modalAtom);

  let { trigger: RoundStatusTrigger, isMutating: RoundStatusIsMutating } =
    usePatchRoundStatus(blockVerified?.id);

  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };
  const isBlock = blockVerified.type === "block";
  const handleClick = () => {
    RoundStatusTrigger({
      status: isBlock ? false : true,
    });
  };

  const show = {
    title: `${isBlock ? "Close" : "Open"} this round`,
    desc: ` Are you sure you want to ${isBlock ? "close" : "open"} ? After ${
      isBlock ? "close" : "open"
    }, this round will not be available to use or provide any services of your application.`,
    confirmActionName: `${isBlock ? "Close" : "Open"}`,
  };
  return (
    <div className="relative  px-3 py-3 bg-white rounded-md w-[300px] md:w-[500px]">
      <FiX className="absolute right-5 top-5" onClick={handleClose} />
      <div className="flex items-center justify-center mt-6 mb-3">
        {isBlock ? (
          <BlockCircleModalIcon className="flex self-center " />
        ) : (
          <CheckCircleModalIcon className="flex self-center " />
        )}
      </div>

      <span className="block font-bold text-center">{show.title}</span>
      <span className="block mt-2 mb-4 text-center text-[12px]">
        {show.desc}
      </span>
      <div className="grid grid-cols-2 place-items-center gap-x-5">
        <span
          className="w-full bg-lynx_white text-dynamic_black rounded-[10px] text-center py-2 cursor-pointer"
          onClick={handleClose}
        >
          Cancel
        </span>
        <span
          className={`w-full ${
            isBlock ? "bg-vermilion_bird" : "bg-grass_green"
          }  text-white rounded-[10px] text-center py-2 cursor-pointer ${
            RoundStatusIsMutating && "pointer-events-none opacity-25	"
          }`}
          onClick={handleClick}
        >
          {RoundStatusIsMutating ? (
            <CircularProgress
              size={20}
              sx={{
                color: "#fff",
              }}
            />
          ) : (
            show?.confirmActionName
          )}
        </span>
      </div>
    </div>
  );
};

export default RoundActiveUnActiveModalContent;
