import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AdminDetailPops } from "../../components/DataDeclare";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { EditIcon, LogoutIcon } from "../../pages/common/Icon";
import { AuthContext } from "../../contexts/authContext";
import {
  setAccessToken,
  setPermissionIds,
  setSuperAdmin,
  setUser,
} from "../../services/storage";
import { RouteName } from "../../app/constants/route-constant";

export default function DrawHeaderC() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { setAuthenticated } = React.useContext(AuthContext);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [localuser, setLocalUser] = React.useState<AdminDetailPops>();
  //const [user, setUser] =  useState(localStorage.getItem("user")?localStorage.getItem("user"):undefined);
  const navigate = useNavigate();
  const loadUser = async () => {
    const userDataCheck = localStorage.getItem("user");
    if (userDataCheck !== null) {
      const userData = JSON.parse(userDataCheck);
      setLocalUser(userData);
    } else {
      console.error("Permission data not found in localStorage.");
    }
  };
  React.useEffect(() => {
    loadUser();
  }, []);

  const { pathname } = useLocation();

  const { id, resultId, lang, userId } = useParams();

  const title = [RouteName.dashboard, "/"].includes(pathname)
    ? "Dashboard"
    : pathname === RouteName.profile
    ? "Account Setting"
    : pathname === RouteName.users_userlist
    ? "Users"
    : pathname === `/users/detail/${id}`
    ? "Users > Details"
    : pathname === RouteName.twod_betlist
    ? "2D > Bet List"
    : pathname === RouteName.threed_betlist
    ? "3D > Bet List"
    : pathname === `/twod/betlist/user/${userId}/${id}`
    ? "2D > Bet Details"
    : pathname === `/threed/betlist/user/${userId}/${id}`
    ? "3D > Bet Details"
    : pathname === `/twod/previousresults`
    ? "2D > Previous Result"
    : pathname === `/twod/previousresults/winnerlist`
    ? "2D > Winner List"
    : pathname === `/twod/roundsettings`
    ? "2D > Round Settings"
    : pathname === `/twod/summary`
    ? "2D > Summary"
    : pathname === `/threed/previousresults`
    ? "3D > Result History"
    : pathname === `/threed/previousresults/winnerlist`
    ? "3D > Winner List"
    : pathname === `/threed/roundsettings`
    ? "3D > Round Settings"
    : pathname === `/threed/summary`
    ? "3D > Summary"
    : pathname === `/admins/list`
    ? "Admins"
    : pathname === `/admins/create`
    ? "Admins > Create Admin"
    : pathname === `/admins/role/list`
    ? "Admins > User Roles & Permissions"
    : pathname === `/admins/role/create`
    ? "Admins>  Create User Role"
    : pathname === `/admins/detail/${id}`
    ? "Admins > Admin Details"
    : pathname === `/admins/edit/${id}`
    ? "Admins > Edit Admin"
    : pathname === `/agents/list`
    ? "Agents"
    : pathname === `/agent/detail/${id}`
    ? "Agents > Agent Details"
    : pathname === `/notifications/list`
    ? "Notification Center"
    : pathname === `/notifications/create`
    ? "Notification Center > Create Notifications"
    : pathname === `/notifications/detail/${id}`
    ? "Notification Center > Detail Notifications"
    : pathname === `/report/list`
    ? "Report > Overall Report"
    : pathname === `/report/two_d/daily_report`
    ? "Report > 2D Daily Report"
    : pathname === `/report/two_d/monthly_report`
    ? "Report > 2D Monthly Report"
    : pathname === `/report/two_d/annual_report`
    ? "Report > 2D Annual Report"
    : pathname === `/report/three_d/monthly_report`
    ? "Report > 3D Monthly Report"
    : pathname === `/report/three_d/annual_report`
    ? "Report> 3D Annual Report"
    : pathname === `/setting/closedays/list`
    ? "Settings > Closed Days"
    : pathname === `/setting/closedays/detail/${id}`
    ? "Settings > Update Closed Days"
    : pathname === `/setting/termconditions/list`
    ? "Settings > Terms & Condition"
    : pathname === `/setting/termconditions/${lang}`
    ? "Settings > Update Terms & Condition"
    : pathname === `/setting/privacypolicy/list`
    ? "Settings> Privacy Policy"
    : pathname === `/setting/privacypolicy/edit/${lang}`
    ? "Settings > Update Privacy Policy"
    : pathname === `/setting/faqs/list`
    ? "Settings > FAQs"
    : pathname === `/setting/faqs/create`
    ? "Settings > Create FAQs"
    : pathname === `/setting/faqs/edit/${id}`
    ? "Settings > Update FAQs"
    : pathname === `/setting/maintenance`
    ? "Settings > Maintenance"
    : pathname === `/agents/edit/${id}`
    ? "Agents > Edit Agent"
    : pathname === `/agents/create`
    ? "Agents > Create Agent"
    : pathname === `/threed/roundsettings/${id}`
    ? "3D Round Setting > Edit Round"
    : pathname === `/setting/ads-banner`
    ? "Setting >  Banner"
    : pathname === `/setting/app-ad`
    ? "Setting > App Ads"
    : pathname === `/users/detail/${id}/winning/${resultId}`
    ? "Users> Winning Transaction Details"
    : pathname === `/threed/generate-invoice`
    ? "Generate 3D Invoices"
    : "";

  return (
    <div className="flex flex-row items-center justify-between flex-1 w-full pl-3 pr-3">
      <span className="block text-sm font-bold md:text-lg text-dynamic_black">
        {title}
      </span>

      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="mr-5 text-dynamic_black">
          <span className="text-xs no-icon md:text-base">
            <b>{localuser?.name}</b>
            <br />
            <span className=" text-[10px] md:text-sm">{localuser?.roleId?.name}</span>
          </span>
        </div>
        <FiChevronDown className="text-dynamic_black" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
        >
          {" "}
          <div className="flex">
            {" "}
            <EditIcon width={20} height={20} />
            <span className="dorp-down-menu-span p-l-10 text-[14px] fw-500">
              View Profile
            </span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAccessToken("");
            setPermissionIds("");
            setSuperAdmin("");
            setUser("");
            setAuthenticated(false);
            navigate("/login");
            handleClose();
          }}
        >
          {" "}
          <div className="flex">
            <LogoutIcon width={20} height={20} />
            <span className="dorp-down-menu-span p-l-10 text-[14px] fw-500">
              Log Out
            </span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
