import React, { useMemo } from "react";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate, useParams } from "react-router-dom";
import UserInformationOverview from "../../components/ui/users/UserInformationOverview";
import { useGetUserById } from "../../api/funs/user";
import { useAtom } from "jotai";
import { userDetailAtom } from "../../store";
import BetDetailTable from "../../table/other/BetDetailTable";
import Bets from "../../components/ui/bet/Bets";

const TwoDBetDetailPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { data: userDetail } = useGetUserById(userId as string);
  const [, setUserDetailData] = useAtom(userDetailAtom);

  useMemo(() => {
    if (userDetail?.data?.data) {
      setUserDetailData(userDetail?.data?.data);
    }

    return () => {};
  }, [userDetail, setUserDetailData]);
  return (
    <div className="mt-3">
      {" "}
      <div>
        <div className="">
          <BackArrowButton
            handleClick={() => navigate(RouteName.twod_betlist)}
          />
        </div>
        <div className="">
          <UserInformationOverview />
          <BetDetailTable />
          <Bets />
        </div>
      </div>
    </div>
  );
};

export default TwoDBetDetailPage;
