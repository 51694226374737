import DateFormatCell from "../../cell/DateFormatCell";
import { RenderHtmlCell } from "../../cell/RenderHtmlCell";

export const TermsConditionCol = [
  {
    accessorKey: "language", //simple recommended way to define a column
    header: "Language",
    filterVariant: "multi-select",
    filterSelectOptions: ["English", "Myanmar", "Chinese"],
  },
  {
    accessorKey: "content", //simple recommended way to define a column
    header: "Content",
    Cell: RenderHtmlCell,
  },
  {
    accessorKey: "date", //simple recommended way to define a column
    header: "Date",
    accessorFn: (originalRow: any) => new Date(originalRow?.date),
    Cell: DateFormatCell,
    filterVariant: "date-range",
  },
];
