import { Link, useLocation, useParams } from "react-router-dom";
import { RouteName } from "../../app/constants/route-constant";
import {
  ArrowRightIcon,
  EditIcon,
  EyeIcon,
  TrashIcon,
} from "../../pages/common/Icon";
import { useDeleteHolidayOne } from "../../api/mutation/holidays";
import { useDeleteFaqOne } from "../../api/mutation/faq";
import IOSSwitchAction from "./IOSSwitchAction";
import { FiFileText } from "react-icons/fi";
import { useAtom } from "jotai";
import { modalAtom } from "../../store";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";
import { useDeleteAdsBannerOne } from "../../api/mutation/ads-banner";

const ViewAction = (row: any, pagination: any) => {
  const [, setIsOpenModal] = useAtom(modalAtom);
  const { data: profileInfo } = useProfileInfo();

  const { pathname } = useLocation();
  const { id } = useParams();
  const navigateRoute =
    pathname === RouteName.notification_list
      ? `/notifications/detail/${row?.row?.original?._id}`
      : pathname === RouteName.users_userlist
      ? `/users/detail/${row?.row?.original?._id}`
      : pathname === RouteName.setting_closedays_list
      ? `/setting/closedays/detail/${row?.row?.original?._id}`
      : pathname === RouteName.admins_list
      ? `/admins/detail/${row?.row?.original?._id}`
      : pathname === RouteName.agents_list
      ? `/agent/detail/${row?.row?.original?._id}`
      : "";
  const navigateEditRoute =
    pathname === RouteName.setting_closedays_list
      ? `/setting/closedays/detail/${row?.row?.original?._id}`
      : pathname === RouteName.setting_faqs_list
      ? `/setting/faqs/edit/${row?.row?.original?._id}`
      : pathname === RouteName.setting_termconditions_list
      ? `/setting/termconditions/${row?.row?.original?.lang}`
      : pathname === RouteName.setting_privaypolicy_list
      ? `/setting/privacypolicy/edit/${row?.row?.original?.lang}`
      : pathname === RouteName.role_list
      ? `/admins/role/edit/${row?.row?.original?._id}`
      : pathname === RouteName.admins_list
      ? `/admins/edit/${row?.row?.original?._id}`
      : pathname === RouteName.agents_list
      ? `/agents/edit/${row?.row?.original?._id}`
      : pathname === RouteName.three_roundsetting
      ? `/threed/roundsettings/${row?.row?.original?._id}`
      : "";

  const isShowSwitch = [
    RouteName.agents_list,
    RouteName.admins_list,
    RouteName.users_userlist,
    RouteName.twod_roundsetting,
  ].includes(pathname)
    ? true
    : false;
  const isShowSwitchPermission =
    ([RouteName.users_userlist].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.USER_TOGGLE_ACTIVE_BLOCK
      )) ||
    ([RouteName.admins_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.ADMIN_ACTIVE_BLOCK_TOGGLE
      )) ||
    ([RouteName.agents_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.AGENT_ACTIVE_BLOCK_TOGGLE
      )) ||
    [RouteName.twod_roundsetting].includes(pathname);
  const isShowView = [
    RouteName.users_userlist,
    RouteName.admins_list,
    RouteName.notification_list,

    RouteName.agents_list,
  ].includes(pathname)
    ? true
    : false;
  const isShowPermissionView =
    ([RouteName.users_userlist].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.USER_DETAIL
      )) ||
    ([RouteName.admins_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.ADMIN_DETAIL
      )) ||
    ([RouteName.agents_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.AGENT_DETAIL
      )) ||
    ([RouteName.notification_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.NOTIFICATION_DETAIL
      ));

  const isShowEditIcon = [
    RouteName.admins_list,
    RouteName.agents_list,
    RouteName.three_roundsetting,
    RouteName.setting_closedays_list,
    RouteName.setting_termconditions_list,
    RouteName.setting_privaypolicy_list,
    RouteName.setting_faqs_list,
    RouteName.role_list,
  ].includes(pathname)
    ? true
    : false;

  const isShowPermissionEdit =
    ([RouteName.admins_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.ADMIN_EDIT
      )) ||
    ([RouteName.agents_list].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.AGENT_EDIT
      )) ||
    (["/setting/closedays/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_CLOSE_DAYS_EDIT
      )) ||
    (["/setting/termconditions/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_TERMS_CONDITION_EDIT
      )) ||
    (["/setting/privacypolicy/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_POLICY_EDIT
      )) ||
    (["/setting/faqs/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_FAQS_EDIT
      )) ||
    ["/admins/role/list", "/threed/roundsettings"].includes(pathname);
  const isSHowDeleteIcon = [
    RouteName.setting_closedays_list,

    RouteName.setting_faqs_list,
    "/setting/ads-banner",
  ].includes(pathname)
    ? true
    : false;

  const isShowPermissionDelete =
    (["/setting/closedays/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_CLOSE_DAYS_DELETE
      )) ||
    (["/setting/faqs/list"].includes(pathname) &&
      permissionValidate(
        profileInfo?.data?.data?.roleId?.permissionIds,
        CONTENT.permission_const.SETTING_FAQS_DELETE
      )) ||
    ["/setting/ads-banner"].includes(pathname);

  const isSHowViewRightArrowIcon = [
    `/users/detail/${id}`,
    RouteName.twod_betlist,
    RouteName.threed_betlist,
  ].includes(pathname)
    ? true
    : false;
  const { trigger: deleteHolidayTrigger } = useDeleteHolidayOne();
  const { trigger: deleteFaqTrigger } = useDeleteFaqOne();
  const { trigger: deleteAdsBannerTrigger } = useDeleteAdsBannerOne();

  const handleDelete = () => {
    if (RouteName.setting_closedays_list === pathname) {
      deleteHolidayTrigger({
        id: row?.row?.original?._id,
      });
    } else if (RouteName.setting_faqs_list === pathname) {
      deleteFaqTrigger({
        id: row?.row?.original?._id,
      });
    } else if ("/setting/ads-banner" === pathname) {
      deleteAdsBannerTrigger({
        id: row?.row?.original?._id,
      });
    } else {
    }
  };

  const isSHowSaveInvoiceIcon = [
    `/agent/detail/${id}`,
    // RouteName.twod_betlist,
    // RouteName.threed_betlist,
  ].includes(pathname)
    ? true
    : false;

  const handleSaveInvoice = () => {
    setIsOpenModal({
      isOpen: true,
      showData: {
        type: "saveInvoice",
        data: row?.row?.original,
      },
    });
  };
  return (
    <div className="flex gap-x-4">
      {row?.row?.original && isShowSwitch && isShowSwitchPermission && (
        <>
          <IOSSwitchAction row={row?.row?.original} />
        </>
      )}
      {row?.row?.original && isShowView && isShowPermissionView && (
        <Link to={navigateRoute} className="px-2">
          <EyeIcon className="text-center text-vermilion_bird text-[20px]" />
        </Link>
      )}
      {row?.row?.original && isShowEditIcon && isShowPermissionEdit && (
        <Link to={navigateEditRoute}>
          <EditIcon className="text-center text-vermilion_bird text-[20px]" />
        </Link>
      )}
      {row?.row?.original && isSHowDeleteIcon && isShowPermissionDelete && (
        <TrashIcon
          className="text-center text-vermilion_bird text-[20px]"
          onClick={handleDelete}
        />
      )}
      {row?.row?.original && isSHowViewRightArrowIcon && (
        <ArrowRightIcon className="text-center text-glagstone text-[20px]" />
      )}
      {row?.row?.original && isSHowSaveInvoiceIcon && (
        <FiFileText
          className="text-center text-vermilion_bird text-[25px]"
          onClick={handleSaveInvoice}
        />
      )}
    </div>
  );
};

export default ViewAction;
