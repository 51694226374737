import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AddCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
} from "../../pages/common/Icon";
import { RouteName } from "../../app/constants/route-constant";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useGetRoleList } from "../../api/funs/role";
import { DevTool } from "@hookform/devtools";

import { useCreateAdmin } from "../../api/mutation/admin";
import { toast } from "react-toastify";
import { useGetAdminList } from "../../api/funs/admin";

const AdminCreate: React.FC = () => {
  const navigate = useNavigate();

  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const { mutate } = useGetAdminList(pagination, "");

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const { data: roleList } = useGetRoleList();

  const { control, handleSubmit, formState, reset, getValues } = useForm<{
    username: string;
    userRole: string;
    phoneNo: string;
    email: string;
    password: string;
    confirmPassword: string;
    ipAddress: string;
    ipAddressA: { text: string }[];
  }>({
    defaultValues: {
      username: "",
      userRole: "",
      phoneNo: "",
      email: "",
      password: "",
      confirmPassword: "",
      ipAddressA: [{ text: "" }],
    },
    // resolver: yupResolver(formSchema),
  });
  const { trigger, isMutating } = useCreateAdmin();

  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "ipAddressA",
    control,
  });
  const onSubmit = (data: any) => {
    trigger({
      name: data?.username,
      roleId: data?.userRole,
      phone: data?.phoneNo,
      email: data?.email,
      password: data?.confirmPassword,
      ipAddress: data?.ipAddressA?.map((_: any) => _?.text),
    }, {
      onError: () => {
        toast.error("Something wrong.", {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {


        if (d?.data?.status === "success") {
          toast.success("Successfully Created.", {
            position: "bottom-right",
          });
          mutate();
          navigate(RouteName.admins_list);
          reset();
        }
       
      },
    });
    
    
  };

  return (
    <>
      <div className="mt-10 md:pl-5 md:mt-0">
        <BackArrowButton handleClick={() => navigate(RouteName.admins_list)} />
      </div>
      <form
        className="grid grid-cols-2 md:pl-5 gap-x-5"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="username"
          rules={{
            required: {
              value: true,
              message: "Username is required.",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Username (e.g.Ko Soe)"
                error={!!errors?.username}
                label="Username (e.g.Ko Soe)"
                sx={{
                  marginBottom: "1.5rem",
                }}
                helperText={
                  !!errors?.username?.message && errors?.username?.message
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="userRole"
          rules={{
            required: {
              value: true,
              message: "User role is required.",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <>
                {" "}
                <FormControl
                  size="small"
                  fullWidth
                  error={!!formState.errors?.userRole}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select User Role
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select User Role"
                  >
                    {roleList &&
                      roleList?.data?.data?.map((_: any, i: any) => (
                        <MenuItem key={i} value={_?._id}>
                          {_?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors?.userRole?.message && (
                    <FormHelperText>{errors?.userRole?.message}</FormHelperText>
                  )}
                </FormControl>
              </>
            );
          }}
        />
        <Controller
          control={control}
          name="phoneNo"
          // rules={{
          //   validate: {
          //     required: (value: any) => {
          //       if (!value) {
          //         return "Phone Number is required.";
          //       }
          //       if (/^09[0-9]{9}$/.test(value)) {
          //         return undefined;
          //       }
          //       return "Phone format is invalid.";
          //     },
          //   },
          // }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Phone Number"
                error={!!errors?.phoneNo}
                label="Phone Number (Optional)"
                sx={{
                  marginBottom: "1.5rem",
                }}
                helperText={
                  !!errors?.phoneNo?.message && errors?.phoneNo?.message
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="email"
          // rules={{
          //   validate: {
          //     required: (value: any) => {
          //       if (!value) {
          //         return "Email is required.";
          //       }
          //       if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          //         return undefined;
          //       }
          //       return "Email is not format.";
          //     },
          //   },
          // }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Email"
                error={!!errors?.email}
                label="Email (Optional)"
                sx={{
                  marginBottom: "1.5rem",
                }}
                helperText={!!errors?.email?.message && errors?.email?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter a number",
            },

            validate: (value: string) => {
              if (!value) {
                return "Not a Number";
              }
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                type={isShowPassword ? "text" : "password"}
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setIsShowPassword(!isShowPassword);
                      }}
                    >
                      {isShowPassword ? (
                        <EyeSlashIcon className="w-6 h-6" />
                      ) : (
                        <EyeIcon className="w-6 h-6" />
                      )}
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.password}
                label="Password"
                sx={{
                  marginBottom: "1.5rem",
                }}
                helperText={
                  !!errors?.password?.message && errors?.password?.message
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            pattern: {
              value: /^[0-9]+$/,
              message: "Please enter a number",
            },

            validate: (value: string) => {
              if (!value) {
                return "Not a Number";
              }
              if (value !== getValues("password")) {
                return "Do not match.";
              }
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                type={isShowConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setIsShowConfirmPassword(!isShowConfirmPassword);
                      }}
                    >
                      {isShowPassword ? (
                        <EyeSlashIcon className="w-6 h-6" />
                      ) : (
                        <EyeIcon className="w-6 h-6" />
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder="Confirm Password"
                error={!!errors?.confirmPassword}
                label="Confirm Password"
                sx={{
                  marginBottom: "1.5rem",
                }}
                helperText={
                  !!errors?.confirmPassword?.message &&
                  errors?.confirmPassword?.message
                }
              />
            );
          }}
        />
        <div className="col-span-2 md:col-span-1">
          {fields.map((field, index) => (
            <div key={field.text} className="flex justify-center mb-3 gap-x-4">
              <Controller
                control={control}
                name={`ipAddressA.${index}.text`}
                rules={{
                  validate: (v: any) => {
                    if (v?.length === 0) {
                      return "Ip Address is required.";
                    } else if (/^([0-9]{1,3}\.){3}[0-9]{1,3}$/.test(v)) {
                      return undefined;
                    }

                    return "Invalid Ip Address";
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      fullWidth
                      size="small"
                      {...field}
                      placeholder="Ip Address"
                      label="Ip Address"
                      error={!!errors.ipAddressA?.[index]?.text}
                      helperText={errors.ipAddressA?.[index]?.text?.message}
                    />
                  );
                }}
              />

              {fields?.length !== 1 && (
                <div className="mt-2 cursor-pointer">
                  <TrashIcon onClick={() => remove(index)} />
                </div>
              )}
            </div>
          ))}

          <div>
            <span
              className="flex justify-center items-center w-[160px] h-[40px] gap-x-2 bg-pink_lemonade rounded-md cursor-pointer"
              onClick={() => append({ text: " " })}
            >
              <AddCircleIcon className="w-5 h-5" />
              <span className="text-vermilion_bird text-[12px] font-bold">
                Add IP address
              </span>
            </span>
          </div>

          <br />
        </div>

        <div className="flex justify-between flex-1 col-span-2">
          <div>
            <button
              type="submit"
              className={`btn-create-active ${
                isMutating ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={isMutating}
            >
              {isMutating ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "Create"
              )}
            </button>
          </div>
        </div>

        <DevTool control={control} />
      </form>
    </>
  );
};

export default AdminCreate;
