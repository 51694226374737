import { useNavigate } from "react-router-dom";

import useSWRMutation from "swr/mutation";
import { useState } from "react";

import { RouteName } from "../../../app/constants/route-constant";

import { useGetAdminList } from "../../funs/admin";
import { createAdminApi, updateAdminApi } from "../../admin";
import { toast } from "react-toastify";
import { useProfileInfo } from "../../funs/profile";

export function useCreateAdmin() {
  

  return useSWRMutation("/api/admins/auth/register", createAdminApi, {
    throwOnError: false
  });
}

export function useUpdateAdmin(id: string) {
  const navigate = useNavigate();
  const {  mutate: profileMutate } = useProfileInfo();

  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const { mutate } = useGetAdminList(pagination, "");
  return useSWRMutation(id ? `/api/admins/${id}` : null, updateAdminApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.admins_list);
      profileMutate();
    },
  });
}
