import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";

import { betThreeListCol } from "../../../table/columns/bet/betthreeColList";

import { useGetBetList } from "../../../api/funs/rounds";
import moment from "moment";
import dayjs from "dayjs";

const Bet3DList = ({
  round,
  date,
}: {
  round: {
    label?: string;
    value?: string;
  };
  date: string | null;
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const {
    data: betList,
    isLoading,
    error,
  } = useGetBetList(
    "three-d",
    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    pagination.pageIndex,
    pagination.pageSize,
    round?.value as string
  );

  const historyData = useMemo(() => {
    return betList?.data?.data?.map((d: any) => ({
      _id: d?._id,
      _userId: d?.userId,
      agent_code: d?.agent?.userCode,

      name: d?.user?.name,
      phone: d?.user?.phone,
      bet_date: d?.betDate,
      bet_numbers: d?.betNumbers?.map((_: any) => _?.number),
      total_no_of_bet: d?.totalNumbersOfBets,
      bet_amount: d?.total,
    }));
  }, [betList]);

  return (
    <CustomizeTable
      column={betThreeListCol}
      data={historyData}
      totalCount={betList?.data?.totalCounts}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

export default Bet3DList;
