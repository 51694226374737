import { CircularProgress } from '@mui/material'
import React from 'react'

const SpinnerLoading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
    <CircularProgress
      size={20}
      sx={{
        color: "#F44336",
      }}
    />
  </div>
  )
}

export default SpinnerLoading