import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AddCircle1Icon } from "../../pages/common/Icon";

import { useNotificationLists } from "../../api/funs/notification";
import { notificationTableCol } from "../../table/columns/notification";
import CustomizeTable from "../../table/CustomizeTable";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const NotificationList: React.FC = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: profileInfo } = useProfileInfo();

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: notiList, isLoading, error } = useNotificationLists(pagination);

  const notiMemozied = useMemo(() => {
    if (notiList?.data?.data) {
      return notiList?.data?.data?.map((d: any, i: number) => ({
        _id: d?._id,
        // sr_no: `${pagination.pageIndex}${i}`,
        type: d?.type.charAt(0).toUpperCase() + d?.type.slice(1),
        title: d?.title,
        message: d?.text,
        created_at: d?.createdAt,
      }));
    }
  }, [notiList]);

  const onCreate = () => {
    navigate("/notifications/create");
  };

  return (
    <>
      <div className="flex mb-3 gap-x-4">
        <div className="col-2 d-flex align-items-center">
          {permissionValidate(
            profileInfo?.data?.data?.roleId?.permissionIds,
            CONTENT.permission_const.NOTIFICATION_CREATE
          ) && (
            <div onClick={onCreate}      className="flex items-center px-2 py-1 text-white rounded-md md:py-2 bg-vermilion_bird">
              <AddCircle1Icon width={18} height={18} />
              <span className="font-bold p-l-5">Create</span>
            </div>
          )}
        </div>
      </div>

      <CustomizeTable
        column={notificationTableCol}
        data={notiMemozied || []}
        totalCount={notiList?.data?.totalCounts}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </>
  );
};

export default NotificationList;
