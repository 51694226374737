import React from "react";
import { useNavigate } from "react-router-dom";

import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import EditPolicesForm from "../../forms/EditPoliciesForm";

const Edit: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="pl-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.setting_privaypolicy_list)}
        />
      </div>
      <div className="pl-5">
        <EditPolicesForm />
      </div>
    </>
  );
};

export default Edit;
