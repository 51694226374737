
import { CheckCircleBrownIcon, CheckCircleIcon } from "../../pages/common/Icon";

const IsClaimedCell = ({ renderedCellValue, row }: any) => {
  return (
    <>
      {renderedCellValue === "UNCLAIMED" ? (
        <span className="flex items-center ">
          <CheckCircleBrownIcon />
          <span className=" text-[14px] ml-1 text-dim_grey">UnClaimed</span>
        </span>
      ) : (
        <div className="flex items-center">
          <CheckCircleIcon />
          <span className=" text-[14px] ml-1 text-grass_green">Claimed</span>
        </div>
      )}
    </>
  );
};

export default IsClaimedCell;
