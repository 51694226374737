import { CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoBlueIcon } from "../../../../pages/common/Icon";
import { FiX } from "react-icons/fi";
import { useAtom } from "jotai";
import { modalAtom, roundInfoAtom } from "../../../../store";
import { useCreateAnnounced } from "../../../../api/mutation/rounds";
import moment from "moment";
import { DevTool } from "@hookform/devtools";
import { tootNumberGenerateFun } from "../../../../util/funs/tootNumberGenerateFun";

const AnnounceThreeDResultModalContent = () => {
  const [, setIsOpenModal] = useAtom(modalAtom);
  const [roundId] = useAtom(roundInfoAtom);
  const [twtThreeA, setTwtThreeA] = useState<string[]>([]);

  const { control, handleSubmit, formState, watch } = useForm<{
    dett_three: string;
    twt_three: string;
    twt_three_a: string[];
  }>({
    defaultValues: {
      dett_three: "",
      twt_three: "",
      twt_three_a: [],
    },
  });
  const { errors } = formState;

  const { trigger, isMutating } = useCreateAnnounced(
    "three-d",
    roundId?.value as string
  );

  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };
  const onSubmit = (data: any) => {
    trigger({
      winningNumber: data?.dett_three,
      tootNumbers: twtThreeA,
    });
    handleClose();
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        value.dett_three?.toString()?.length === 3 &&
        +value.dett_three < 1001
      ) {
        const tootNums = tootNumberGenerateFun(value.dett_three);

        setTwtThreeA(tootNums?.filter((_: any) => _ !== value.dett_three));
      } else {
        setTwtThreeA([]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <div className="relative flex flex-col px-3 py-3 bg-white rounded-md">
      <form
        className="flex flex-col "
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FiX className="absolute right-5 top-5" onClick={handleClose} />

        <p className="text-[1rem] text-dynamic_black font-semibold">
          Announce Result
        </p>
        <p className="text-[0.8rem] text-vermilion_bird">
          3D <span className="text-dynamic_black">Result for</span>{" "}
          {moment().format("DD MMM YYYY")}
        </p>

        <p className="w-[42px] text-[12px] h-[36px] flex justify-center items-center text-white rounded-[10px]  bg-vermilion_bird">
          ဒဲ့
        </p>

        <Controller
          control={control}
          name="dett_three"
          rules={{
            validate: {
              required: (value: any) => {
                if (value === "000") {
                  return undefined;
                }
                if (
                  !(value?.toString()?.length === 3) ||
                  !(+value > 0 && +value < 1001)
                ) {
                  return "Please enter only three numbers.";
                }
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Result (e.g. 289)"
                error={!!errors?.dett_three}
                label="Result (e.g. 289)"
                sx={{
                  width: "20rem",
                  marginBottom: "1.5rem",
                }}
                helperText={
                  !!errors?.dett_three?.message && errors?.dett_three?.message
                }
              />
            );
          }}
        />
        <p className="w-[100px] text-[12px] h-[36px] flex justify-center items-center text-white rounded-[10px]  bg-vermilion_bird">
          တွက် နံပါတ်များ
        </p>
        {/* <Controller
          control={control}
          name="twt_three"
          // rules={{
          //   required: {
          //     value: true,
          //     message: "Three Twt is required.",
          //   },
          // }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                type="number"
                value={getValues("twt_three")}
                onKeyPress={(e) => {
                  if (
                    !twtThreeA.includes(field?.value?.toString()) &&
                    e.key === "Enter"
                  ) {
                    setTwtThreeA([
                      ...twtThreeA,
                      getValues("twt_three").toString(),
                    ]);
                  }
                }}
                onChange={(e: any) => {
                  if (e.target.value < 1000) {
                    setValue("twt_three", e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }
                }}
                placeholder="Result (e.g. 289)"
                error={!!errors?.twt_three}
                label="Result (e.g. 289)"
                inputProps={{ maxLength: 3 }}
                sx={{
                  width: "20rem",
                }}
                helperText={
                  !!errors?.twt_three?.message && errors?.twt_three?.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!twtThreeA.includes(field?.value?.toString()) &&
                        field?.value?.toString().length === 3 && (
                          <MdControlPoint
                            className="text-vermilion_bird"
                            onClick={() => {
                              setTwtThreeA([
                                ...twtThreeA,
                                getValues("twt_three").toString(),
                              ]);
                              setValue("twt_three", "", {
                                shouldValidate: false,
                                shouldDirty: false,
                                shouldTouch: false,
                              });
                            }}
                          />
                        )}
                    </InputAdornment>
                  ),
                }}
              />
            );
          }}
        /> */}
        <div className="flex flex-wrap w-[20rem] mt-2 gap-2">
          {twtThreeA?.length === 0 ? (
            <span className="ml-3">---</span>
          ) : (
            twtThreeA.map((_) => (
              <div
                className="flex items-center justify-start gap-1 px-2 rounded-md bg-pink_lemonade"
                key={_}
              >
                <span className="py-1 text-[10px] font-bold text-vermilion_bird">
                  {_}
                </span>
              </div>
            ))
          )}
        </div>

        <div className="flex items-start gap-x-3  my-[1.5rem]">
          <InfoBlueIcon className="mt-1" />
          <span className=" w-[18rem] text-blizzy_blueberry text-[0.8rem]">
            Please enter the correct result and make sure you have double
            checked before confirming it. This action can not be edited later.
          </span>
        </div>
        <div className="grid flex-1 grid-cols-2 gap-x-5">
          <button
            className="flex items-center justify-center w-full py-2 font-semibold bg-lynx_white text-dim_grey rounded-[12px]"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className={`flex items-center justify-center w-full py-2 font-semibold text-white bg-vermilion_bird rounded-[12px] ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
        <DevTool control={control} />
      </form>
    </div>
  );
};

export default AnnounceThreeDResultModalContent;
