import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";

const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getReportApi = async (
  type: string,
  agentId: string,
  date: string,
  reportType: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/reports/${reportType}/${type}?agentId=${agentId}&date=${date}`
  );

  return axiosInstance.get(axios_url);
};
export const getReportOverviewApi = async (
  type: string,
  startDate: string,
  endDate: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/reports/overall?from=${startDate}&to=${endDate}&type=${type}`
  );

  return axiosInstance.get(axios_url);
};
