import React from "react";

import {
  ArrowRightIcon,

} from "../common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import { useGetPreviousWinnersList } from "../../api/funs/rounds";
import { getPreviousResult } from "../../services/storage";
import SpinnerLoading from "../common/SpinnerLoading";

const TwodPreviousWinnerListPage = () => {
  const navigate = useNavigate();
  const winningValue = getPreviousResult();
  const { data: PreviousWinnings, isLoading } = useGetPreviousWinnersList(
    "two-d",
    JSON.parse(winningValue as any)?.resultId as string,
    0,
    100
  );

  return (
    <>
      {" "}
      <BackArrowButton
        handleClick={() => navigate(RouteName.twod_previousresults)}
      />
      <div>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div className="bg-white rounded-[10px] px-1 md:px-3 py-2 mt-3">
            <div className="md:flex gap-x-10">
              <div className="mb-3 md:mb-0 py-2 md:py-0 md:w-[299px] md:h-[259px] bg-center bg-no-repeat bg-cover bg-round_image rounded-[10px] flex flex-col justify-center items-center">
                <span className="text-[12px]">Result</span>
                <span className="text-[50px] font-bold -mt-3 mb-2">
                  {JSON.parse(winningValue as any)?.winningNumber}
                </span>

                <div className="flex gap-x-10">
                  <div className="text-center">
                    <span className="text-[12px] font-normal">Set</span>
                    <br />
                    <span className="text-[16px] font-bold">
                      {JSON.parse(winningValue as any)?.set}
                    </span>
                  </div>
                  <div className="text-center">
                    <span className="text-[12px] font-normal">Value</span>
                    <br />
                    <span className="text-[16px] font-bold">
                      {JSON.parse(winningValue as any)?.value}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start flex-auto">
                <span className="block mb-2">
                  Total of{" "}
                  {PreviousWinnings?.data?.data?.twodWinnerLists?.length}{" "}
                  Winners
                </span>
                <table className="w-full text-dim_grey">
                  <tbody>
                    {PreviousWinnings?.data?.data?.length === 0 ? (
                      <div className="flex items-center justify-center">
                        No Record ...
                      </div>
                    ) : (
                      <>
                        {" "}
                        {PreviousWinnings?.data?.data?.twodWinnerLists?.map(
                          (_d: any, i: number) => (
                            <tr
                              className="border-dashed cursor-pointer border-steam border-y"
                              key={i}
                              onClick={() => {
                                navigate({
                                  pathname: `/users/detail/${_d?.userId?._id}/winning/${_d?.twodResultId}`,
                                  search: "?type=twod",
                                });
                              }}
                            >
                              <td className="py-3">
                                {i < 3 && (
                                  <span className="px-1 md:px-2 md:py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                                    {i + 1}
                                  </span>
                                )}
                              </td>
                              <td>
                                {" "}
                                <div className="flex items-center ">
                                  <div>
                                    <div className="text-[12px] w-16 md:text-[14px] font-medium  text-dynamic_black">
                                      {_d?.userId?.name}
                                    </div>
                                    <div className="text-[10px] md:text-[12px] text-dim_grey">
                                      {_d?.userId?.phone}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="py-1 ">
                                <div className="">
                                  <>
                                    <span className="block text-[8px] md:text-[10px] text-dim_grey">
                                      Agent Code
                                    </span>
                                    <span className="text-[10px] md:text-[12px]">
                                      {_d?.agentCode}
                                    </span>
                                  </>
                                </div>
                              </td>
                              <td>
                                <span className="text-[10px] md:text-[12px]">
                                  Bet <br />
                                  <span className="text-[12px] md:text-[16px]">
                                    {_d?.totalBetAmount?.toLocaleString()}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span className="text-[10px] md:text-[12px] block">Won</span>
                                <span className="text-[12px] md:text-[16px] text-grass_green">
                                  {_d?.totalWinningAmount?.toLocaleString()}
                                </span>
                              </td>
                              {/* <td>
                                <span className="text-[12px] block">
                                  Status
                                </span>
                                {_d?.status === "UNCLAIMED" ? (
                                  <div className="flex items-center">
                                    <CheckCircleBrownIcon />
                                    <span className=" text-[14px] ml-1 text-dim_grey">
                                      UnClaimed
                                    </span>
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                    <CheckCircleIcon />
                                    <span className=" text-[14px] ml-1 text-grass_green">
                                      Claimed
                                    </span>
                                  </div>
                                )}
                              </td> */}
                              <td>
                                <ArrowRightIcon />
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>

                <div className="flex justify-between w-full mt-2 md:mt-5">
                  <p className="text-grass_green text-[12px] md:text-[14px]">Total Won</p>
                  <p className="text-grass_green font-bold text-[14px] md:text-[16px]">
                    {PreviousWinnings
                      ? PreviousWinnings?.data?.data?.totalAmount?.toLocaleString()
                      : "--"}{" "}
                    <span className="text-[14px] font-normal"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TwodPreviousWinnerListPage;
