import React, { useMemo, useState } from "react";
import CustomizeTable from "../../table/CustomizeTable";
import { AmountGreenMMKCell } from "../../table/cell/AmountGreenMMKCell";
import { AmountMMKCell } from "../../table/cell/AmountMMKCell";
import { Autocomplete, TextField } from "@mui/material";
import SpinnerLoading from "../common/SpinnerLoading";
import { useGetAgentCodeList } from "../../api/funs/agent";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { CalendarIcon } from "../common/Icon";
import { useReport } from "../../api/funs/reports";
import ExportButton from "../../components/ui/buttons/ExportButton";

const ReportTwoDAnnualPage = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [date, setDate] = useState(null);
  const [agentType, setAgentType] = useState<any>({
    label: "All",
    value: "",
  });

  const { data: agentCodeList, isLoading } = useGetAgentCodeList();

  const agentCodeListAll = agentCodeList?.data?.data && [
    {
      _id: "",
      userCode: "All",
    },
    ...agentCodeList?.data?.data,
  ];

  const {
    data: reports,
    isLoading: reportLoading,
    error,
  } = useReport(
    "twod",
    agentType?.value as string,
    date
      ? `${dayjs(date).format("YYYY-MM-")}01`
      : `${moment().format("YYYY-MM-")}01`,
    "annual"
  );

  const reportTwoDDailyMemozied = useMemo(() => {
    return reports?.data?.data?.annualReportData?.map((d: any, i: number) => ({
      agent: agentType?.label,
      // sr_no: `${pagination.pageIndex}${i}`,
      month: d?.betMonth,
      bet_amount: d?.totalBetAmount,
      players: d?.players,
      winning_amount: d?.totalWinningAmount || 0,
      win_lose: d?.profit || 0,
    }));
  }, [reports, agentType?.label]);

  // const averageSalaryWinLose = useMemo(
  //   () => reportTwoDDailyMemozied.reduce((acc, curr) => acc + curr.win_lose, 0),
  //   []
  // );
  // const averageSalaryWinningAmount = useMemo(
  //   () =>
  //     reportTwoDDailyMemozied.reduce(
  //       (acc, curr) => acc + curr.winning_amount,
  //       0
  //     ),
  //   []
  // );
  // const averageSalaryPlayers = useMemo(
  //   () => reportTwoDDailyMemozied.reduce((acc, curr) => acc + curr.players, 0),
  //   []
  // );
  // const averageBetAmount = useMemo(
  //   () =>
  //     reportTwoDDailyMemozied.reduce((acc, curr) => acc + curr.bet_amount, 0),
  //   []
  // );

  const memorizedCol = useMemo(() => {
    return [
      {
        accessorKey: "agent", //simple recommended way to define a column
        header: "Agent",
        Footer: () => <span className="font-semibold text-[16px]">Total</span>,
      },
      {
        accessorKey: "month", //simple recommended way to define a column
        header: "Month",
      },
      {
        accessorKey: "bet_amount", //simple recommended way to define a column
        header: "Bet Amount",
        Cell: AmountMMKCell,
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(
              +reports?.data?.data?.totals?.totalBetAmount || 0
            )?.toLocaleString()}
            <span className="text-[8px] ml-1 "></span>
          </span>
        ),
      },
      {
        accessorKey: "players", //simple recommended way to define a column
        header: "Players",
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(+reports?.data?.data?.totals?.totalPlayers)?.toLocaleString()}
          </span>
        ),
      },

      {
        accessorKey: "winning_amount", //simple recommended way to define a column
        header: "Winning Amount",
        Cell: AmountMMKCell,
        aggregationFn: "mean",
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(+reports?.data?.data?.totals
              ?.totalWinningAmount)?.toLocaleString()}
            <span className="text-[8px] ml-1 "></span>
          </span>
        ),
      },
      {
        accessorKey: "win_lose", //simple recommended way to define a column
        header: "Win/Lose",
        Cell: AmountGreenMMKCell,
        aggregationFn: "mean",
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(+reports?.data?.data?.totals?.totalProfit)?.toLocaleString()}
            <span className="text-[8px] ml-1 "></span>
          </span>
        ),
      },
    ];
  }, [reports]);

  return (
    <div className="mt-10 md:mt-3">
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
   <div className="flex flex-col mb-5 md:flex-row gap-x-4 gap-y-3 md:gap-y-0 items-self md:items-center ">
            {agentCodeList && (
              <Autocomplete
                sx={{
                  "& .MuiAutocomplete-clearIndicator": { display: "none" },
                }}
                id="combo-box-demo"
                onChange={(e, value) => {
                  setAgentType(value);
                }}
                options={agentCodeListAll?.map((_e: any, i: any) => ({
                  label: _e?.userCode,
                  value: _e?._id,
                }))}
                defaultValue={agentType}
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...params}
                    label="Agent"
                    size="small"
                    defaultValue={"All"}
                    variant="outlined"
                  />
                )}
              />
            )}

            <DatePicker
              format="YYYY"
              defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
              label="Date"
              views={["year"]}
              // value={date}
              onChange={(newValue: any) => setDate(newValue)}
              slotProps={{ textField: { size: "small", color: "error" },field:{
                readOnly:true
              }  }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />

            <ExportButton
              exportdata={reportTwoDDailyMemozied}
              fileName="twod_annual_report"
              firstTotalValue={reports?.data?.data?.totals?.totalBetAmount}
              secondTotalValue={reports?.data?.data?.totals?.totalPlayers}
              thirdTotalValue={reports?.data?.data?.totals?.totalWinningAmount}
              fouthTotalValue={reports?.data?.data?.totals?.totalProfit}
            />
          </div>
          <CustomizeTable
            column={memorizedCol}
            data={reportTwoDDailyMemozied}
            totalCount={reportTwoDDailyMemozied?.length}
            isErroe={error}
            isRefetching={reportLoading}
            pagination={pagination}
            setPagination={setPagination}
            isShowAction={"inactive"}
          />
          <div className="flex mt-3 border border-steam">
            <div className="flex items-center justify-center flex-1 py-4 border-r border-steam">
              <span className=" text-[14px]">New Users</span>
            </div>
            <div className="flex items-center justify-center flex-1 py-4">
              <span className="text-vermilion_bird text-[14px]">
                {reports?.data?.data?.newUsers?.toLocaleString()}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportTwoDAnnualPage;
