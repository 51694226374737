import { createContext, useState, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};
type IAuthContext = {
  authenticated: boolean;
  setAuthenticated: (newState: boolean) => void;
  superAdmin: boolean;
  setSuperAdmin: (newState: boolean) => void;
  user: any;
  permissionIds: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  setPermissionIds: React.Dispatch<React.SetStateAction<any>>;
};
const initialValue = {
  authenticated: false,
  setAuthenticated: () => {},
  superAdmin: false,
  setSuperAdmin: () => {},
  user: undefined,
  permissionIds: undefined,
  setUser: () => {},
  setPermissionIds: () => {},
};

export const AuthContext = createContext<IAuthContext>(initialValue);

export const AuthContextProvider = ({ children }: Props) => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("access_token") ? true : initialValue.authenticated
  );
  const [user, setUser] = useState(
    localStorage.getItem("user")
      ? localStorage.getItem("user")
      : initialValue.user
  );
  const [permissionIds, setPermissionIds] = useState(
    localStorage.getItem("permission")
      ? localStorage.getItem("permission")
      : initialValue.permissionIds
  );

  const [superAdmin, setSuperAdmin] = useState(
    localStorage.getItem("super_admin") ? true : initialValue.superAdmin
  );

  // useEffect(() => {
  //     if (localStorage.getItem("access_token")) {
  //         setTimeout(() => {
  //             setAccessToken('');
  //         }, 60 * 60 * 1000); // 60 minutes in milliseconds
  //     }
  //     loadUser();
  //   }, []);
  //use Memo

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        setSuperAdmin,
        superAdmin,
        user,
        setUser,
        permissionIds,
        setPermissionIds,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
