import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getProfileInfoApi = async () => {
  return axiosInstance.get(`${url}${API_NAME.profile_info_api}`);
};
export const getProfileByApi = async (id:string) => {
  return axiosInstance.get(`${url}/api/admins/${id}`);
};
export const updateProfileApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      password: string;
      roleId: string;
      ipAddress: string;
      name: string;
      email: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
