import React from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import AdminInformationOverview from "../../components/ui/admin/AdminInformationOverview";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { useGetAdminDetail } from "../../api/funs/admin";
import DetailAdminTable from "../../table/other/DetailAdminTable";
import SpinnerLoading from "../common/SpinnerLoading";

const AdminDetail: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data: adminDetail } = useGetAdminDetail(id as string);

  return (
    <>
      <div>
        <div className="md:pl-5">
          <BackArrowButton
            handleClick={() => navigate(RouteName.admins_list)}
          />
        </div>
      </div>
      {adminDetail ? (
        <div className="md:pl-5">
          <AdminInformationOverview adminDetail={adminDetail} />
          <DetailAdminTable adminDetail={adminDetail} />{" "}
        </div>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default AdminDetail;
