import { TimeFormatCell } from "../../cell/TimeFormatCell";

export const faqCol = [
  {
    accessorKey: "question", //simple recommended way to define a column
    header: "Question",
  },
  {
    accessorKey: "answer", //simple recommended way to define a column
    header: "Answer",
  },
  {
    accessorKey: "language", //simple recommended way to define a column
    header: "Language",
    filterVariant: "multi-select",
    filterSelectOptions: ["English", "Myanmar", "Chinese"],
  },
  {
    accessorKey: "date", //simple recommended way to define a column
    header: "Date",
    accessorFn: (originalRow: any) => new Date(originalRow?.date),
    Cell: TimeFormatCell,
    filterVariant: "date-range",
  },
];
