import React, { useState } from "react";
import DateFilter from "../../components/ui/bet/DateFilter";
import PreviousResultCard from "./PreviousResultCard";
import { useGetPreviousResultList } from "../../api/funs/rounds";
import dayjs from "dayjs";
import moment from "moment";
import SpinnerLoading from "../common/SpinnerLoading";
import { MdCampaign } from "react-icons/md";
import { useAtom } from "jotai";
import { modalAtom } from "../../store";

const PreviousResult: React.FC = () => {
  const [, setIsOpenModal] = useAtom(modalAtom);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data: previousResults, isLoading } = useGetPreviousResultList(
    "two-d",
    startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );

  const handleClick = () => {
    setIsOpenModal({ isOpen: true });

  }

  return (
    <div className="mt-7 md:mt-3">

      <div className="gap-4  md:flex">

         <div
        className="flex items-center justify-center bg-vermilion_bird rounded-[10px] py-[9px] px-3 gap-x-2 cursor-pointer mb-4 md:mb-0"
        onClick={handleClick}
      >
        <MdCampaign className="text-white text-[20px]" />
        <span className="text-[12px] md:text-[14px] text-white font-semibold">
          Announce Result{" "}
        </span>
        </div> 
        <DateFilter setStartDateP={setStartDate} setEndDateP={setEndDate} />

      </div>

      <>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <>
            {previousResults?.data?.data?.length === 0 ? (
              <div className="flex flex-col items-center justify-center flex-1 w-full h-[50vh]">
                <div className="block text-2xl font-bold text-dim_grey">
                  No Record...
                </div>
                <div className="block text-dim_grey text-[12px] mt-1">
                  There is no “2D” for this day. Please try to change the date
                  to see results.
                </div>
              </div>
            ) : (
              <>
                {previousResults?.data?.data?.map((_: any, i: number) => (
                  <PreviousResultCard
                    key={i}
                    winningNumber={_?.winningNumber}
                    set={_?.set}
                    value={_?.value}
                    resultDate={_?.resultDate}
                    roundType={_?.roundId?.roundName}
                    resultId={_?._id}
                    announceTime={_?.announceTime}
                  />
                ))}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default PreviousResult;
