import React, { ReactNode } from "react";
type Props = {
  headerdata: any[];
  customClass: string;
  children: ReactNode;
};
const Header = (props: Props) => {
  const { headerdata, children, customClass } = props;
  return (
    <table className={`view-grid-table ${customClass}`}>
      <thead>
        <tr>
          {headerdata.map((header, i) => (
            <th className="table-header">{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default Header;
