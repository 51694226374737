import React, { Dispatch, SetStateAction } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CalendarIcon } from "../../../pages/common/Icon";
import moment from "moment";
const DateFilter = ({
  setStartDateP,
  setEndDateP,
}: {
  setStartDateP: Dispatch<SetStateAction<null>>;
  setEndDateP: Dispatch<SetStateAction<null>>;
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="flex gap-x-2">
          {" "}
          <DesktopDatePicker
            format="DD MMM YYYY"
            label="From Date"
            slotProps={{
              textField: {
                size: "small",
                color: "error",
              },
            }}
            defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            onChange={(newValue: any) => {
              // const dayFormat = dayjs(newValue).date();

              setStartDateP(newValue);
            }}
          />
          <DesktopDatePicker
            format="DD MMM YYYY"
            label="To Date"
            slotProps={{
              textField: {
                size: "small",
                color: "error",
              },
            }}
            defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
            slots={{
              openPickerIcon: CalendarIcon,
            }}
            onChange={(newValue: any) => {
              // const dayFormat = dayjs(newValue).date();

              setEndDateP(newValue);
            }}
          />
        </div>
      </LocalizationProvider>
      {/* <div className="flex gap-x-3">
    //          <div className="inline-block px-6 py-2 bg-white rounded-[10px]">
    //   <div className="flex items-center gap-x-2">
    //     <span className="text-[14px] text-dim_grey">From Date</span>
    //     <img src={CalenderSvg} alt="calender" className="w-6 h-6 " />
    //   </div>
    //         </div>
    //         <div className="inline-block px-6 py-2 bg-white rounded-[10px]">
    //   <div className="flex items-center gap-x-2">
    //     <span className="text-[14px] text-dim_grey">To Date</span>
    //     <img src={CalenderSvg} alt="calender" className="w-6 h-6 " />
    //   </div>
    // </div>
                   </div> */}
    </>
  );
};

export default DateFilter;
