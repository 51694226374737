import React, { useState, useEffect } from "react";
import Gridtable from "../common/gridtable";
import { fetchData } from "../../services/fetch";
import Loading from "../common/loading";

const GameTypesList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const headerdata = [
    {
      name: "Game Type Name",
    },
    {
      name: "Round",
    },
  ];
  const [bodydata, setBodydata] = useState<any[]>([]);
  const fetchgametypesData = async () => {
    fetchData({
      url: "api/gametypes",

      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setBodydata(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchgametypesData();
  }, []);
  return (
    <div className="row">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Game Type List</h5>
          <Gridtable headerdata={headerdata} customClass="">
            {isLoading ? (
              <Loading></Loading>
            ) : (
              bodydata.map((body, i) => (
                <tr key={i}>
                  <td>{body?.name}</td>
                  <td>{body?.roundName}</td>
                </tr>
              ))
            )}
          </Gridtable>
        </div>
      </div>
    </div>
  );
};

export default GameTypesList;
