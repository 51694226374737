import React, { useMemo, useState } from "react";
import CustomizeTable from "../../table/CustomizeTable";
import { AmountMMKCell } from "../../table/cell/AmountMMKCell";
import { Autocomplete, TextField } from "@mui/material";
import SpinnerLoading from "../common/SpinnerLoading";
import { useGetAgentCodeList } from "../../api/funs/agent";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { CalendarIcon } from "../common/Icon";
import { useReport } from "../../api/funs/reports";
import ExportButton from "../../components/ui/buttons/ExportButton";

const ReportThreeDMonthlyPage = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const [date, setDate] = useState(null);
  const [agentType, setAgentType] = useState<any>({
    label: "All",
    value: "",
  });

  const { data: agentCodeList, isLoading } = useGetAgentCodeList();

  const agentCodeListAll = agentCodeList?.data?.data && [
    {
      _id: "",
      userCode: "All",
    },
    ...agentCodeList?.data?.data,
  ];

  const {
    data: reports,
    isLoading: reportLoading,
    error,
  } = useReport(
    "threed",
    agentType?.value as string,
    date ? `${dayjs(date).format("YYYY-MM")}` : `${moment().format("YYYY-MM")}`,
    "monthly"
  );

  const reportTwoDDailyMemozied = useMemo(() => {
    return reports?.data?.data?.monthlyReportData?.map((d: any, i: number) => ({
      agent: agentType?.label,
      // sr_no: `${pagination.pageIndex}${i}`,
      date: d?.betDate
        ? moment(d?.betDate).format("DD MMM YYYY")
        : moment().format("DD MMM YYYY"),
      bet_amount: d?.totalBetAmount,
      players: d?.players,
      // winning_amount: d?.totalWinningAmount || 0,
      // win_lose: d?.profit || 0,
    }));
  }, [reports, agentType?.label]);

  const memorizedCol = useMemo(() => {
    return [
      {
        accessorKey: "agent", //simple recommended way to define a column
        header: "Agent",
        Footer: () => <span className="font-semibold text-[16px]">Total</span>,
      },
      {
        accessorKey: "date", //simple recommended way to define a column
        header: "Date",
      },
      {
        accessorKey: "bet_amount", //simple recommended way to define a column
        header: "Bet Amount",
        Cell: AmountMMKCell,
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(
              +reports?.data?.data?.totals?.totalBetAmount || 0
            )?.toLocaleString()}
            <span className="text-[8px] ml-1 "></span>
          </span>
        ),
      },
      {
        accessorKey: "players", //simple recommended way to define a column
        header: "Players",
        Footer: () => (
          <span className="font-semibold text-[16px]">
            {(+reports?.data?.data?.totals?.totalPlayers)?.toLocaleString()}
          </span>
        ),
      },

      // {
      //   accessorKey: "winning_amount", //simple recommended way to define a column
      //   header: "Winning Amount",
      //   Cell: AmountMMKCell,
      //   aggregationFn: "mean",
      //   Footer: () => (
      //     <span className="font-semibold text-[16px]">
      //       {(+reports?.data?.data?.totals
      //         ?.totalWinningAmount)?.toLocaleString()}
      //       <span className="text-[8px] ml-1 "></span>
      //     </span>
      //   ),
      // },
      // {
      //   accessorKey: "win_lose", //simple recommended way to define a column
      //   header: "Win/Lose",
      //   Cell: AmountGreenMMKCell,
      //   aggregationFn: "mean",
      //   Footer: () => (
      //     <span className="font-semibold text-[16px]">
      //       {(+reports?.data?.data?.totals?.totalProfit)?.toLocaleString()}
      //       <span className="text-[8px] ml-1 "></span>
      //     </span>
      //   ),
      // },
    ];
  }, [reports]);

  return (
    <div className="mt-10 md:mt-3">
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="flex mb-4 gap-x-4">
            {agentCodeList && (
              <Autocomplete
                sx={{
                  "& .MuiAutocomplete-clearIndicator": { display: "none" },
                }}
                id="combo-box-demo"
                onChange={(e, value) => {
                  setAgentType(value);
                }}
                options={agentCodeListAll?.map((_e: any, i: any) => ({
                  label: _e?.userCode,
                  value: _e?._id,
                }))}
                defaultValue={agentType}
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...params}
                    label="Agent"
                    size="small"
                    defaultValue={"All"}
                    variant="outlined"
                  />
                )}
              />
            )}
            <DatePicker
              views={["month"]}
              format="MMM YYYY"
              defaultValue={dayjs(moment().format("YYYY-MM"))}
              label="Date"
              // value={date}
              onChange={(newValue: any) => setDate(newValue)}
              slotProps={{ textField: { size: "small", color: "error" },field:{
                readOnly:true
              }  }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
          </div>
          {reports?.data?.data?.threedResultWithWinLose?.map(
            (_: any, i: number) => (
              <div key={i}>
                <div className="flex items-center justify-between mb-3">
                  <p className="text-center text-vermilion_bird">
                    {moment(_?.resultDate, "YYYY-MM-DD").format("DD MMMM YYYY")}
                  </p>
                  <ExportButton
                    exportdata={[
                      {
                        name: "Bet",
                        qty: _?.totalBetCount?.toLocaleString(),
                        amount: _?.totalBettingAmount?.toLocaleString(),
                      },
                      {
                        name: "Player",
                        qty: _?.betPlayersCount?.toLocaleString(),
                        amount: "",
                      },
                      {
                        name: "Winning",
                        qty: _?.winningPlayersCount?.toLocaleString(),
                        amount: _?.totalWinningAmount?.toLocaleString(),
                      },
                      {
                        name: " Win/Lose",
                        qty: "",
                        amount: _?.totalLosingAmount?.toLocaleString(),
                      },
                    ]}
                    fileName={`threed_monthly_win_lose_report`}
                    type="3DmonthlyReport"
                    date={_?.resultDate}
                    // firstTotalValue={
                    //   reports?.data?.data?.totals?.totalBetAmount
                    // }
                    // secondTotalValue={reports?.data?.data?.totals?.totalPlayers}
                    // thirdTotalValue={reports?.data?.data?.totals?.totalWinningAmount}
                    // fouthTotalValue={reports?.data?.data?.totals?.totalProfit}
                  />{" "}
                </div>

                <table className="w-full mb-5 table-auto">
                  <tbody className="text-center border border-steam text-[14px]">
                    <tr>
                      <td className="py-2 font-semibold border border-steam">
                        Name
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        QTY
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        Amount
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold border border-steam">
                        Bet
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        {_?.totalBetCount?.toLocaleString()}
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        {_?.totalBettingAmount?.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold border border-steam">
                        Player
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        {_?.betPlayersCount?.toLocaleString()}
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam"></td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold border border-steam">
                        Winning
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        {_?.winningPlayersCount?.toLocaleString()}
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam text-grass_green">
                        {" "}
                        {_?.totalWinningAmount?.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 font-semibold border border-steam">
                        Win/Lose
                      </td>
                      <td className="py-2 font-semibold capitalize border border-steam">
                        {" "}
                      </td>
                      <td
                        className={`py-2 font-semibold capitalize border border-steam  ${
                          _?.totalLosingAmount <= 0
                            ? "text-vermilion_bird"
                            : "text-grass_green"
                        }`}
                      >
                        {" "}
                        {_?.totalLosingAmount?.toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          )}

          <div className="flex mb-2 gap-x-4">
            <ExportButton
              exportdata={reportTwoDDailyMemozied}
              fileName="threed_monthly_report"
              firstTotalValue={reports?.data?.data?.totals?.totalBetAmount}
              secondTotalValue={reports?.data?.data?.totals?.totalPlayers}
              // thirdTotalValue={reports?.data?.data?.totals?.totalWinningAmount}
              // fouthTotalValue={reports?.data?.data?.totals?.totalProfit}
            />{" "}
          </div>
          <CustomizeTable
            column={memorizedCol}
            data={reportTwoDDailyMemozied}
            totalCount={reportTwoDDailyMemozied?.length}
            isErroe={error}
            isRefetching={reportLoading}
            pagination={pagination}
            setPagination={setPagination}
            isShowAction={"inactive"}
          />
          <div className="flex mt-3 border border-steam">
            <div className="flex items-center justify-center flex-1 py-4 border-r border-steam">
              <span className=" text-[14px]">New Users</span>
            </div>
            <div className="flex items-center justify-center flex-1 py-4">
              <span className="text-vermilion_bird text-[14px]">
                {reports?.data?.data?.newUsers?.toLocaleString()}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportThreeDMonthlyPage;
