import React, { ReactNode, useCallback } from "react";
import "../../style/modal.scss"; // You can style the modal in a separate CSS file

interface ModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, onClose, children }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      {isOpen && (
        <div className="popup-modal-overlay">
          <div className="popup-modal">
            <div className="card">
              <div className="card-header container-fluid">
                <div className="row">
                  <div className="col-md-10">
                    <b>{title}</b>
                  </div>
                  <div className="float-right col-md-2">
                    <button
                      className="popup-close-button"
                      onClick={handleClose}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
