import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CalendarIcon, InfoBlueIcon } from "../../../../pages/common/Icon";
import { FiX } from "react-icons/fi";
import { useAtom } from "jotai";
import { modalAtom } from "../../../../store";
import {  useCreateAnnouncedAfter } from "../../../../api/mutation/rounds";
import moment from "moment";
import { useGetPreviousResultList, useGetRoundList } from "../../../../api/funs/rounds";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const AfterAnnounceResultModalContent = () => {

  const { data: roundsList } = useGetRoundList("2d");
  const [round, setRound] = useState<any>({
    label: "",
    value: "",
  });

  // const { mutate } = useGetFaqList(pagination);
  const { mutate } = useGetPreviousResultList(
    "two-d",
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  );

  const [, setIsOpenModal] = useAtom(modalAtom);
  const [date, setDate] = useState(null);



  const { control, handleSubmit, formState } = useForm<{
    winningNumber: string;
    set: string;
    value: string;
  }>({
    defaultValues: {
      winningNumber: "",
      set: "",
      value: "",
    },
  });
  const { errors } = formState;

  useEffect(() => {
    if (roundsList) {
      setRound({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
    }
  }, [roundsList]);

  const { trigger, isMutating } = useCreateAnnouncedAfter(
    "twod",
    round?.value as string
  );

  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };
  const onSubmit = (data: any) => {
    trigger({ ...data, resultAnnounceDate: date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") }, {
      onSuccess: (data) => {

        mutate()
        if (data.data.isSuccess) {
          handleClose();

        } else {
          toast.info(data?.data?.message, {
            position: "bottom-right",
          });
        }
      }
    });
  };
  return (
    <div className="relative flex flex-col px-3 py-3 bg-white rounded-md">
      <form
        className="flex flex-col "
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FiX className="absolute right-5 top-5" onClick={handleClose} />

        <p className="text-[1rem] text-dynamic_black font-semibold">
          Announce Result
        </p>
        <p className="text-[0.8rem] text-vermilion_bird">
          2D ( {round?.label} ){" "}
          <span className="text-dynamic_black">Result for</span>{" "}
          {date? dayjs(date).format("DD MM YYYY") :moment().format("DD MM YYYY")}
        </p>

        {roundsList && (
          <Autocomplete
            fullWidth
                sx={{
                  "& .MuiAutocomplete-clearIndicator": { display: "none" },
                }}
                id="combo-box-demo"
                onChange={(e, value) => {
                  setRound(value);
                }}
                value={round}
                options={roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))}
                defaultValue={{
                  label: roundsList?.data?.data?.map((_e: any, i: any) => ({
                    label: _e?.roundName,
                    value: _e?._id,
                  }))?.[0]?.label,
                  value: roundsList?.data?.data?.map((_e: any, i: any) => ({
                    label: _e?.roundName,
                    value: _e?._id,
                  }))?.[0]?.value,
                }}
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                renderInput={(params) => (
                  <TextField
                    style={{ 
                      marginBottom: "1.2rem",

                     }}
                    
                    autoComplete="off"
                    color="error"
                    {...params}
                    label="Select Round"
                    size="small"
                    defaultValue={round?.value}
                    variant="outlined"
                  />
                )}
              />
            )}

<DatePicker

          maxDate={dayjs()}
              format="DD MMM YYYY"
              defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
              label="Select Date"
              // value={date}
              onChange={(newValue: any) => setDate(newValue)}
              slotProps={{ textField: { size: "small", color: "error" }, field: {
                        readOnly: true
                    } }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />

        <Controller
          control={control}
          name="winningNumber"
          rules={{
            validate: {
              required: (value: any) => {
                if (value === "00") {
                  return undefined;
                }
                if (
                  !(value?.toString()?.length === 2) ||
                  !(+value > 0 && +value < 101)
                ) {
                  return "Please enter only two numbers.";
                }
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                inputProps={{ inputMode: "decimal" }}
                error={!!errors?.winningNumber}
                label="Result (e.g. 29)"
                placeholder="Result (e.g. 29)"
                sx={{
                  width: "20rem",
                  marginBottom: "1.2rem",
                  marginTop: "1.2rem",
                  ".MuiInputBase-input": {
                    fontSize: "1.2rem",
                    color: "#F44336",
                  },
                }}
                helperText={
                  !!errors?.winningNumber?.message &&
                  errors?.winningNumber?.message
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="set"
          rules={{
            validate: {
              required: (value: any) => {
                if (!value) {
                  return "Set is required.";
                }
                if (/^\d+(?:\.\d+)?$/.test(value) && value.includes(".")) {
                  return undefined;
                }

                return "Please enter float numbers.";
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Set (e.g. 1299.39)"
                error={!!errors?.set}
                label="Set"
                sx={{
                  width: "20rem",
                  marginBottom: "1.2rem",
                }}
                inputProps={{ inputMode: "decimal" }}
                helperText={!!errors?.set?.message && errors?.set?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="value"
          rules={{
            validate: {
              required: (value: any) => {
                if (!value) {
                  return "Value is required.";
                }
                if (/^\d+(?:\.\d+)?$/.test(value) && value.includes(".")) {
                  return undefined;
                }

                return "Please enter float numbers.";
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Value (e.g. 38992.99)"
                error={!!errors?.value}
                label="Value"
                sx={{
                  width: "20rem",
                  marginBottom: "1.2rem",
                }}
                helperText={!!errors?.value?.message && errors?.value?.message}
                inputProps={{ inputMode: "decimal" }}
              />
            );
          }}
        />

        <div className="flex items-start gap-x-3 mb-[1.2rem]">
          <InfoBlueIcon className="mt-1" />
          <span className=" w-[18rem] text-blizzy_blueberry text-[0.8rem]">
            Please enter the correct result and make sure you have double
            checked before confirming it. This action can not be edited later.
          </span>
        </div>
        <div className="grid flex-1 grid-cols-2 gap-x-5">
          <button
            className="flex items-center justify-center w-full py-2 font-semibold bg-lynx_white text-dim_grey rounded-[12px]"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className={`flex items-center justify-center w-full py-2 font-semibold text-white bg-vermilion_bird rounded-[12px] ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {" "}
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AfterAnnounceResultModalContent;
