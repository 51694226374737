import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { useGetUserByWinningHistory } from "../../../api/funs/user";
import { useParams } from "react-router-dom";
import { winningTransactionHistoryTableCol } from "../../../table/columns/user/winningTransactionHistory";
import moment from "moment";

const WinningTransactionTwoD = ({ betTabIndex }: { betTabIndex?: number }) => {
  const { id } = useParams();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const {
    data: winningHistory,
    error,
    isLoading,
  } = useGetUserByWinningHistory(id || "", pagination, "twod");

  const transferHistoryMemorized = useMemo(() => {
    return winningHistory?.data?.data?.map((d: any, i: number) => ({
      resultId: d?.twodResultId,
      winningNumber: d?.winningNumber,
      totalBetAmount: d?.totalBetAmount,
      totalWinningAmount: d?.totalWinningAmount,
      round: d?.roundName,
      announceTime: moment(moment(d?.announceTime, "HH:mm")).format("hh:mm A"),
      result_date: d?.resultDate,
      status: "UNCLAIMED",
      // date: d?.createdAt,
    }));
  }, [winningHistory]);
  return (
    <CustomizeTable
      column={winningTransactionHistoryTableCol}
      data={transferHistoryMemorized}
      totalCount={winningHistory?.data?.totalCounts}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
      menuType={"WinningHistory"}
      betTabIndex={betTabIndex}
    />
  );
};

export default WinningTransactionTwoD;
