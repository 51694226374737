import React from "react";
import BetWinningCard from "./BetWinningCard";
import TodayBetListCard from "./TodayBetListCard";

const BetCard = () => {
  return (
    <div className="grid md:grid-cols-2 gap-x-6 gap-y-3 md:gap-y-0">
      <BetWinningCard />
      <TodayBetListCard />
    </div>
  );
};

export default BetCard;
