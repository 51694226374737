import { useAtom } from "jotai";
import { modalAtom } from "../../../../store";
import { FiX } from "react-icons/fi";

import { Margin, usePDF } from "react-to-pdf";

import AgentInvoiceShowCard from "../../agent/AgentInvoiceShowCard";

const AgentInvoiceModalContent = () => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalAtom);

  const { toPDF, targetRef } = usePDF({
    method: "save",

    filename: `${
      isOpenModal?.showData?.data?.invoice_type
    }-${isOpenModal?.showData?.data?.invoice_month?.toLowerCase()}-agent-invoice`,

    page: {
      margin: Margin.MEDIUM,
      format: "A4",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
  });

  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };

  return (
    <>
      {isOpenModal?.showData?.data && (
        <>
          <div className="relative px-3 pt-8 pb-3 bg-white rounded-md ">
            <FiX className="absolute right-3 top-3" onClick={handleClose} />
            <AgentInvoiceShowCard agentInfo={isOpenModal?.showData?.data} />
            <div className="flex justify-end gap-4 mt-6">
              <div
                onClick={() => {
                  toPDF();

                  // handleClose();
                }}
                className="px-2 py-1 mx-0 bg-white border rounded-lg cursor-pointer w-fit border-red text-vermilion_bird"
              >
                Save Invoice
              </div>
            </div>
            <div
              ref={targetRef}
              className="absolute -left-[9999px] -top-[9999px] w-full"
            >
              <AgentInvoiceShowCard agentInfo={isOpenModal?.showData?.data} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AgentInvoiceModalContent;
