import useSWR from "swr";

import { getAdsBannersApi } from "../../ads-banner";

export const useGetAdsBannerList = () => {
  return useSWR(
    ["get-adsbanner"],
    () => getAdsBannersApi(),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
