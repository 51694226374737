import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import { useParams } from "react-router-dom";
import Modal from "../common/modal";
import Loading from "../common/loading";
interface NumberArrayManagerProps {
  initialNumbers: number[];
}

const RoundEdit: React.FC<NumberArrayManagerProps> = ({ initialNumbers }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [endTime, setEndTime] = useState("");
  const [gameTypeId, setGameTypeId] = useState("");
  const [winningNumber, setWinningNumber] = useState("");
  const [tootNumbers, setTootNumbers] = useState<number[]>(initialNumbers);
  const [message, setMessage] = useState("");
  const [validmessage, setValidMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [newNumber, setNewNumber] = useState<number>(0);

  const [gameType, setGameType] = useState<any[]>([]);

  const fetchgametypesData = async () => {
    fetchData({
      url: "api/admins/gametypes",

      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setGameType(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddNumber = () => {
    if (tootNumbers?.includes(newNumber)) {
      setValidMessage("Already exit Number");
    } else {
      setValidMessage("");
      setTootNumbers([...tootNumbers, newNumber]);
      setNewNumber(0);
    }
  };

  const handleRemoveNumber = (index: number) => {
    debugger;
    const updatedNumbers = [...tootNumbers];
    updatedNumbers.splice(index, 1);
    setTootNumbers(updatedNumbers);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/admins/rounds/" + id,
      data: {
        endTime: endTime,
        date: selectedDate,
        gameTypeId: gameTypeId,
        winningNumber: winningNumber,
        tootNumbers: tootNumbers,
      },
      method: "put",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setMessage(res.data.message);
          setIsModalOpen(true);
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchroundData = async () => {
    fetchData({
      url: "api/admins/rounds/" + id,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          let editdate = res?.data?.data?.date.substr(0, 10);
          setSelectedDate(editdate);
          setEndTime(res.data.data?.endTime);
          setGameTypeId(res.data.data?.gameTypeId?._id);
          setWinningNumber(res.data.data?.winningNumber);
          setTootNumbers(res.data.data?.tootNumbers);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchroundData();
    fetchgametypesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="container">
                {message ? (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                ) : (
                  <></>
                )}
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className="row g-3">
                    <div className="col-12 form-group">
                      <label
                        htmlFor="dateInput"
                        className="control-label required"
                      >
                        Date:
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="dateInput"
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="name" className="control-label required">
                        Group Type Name :
                      </label>
                      <select
                        className="form-input-select"
                        onChange={(e) => setGameTypeId(e.target.value)}
                        aria-label="Default select Game Type"
                      >
                        {gameType.map((game, i) =>
                          gameTypeId === game?._id ? (
                            <option value={game?._id} selected>
                              {game?.name} - {game?.roundName}
                            </option>
                          ) : (
                            <option value={game?._id}>
                              {game?.name} - {game?.roundName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="col-12">
                      <label htmlFor="winnernumber">Winner Number :</label>
                      <input
                        type="number"
                        className="form-control"
                        id="winnernumber"
                        placeholder="Enter Winner Number"
                        value={winningNumber}
                        onChange={(e) => setWinningNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="time" className="control-label required">
                        Time :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="time"
                        placeholder="Enter Time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                    </div>
                    <div className="col-md-8">
                      <label htmlFor="tootnumber">Toot Number :</label>
                      <input
                        className="form-control col-8"
                        type="number"
                        value={newNumber}
                        onChange={(e) =>
                          setNewNumber(parseInt(e.target.value, 10))
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="tootnumber"></label>
                      <div className="mt-10 col-3" onClick={handleAddNumber}>
                        <i className="bi bi-plus-square"></i>
                      </div>
                    </div>
                    {validmessage ? (
                      <div className="alert alert-danger" role="alert">
                        {validmessage}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-12">
                      <p>Click on a number to remove it:</p>
                      {tootNumbers.map((number, index) => (
                        <span
                          key={index}
                          onClick={() => handleRemoveNumber(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {number}{" "}
                        </span>
                      ))}
                    </div>
                    <div className="col-3">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        Edit
                      </button>
                    </div>
                    <div className="col-3">
                      <Link to="/holiday/list" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Round Edit" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/round/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RoundEdit;
