import { Modal } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { modalAtom } from "../../../store";
import AnnounceResultModalContent from "./modal-content/AnnounceResultModalContent";
import { useLocation, useParams } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";
import AnnounceThreeDResultModalContent from "./modal-content/AnnounceThreeDResultModalContent";
import CredentialModalContent from "./modal-content/CredentialModalContent";
import TokenExpiredModalContent from "./modal-content/TokenExpiredModalContent";
import AgentInvoiceModalContent from "./modal-content/AgentInvoiceModalContent";
import RoundActiveUnActiveModalContent from "./modal-content/RoundActiveUnActiveModalContent";
import AgentVerifiedBlockModalContent from "./modal-content/AgentVerifiedBlockModalContent";
import UserVerifiedBlockModalContent from "./modal-content/UserVerifiedBlockModalContent";
import AdminVerifiedBlockModalContent from "./modal-content/AdminVerifiedBlockModalContent";
import AfterAnnounceResultModalContent from "./modal-content/AfterAnnounceResultModalContent";

const CustomizeModal = () => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const [isOpenModal, setIsOpenModal] = useAtom(modalAtom);
  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };

  const tokenExpiredCondi = JSON.parse(
    localStorage.getItem("token_expired") ||
      JSON.stringify({ token_expired: false })
  );

  return (
    <Modal
      className="z-10"
      // open={true}
      open={isOpenModal.isOpen || tokenExpiredCondi?.token_expired}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <>
        {tokenExpiredCondi?.token_expired ? (
          <TokenExpiredModalContent />
        ) : pathname === RouteName.twod_betlist &&
          !tokenExpiredCondi?.token_expired ? (
          <AnnounceResultModalContent />
        ) : pathname === RouteName.threed_betlist &&
          !tokenExpiredCondi?.token_expired ? (
          <AnnounceThreeDResultModalContent />
        ) : [RouteName.agents_create].includes(pathname) ? (
          <CredentialModalContent />
        ) : [`/agent/detail/${id}`].includes(pathname) &&
          isOpenModal?.showData?.type === "saveInvoice" ? (
          <AgentInvoiceModalContent />
        ) : [`/twod/roundsettings`].includes(pathname) &&
          !tokenExpiredCondi?.token_expired ? (
          <RoundActiveUnActiveModalContent />
        ) : [`/twod/roundsettings`].includes(pathname) &&
          !tokenExpiredCondi?.token_expired ? (
          <RoundActiveUnActiveModalContent />
        ) : [RouteName.agents_list, `/agent/detail/${id}`].includes(pathname) &&
          isOpenModal?.showData?.type !== "saveInvoice" ? (
          <AgentVerifiedBlockModalContent />
        ) : [RouteName.users_userlist, `/users/detail/${id}`].includes(
            pathname
          ) &&
          !tokenExpiredCondi?.token_expired &&
          isOpenModal?.showData?.type !== "saveInvoice" ? (
          <UserVerifiedBlockModalContent />
        ) : [RouteName.admins_list, `/admins/detail/${id}`].includes(
            pathname
          ) &&
          !tokenExpiredCondi?.token_expired &&
          isOpenModal?.showData?.type !== "saveInvoice" ? (
          <AdminVerifiedBlockModalContent />
        ) :!tokenExpiredCondi?.token_expired && pathname ==="/twod/previousresults" ? <AfterAnnounceResultModalContent />: (
          ""
        )}
      </>
    </Modal>
  );
};

export default CustomizeModal;
