import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useGetTernsConditionByLang } from "../api/funs/terms&condition";
import { useUpdateTermsCondition } from "../api/mutation/terms&condition/indext";
import { CircularProgress } from "@mui/material";
const EditTermConditionForm = () => {
  const { lang } = useParams();
  const { data: termsDetail } = useGetTernsConditionByLang(lang);

  const { trigger, isMutating } = useUpdateTermsCondition(lang);

  const { control, handleSubmit, formState, reset } = useForm<{
    terms: string;
  }>({
    values: {
      terms: termsDetail?.data?.data?.terms?.terms
        ?.replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">"),
    },
    defaultValues: {
      terms: termsDetail?.data?.data?.terms?.terms
        ?.replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">"),
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    trigger({
      terms: data?.terms,
    });

    reset();
  };
  return (
    <div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <p className="">
          Language :{" "}
          {termsDetail?.data?.data?.terms?.language === "en"
            ? "English"
            : termsDetail?.data?.data?.terms?.language === "mm"
            ? "Myanmar"
            : "Chinese"}
        </p>
        <label className="text-[14px]">Term & Condition:</label>
        <Controller
          control={control}
          name="terms"
          rules={{
            validate: {
              required: (value: any) => {
                if (["<p><br></p>", "<h1><br></h1>"].includes(value)) {
                  return "Terms is required.";
                }
                if (!value) return "Terms is required.";
              },
            },
            minLength: 11,
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <ReactQuill
                {...field}
                placeholder={"Write Description"}
                onChange={(text) => {
                  field.onChange(text);
                }}
              />
            );
          }}
        />
        <span className="text-[14px] text-red-600">
          {errors?.terms?.message}
        </span>
        <div className="flex items-center justify-between w-full mt-2">
          <div></div>
          <button
            type="submit"
            className={`btn-create-active ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Update"
            )}
          </button>
        </div>
        <DevTool control={control} />
      </form>
    </div>
  );
};

export default EditTermConditionForm;
