import React from "react";
import { CONTENT } from "../../../content";

const BetTab = ({
  betTabIndex,
  setBetTabIndex,
}: {
  betTabIndex?: number;

  setBetTabIndex?: any;
}) => {
  // const [betTabIndex, setBetTabIndex] = useAtom(betTabAtom);
  return (
    <div className="flex mb-2">
      {CONTENT.user_detail.betTab.array.map((_d: string, i: number) => (
        <div
          key={i}
          className={`px-4 py-2 md:px-5 md:py-2 border-b-[2px] cursor-pointer  ${
            betTabIndex === i
              ? "border-vermilion_bird text-vermilion_bird"
              : ""
          }`}
          onClick={() => {
            setBetTabIndex(i);
          }}
        >
          <span className="text-sm font-semibold md:text-base">{_d}</span>
        </div>
      ))}
    </div>
  );
};

export default BetTab;
