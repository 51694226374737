// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RichTextEditor.css */
.ql-toolbar {
  border-bottom: 1px solid #dee2e6;
}

.ql-container {
  font-size: 16px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/pages/common/RichTextEditor.scss"],"names":[],"mappings":"AAAA,uBAAA;AACA;EACI,gCAAA;AACJ;;AAEE;EACE,eAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;AACJ","sourcesContent":["/* RichTextEditor.css */\n.ql-toolbar {\n    border-bottom: 1px solid #dee2e6;\n  }\n  \n  .ql-container {\n    font-size: 16px;\n    border: 1px solid #dee2e6;\n    border-radius: 0.25rem;\n    height: 300px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
