import {
  CheckCircleIcon,
  EditIcon,
  UnCheckCircleIcon,
} from "../../../pages/common/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import IOSSwitchAction from "../../../table/actions/IOSSwitchAction";
import { useProfileInfo } from "../../../api/funs/profile";
import { permissionValidate } from "../../../util/funs";
import { CONTENT } from "../../../content";

const AdminInformationOverview = ({ adminDetail }: any) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: profileInfo } = useProfileInfo();

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="">
          <span className="text-[14px] md:text-[18px] font-bold ">{adminDetail?.data?.data?.name}</span>
          <span className="font-semibold text-[12px] md:text-[16px]  block">
          {adminDetail?.data?.data?.userCode}
        </span>
        <span className="font-normal text-[10px] md:text-[14px] block">
          {adminDetail?.data?.data?.phone}
        </span>
        </div>
        {pathname === `/twod/betlist/user/${id}` ? null : (
          <div className="flex flex-col items-end gap-y-2 md:gap-y-0 md:items-center md:flex md:flex-row">
            {adminDetail?.data?.data?.status ? (
              <span className="flex items-center p-r-10 gap-x-1">
                <CheckCircleIcon /> Verified
              </span>
            ) : (
              <span className="flex items-center p-r-10 gap-x-1">
                <UnCheckCircleIcon /> Blocked
              </span>
            )}

            {permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.ADMIN_ACTIVE_BLOCK_TOGGLE
            ) && <IOSSwitchAction detail={adminDetail?.data?.data} />}

            {/* <CustomSwitch
              status={adminDetail?.data?.data?.status}
              handleSwitch={handleToggle}
            /> */}

            <div
              className="flex gap-x-2 items-center px-2 py-1 rounded-[10px]  ml-3 border border-steam bg-white"
              onClick={() => {
                navigate(`/admins/edit/${id}`);
              }}
            >
              <EditIcon /> <span>Edit</span>
            </div>
          </div>
        )}
      </div>
     
    </>
  );
};

export default AdminInformationOverview;
