import { TimeFormatCell } from "../../cell/TimeFormatCell";
import { ActiveBlockCell } from "../../cell/ActiveBlockCell";
import { AmountMMKCell } from "../../cell/AmountMMKCell";
import { PercentageCell } from "../../cell/PercentageCell";
import { AvatarCell } from "../../cell/AvatarCell";
import { VerifiedCell } from "../../cell/VerifiedCell";
import { PRoundCell } from "../../cell/PRoundCell";
import { InvoiceButtonCell } from "../../cell/InvoiceButtonCell";

export const agentTableCol = [
  {
    accessorKey: "agent_id", //simple recommended way to define a column
    header: "Agent ID",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "agent", //simple recommended way to define a column
    header: "Agent",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "status", //simple recommended way to define a column
    header: "Status",
    Cell: ActiveBlockCell,
    filterVariant: "checkbox",
    accessorFn: (originalRow: any) => (originalRow.status ? "true" : "false"), //must be strings
    id: "status",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "p_round", //simple recommended way to define a column
    header: "P.Round (2D)",
    Cell: PRoundCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "est_live_date", //simple recommended way to define a column
    header: "Est.Live Date",
    Cell: TimeFormatCell,
    accessorFn: (originalRow: any) => new Date(originalRow?.est_live_date), //convert to date for sorting and filtering
    filterVariant: "date-range",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },

  {
    accessorKey: "domain", //simple recommended way to define a column
    header: "Domain",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "email", //simple recommended way to define a column
    header: "Email",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  // {
  //   accessorKey: "ip_address", //simple recommended way to define a column
  //   header: "IPAddress",
  //   Cell: IpAddressCell,

  //   // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
  //   // enableHiding: false, //disable a feature for this column
  // },
  {
    accessorKey: "receiving_mail", //simple recommended way to define a column
    header: "Receiving Mail",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  // {
  //   // accessorFn: (originalRow) => parseInt(originalRow?.age), //alternate way
  //   id: "age", //id required if you use accessorFn instead of accessorKey
  //   header: "Age",
  //   Header: <i style={{ color: "red" }}>Age</i>, //optional custom markup
  //   // Cell: ({ cell }) => <i>{cell.getValue<number>().toLocaleString()}</i>, //optional custom cell render
  // },
];
export const invoiceAgentCol = [
  {
    accessorKey: "invoice_month", //simple recommended way to define a column
    header: "Invoice Month",
  },

  {
    accessorKey: "invoice_type", //simple recommended way to define a column
    header: "Invoice Type",
  },

  {
    accessorKey: "total_bet_amount", //simple recommended way to define a column
    header: "Total Bet Amount",
    Cell: AmountMMKCell,
  },
  {
    accessorKey: "percentage", //simple recommended way to define a column
    header: "Percentage",
    Cell: PercentageCell,
  },
  {
    accessorKey: "invoice_status", //simple recommended way to define a column
    header: "Paid/UnPaid",
    Cell: InvoiceButtonCell,
  },
  {
    accessorKey: "total_invoice_amount", //simple recommended way to define a column
    header: "Total Invoice Amount",
    Cell: AmountMMKCell,
  },
  // {
  //   accessorKey: "status", //simple recommended way to define a column
  //   header: "Status",
  //   Cell:CollectCell
  // },
];
export const invitedUsersAgentCol = [
  {
    accessorKey: "user_id", //simple recommended way to define a column
    header: "User ID",
  },
  {
    accessorKey: "User", //simple recommended way to define a column
    header: "phone",
    Cell: AvatarCell,
  },
  {
    accessorKey: "status", //simple recommended way to define a column
    header: "Status",
    Cell: VerifiedCell,
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.reg_date),
    accessorKey: "reg_date", //simple recommended way to define a column
    header: "Reg Date",
    filterVariant: "date-range",
    Cell: TimeFormatCell,
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.last_login),
    accessorKey: "last_login", //simple recommended way to define a column
    header: "Last Login",
    filterVariant: "date-range",
    Cell: TimeFormatCell,
  },
];
