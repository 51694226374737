import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const createAdminApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      phone: string;
      password: string;
      ipAddress: string;
      roleId: string;
      name: string;
      email: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};

export const getAdminListApi = async (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter: string
) => {
  const axios_url = new URL(`${url}${API_NAME.admins_list_api}`);
  axios_url.searchParams.set("keyword", globalFilter ?? "");

  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());

  return axiosInstance.get(axios_url);
};
export const getAdminDetailApi = async (id: string) => {
  const axios_url = new URL(`${url}/api/admins/${id}`);

  return axiosInstance.get(axios_url);
};

export const updateAdminApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      phone: string;
      password: string;
      ipAddress: string;
      roleId: string;
      name: string;
      email: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
