import React from "react";
import RootRouter from "./app/routes";
import "react-datepicker/dist/react-datepicker.css";


const App: React.FC = () => {
  return (
    <>
      <RootRouter />
    </>
  );
};

export default App;
