import React from "react";
import { CONTENT } from "../../content";
import { format } from "date-fns";
import { CheckCircleIcon, UnCheckCircleIcon } from "../../pages/common/Icon";
import CryptoJS from "crypto-js";

const DetailAdminTable = ({ adminDetail }: any) => {
  const decryptHashedData = (hashData: string, key: string) => {
    const decryptData = hashData && CryptoJS.AES.decrypt(hashData, key);
    return JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
  };

  return (
    <div className="mt-5">
      <table className="w-full table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.admin_detail.array.map((_d, i) => (
            <tr key={i} className="">
              <td className="py-3 text-xs border md:text-base border-steam">{_d}</td>
              <td className="py-3 font-semibold border border-steam">
              <span className="text-xs md:text-base ">

              {i === 0 ? (
                  adminDetail?.data?.data?.roleId?.name
                ) : i === 1 ? (
                  adminDetail?.data?.data?.userCode
                ) : i === 2 ? (
                  adminDetail?.data?.data?.phone
                ) : i === 3 ? (
                  `${format(new Date(new Date()), "dd MMM  yyyy")} at ${format(
                    new Date(adminDetail?.data?.data?.createdAt),
                    "hh:mm:ss a"
                  )}`
                ) : i === 4 ? (
                  adminDetail?.data?.data?.createdBy
                ) : i === 5 ? (
                  adminDetail?.data?.data?.status ? (
                    <span className="flex items-center justify-center gap-x-1">
                      <CheckCircleIcon />{" "}
                      <span className="font-normal">Verified</span>
                    </span>
                  ) : (
                    <span className="flex items-center justify-center gap-x-1">
                      <UnCheckCircleIcon />{" "}
                      <span className="font-normal">Blocked</span>
                    </span>
                  )
                ) : (
                  ""
                )}
              </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="w-full my-5 table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.admin_detail.array2.map((_d, i) => (
            <tr key={i} className="">
              <td className="w-16 py-3 text-xs border md:text-base border-steam">{_d}</td>
              <td className="w-16 py-3 text-xs font-semibold capitalize border md:text-base border-steam ">
                {i === 0
                  ? adminDetail?.data?.data?.encryptPassword &&
                    decryptHashedData(
                      adminDetail.data?.data.encryptPassword,
                      "AIO_PASSWORD_ENCRYPT"
                    )
                  : i === 1
                  ?  <div className="">
                  <div className="grid grid-cols-1 place-items-center gap-y-2">
                    {adminDetail?.data?.data?.loginIpAddresses?.map(
                      (_d: any, i: number) => (
                        <div
                          key={i}
                          className="px-2 md:px-0 md:w-[200px] h-[33px] flex justify-center items-center rounded-md bg-goldenrod text-dynamic_black"
                        >
                          {_d}
                        </div>
                      )
                    )}
                  </div>
                </div>
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailAdminTable;
