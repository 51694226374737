import React, { PropsWithChildren } from "react";
import Drawer from "./Drawer";
import { Outlet, useLocation } from "react-router-dom";

interface PageLayoutProps extends PropsWithChildren {}
const PageLayout: React.FC<PageLayoutProps> = ({
  children,
}: PageLayoutProps) => {
  const { pathname } = useLocation();
  return (
    <div className="w-full h-screen overflow-auto">
      {pathname === "/login" ? (
        <Outlet />
      ) : (
        <Drawer>
          <Outlet />
        </Drawer>
      )}
    </div>
  );
};

export default PageLayout;
