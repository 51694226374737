import useSWR from "swr";
import { API_NAME } from "../../constant";
import { getAdminDetailApi, getAdminListApi } from "../../admin";

export const useGetAdminList = (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter: string
) => {
  return useSWR(
    [API_NAME.admins_list_api, pageIndex, pageSize, globalFilter],
    () => getAdminListApi({ pageIndex, pageSize }, globalFilter),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetAdminDetail = (id?: string) => {
  return useSWR(
    id ? [API_NAME.admins_edit_id_api, id] : null,
    () => getAdminDetailApi(id as string),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
