import React from "react";
import { format } from "date-fns";
import { CONTENT } from "../../../content";
import { useParams } from "react-router-dom";
import { useGetUserByOverview } from "../../../api/funs/user";

const UserOverview = () => {
  const { id } = useParams();
  const { data: userOverview } = useGetUserByOverview(id);

  return (
    <div>
      <div className="mb-4 md:grid md:grid-cols-2 gap-x-4 gap-y-3">
        <div className="bg-grass_green rounded-[10px] px-4 py-2 flex flex-col justify-between mb-4 md:mb-0">
          <div>
            <span className="text-white text-[10px]">Total Winning</span>
            <p className="text-white text-[28px] font-semibold">
              {(+userOverview?.data?.data?.totalWinning || 0).toLocaleString()}{" "}
              <span className="text-[12px] md:text-[14px] font-normal"></span>
            </p>
          </div>

          <span className="text-[12px] md:text-[14px] font-normal text-white mb-3">
            Last won in{" "}
            {`${format(new Date(new Date()), "dd MMM  yyyy")} at ${format(
              new Date(new Date()),
              "hh:mm:ss a"
            )}`}
          </span>
        </div>

        <div className="flex flex-col gap-y-5">
          {CONTENT.user_detail.overview.array2.map((_y, i) => (
            <div className="bg-lynx_white rounded-[10px] px-4 py-2" key={i}>
              <span className=" text-[12px] text-dynamic_black mb-2 font-semibold">
                {_y}
              </span>

              <p
                className={`${
                  i === 0 ? "text-dynamic_black" : "text-vermilion_bird"
                } text-[16px] font-semibold`}
              >
                {i === 0
                  ? (
                      +userOverview?.data?.data?.totalBetting || 0
                    ).toLocaleString()
                  : i === 1
                  ? (+userOverview?.data?.data?.totalLost || 0).toLocaleString()
                  : ""}{" "}
                <span className="text-[14px] font-normal"></span>
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-x-4 gap-y-5 md:gap-y-3">
        {CONTENT.user_detail.overview.array.map((_d, i) => (
          <div key={i} className="bg-lynx_white rounded-[10px] px-4 py-2">
            <span className=" text-[12px] text-dynamic_black mb-2 font-semibold">
              {_d}
            </span>

            <p
              className={`${
                i === 0
                  ? "text-dynamic_black "
                  : i === 1
                  ? "text-grass_green "
                  : i === 2
                  ? "text-grass_green "
                  : i === 3
                  ? "text-vermilion_bird "
                  : ""
              }text-[16px] font-semibold`}
            >
              {i === 0
                ? (
                    +userOverview?.data?.data?.totalBetTime || 0
                  ).toLocaleString()
                : i === 1
                ? (
                    +userOverview?.data?.data?.totalWonTime || 0
                  ).toLocaleString()
                : i === 2
                ? (0).toLocaleString()
                : i === 3
                ? (
                    +userOverview?.data?.data?.totalWithdraw || 0
                  ).toLocaleString()
                : 0}{" "}
              {[0, 1].includes(i) ? (
                <></>
              ) : (
                <span className="text-[14px] font-normal"></span>
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserOverview;
