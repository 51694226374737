import React, { useEffect } from "react";
import { MdCampaign, MdCheckCircle } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { useAtom } from "jotai";
import { modalAtom, roundInfoAtom } from "../../../store";
import {
  useGetPreviousResultList,
  useGetRoundList,
} from "../../../api/funs/rounds";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";
import dayjs from "dayjs";

const BetHeadCard = ({
  betTypeTrue,
  round,
  date,
}: {
  betTypeTrue?: boolean;
  round?: {
    label?: string;
    value?: string;
  };
  date?: string | null;
}) => {
  const [, setIsOpenModal] = useAtom(modalAtom);

  const { data: roundsList } = useGetRoundList("2d");
  const { data: rounds3DList } = useGetRoundList("3d");
  const [, setRoundId] = useAtom(roundInfoAtom);

  const { pathname } = useLocation();

  const betType = pathname === "/twod/betlist" ? "two-d" : "three-d";
  const { data: previousResult } = useGetPreviousResultList(
    betType,

    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
  );

  const roundResultRound1 = previousResult?.data?.data?.find(
    (_e: any) => _e?.roundId?.roundName === round?.label
  );
  const roundResultRound2 = previousResult?.data?.data?.find(
    (_e: any) => _e?.roundId?.roundName === round?.label
  );
  const roundResultRound3 = previousResult?.data?.data?.find(
    (_e: any) => _e?.roundId?.roundName === round?.label
  );
  const roundResultRound4 = previousResult?.data?.data?.find(
    (_e: any) => _e?.roundId?.roundName === round?.label
  );

  const winningResult =
    roundsList?.data?.data?.[1]?.endTime > moment().format("HH:mm")
      ? roundResultRound1
      : roundsList?.data?.data?.[2]?.endTime > moment().format("HH:mm")
      ? roundResultRound2
      : roundsList?.data?.data?.[3]?.endTime > moment().format("HH:mm")
      ? roundResultRound3
      : roundResultRound4;

  const handleClick = () => {
    if (round) {
      setRoundId(round);
    }

    setIsOpenModal({ isOpen: true });
  };

  useEffect(() => {
    if (pathname === "/twod/betlist") {
      // setRoundId(round);
    } else {
      setRoundId({
        label: rounds3DList?.data?.data?.[0]?.RoundName,
        value: rounds3DList?.data?.data?.[0]?._id,
      });
    }
  }, [rounds3DList, setRoundId, pathname]);
  // console.log("GGG",rounds3DList?.data?.data)

  return (
    <div className="md:grid md:grid-cols-2 px-3 md:px-10 mb-4 md:mb-0 gap-x-10  bg-white rounded-[10px] py-3 md:py-4">
      <div className="mb-2 md:mb-0">
        <p className="font-bold text-dynamic_black">
          {" "}
          {date
            ? dayjs(date).format("DD MMM YYYY")
            : moment().format("DD MMM YYYY")}
          , {round?.label}
        </p>
        <div>
          <p className="mb-4 text-[12px] font-normal text-dim_grey">
            Coming soon!
          </p>
          <div className="grid items-center grid-cols-3 ">
            {betTypeTrue ? (
              <div className="flex mr-3 gap-x-2 md:mr-0">
                <div className="flex justify-center items-center w-[50px] h-[60px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
                  {previousResult?.data?.data?.[0]?.winningNumber?.[0] || "-"}
                </div>
                <div className="flex justify-center items-center w-[50px] h-[60px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
                  {previousResult?.data?.data?.[0]?.winningNumber?.[1] || "-"}
                </div>
                <div className="flex justify-center items-center w-[50px] h-[60px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
                  {previousResult?.data?.data?.[0]?.winningNumber?.[2] || "-"}
                </div>
              </div>
            ) : (
              <div className="flex mr-3 gap-x-2 md:mr-0">
                <div className="flex justify-center items-center w-[50px] h-[60px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
                  {winningResult?.winningNumber?.[0] || "-"}
                </div>
                <div className="flex justify-center items-center w-[50px] h-[60px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
                  {winningResult?.winningNumber?.[1] || "-"}
                </div>
              </div>
            )}

            {betTypeTrue ? (
              <></>
            ) : (
              <>
                <div>
                  <span className="text-dim_gray text-[12px]">Set</span>
                  <br />
                  <span className="font-semibold">
                    {winningResult?.set || "--"}
                  </span>
                </div>
                <div>
                  <span className="text-dim_gray text-[12px]">Value</span>
                  <br />
                  <span className="font-semibold">
                    {winningResult?.value || "--"}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-full  border border-steam rounded-[10px] py-2 px-4 flex flex-col justify-evenly">
        {pathname === RouteName.twod_betlist ? (
          <>
            <div>
              {roundsList?.data?.data?.map((_d: any, i: number) => (
                <div key={i}>
                  {i === 0 && round?.label === _d?.roundName ? (
                    moment().format("HH:mm") >
                      moment(_d?.announceTime, "HH:mm")?.format("HH:mm") ||
                    roundResultRound1 ? (
                      <span
                        className={`flex items-center mb-2 gap-x-1 `}
                        key={i}
                      >
                        <MdCheckCircle className="text-green-500 text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet opened at {moment().format("DD MMM YYYY")} at{" "}
                          {moment(_d?.announceTime, "HH:mm")?.format(
                            "hh:mm:ss a"
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="flex items-center gap-x-1">
                        <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet will be closed at {moment().format("DD MMM YYYY")}{" "}
                          at{" "}
                          {moment(_d?.endTime, "HH:mm")?.format("hh:mm:ss a")}
                        </span>
                      </span>
                    )
                  ) : i === 1 && round?.label === _d?.roundName ? (
                    moment().format("HH:mm") >
                      moment(_d?.announceTime, "HH:mm")?.format("HH:mm") ||
                    roundResultRound2 ? (
                      <span
                        className={`flex items-center mb-2 gap-x-1 `}
                        key={i}
                      >
                        <MdCheckCircle className="text-green-500 text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet opened at {moment().format("DD MMM YYYY")} at{" "}
                          {moment(_d?.announceTime, "HH:mm")?.format(
                            "hh:mm:ss a"
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="flex items-center gap-x-1">
                        <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet will be closed at {moment().format("DD MMM YYYY")}{" "}
                          at{" "}
                          {moment(_d?.endTime, "HH:mm")?.format("hh:mm:ss a")}
                        </span>
                      </span>
                    )
                  ) : i === 2 && round?.label === _d?.roundName ? (
                    moment().format("HH:mm") >
                      moment(_d?.announceTime, "HH:mm")?.format("HH:mm") ||
                    roundResultRound3 ? (
                      <span
                        className={`flex items-center mb-2 gap-x-1 `}
                        key={i}
                      >
                        <MdCheckCircle className="text-green-500 text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet opened at {moment().format("DD MMM YYYY")} at{" "}
                          {moment(_d?.announceTime, "HH:mm")?.format(
                            "hh:mm:ss a"
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="flex items-center gap-x-1">
                        <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet will be closed at {moment().format("DD MMM YYYY")}{" "}
                          at{" "}
                          {moment(_d?.endTime, "HH:mm")?.format("hh:mm:ss a")}
                        </span>
                      </span>
                    )
                  ) : i === 3 && round?.label === _d?.roundName ? (
                    moment().format("HH:mm") >
                      moment(_d?.announceTime, "HH:mm")?.format("HH:mm") ||
                    roundResultRound4 ? (
                      <span
                        className={`flex items-center mb-2 gap-x-1 `}
                        key={i}
                      >
                        <MdCheckCircle className="text-green-500 text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet opened at {moment().format("DD MMM YYYY")} at{" "}
                          {moment(_d?.announceTime, "HH:mm")?.format(
                            "hh:mm:ss a"
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="flex items-center gap-x-1">
                        <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                        <span className="text-[12px] md:text-[14px]">
                          Bet will be closed at {moment().format("DD MMM YYYY")}{" "}
                          at{" "}
                          {moment(_d?.endTime, "HH:mm")?.format("hh:mm:ss a")}
                        </span>
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>

            {round?.label === roundsList?.data?.data?.[0]?.roundName &&
              roundsList?.data?.data?.[0]?.endTime < moment().format("HH:mm") &&
              roundsList?.data?.data?.[0]?.untilEndTime >
                moment().format("HH:mm") &&
              !winningResult && (
                <div
                  className="flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2 cursor-pointer"
                  onClick={handleClick}
                >
                  <MdCampaign className="text-white text-[20px]" />
                  <span className="text-[12px] md:text-[14px] text-white font-semibold">
                    Announce Result{" "}
                  </span>
                </div>
              )}
            {round?.label === roundsList?.data?.data?.[1]?.roundName &&
              moment(roundsList?.data?.data?.[1]?.endTime, "HH:mm")?.format(
                "HH:mm"
              ) < moment().format("HH:mm") &&
              // "12:40" > moment().format("HH:mm") && (
              moment(
                roundsList?.data?.data?.[1]?.untilEndTime,
                "HH:mm"
              )?.format("HH:mm") > moment().format("HH:mm") &&
              !winningResult && (
                <div
                  className="cursor-pointer flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2"
                  onClick={handleClick}
                >
                  <MdCampaign className="text-white text-[20px]" />
                  <span className="text-[12px] md:text-[14px] text-white font-semibold">
                    Announce Result{" "}
                  </span>
                </div>
              )}
            {round?.label === roundsList?.data?.data?.[2]?.roundName &&
              roundsList?.data?.data?.[2]?.endTime < moment().format("HH:mm") &&
              roundsList?.data?.data?.[2]?.untilEndTime >
                moment().format("HH:mm") &&
              !winningResult && (
                <div
                  className="cursor-pointer flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2"
                  onClick={handleClick}
                >
                  <MdCampaign className="text-white text-[20px]" />
                  <span className="text-[12px] md:text-[14px] text-white font-semibold">
                    Announce Result{" "}
                  </span>
                </div>
              )}
            {round?.label === roundsList?.data?.data?.[3]?.roundName &&
              roundsList?.data?.data?.[3]?.endTime < moment().format("HH:mm") &&
              roundsList?.data?.data?.[3]?.untilEndTime >
                moment().format("HH:mm") &&
              !winningResult && (
                <div
                  className="flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2 cursor-pointer"
                  onClick={handleClick}
                >
                  <MdCampaign className="text-white text-[20px]" />
                  <span className="text-[12px] md:text-[14px] text-white font-semibold">
                    Announce Result{" "}
                  </span>
                </div>
              )}
          </>
        ) : (
          <>
            <div>
              {(moment().format("HH:mm") >
                moment(
                  rounds3DList?.data?.data?.[0]?.untilEndTime,
                  "HH:mm"
                )?.format("HH:mm") && moment().format("YYYY-MM-DD") === moment(rounds3DList?.data?.data?.[0]?.endDate).format("YYYY-MM-DD")) ||
              previousResult?.data?.data?.[0]?.winningNumber ? (
                <span className={`flex items-center mb-2 gap-x-1 `}>
                  <MdCheckCircle className="text-green-500 text-[12px]" />
                  <span className="text-[12px] md:text-[14px]">
                    Announce Result opened at {moment(rounds3DList?.data?.data?.[0]?.endDate).format("DD MMM YYYY")}{" "}
                    at{" "}
                    {moment(
                      rounds3DList?.data?.data?.[0]?.untilEndTime,
                      "HH:mm"
                    )?.format("hh:mm:ss a")}
                  </span>
                </span>
              ) : (
                <span className="flex items-center gap-x-1">
                  <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                  <span className="text-[12px] md:text-[14px]">
                    Bet will be closed at {moment(rounds3DList?.data?.data?.[0]?.endDate).format("DD MMM YYYY")} at{" "}
                    {moment(
                      rounds3DList?.data?.data?.[0]?.endTime,
                      "HH:mm"
                    )?.format("hh:mm:ss a")}
                  </span>
                </span>
              )}
            </div>

            {previousResult?.data?.data?.[0]?.winningNumber ? null : (
              <>
                {" "}
                {moment(rounds3DList?.data?.data?.[0]?.endDate).format(
                  "YYYY-MM-DD"
                ) === moment().format("YYYY-MM-DD") &&
                  rounds3DList?.data?.data?.[0]?.endTime <
                    moment().format("HH:mm") &&
                  rounds3DList?.data?.data?.[0]?.untilEndTime >
                    moment().format("HH:mm") && (
                    <div
                      className="flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2"
                      onClick={handleClick}
                    >
                      <MdCampaign className="text-white text-[20px]" />
                      <span className="text-[12px] md:text-[14px] text-white font-semibold">
                      Announce Result{" "}
                      </span>
                    </div>
                  )}
              </>
            )}

            
          </>
        )}
      </div>
      {/* <div
        className="flex items-center justify-center bg-vermilion_bird rounded-[10px] py-3 gap-x-2 cursor-pointer"
        onClick={handleClick}
      >
        <MdCampaign className="text-white text-[20px]" />
        <span className="text-[12px] md:text-[14px] text-white font-semibold">
        Announce Result{" "}
        </span>
      </div>  */}
    </div>
  );
};

export default BetHeadCard;
