import React from "react";
import { CONTENT } from "../../../content";
import { useAtom } from "jotai";
import { menuAtom } from "../../../store";
import { permissionValidate } from "../../../util/funs";
import { useProfileInfo } from "../../../api/funs/profile";

const AgentDetailMenu = () => {
  const [menuIndex, setMenuIndex] = useAtom(menuAtom);
  const { data: profileInfo } = useProfileInfo();

  return (
    <div className="flex flex-wrap mt-4 mb-4 gap-x-2 gap-y-2 md:gap-y-0">
      {CONTENT.agent_detail.menu.map((_d: string, i: number) => (
        <>
          {(i === 0 &&
            !permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.AGENT_DETAIL_INFORMATION
            )) ||
          (i === 1 &&
            !permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.AGENT_DETAIL_INVOICE
            )) ||
          (i === 2 &&
            !permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.AGENT_DETAIL_INVITED_USER
            )) ||
          (i === 3 &&
            !permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.AGENT_DETAIL_OVERVIEW
            )) ? (
            <></>
          ) : (
            <div
              className={`px-3 py-2 text-center rounded-[10px] pointer-events-auto cursor-pointer ${
                menuIndex === i
                  ? "bg-vermilion_bird text-white"
                  : "bg-lynx_white text-dim_grey"
              }`}
              onClick={() => {
                setMenuIndex(i);
              }}
            >
              <span className="text-[12px] font-semibold">{_d}</span>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default AgentDetailMenu;
