import React from "react";
import AgentCredentialsCard from "./AgentCredentialsCard";
import InfoCard from "./InfoCard";

const AgentInvoiceShowCard = ({ agentInfo }: any) => {
  return (
    <AgentCredentialsCard header="Agent Invoice" className="px-0">
      <InfoCard label="Invoice Type" info={agentInfo?.invoice_type} />
      <InfoCard label="Invoice Month" info={agentInfo?.invoice_month} />

      <InfoCard label="Total Bet Amount" info={(+agentInfo?.total_bet_amount)?.toLocaleString()} />
      <InfoCard label="Percentage" info={agentInfo?.percentage} />
      <InfoCard
        label="Total Invoice Amount"
        info={(+agentInfo?.total_invoice_amount)?.toLocaleString()}
      />
    </AgentCredentialsCard>
  );
};

export default AgentInvoiceShowCard;
