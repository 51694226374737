import { AvatarCell } from "../../cell/AvatarCell";
import { VerifiedCell } from "../../cell/VerifiedCell";
import { TimeFormatCell } from "../../cell/TimeFormatCell";

export const userTableCol = [
  // {
  //   accessorFn: (originalRow: any) => originalRow?.amount?.toString(),
  //   filterVariant: "range-slider",
  //   filterFn: "betweenInclusive", // default (or between)
  //   muiFilterSliderProps: {
  //     marks: true,
  //     max: 200000, //custom max (as opposed to faceted max)
  //     min: 30000, //custom min (as opposed to faceted min)
  //     step: 100,
  //     valueLabelFormat: (value: any) =>
  //       value.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       }),
  //   },

  //   accessorKey: "amount", //simple recommended way to define a column
  //   header: "Amount",
  //   muiFilterTextFieldProps: { placeholder: "User  code" },
  // },
  {
    accessorKey: "user_code", //simple recommended way to define a column
    header: "User ID",
    muiFilterTextFieldProps: { placeholder: "User  code" },
    enableClickToCopy: true,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "name", //simple recommended way to define a column
    header: "User",
    Cell: AvatarCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "status", //simple recommended way to define a column
    header: "Status",
    // accessorFn: (originalRow: any) => (originalRow.status ? "true" : "false"), //must be strings
    id: "status",
    Cell: VerifiedCell,
    // filterVariant: "checkbox",
    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "agent_code", //simple recommended way to define a column
    header: "Agent Code",
    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.reg_date), //convert to date for sorting and filtering
    id: "reg_date",
    accessorKey: "reg_date", //simple recommended way to define a column
    header: "Reg.Date",
    filterVariant: "date-range",
    Cell: TimeFormatCell,
    // Cell: TimeFormatCell,
    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.last_login_date),
    accessorKey: "last_login_date", //simple recommended way to define a column
    header: "Last Login Date",
    filterVariant: "date-range",
    Cell: TimeFormatCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  // {
  //   // accessorFn: (originalRow) => parseInt(originalRow?.age), //alternate way
  //   id: "age", //id required if you use accessorFn instead of accessorKey
  //   header: "Age",
  //   Header: <i style={{ color: "red" }}>Age</i>, //optional custom markup
  //   // Cell: ({ cell }) => <i>{cell.getValue<number>().toLocaleString()}</i>, //optional custom cell render
  // },
];
