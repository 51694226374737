import moment from "moment";
import React from "react";
import { MdCheckCircle } from "react-icons/md";
import { useGetRoundList } from "../../../api/funs/rounds";

const LatestResultWinningCard = ({
  previousResultRound,
  winningResult,
}: any) => {
  const { data: roundsList } = useGetRoundList("2d");

  const roundInfo = roundsList?.data?.data?.find(
    (_d: any) => _d?.roundName === winningResult?.roundId?.roundName
  );

  return (
    <div className="w-full md:w-[55%] px-2 md:px-3 py-3  rounded-md">
      {" "}
      <div className="flex justify-between mb-4">
        <div className="text-[14px] font-bold">
          <span className="block">Latest Result</span>
          <span>
            2D, {moment(winningResult?.resultDate).format("DD MMM YYYY")},{" "}
            {winningResult?.roundId?.roundName}
          </span>
        </div>
      </div>
      {!winningResult && (
        <p className=" text-dim_grey text-[12px]">Coming soon!</p>
      )}
      <div className="grid items-center w-full grid-cols-2 mt-10 mb-5">
        <div className="flex gap-x-2">
          <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
            {winningResult?.winningNumber?.[0] || "-"}
          </div>
          <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
            {winningResult?.winningNumber?.[1] || "-"}
          </div>
        </div>

        <div className="flex gap-x-[30%]">
          <div>
            <span className="text-dim_gray text-[12px]">Set</span>
            <br />
            <span className="font-bold"> {winningResult?.set || "--"}</span>
          </div>
          <div>
            <span className="text-dim_gray text-[12px]">Value</span>
            <br />
            <span className="font-bold"> {winningResult?.value || "--"}</span>
          </div>
        </div>
      </div>
      <div className="w-full md:ml-0">
        {winningResult && (
          <>
            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-xs md:text-[14px]">
                Result opened at{" "}
                {moment(winningResult?.resultDate).format("DD MMM YYYY")} at{" "}
                {winningResult?.announceTime &&
                  moment(winningResult?.announceTime, "HH:mm")?.format(
                    "hh:mm:ss a"
                  )}
              </span>
            </span>

            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-xs md:text-[14px]">
                Bet opened at {}
                {["ROUND 1", "ROUND 2"].includes(roundInfo?.roundName)
                  ? moment(winningResult?.resultDate)
                      .subtract(1, "days")
                      .format("DD MMM YYYY")
                  : moment(winningResult?.resultDate).format(
                      "DD MMM YYYY"
                    )}{" "}
                at{" "}
                {roundInfo?.openTime &&
                  moment(roundInfo?.openTime, "HH:mm")?.format("hh:mm:ss a")}
              </span>
            </span>
            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-xs md:text-[14px]">
                Bet closed at{" "}
                {moment(winningResult?.resultDate).format("DD MMM YYYY")} at{" "}
                {roundInfo?.endTime &&
                  moment(roundInfo?.endTime, "HH:mm")?.format("hh:mm:ss a")}
              </span>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default LatestResultWinningCard;
