import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { blockVerifiedAtom, modalAtom } from "../../store";
import IOSSwitch from "./IOSSwitch";

const IOSSwitchAction = ({ row, detail }: any) => {
  const [, setIsOpenModal] = useAtom(modalAtom);
  const [, setBlockVerified] = useAtom(blockVerifiedAtom);

  const [checked, setChecked] = useState<boolean>(false);
  useEffect(() => {
    setChecked(row?.status || detail?.status);
  }, [row?.status, detail]);
  const handleSwitchToggle = () => {
    if (checked) {
      setIsOpenModal({ isOpen: true });
      setBlockVerified({
        type: "block",
        id: row?._id || detail?._id,
      });
    } else {
      // setChecked(!checked);
      setIsOpenModal({ isOpen: true });
      setBlockVerified({
        type: "verified",
        id: row?._id || detail?._id,
      });
    }
  };
  return (
    <form>
      <div className={`flex items-center  `}>
        <IOSSwitch value={checked} handleClick={handleSwitchToggle} />
      </div>
    </form>
  );
};

export default IOSSwitchAction;
