export const RouteName = {
  dashboard:"/dashboard",
  users_userlist: "/users/userlist",
  notification_list: "/notifications/list",
  admins_list: "/admins/list",
  admins_create: "/admins/create",
  agents_list: "/agents/list",
  agents_create: "/agents/create",
  twod_betlist: "/twod/betlist",
  twod_previousresults_winnerlist: "/twod/previousresults/winnerlist",
  twod_previousresults: "/twod/previousresults",
  twod_roundsetting: "/twod/roundsettings",
  threed_betlist: "/threed/betlist",
  threed_previousresults_winnerlist: "/threed/previousresults/winnerlist",
  threed_previousreslts: "/threed/previousresults",
  three_roundsetting: "/threed/roundsettings",
  report_list: "/report/list",
  setting_closedays_list: "/setting/closedays/list",
  setting_closedays_create: "/setting/closedays/create",
  setting_termconditions_list: "/setting/termconditions/list",
  setting_privaypolicy_list: "/setting/privacypolicy/list",
  setting_faqs_list: "/setting/faqs/list",
  report_twod_daily_report: "/report/two_d/daily_report",
  report_twod_monthly_report: "/report/two_d/monthly_report",
  report_twod_annual_report: "/report/two_d/annual_report",
  report_threed_monthly_report: "/report/threed/monthly_report",
  report_threed_annual_report: "/report/threed/annual_report",
  setting_faqs_create: "/setting/faqs/create",
  role_list: "/admins/role/list",
  role_create: "/admins/role/create",
  profile:"/profile"
};
