import React, { useState } from "react";
import BetTab from "../tabs/BetTab";

import Bet2DHistory from "./Bet2DHistory";
import Bet3DHistory from "./Bet3DHistory";

const BetHistory = () => {
  const [betTabIndex, setBetTabIndex] = useState(0);

  return (
    <div>
      <BetTab betTabIndex={betTabIndex} setBetTabIndex={setBetTabIndex} />
      {betTabIndex === 0 ? (
        <Bet2DHistory betTabIndex={betTabIndex}/>
      ) : betTabIndex === 1 ? (
        <Bet3DHistory betTabIndex={betTabIndex}/>
      ) : (
        ""
      )}
    </div>
  );
};

export default BetHistory;
