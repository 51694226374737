import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../common/modal";
import { AirrowLeftIcon } from "../../pages/common/Icon";
import InAppNotiForm from "../../forms/InAppNotiForm";
import TelegramNotiForm from "../../forms/TelegramNotiForm";
import EmailNotiForm from "../../forms/EmailNotiForm";

const NotificationCreate: React.FC = () => {
  const [message] = useState("");
  const [status, setStatus] = useState("telegram");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const onNavStatus = (value: string) => {
    setStatus(value);
  };

  // Function to remove specific data from the list

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const renderNotification = () => {
    if (status === "telegram") {
      return (
        <div>
          <TelegramNotiForm />
        </div>
      );
    } else if (status === "inapp") {
      return (
        <>
          <InAppNotiForm />
        </>
      );
    } else if (status === "email") {
      return (
        <>
          <EmailNotiForm />
        </>
      );
    }
  };
  return (
    <>
      <div className="min-h-screen px-4">
        <div className="row">
          <div className="flex col">
            <AirrowLeftIcon onClick={() => navigate("/notifications/list")} />
            <span
              className="p-l-5"
              onClick={() => navigate("/notifications/list")}
            >
              Back
            </span>
          </div>
        </div>
        {/* {errorArray ? (
            errorArray.map((error, i) => (
              <div className="row p-t-10 p-b-10 alert alert-danger">
                {error.msg}
              </div>
            ))
          ) : (
            <></>
          )} */}
        <nav className="p-t-10 p-b-10">
          <div className="flex mb-3 cursor-pointer d-flex gap-x-3 justify-context-between">
            <div
              className={` ${
                status === "telegram"
                  ? "btn-notification-type-selected"
                  : "btn-notification-type"
              } `}
              onClick={() => onNavStatus("telegram")}
            >
              <span>Telegram</span>
            </div>
            {/* <div
              className={` ${
                status === "inapp"
                  ? "btn-notification-type-selected"
                  : "btn-notification-type"
              } `}
              onClick={() => onNavStatus("inapp")}
            >
              <span>In-App</span>
            </div> */}
            <div
              className={` ${
                status === "email"
                  ? "btn-notification-type-selected"
                  : "btn-notification-type"
              } `}
              onClick={() => onNavStatus("email")}
            >
              <span>Email</span>
            </div>
          </div>
        </nav>
        {renderNotification()}
      </div>

      <Modal
        title="Notifications Create"
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/notifications/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationCreate;
