import React, { useState, useMemo } from "react";

import CustomizeTable from "../../table/CustomizeTable";
import { TermsConditionCol } from "../../table/columns/settings/TermsConditionCol";
import { useGetTermsCondition } from "../../api/funs/terms&condition";

const List: React.FC = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const { data: terms, error, isLoading } = useGetTermsCondition();

  const termsConditionMemorizedData = useMemo(() => {
    return terms?.data?.data?.map((d: any, i: number) => ({
      _id: d?._id,
      language:
        d?.language === "en"
          ? "English"
          : d?.language === "zh"
          ? "Chinese"
          : "Myanmar",
      lang: d?.language,
      // sr_no: `${pagination.pageIndex}${i}`,
      content: d?.terms,
      date: d?.createdAt,
    }));
  }, [terms]);

  return (
    <div className="mt-10 md:mb-0">
      <CustomizeTable
        column={TermsConditionCol}
        data={termsConditionMemorizedData || []}
        totalCount={termsConditionMemorizedData?.length}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </div>
  );
};

export default List;
