
import useSWRMutation from "swr/mutation";

import { toast } from "react-toastify";
import { createMaintenanceApi } from "../../maintence";

export function useCreateSystemMaintenance(mutate:any) {

  return useSWRMutation(
    "/api/admins/setting/system-maintenance",
    createMaintenanceApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Updated.", {
          position: "bottom-right",
        });
        mutate();
      },
    }
  );
}
