export const tootNumberGenerateFun = (value: string) => {
  const strA = value?.toString();

  const firstN = strA[0];
  const secondN = strA[1];
  const thirdN = strA[2];
  const num1 = thirdN + secondN + firstN;
  const num2 = thirdN + firstN + secondN;
  const num3 = secondN + firstN + thirdN;
  const num4 = secondN + thirdN + firstN;
  const num5 = firstN + thirdN + secondN;
  const minus =(+value-1<10?`00${+value-1}` :+value-1<100?`0${+value-1}` :(+value-1).toString());
  const plus = (+value + 1 < 10 ? `00${+value + 1}` : +value + 1 < 100 ? `0${+value + 1}` : (+value + 1).toString());
  const tootArray = [num1, num2, num3, num4, num5, minus, plus];

  const uniqueA = Array.from(new Set(tootArray));

  return uniqueA;
};
