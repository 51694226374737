import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getRoundsApi = async (type: string) => {
  const axios_url = new URL(`${url}${API_NAME.round_list_api}?type=${type}`);

  return axiosInstance.get(axios_url);
};
export const createAnnouncedApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      winningNumber?: string;
      set?: string;
      value?: string;
      tootNumbers?: string[];
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
export const createAnnouncedAfterApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      winningNumber?: string;
      set?: string;
      value?: string;
      resultAnnounceDate?:string
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};

export const getPreviousResultApi = async (
  type: string,
  startDate?: string,
  endDate?: string
) => {
  let axios_url;
  if (startDate && endDate) {
    axios_url = new URL(
      `${url}/api/admins/${type}/previous-results?from=${startDate}&to=${endDate}&pageSize=10&pageNumber=1`
    );
  } else {
    axios_url = new URL(
      `${url}/api/admins/${type}/previous-results?pageSize=10&pageNumber=1`
    );
  }

  return axiosInstance.get(axios_url);
};
export const getBetListApi = async (
  type: string,
  startDate: string,
  endDate: string,
  pageNumber: number,
  pageSize: number,
  roundId: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/${type}/${roundId}/bet-lists?keyword=&pageSize=${pageSize}&sort=desc&date=${endDate}&pageNumber=${
      pageNumber + 1
    }`
  );

  return axiosInstance.get(axios_url);
};
export const getDSummaryApi = async (type: string) => {
  const axios_url = new URL(`${url}/api/admins/${type}/summary`);

  return axiosInstance.get(axios_url);
};
export const getTwoDBetDetailApi = async (
  id: string,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/${type}/${id}/details?pageSize=${pageSize}&pageNumber=${
      pageIndex + 1
    }`
  );

  return axiosInstance.get(axios_url);
};
export const getPreviousWinningApi = async (
  type: string,
  resultId: string,
  pageNumber: number,
  pageSize: number
) => {
  const axios_url = new URL(
    `${url}/api/admins/${type}/${resultId}/winners?pageSize=${pageSize}&pageNumber=${
      pageNumber + 1
    }`
  );

  return axiosInstance.get(axios_url);
};
export const getPreviousWinningThreeApi = async (
  type: string,
  resultId: string,
  pageNumber: number,
  pageSize: number,
  tootDaeType: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/${type}/${resultId}/${tootDaeType}?pageSize=${pageSize}&pageNumber=${
      pageNumber + 1
    }`
  );

  return axiosInstance.get(axios_url);
};

export const patchStatusRoundApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      status: boolean;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.patch(`${axios_url}`, arg);
};

export const getRoundsByIdApi = async (id: string) => {
  const axios_url = new URL(`${url}/api/admins/rounds/${id}`);

  return axiosInstance.get(axios_url);
};
export const updateRoundsByIdApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      endDate: string;
      openTime: string;
      endTime: string;
      announceTime: string;
      untilEndTime: string;
      status: boolean;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
