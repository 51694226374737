import useSWR from "swr";
import { API_NAME } from "../../constant";
import { getProfileByApi, getProfileInfoApi } from "../../profile";

export const useProfileInfo = () => {
  return useSWR(
    API_NAME.profile_info_api,
    getProfileInfoApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useProfileById = (id:string) => {
  return useSWR(
    id?[API_NAME.profile_by_id_api,id]:null,
    ()=> getProfileByApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
