import React, { useState } from "react";
import DateFilter from "../../components/ui/bet/DateFilter";
import ReportOverviewCard from "../../components/ui/report/ReportOverviewCard";
import ReportUserOverViewCard from "../../components/ui/report/ReportUserOverViewCard";
import { useReportOverview } from "../../api/funs/reports";
import moment from "moment";
import dayjs from "dayjs";

const ReportPage = () => {
  const [active, setActive] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data: reportOverview } = useReportOverview(
    active === 0 ? "" : active === 1 ? "2d" : active === 2 ? "3d" : "",
    startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );
  return (
    <div className="mt-10 md:pl-4 md:mt-3">
      <DateFilter setStartDateP={setStartDate} setEndDateP={setEndDate} />
      <div className="flex my-3 mb-2 gap-x-2">
        {["All Games", "2D", "3D"].map((_: string, i: number) => (
          <div
            key={i}
            className={`px-3 py-1 ${
              active === i
                ? "text-white bg-vermilion_bird"
                : "bg-white text-dynamic_black"
            }  rounded-[10px]`}
            onClick={() => {
              setActive(i);
            }}
          >
            <span>{_}</span>
          </div>
        ))}
      </div>

      <ReportOverviewCard reportOverview={reportOverview} />

      <ReportUserOverViewCard reportOverview={reportOverview} />
    </div>
  );
};

export default ReportPage;
