import { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";
import { MenuItem } from "@mui/material";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import {

  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ViewAction from "./actions/ViewAction";
import TitleTotalList from "./ui/TitleTotalList";
import { RouteName } from "../app/constants/route-constant";
import TotalBetAmountMMK from "./ui/TotalBetAmountMMK";


// import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
// const csvConfig = mkConfig({
//   fieldSeparator: ',',
//   decimalSeparator: '.',
//   useKeysAsHeaders: true,
// });
//If using TypeScript, define the shape of your data (optional, but recommended)


//mock data - strongly typed if you are using TypeScript (optional, but recommended)

const CustomizeTable = ({
  column,
  data,
  totalCount,
  isRefetching,
  pagination,
  setPagination,
  error,
  isShowAction,
  total,
  menuType,
  betTabIndex,
  globalFilter,
  setGlobalFilter,
  showGlobalFilter,
}: any) => {
  // const handleExportRows = (rows: any) => {
  //   // const rowData = rows.map((row:any) => row?.original);
  //   // const csv = generateCsv(csvConfig)(rowData);
  //   // download(csvConfig)(csv);
  // };
  const navigate = useNavigate();
  const { id } = useParams();

  const { pathname } = useLocation();

  const handleExportRows = (rows: any) => {
    const doc = new jsPDF();
    const tableData = rows.map((row: any) => Object.values(row.original));
    const tableHeaders = column.map((c: any) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };

  const [dataRow, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleExportData = () => {
  //   // const csv = generateCsv(csvConfig)(data);
  //   // download(csvConfig)(csv);
  // };

  const isShowExport = [
    // RouteName.report_twod_daily_report,
    // RouteName.report_twod_monthly_report,
    // RouteName.report_twod_annual_report,
    // RouteName.report_threed_monthly_report,
    // RouteName.report_threed_annual_report,
    "",
  ].includes(pathname);
  const isSelectRow = [
    // RouteName.report_twod_daily_report,
    // RouteName.report_twod_monthly_report,
    // RouteName.report_twod_annual_report,
    // RouteName.report_threed_monthly_report,
    // RouteName.report_threed_annual_report,
    "",
  ].includes(pathname);
  const isRowSort = false;

  const isShowActionName = [RouteName.twod_betlist].includes(pathname)
    ? true
    : false;
  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns: column,
    data: data || [], //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: showGlobalFilter ? true : false, //turn off a feature
    // enableRowSelection: true,
    localization: {
      actions: isShowActionName ? "Details" : "Actions",
    },

    enableRowOrdering: isRowSort,

    enableRowSelection: isSelectRow,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,

    enableFilterMatchHighlighting: true,
    manualPagination: true,
    // muiCircularProgressProps: {
    //   Component: <MyCustomSpinner />,
    // },
    muiLinearProgressProps: {
      color: "error",
    },
    muiCircularProgressProps: {
      color: "error",
    },

    defaultDisplayColumn: {
      enableResizing: true, //turn on some features that are usually off for all display columns
    },

    muiTableBodyRowProps: ({ row, table }) => {
      const { density } = table.getState();
      return {
        sx: {
          cursor: "pointer",

          //Set a fixed height for pinned rows
          height: row.getIsPinned()
            ? `${
                //Default mrt row height estimates. Adjust as needed.
                density === "compact" ? 37 : density === "comfortable" ? 53 : 69
              }px`
            : undefined,
        },
        onClick: (event) => {
          if (pathname === `/users/detail/${id}`) {
            if (menuType === "Bet2DHistory" && betTabIndex === 0) {
              navigate(
                `/twod/betlist/user/${row?.original?._userId}/${row?.original?._id}`
              );
            } else if (menuType === "Bet3DHistory" && betTabIndex === 1) {
              navigate(
                `/threed/betlist/user/${row?.original?._userId}/${row?.original?._id}`
              );
            } else if (menuType === "WinningHistory" && betTabIndex === 0) {
              navigate({
                pathname: `/users/detail/${id}/winning/${row?.original?.resultId}`,
                search: "?type=twod",
              });
            } else if (menuType === "WinningHistory" && betTabIndex === 1) {
              navigate({
                pathname: `/users/detail/${id}/winning/${row?.original?.resultId}`,
                search: `?type=threed&tdType=${
                  row?.original?.winningType === "Toot" ? "box" : "straight"
                }`,
              });
            } else {
            }
          } else if (pathname === RouteName.twod_betlist) {
            navigate(
              `/twod/betlist/user/${row?.original?._userId}/${row?.original?._id}`
            );
          } else if (pathname === RouteName.threed_betlist) {
            navigate(
              `/threed/betlist/user/${row?.original?._userId}/${row?.original?._id}`
            );
          } else {
          }
        },
      };
    },

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          dataRow.splice(
            (hoveredRow as any).index,
            0,
            dataRow.splice(draggingRow.index, 1)[0]
          );
          setRowData([...dataRow]);
        }
      },
    }),

    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#FFECEB",
        color: "#F44336",
        whiteSpace: "unset",

        // "& .MuiTableSortLabel-icon": {
        //   color: "white !important",
        // },
      },
    },
    muiTableFooterCellProps: {
      sx: {
        backgroundColor: "#F44336",
        color: "white",
        whiteSpace: "unset",

        // "& .MuiTableSortLabel-icon": {
        //   color: "white !important",
        // },
      },
    },

    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: true,
      showColumnFilters: false, // false/true
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    //customize the MRT components
    muiPaginationProps: {
      rowsPerPageOptions: [10, 50, 100],
      variant: "outlined",
    },
    enableRowActions: isShowAction === "inactive" ? false : true,
    enableColumnActions: isShowAction === "inactive" ? false : true,
    positionActionsColumn: "last",
    muiSelectCheckboxProps: {
      color: "error",
    },
    muiSelectAllCheckboxProps: {
      color: "error",
    },
    muiFilterTextFieldProps: {
      sx: { m: "0.5rem 0", width: "100%" },
      variant: "outlined",
    },
    //   <Link to={`/admins/edit/${body._id}`} className="px-2">
    //   <EditIcon />
    // </Link>
    renderRowActions: ({ row }) => {
      return <ViewAction row={row} />;
    },

    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => console.info("Edit")}>
        Edit
      </MenuItem>,
      <MenuItem key="delete" onClick={() => console.info("Delete")}>
        Delete
      </MenuItem>,
    ],
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       gap: "16px",
    //       padding: "8px",
    //       flexWrap: "wrap",
    //     }}
    //   >
    //     <Button
    //       sx={{ color: "#F44336" }}
    //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //       onClick={handleExportData}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export All Data
    //     </Button>
    //     <Button
    //       sx={{ color: "#F44336" }}
    //       disabled={table.getPrePaginationRowModel().rows.length === 0}
    //       //export all rows, including from the next page, (still respects filtering and sorting)
    //       onClick={() =>
    //         handleExportRows(table.getPrePaginationRowModel().rows)
    //       }
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export All Rows
    //     </Button>
    //     <Button
    //       sx={{ color: "#F44336" }}
    //       disabled={table.getRowModel().rows.length === 0}
    //       //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
    //       onClick={() => handleExportRows(table.getRowModel().rows)}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export Page Rows
    //     </Button>
    //     <Button
    //       sx={{ color: "#F44336" }}
    //       disabled={
    //         !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
    //       }
    //       //only export selected rows
    //       onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
    //       startIcon={<FileDownloadIcon />}
    //     >
    //       Export Selected Rows
    //     </Button>
    //   </Box>
    // ),

    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {isShowExport ? (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              padding: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              sx={{ color: "#F44336" }}
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
            >
              Export All Rows
            </Button>
            <Button
              sx={{ color: "#F44336" }}
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export Page Rows
            </Button>
            <Button
              sx={{ color: "#F44336" }}
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
            >
              Export Selected Rows
            </Button>
          </Box>
        ) : (
          <TitleTotalList total={totalCount} />
        )}
      </>
    ),
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        <TotalBetAmountMMK total={total} />
      </>
    ),
    onPaginationChange: setPagination,
    rowCount: totalCount,
    onGlobalFilterChange: setGlobalFilter,

    state: {
      pagination,
      isLoading: isRefetching,
      showAlertBanner: error,
      showProgressBars: isRefetching,
      globalFilter,
    },
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
  });

  //note: you can also pass table options as props directly to <MaterialReactTable /> instead of using useMaterialReactTable
  //but the useMaterialReactTable hook will be the most recommended way to define table options
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
  // return <></>;
};

export default CustomizeTable;
