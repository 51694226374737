export const betRoundCol = [
  // {
  //   accessorKey: "date", //simple recommended way to define a column
  //   header: "Date",
  //   accessorFn: (originalRow: any) => new Date(originalRow?.date),
  //   Cell: DateFormatCell,
  //   filterVariant:"date-range"
  // },
  {
    accessorKey: "round", //simple recommended way to define a column
    header: "Round",
  },
  {
    accessorKey: "bet_open_time", //simple recommended way to define a column
    header: "Bet Open Time",
    // accessorFn: (originalRow: any) => new Date(originalRow?.bet_open_time),
    // Cell: TimeOnlyFormatCell,
    // filterVariant: "time",
  },
  {
    accessorKey: "bet_close_time", //simple recommended way to define a column
    header: "Bet Close Time",
    // accessorFn: (originalRow: any) => new Date(originalRow?.bet_close_time),
    // Cell: TimeOnlyFormatCell,
    // filterVariant: "time",
  },
  {
    accessorKey: "result_announce_time", //simple recommended way to define a column
    header: "R.A Time",
    // accessorFn: (originalRow: any) =>
    //   new Date(originalRow?.result_announce_time),
    // Cell: TimeOnlyFormatCell,
    // filterVariant: "time",
  },
  {
    accessorKey: "extra_result_announce_time", //simple recommended way to define a column
    header: "Extra R.A Time",
    // accessorFn: (originalRow: any) =>
    //   new Date(originalRow?.result_announce_time),
    // Cell: TimeOnlyFormatCell,
    // filterVariant: "time",
  },
];
