import useSWR from "swr";
import { API_NAME } from "../../constant";
import { getTermsConditionApi, getTermsConditionByLangApi } from "../../terms&condition";

export const useGetTermsCondition = () => {
  return useSWR(
    API_NAME.terms_api,
    getTermsConditionApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetTernsConditionByLang= (lang?:string) => {
  return useSWR(
    lang ? [ API_NAME.terms_condition_by_lang_api,lang]:null,
    ()=>getTermsConditionByLangApi(lang),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
