import React from "react";

const PageNotFound: React.FC = () => {
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title ">Page Not Found</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
