import React from "react";

import { useNavigate } from "react-router-dom";
import { useProfileInfo } from "../api/funs/profile";
import SpinnerLoading from "./common/SpinnerLoading";
import TextIconButton from "../components/ui/buttons/TextIconButton";
import { CiEdit } from "react-icons/ci";
import { decryptHashedData } from "../util/funs";
import { sliceStarPhoneFun } from "../util/funs/sliceStarFun";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { data: profileInfo, isLoading } = useProfileInfo();

  const handleNavigate = () => {
    navigate(`/profile/${profileInfo?.data?.data?._id}`);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="block">
          <div className="h-screen p-3 mx-2 bg-white">
            <h5 className="pb-4 mb-3 border-b border-dotted card-title border-steam">
              My Profile
            </h5>

            <div className="flex items-center justify-between pb-4 mb-3 border-b border-dotted border-steam">
              <div>
                <span className="text-[16px] font-semibold">
                  {profileInfo?.data?.data?.name}
                </span>
                <br />
                <span className="text-[12px] text-dim_grey">
                  {profileInfo?.data?.data?.roleId?.name}
                </span>
                <br />
                {/* <span className="text-[12px] text-dim_grey">Yangon</span> */}
              </div>{" "}
              <TextIconButton
                label="Edit"
                icon={<CiEdit className="text-[18px]" />}
                handleClick={handleNavigate}
              />
            </div>
            <div className="flex items-start justify-between pb-4 mb-3 border-b border-dotted border-steam">
              <div>
                <span className="text-[16px] font-semibold">
                  Personal Information
                </span>
                <br />
                <div>
                  <span className="text-[10px] text-dim_grey">Username</span>
                  <br />
                  <span className="text-[12px] text-dynamic_black">
                    {profileInfo?.data?.data?.name}
                  </span>
                </div>
                <div>
                  <span className="text-[10px] text-dim_grey">
                    Phone Number
                  </span>
                  <br />
                  <span className="text-[12px] text-dynamic_black">
                    {profileInfo?.data?.data?.phone}
                  </span>
                </div>
              </div>
              <TextIconButton
                label="Edit"
                icon={<CiEdit className="text-[18px]" />}
                handleClick={handleNavigate}
              />
            </div>
            <div className="flex items-start justify-between pb-2 border-b border-dotted border-steam">
              <div>
                <span className="text-[16px] font-semibold">Security</span>
                <br />
                <div>
                  <span className="text-[10px] text-dim_grey">Password</span>
                  <br />
                  <span className="text-[12px] text-dynamic_black">
                    {profileInfo?.data?.data?.encryptPassword &&
                      sliceStarPhoneFun(
                        decryptHashedData(
                          profileInfo?.data?.data?.encryptPassword?.toString(),
                          "AIO_PASSWORD_ENCRYPT"
                        )?.toString(),
                        decryptHashedData(
                          profileInfo?.data?.data?.encryptPassword?.toString(),
                          "AIO_PASSWORD_ENCRYPT"
                        )?.toString().length
                      )}
                  </span>
                </div>
              </div>
              <TextIconButton
                label="Edit"
                icon={<CiEdit className="text-[18px]" />}
                handleClick={handleNavigate}
              />
            </div>

            {/* <Link to="/dashboard" className="btn btn-secondary">
              Back
            </Link> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
