import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
  AddCircleIcon,
} from "../../pages/common/Icon";
import { RouteName } from "../../app/constants/route-constant";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useGetRoleList } from "../../api/funs/role";
import { DevTool } from "@hookform/devtools";

import { useUpdateAdmin } from "../../api/mutation/admin";
import { useGetAdminDetail } from "../../api/funs/admin/index";
import CryptoJS from "crypto-js";
import SpinnerLoading from "../common/SpinnerLoading";

const AdminEdit: React.FC = () => {
  const navigate = useNavigate();

  const decryptHashedData = (hashData: string, key: string) => {
    const decryptData = CryptoJS.AES.decrypt(hashData, key);
    return JSON.parse(decryptData?.toString(CryptoJS.enc.Utf8));
  };
  const { id } = useParams();
  const { data: adminDetail } = useGetAdminDetail(id as string);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const { data: roleList } = useGetRoleList();
  const { trigger, isMutating,error:errorAdmin } = useUpdateAdmin(id as string);

  const { control, handleSubmit, formState, reset } = useForm<{
    username: string;
    userRole: string;
    phoneNo: string;
    email: string;
    password: string;
    confirmPassword: string;
    ipAddressA: { text: string }[];
  }>({
    values: {
      username: adminDetail?.data?.data?.name,
      userRole: adminDetail?.data?.data?.roleId?._id,
      phoneNo: adminDetail?.data?.data?.phone,
      email: adminDetail?.data?.data?.email,
      password:
        adminDetail?.data?.data?.encryptPassword &&
        decryptHashedData(
          adminDetail?.data?.data?.encryptPassword,
          "AIO_PASSWORD_ENCRYPT"
        ),
      confirmPassword:
        adminDetail?.data?.data?.encryptPassword &&
        decryptHashedData(
          adminDetail?.data?.data?.encryptPassword,
          "AIO_PASSWORD_ENCRYPT"
        ),
      ipAddressA: adminDetail?.data?.data?.loginIpAddresses?.map((_: any) => ({
        text: _,
      })),
    },
  });

  // var bytes  =  adminDetail?.data?.data?.password && CryptoJS.AES.decrypt(adminDetail?.data?.data?.password, 'AIO_PASSWORD_ENCRYPT')?.toString(CryptoJS.enc.Utf8);

  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "ipAddressA",
    control,
  });
  const onSubmit = (data: any) => {
    trigger({
      name: data?.username,
      roleId: data?.userRole,
      phone: data?.phoneNo,
      email: data?.email,
      password: data?.confirmPassword.toString(),
      ipAddress: data?.ipAddressA?.map((_: any) => _?.text),
    });
    if(errorAdmin){

    }else{
      reset();

    }

  };
  return (
    <>
      {adminDetail ? (
        <>
          <div className="md:pl-5">
            <BackArrowButton
              handleClick={() => navigate(RouteName.admins_list)}
            />
          </div>
          <form
            className="grid grid-cols-2 md:pl-5 gap-x-5"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="username"
              rules={{
                required: {
                  value: true,
                  message: "Username is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    size="small"
                    {...field}
                    placeholder="Username (e.g.Ko Soe)"
                    error={!!errors?.username}
                    label="Username (e.g.Ko Soe)"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    helperText={
                      !!errors?.username?.message && errors?.username?.message
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="userRole"
              rules={{
                required: {
                  value: true,
                  message: "User role is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <>
                    {" "}
                    <FormControl
                      size="small"
                      fullWidth
                      error={!!formState.errors?.userRole}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select User Role
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select User Role"
                      >
                        {roleList &&
                          roleList?.data?.data?.map((_: any, i: any) => (
                            <MenuItem key={i} value={_?._id}>
                              {_?.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors?.userRole?.message && (
                        <FormHelperText>
                          {errors?.userRole?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                );
              }}
            />
            <Controller
              control={control}
              name="phoneNo"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Phone number is required.",
              //   },
              // }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    size="small"
                    {...field}
                    placeholder="Phone Number"
                    error={!!errors?.phoneNo}
                    label="Phone Number (Optional)"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    helperText={
                      !!errors?.phoneNo?.message && errors?.phoneNo?.message
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="email"
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Email is required.",
              //   },
              // }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    size="small"
                    {...field}
                    placeholder="Email"
                    error={!!errors?.email}
                    label="Email (Optional)"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    helperText={
                      !!errors?.email?.message && errors?.email?.message
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: {
                  value: true,
                  message: "Password is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    size="small"
                    {...field}
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            setIsShowPassword(!isShowPassword);
                          }}
                        >
                          {isShowPassword ? (
                            <EyeSlashIcon className="w-6 h-6" />
                          ) : (
                            <EyeIcon className="w-6 h-6" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.password}
                    label="Password"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    helperText={
                      !!errors?.password?.message && errors?.password?.message
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: {
                  value: true,
                  message: "Confirm Password is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    size="small"
                    {...field}
                    type={isShowConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            setIsShowConfirmPassword(!isShowConfirmPassword);
                          }}
                        >
                          {isShowPassword ? (
                            <EyeSlashIcon className="w-6 h-6" />
                          ) : (
                            <EyeIcon className="w-6 h-6" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Confirm Password"
                    error={!!errors?.confirmPassword}
                    label="Confirm Password"
                    sx={{
                      marginBottom: "1.5rem",
                    }}
                    helperText={
                      !!errors?.confirmPassword?.message &&
                      errors?.confirmPassword?.message
                    }
                  />
                );
              }}
            />
            <div className="col-span-2 md:col-span-1">
              {fields.map((field, index) => (
                <div
                  key={field.text}
                  className="flex justify-center mb-3 gap-x-4"
                >
                  <Controller
                    control={control}
                    name={`ipAddressA.${index}.text`}
                    rules={{
                      validate: (v: any) => {
                        if (v?.length === 0) {
                          return "Ip Address is required.";
                        } else if (/^([0-9]{1,3}\.){3}[0-9]{1,3}$/.test(v)) {
                          return undefined;
                        }

                        return "Invalid Ip Address";
                      },
                    }}
                    render={({ field, formState, fieldState }) => {
                      return (
                        <TextField
                          autoComplete="off"
                          color="error"
                          fullWidth
                          size="small"
                          {...field}
                          placeholder="Ip Address"
                          label="Ip Address"
                          error={!!errors.ipAddressA?.[index]?.text}
                          helperText={errors.ipAddressA?.[index]?.text?.message}
                        />
                      );
                    }}
                  />

                  {fields?.length !== 1 && (
                    <div className="mt-2 cursor-pointer">
                      <TrashIcon onClick={() => remove(index)} />
                    </div>
                  )}
                </div>
              ))}

              <div className="mb-3">
                <span
                  className="flex justify-center items-center w-[160px] h-[40px] gap-x-2 bg-pink_lemonade rounded-md cursor-pointer"
                  onClick={() => append({ text: " " })}
                >
                  <AddCircleIcon className="w-5 h-5" />
                  <span className="text-vermilion_bird text-[12px] font-bold">
                    Add IP address
                  </span>
                </span>
              </div>
              <br />
            </div>

            <div className="flex justify-between flex-1 col-span-2">
              <div></div>
              <div>
                <button
                  type="submit"
                  className={`btn-create-active ${
                    isMutating ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  disabled={isMutating}
                >
                  {isMutating ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>

            <DevTool control={control} />
          </form>
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default AdminEdit;
