import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getHolidayListApi = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  const axios_url = new URL(`${url}${API_NAME.holidays_lists_api}`);

  return axiosInstance.get(axios_url);
};

export const createHolidayDayApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      date: string;
      holidayName: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
export const updateHolidayDayApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      date: string;
      holidayName: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
export const deleteHolidayOneApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      id: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}/${arg.id}`);

  return axiosInstance.delete(`${axios_url}`);
};

export const getHolidayListByIdApi = async (id?: string) => {
  const axios_url = new URL(`${url}/api/admins/holidays/${id}`);

  return axiosInstance.get(axios_url);
};
