import useSWR from "swr";
import { API_NAME } from "../../constant";
import {
  getAgentCodeListApi,
  getAgentDetailApi,
  getAgentDetailOverviewApi,
  getAgentInvoiceApi,
  getAgentListApi,
  getInvitedUserAgentApi,
} from "../../agent";

export const useGetAgentList = (
  { pageIndex, pageSize }: { pageIndex: number; pageSize: number },
  globalFilter?: string
) => {
  return useSWR(
    [API_NAME.admins_agents_list_api, pageIndex, pageSize, globalFilter],
    () => getAgentListApi({ pageIndex, pageSize }, globalFilter),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetAgentCodeList = () => {
  return useSWR(
    [API_NAME.agent_code_list],
    getAgentCodeListApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};

export const useGetAgentDetail = (id?: string) => {
  return useSWR(
    id ? [API_NAME.agent_edit_id_api, id] : null,
    () => getAgentDetailApi(id as string),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetInvitedUserAgentDetail = (
  id: string,
  { pageIndex, pageSize }: { pageIndex: number; pageSize: number }
) => {
  return useSWR(
    id ? [API_NAME.agent_invited_user__id_api, id, pageIndex, pageSize] : null,
    () => getInvitedUserAgentApi({ pageIndex, pageSize }, id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetAgentDetailOverview = (id: string) => {
  return useSWR(
    id ? [API_NAME.agent__overview_detail_id_api, id] : null,
    () => getAgentDetailOverviewApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};

export const useGetAgentInvoice = (
  { pageIndex, pageSize }: { pageIndex: number; pageSize: number },
  globalFilter?: string,
  invoicetype?: string,
  id?: string,
  month?: string
) => {
  return useSWR(
    ["agentInvoice", pageIndex, pageSize, globalFilter, invoicetype, id, month],
    () =>
      getAgentInvoiceApi(
        { pageIndex, pageSize },
        globalFilter,
        invoicetype,
        id,
        month
      ),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
