import useSWRMutation from "swr/mutation";

import { toast } from "react-toastify";
import { createAdsBannerApi, deleteAdsBannerOneApi } from "../../ads-banner";
import { useGetAdsBannerList } from "../../funs/ads-banner";

export function useCreateAdsBanner() {
  // const navigate = useNavigate();
  // const [pagination] = useState({
  //   pageIndex: 0,
  //   pageSize: 10, //customize the default page size
  // });

  const { mutate } = useGetAdsBannerList();

  return useSWRMutation("/api/admins/banners/create", createAdsBannerApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}

export function useDeleteAdsBannerOne() {
 

  const { mutate } = useGetAdsBannerList();

  return useSWRMutation(`/api/admins/banners`, deleteAdsBannerOneApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Deleted.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}
