import {
  AdminActiveIcon,
  AdminIcon,
  AgentActiveIcon,
  AgentIcon,
  DashboardActiveIcon,
  DashboardIcon,
  DocumentUploadGreyIcon,
  DocumentUploadWhiteIcon,
  NotificationActiveIcon,
  NotificationIcon,
  ReportActiveIcon,
  ReportIcon,
  SettingActiveIcon,
  SettingIcon,
  TwoDActiveIcon,
  TwoDIcon,
  UserMenuActiveIcon,
  UserMenuIcon,
} from "../../pages/common/Icon";

export interface Item {
  icon?: JSX.Element | string;
  activeIcon?: JSX.Element | string;
  label: string;
  link: string;
}

export interface Menu extends Item {
  children: Item[];
}

export const menuList: Menu[] = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
    label: "Dashboard",
    link: "/dashboard",
    children: [],
  },
  {
    icon: <UserMenuIcon />,
    activeIcon: <UserMenuActiveIcon />,
    label: "User",
    link: "/users/userlist",
    children: [],
  },
  {
    icon: <TwoDIcon />,
    activeIcon: <TwoDActiveIcon />,
    label: "2D",
    link: "/twod/betlist",
    children: [
      {
        icon: "",
        label: "Bet List",
        link: "/twod/betlist",
      },
      {
        icon: "",
        label: "Previous Results",
        link: "/twod/previousresults",
      },
      {
        icon: "",
        label: "Round Settings",
        link: "/twod/roundsettings",
      },
    
      {
        icon: "",
        label: "Summary",
        link: "/twod/summary",
      },
    ],
  },
  {
    icon: <TwoDIcon />,
    activeIcon: <TwoDActiveIcon />,
    label: "3D",
    link: "/threed/betlist",
    children: [
      {
        icon: "",
        label: "Bet List",
        link: "/threed/betlist",
      },
      {
        icon: "",
        label: "Previous Results",
        link: "/threed/previousresults",
      },
      {
        icon: "",
        label: "Round Settings",
        link: "/threed/roundsettings",
      },
      {
        icon: "",
        label: "Summary",
        link: "/threed/summary",
      },
    ],
  },
  {
    icon: <AdminIcon />,
    activeIcon: <AdminActiveIcon />,
    label: "Admins",
    link: "/admins/list",
    children: [
      {
        icon: "",
        label: "Admin List",
        link: "/admins/list",
      },
      {
        icon: "",
        label: "User Roles & Permission",
        link: "/admins/role/list",
      },
    ],
  },
  {
    icon: <AgentIcon />,
    activeIcon: <AgentActiveIcon />,
    label: "Agents",
    link: "/agents/list",
    children: [],
  },
  {
    icon: <NotificationIcon />,
    activeIcon: <NotificationActiveIcon />,
    label: "Notification Center",
    link: "/notifications/list",
    children: [],
  },


  {
    icon: <DocumentUploadGreyIcon />,
    activeIcon: <DocumentUploadWhiteIcon />,
    label: "Generate 3D Invoices",
    link: "/threed/generate-invoice",
    children: [],
  },
  {
    icon: <ReportIcon />,
    activeIcon: <ReportActiveIcon />,
    label: "Report",
    link: "/report/list",
    children: [
      {
        icon: "",
        label: "Overall Report",
        link: "/report/list",
      },
      {
        icon: "",
        label: "2D Daily Report",
        link: "/report/two_d/daily_report",
      },
      {
        icon: "",
        label: "2D Monthly Report",
        link: "/report/two_d/monthly_report",
      },
      {
        icon: "",
        label: "2D Annual Report",
        link: "/report/two_d/annual_report",
      },
      {
        icon: "",
        label: "3D Monthly Report",
        link: "/report/three_d/monthly_report",
      },
      {
        icon: "",
        label: "3D Annual Report",
        link: "/report/three_d/annual_report",
      },
    ],
  },
  {
    icon: <SettingIcon />,
    activeIcon: <SettingActiveIcon />,
    label: "App Management",
    link: "/setting/ads-banner",
    children: [
      {
        icon: "",
        label: "Banners",
        link: "/setting/ads-banner",
      },
    ],
  },
  {
    icon: <SettingIcon />,
    activeIcon: <SettingActiveIcon />,
    label: "Setting",
    link: "/setting/closedays/list",
    children: [
      {
        icon: "",
        label: "Closed Days",
        link: "/setting/closedays/list",
      },
      {
        icon: "",
        label: "Maintenance",
        link: "/setting/maintenance",
      },
      {
        icon: "",
        label: "Terms & Conditions",
        link: "/setting/termconditions/list",
      },
      // {
      //   icon: "",
      //   label: "Privacy Policy",
      //   link: "/setting/privacypolicy/list",
      // },
      // {
      //   icon: "",
      //   label: "FAQs",
      //   link: "/setting/faqs/list",
      // },
    ],
  },
];
