import React from "react";

import { useDashboardCounts } from "../api/funs/dashoard";
import SpinnerLoading from "./common/SpinnerLoading";
import Card from "../components/ui/dashboard/Card";
import BetCard from "../components/ui/dashboard/BetCard";
import LatestBetCard from "../components/ui/dashboard/LatestBetCard";
import TopAgent from "../components/ui/dashboard/TopAgent";
import LatestBet3DCard from "../components/ui/dashboard/LatestBet3DCard";

const Dashboard: React.FC = () => {
  const { error, isLoading } = useDashboardCounts();

  return (
    <div>
      {/* // <AppWrapper title="Dashboard"> */}
      {isLoading || error ? (
        <SpinnerLoading />
      ) : (
        <>
          <Card />
          <BetCard />
          <LatestBetCard />
          <LatestBet3DCard />
          <TopAgent />
        </>
      )}
      {/* // </AppWrapper> */}
    </div>
  );
};

export default Dashboard;
