import React from "react";
import { CONTENT } from "../../content";
import { format } from "date-fns";

const DetailTable = ({ notiData }: { notiData: any }) => {
  return (
    <table className="w-full table-auto">
      <tbody className="text-center border border-steam">
        {CONTENT.notification.detail.table.map((_d, i) => (
          <tr key={i}>
            <td className="py-3 text-xs border md:text-base border-steam">{_d}</td>
            <td className="py-3 text-xs font-semibold capitalize border md:text-base border-steam">
              {i === 0
                ? notiData?.data?.data?.type
                : `${format(
                    new Date(notiData?.data?.data?.createdAt || new Date()),
                    "dd MMM  yyyy"
                  )} at ${format(
                    new Date(notiData?.data?.data?.createdAt || new Date()),
                    "hh:mm:ss a"
                  )}`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailTable;
