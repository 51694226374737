import useSWR from "swr";
import { API_NAME } from "../../constant";
import { getHolidayListApi, getHolidayListByIdApi } from "../../holidays";


export const useGetHolidayList= () => {
  return useSWR(
    API_NAME.holidays_lists_api,
   getHolidayListApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetHolidayById= (id?:string) => {
  return useSWR(
    id ? [ API_NAME.holidays_lists_id_api,id]:null,
    ()=>getHolidayListByIdApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};