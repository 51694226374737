
import useSWRMutation from "swr/mutation";



import { toast } from "react-toastify";
import { createGenerateInvoiceApi } from "../../agent";

export function useCreateGenerateInvoice() {
 

  return useSWRMutation(
    "/api/admins/invoices/create/3d",
    createGenerateInvoiceApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        
      },
    }
  );
}

