import DateFormatCell from "../../cell/DateFormatCell";

export const closeDayCol = [
  {
    accessorKey: "sr_no", //simple recommended way to define a column
    header: "Sr No",
  },
  {
    accessorKey: "date", //simple recommended way to define a column
    header: "Date",
    accessorFn: (originalRow: any) => new Date(originalRow?.date),
    Cell: DateFormatCell,
    filterVariant: "date-range",
  },

  {
    accessorKey: "description", //simple recommended way to define a column
    header: "Description",
  },
];
