import React from "react";

const BetNumbersCell = ({ renderedCellValue }: any) => {
  return (
    <div className="flex flex-wrap gap-x-2 ">
      {renderedCellValue?.length > 3 ? (
        <div className="flex gap-x-2">
          {renderedCellValue.slice(0, 2).map((_e: any, i: number) => (
            <span
              key={i}
              className="text-dynamic_black flex justify-center items-center bg-goldenrod rounded-[12px] w-[30px] h-[30px]"
            >
              {_e}
            </span>
          ))}
          <span className="text-dim_grey flex justify-center items-center bg-lynx_white rounded-[12px] w-[30px] h-[30px]">
            {`+${renderedCellValue.length -2}`}
          </span>
        </div>
      ) : (
        renderedCellValue.map((_d: any, i: any) => (
          <span
            key={i}
            className="text-dynamic_black flex justify-center items-center bg-goldenrod rounded-[12px] w-[30px] h-[30px]"
          >
            {_d}
          </span>
        ))
      )}
    </div>
  );
};

export default BetNumbersCell;
