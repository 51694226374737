import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import { useParams } from "react-router-dom";
import Modal from "../common/modal";
import Loading from "../common/loading";
import DatePicker from "react-datepicker";
import { AirrowLeftIcon, CalendarIcon } from "../../pages/common/Icon";

const HolidayEdit: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();
  const [errorArray, setErrorArray] = useState<any[]>([]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/admins/holidays/" + id,
      data: {
        holidayName: name,
        date: selectedDate,
      },
      method: "put",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setMessage(res.data.message);
          navigate("/setting/closedays/list");
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchgametypesData = async () => {
    fetchData({
      url: "api/admins/holidays/" + id,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setName(res.data.data?.holidayName);
          setSelectedDate(new Date(res.data.data?.date));
        }
      })
      .catch((err) => {
        setErrorArray(err.response.data.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchgametypesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="flex col">
                <AirrowLeftIcon
                  onClick={() => navigate("/setting/closedays/list")}
                />{" "}
                <span
                  className="p-l-5"
                  onClick={() => navigate("/setting/closedays/list")}
                >
                  Back
                </span>
              </div>
            </div>
            <div className="container">
              {errorArray ? (
                errorArray.map((error, i) => (
                  <div className="row p-t-10 p-b-10 alert alert-danger">
                    {error.msg}
                  </div>
                ))
              ) : (
                <></>
              )}
              {isLoading ? (
                <Loading></Loading>
              ) : (
                <div className="row g-3 p-t-10 p-b-10">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-input-box"
                      id="adminname"
                      placeholder="Name (e.g. Kasone Full Moon Day)"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-6 form-input-date">
                    <div className="form-input-date-container">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MMM d, yyyy"
                        className="form-input-box fs-16 w-140"
                      />
                      <CalendarIcon className="calendar-icon"></CalendarIcon>
                    </div>
                  </div>
                </div>
              )}
              <div className="row ">
                <div className="flex-row-reverse col-12 d-flex">
                  {name && selectedDate ? (
                    <div>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn-create-active"
                      >
                        Edit
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button type="submit" className="btn-create-unactive">
                        Edit
                      </button>
                    </div>
                  )}
                  <div
                    onClick={() => navigate("/setting/closedays/list")}
                    className="btn-cancel"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Holiday Edit" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/holiday/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HolidayEdit;
