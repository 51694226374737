import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { invitedUsersAgentCol } from "../../../table/columns/agent";
import { useGetInvitedUserAgentDetail } from ".";
import { useParams } from "react-router-dom";

const AgentInvitedUser = () => {

  const {id}=useParams()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const {data:invitedUser,error,isLoading}=useGetInvitedUserAgentDetail(id as string,pagination)

  const invitedUsersAgentMemorized = useMemo(() => {
    return invitedUser?.data?.data?.map((d: any, i: number) => ({
      user_id: d?.userCode,
      phone:d?.phone,
      name:d?.name  ,
      status: d?.status,
      reg_date: d?.createdAt,
      last_login:d?.lastLogin
    }));
  }, [invitedUser]);
  
  return (
    <CustomizeTable
      column={invitedUsersAgentCol}
      data={invitedUsersAgentMemorized || []}
      totalCount={invitedUsersAgentMemorized?.length}
      pagination={pagination}
      setPagination={setPagination}
      error={error}
      isRefetching={isLoading}
      isShowAction={"inactive"}
    />
  );
};

export default AgentInvitedUser;
