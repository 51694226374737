import React from 'react'

const DetailTitle = ({message}:{message ?:string}) => {
  return (
      <div className="mt-3">
          <span className="text-dim_grey text-[16px] ">Title</span><br/>
          <span className="text-[14px]">{ message}</span>
    </div>
  )
}

export default DetailTitle