import React, { useState, useEffect } from "react";

import BetHeadCard from "../../components/ui/bet/BetHeadCard";
import Bet3DList from "../../components/ui/users/Bet3DList";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import { useGetRoundList } from "../../api/funs/rounds";
import SpinnerLoading from "../common/SpinnerLoading";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { roundInfoAtom } from "../../store";

const ThreeDList: React.FC = () => {
  const [round, setRound] = useState<any>({
    label: "",
    value: "",
  });
  const [, setRoundId] = useAtom(roundInfoAtom);

  const [date, setDate] = useState(null);

  const { data: roundsList, isLoading } = useGetRoundList("3d");

  useEffect(() => {
    if (roundsList) {
      setRoundId({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
      setRound({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
    }
  }, [roundsList, setRoundId]);

  return (
    <>
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="flex mt-3 mb-5 gap-x-4">
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
              label="Select Date"
              // value={date}
              onChange={(newValue: any) => setDate(newValue)}
              slotProps={{ textField: { size: "small", color: "error" },field:{
                readOnly:true
              }  }}
            />
          </div>
          <BetHeadCard round={round} date={date} betTypeTrue={true} />
          <Bet3DList round={round} date={date} />
        </>
      )}
    </>
  );
};

export default ThreeDList;
