import React from "react";

const TextIconButton = ({
  label,
  icon,
  handleClick,
}: {
  label: string;
  icon: any;
  handleClick: () => void;
}) => {
  return (
    <div
      className="flex items-center gap-x-1  px-3 py-2 bg-white rounded-[6px] border border-steam"
      onClick={handleClick}
    >
      <span className="text-[12px]">{label}</span>

      {icon}
    </div>
  );
};

export default TextIconButton;
