import React, { useMemo, useState } from "react";
import { AddCircle1Icon } from "../../common/Icon";
import CustomizeTable from "../../../table/CustomizeTable";
import { roleCol } from "../../../table/columns/role";
import { useGetRoleList } from "../../../api/funs/role";
import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";

const RoleListsPage = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: roleList, error, isLoading } = useGetRoleList();
  const roleListMemorized = useMemo(() => {
    return roleList?.data?.data?.map((d: any, i: number) => ({
      _id: d?._id,
      user_role: d?.name,

      // created_at: d?.createdAt,
    }));
  }, [roleList]);
  return (
    <div>
      <div className="inline-block mt-10 mb-3 gap-x-4 md:mt-0">
        <div
          onClick={() => {
            navigate(RouteName.role_create);
          }}
          className="flex items-center px-2 py-1 text-white rounded-md md:py-2 bg-vermilion_bird"
        >
          <AddCircle1Icon width={18} height={18} />
          <span className="font-bold p-l-5">Create</span>
        </div>
      </div>
      <CustomizeTable
        column={roleCol}
        data={roleListMemorized || []}
        totalCount={roleListMemorized?.length}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </div>
  );
};

export default RoleListsPage;
