import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { useParams } from "react-router-dom";
import { useGetUserByBetHistory } from "../../../api/funs/user";
import { bet3DHistoryCol } from "../../../table/columns/user/3dBetHistory";
import { useAtom } from "jotai";
import { paginationBetHistoryTwoDAtom } from "../../../store";

const Bet3DHistory = ({ betTabIndex }: { betTabIndex?: number }) => {
  const { id } = useParams();
  const [globalFilter, setGlobalFilter] = useState("");

  const [pagination, setPagination] = useAtom(paginationBetHistoryTwoDAtom);
  const {
    data: transactionBetThreeDHistory,
    error,
    isLoading,
  } = useGetUserByBetHistory(
    id || "",
    pagination,
    "threed-bet-history",
    globalFilter
  );

  const historyData = useMemo(() => {
    return transactionBetThreeDHistory?.data?.data?.map((d: any) => ({
      _id: d?._id,
      _userId: d?.userId,
      bet_id: d?.transactionId,
      bet_date: d?.betDate,
      bet_numbers: d?.betNumbers?.map((e: any) => e?.number),
      total_no_of_bet: d?.totalNumbersOfBets,
      bet_amount: d?.total,
      profile_time_twt: `x${d?.tootOdds}`,
      profile_time: `x${d?.odds}`,
    }));
  }, [transactionBetThreeDHistory]);
  return (
    <CustomizeTable
      column={bet3DHistoryCol}
      data={historyData}
      totalCount={transactionBetThreeDHistory?.data?.totalCounts}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
      menuType={"Bet3DHistory"}
      betTabIndex={betTabIndex}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
      showGlobalFilter={true}
    />
  );
};

export default Bet3DHistory;
