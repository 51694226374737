import moment from "moment-timezone";

export const timeDateMMFun = (date: string): any => {
  const yangonTimezone = "Asia/Yangon";

  const utcDatetime = moment.utc(date);

  // Convert to Yangon time zone
  const yangonDatetime = utcDatetime.clone().tz(yangonTimezone);

  // Format the datetime string in the desired format
  const formattedDatetime = yangonDatetime;

  return formattedDatetime;
};
