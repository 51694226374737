import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";

import useSWRMutation from "swr/mutation";
import { useGetPolicesCondition } from "../../funs/polices";
import { updatePolicesConditionApi } from "../../polices";
import { toast } from "react-toastify";

export const useUpdatePolicies = (lang?: string) => {
  const navigate = useNavigate();

  const { mutate } = useGetPolicesCondition();

  return useSWRMutation(
    lang ? `/api/admins/policies/update/${lang}` : null,
    updatePolicesConditionApi,
    {
      throwOnError: false,
      onError: () => {toast.error("Something wrong.", {
        position: "bottom-right",
      });},
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.setting_privaypolicy_list);
      },
    }
  );
};
