import React from "react";

const BetOneNumberCell = ({ renderedCellValue }: any) => {
  return (
    <div className="flex flex-wrap gap-x-2 ">
       <span
              className="text-dynamic_black flex justify-center items-center bg-goldenrod rounded-[12px] w-[30px] h-[30px]"
            >
              {renderedCellValue}
            </span>
    </div>
  );
};

export default BetOneNumberCell;
