import React, { useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { CONTENT } from "../../../content";
import { useGetTopAgentList } from "../../../api/funs/dashoard";
import { kmbconvertFun } from "../../../util/funs/kmbconvertFun";

const TopAgent = () => {
  const [betType, setBetType] = useState<{
    label: string;
    value: string;
  }>({
    label: "All Game",
    value: "all",
  });

  const { data: topAgents } = useGetTopAgentList(
    betType?.value === "2d"
      ? "twod"
      : betType?.value === "3d"
      ? "threed"
      : "all"
  );

  const betAllA = [
    {
      label: "All Game",
      value: "all",
    },
    ...CONTENT.dashboard.betTypeA,
  ];
  return (
    <div className="flex flex-col items-start justify-between w-full px-3 py-3 mt-3 bg-white rounded-md ">
      <div className="flex justify-between w-full mb-4">
        <span className="block text-xs font-bold md:text-base text-dynamic_black">Top Agents</span>

        <Autocomplete
          id="combo-box-demo"
          onChange={(e, value) => {
            setBetType(value as any);
          }}
          sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
          value={betType}
          options={betAllA}
          defaultValue={{
            label: betAllA?.[0]?.label,
            value: betAllA?.[0]?.value,
          }}
          getOptionLabel={(option) => option?.label}
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          style={{ width: 130 }}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              color="error"
              {...params}
              label="Select Round"
              size="small"
              defaultValue={betType?.value}
              variant="outlined"
            />
          )}
        />
      </div>

      {topAgents ? (
        <table className="w-full text-dim_grey">
          <tbody>
            {topAgents?.data?.data?.map((_d: any, i: any) => (
              <tr className="border-dashed border-steam border-y " key={i}>
                <td className="py-3">
                  <span className="px-2 py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                    {i + 1}
                  </span>
                </td>
                <td className="py-1">
                  {" "}
                  <div className="flex items-center">
                    <div>
                      <div className="text-[14px] font-medium  text-dynamic_black">
                        {_d?.name}
                      </div>
                      <div className="text-[12px] text-dim_grey">
                        {_d?.agentCode }{" "}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="py-1 ">
                  {" "}
                  <div className="">
                    <>
                      <span className="block text-[10px] text-dim_grey">
                        Total Invited Users
                      </span>
                      <span className="text-[14px] font-medium  text-dynamic_black block">
                        {kmbconvertFun(_d?.userCount) || 0}
                      </span>
                    </>
                  </div>
                </td>
                <td className="py-1 ">
                  {" "}
                  <div className="">
                    <>
                      <span className="block text-[10px] text-dim_grey">
                        Total User Betting
                      </span>
                      <span className="text-[14px] font-medium  text-dynamic_black block">
                        {kmbconvertFun(_d?.totalBetAmount) || 0}
                      </span>
                    </>
                  </div>
                </td>
                {/* <td className="py-1 ">
                {" "}
                <div className="">
                  <>
                    <span className="block text-[10px] text-dim_grey">
                      Total Earned %
                    </span>
                    <span className="text-[14px] font-medium  text-dynamic_black block">
                      <span className="text-grass_green">1,200,000</span>
                    </span>
                  </>
                </div>
              </td> */}
                {/* <td className="py-1 ">
                {" "}
                <div className="">
                  <>
                    <span className="block text-[10px] text-dim_grey">
                      Total Withdraw
                    </span>
                    <span className="text-[14px] font-medium  text-dynamic_black block">
                      <span className="text-vermilion_bird">1,200,000</span>
                    </span>
                  </>
                </div>
              </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex justify-center w-full">
          <CircularProgress
            size={20}
            sx={{
              color: "#F44336",
            }}
          />
        </div>
      )}

      {/* <div
        onClick={() => {
          navigate(RouteName.agents_list);
        }}
        className="flex items-center justify-center w-full py-2 bg-pink_lemonade text-vermilion_bird text-[12px] mt-3 cursor-pointer"
      >
        View More
      </div> */}
    </div>
  );
};

export default TopAgent;
