import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getAgentListApi = async (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter?: string
) => {
  const axios_url = new URL(`${url}${API_NAME.admins_agents_list_api}`);
  axios_url.searchParams.set("keyword", globalFilter ?? "");
  axios_url.searchParams.set("sortReg", "desc");
  axios_url.searchParams.set("sortLog", "desc");
  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());
  return axiosInstance.get(axios_url);
};
export const getAgentCodeListApi = async () => {
  const axios_url = new URL(`${url}/api/admins/agents/code-lists`);

  return axiosInstance.get(axios_url);
};

export const createAgentApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      is2DAvailable: number;
      is3DAvailable: number;
      liveDate: string;
      domain: string;
      receivingMail: string;
      phone: string;
      ipAddress: string;
      email: string;
      name: string;
      password: string;
      invoicePercentage: string;
      isRound1Available: number;
      isRound2Available: number;
      isRound3Available: number;
      isRound4Available: number;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
export const updateAgentApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      is2DAvailable: number;
      is3DAvailable: number;
      liveDate: string;
      domain: string;
      receivingMail: string;
      phone: string;
      ipAddress: string;
      email: string;
      name: string;
      password: string;
      invoicePercentage: string;
      isRound1Available: number;
      isRound2Available: number;
      isRound3Available: number;
      isRound4Available: number;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
export const getAgentDetailApi = async (id: string) => {
  const axios_url = new URL(`${url}/api/admins/agents/${id}`);

  return axiosInstance.get(axios_url);
};

export const getInvitedUserAgentApi = async (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  agentId: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/agents/${agentId}/invited-users`
  );

  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());
  return axiosInstance.get(axios_url);
};
export const getAgentDetailOverviewApi = async (agentId: string) => {
  const axios_url = new URL(`${url}/api/admins/agents/${agentId}/overview`);

  return axiosInstance.get(axios_url);
};

export const patchStatusAgentApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      status: boolean;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.patch(`${axios_url}`, arg);
};

export const getAgentInvoiceApi = async (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter?: string,
  type?: string,
  id?: string,
  month?: string
) => {
  const axios_url = new URL(
    `${url}/api/admins/agents/${id}/${type}?pageNumber=${pageIndex}&pageSize=${pageSize}&sort=desc&month=${month}&year=2024`
  );

  return axiosInstance.get(axios_url);
};

export const patchInvoiceStatusAgentApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      status: boolean;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.patch(`${axios_url}`, arg);
};


export const createGenerateInvoiceApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      startDate: string;
      endDate: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
