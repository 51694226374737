import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";



import { RouteName } from "../../../app/constants/route-constant";

import { useGetRoleList } from "../../funs/role";
import { createRoleApi, updateRoleApi } from "../../role";
import { toast } from "react-toastify";

export function useCreateRole() {
  const navigate = useNavigate();

  const { mutate } = useGetRoleList();

  return useSWRMutation("/api/admins/roles", createRoleApi, {
    throwOnError: false,
    onError: () => {toast.error("Something wrong.", {
      position: "bottom-right",
    });},
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.role_list);
    },
  });
}
export function useUpdateRole(id: string) {
  const navigate = useNavigate();

  const { mutate } = useGetRoleList();
  return useSWRMutation(id ? `/api/admins/roles/${id}` : null, updateRoleApi, {
    throwOnError: false,
    onError: () => {toast.error("Something wrong.", {
      position: "bottom-right",
    });},
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.role_list);
    },
  });
}
// export function useDeleteHolidayOne() {

//       const { mutate } = useGetHolidayList();

//   return useSWRMutation(`/api/admins/holidays`, deleteHolidayOneApi, {
//     onError: () => {
//     },
//     onSuccess: (d) => {

//         mutate()
//     },
//   });
// }
