import { AmountMMKCell } from "../../cell/AmountMMKCell";
import { WinningNumberCell } from "../../cell/WinningNumberCell";
import DateFormatCell from "../../cell/DateFormatCell";

export const winningTransactionHistoryTableCol = [
  {
    accessorKey: "winningNumber", //simple recommended way to define a column
    header: "Winning Number",
    Cell: WinningNumberCell,
    // Cell: AvatarCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "totalBetAmount", //simple recommended way to define a column
    header: "Total Bet Amount",
    Cell: AmountMMKCell,

    // muiFilterTextFieldProps: { placeholder: "User  code" },
    // enableClickToCopy: true,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "totalWinningAmount", //simple recommended way to define a column
    header: "total Winning Amount",
    Cell: AmountMMKCell,
    // muiFilterTextFieldProps: { placeholder: "User  code" },
    // enableClickToCopy: true,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "round", //simple recommended way to define a column
    header: "Round",
    // muiFilterTextFieldProps: { placeholder: "User  code" },
    // enableClickToCopy: true,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  // {
  //   accessorKey: "status", //simple recommended way to define a column
  //   header: "Status",
  //   Cell: IsClaimedCell,
  // },

  {
    // accessorFn: (originalRow: any) => new Date(originalRow?.result_date),
    accessorKey: "announceTime", //simple recommended way to define a column
    header: "Announce Time",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.result_date),
    accessorKey: "result_date", //simple recommended way to define a column
    header: "Result Date",
    filterVariant: "date-range",
    Cell: DateFormatCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
];
