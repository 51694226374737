import React, { useMemo } from "react";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate, useParams } from "react-router-dom";
import UserInformationOverview from "../../components/ui/users/UserInformationOverview";
import { useGetUserById } from "../../api/funs/user";
import { useAtom } from "jotai";
import { userDetailAtom } from "../../store";

import BetthreeDDetailTable from "../../table/other/BetthreeDDetailTable";
import ThreeDBets from "../../components/ui/bet/ThreeDBets";

const ThreeDBetDetailPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { data: userDetail } = useGetUserById(
    userId || "65f3ba46e4a1e4e966f4e1c3"
  );
  const [, setUserDetailData] = useAtom(userDetailAtom);

  useMemo(() => {
    if (userDetail?.data?.data) {
      setUserDetailData(userDetail?.data?.data);
    }

    return () => {};
  }, [userDetail, setUserDetailData]);
  return (
    <>
      <div className="">
        <BackArrowButton
          handleClick={() => navigate(RouteName.threed_betlist)}
        />
      </div>
      <div className="">
        <UserInformationOverview />
        <BetthreeDDetailTable />
        <ThreeDBets />
      </div>
    </>
  );
};

export default ThreeDBetDetailPage;
