import React, { useState, useEffect } from "react";

import BetHeadCard from "../../components/ui/bet/BetHeadCard";
import Bet2DList from "../../components/ui/users/Bet2DList";
import { Autocomplete, TextField } from "@mui/material";
import { useGetRoundList } from "../../api/funs/rounds";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import SpinnerLoading from "../common/SpinnerLoading";
import { useAtom } from "jotai";
import { roundInfoAtom } from "../../store";
import { CalendarIcon } from "../common/Icon";

const TwoDList: React.FC = () => {
  const [round, setRound] = useState<any>({
    label: "",
    value: "",
  });
  const [, setRoundId] = useAtom(roundInfoAtom);

  const [date, setDate] = useState(null);

  const { data: roundsList, isLoading } = useGetRoundList("2d");

  useEffect(() => {
    if (roundsList) {
      setRoundId(roundsList?.data?.data?.[0]?._id);
      setRound({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
    }
  }, [roundsList, setRoundId]);

  return (
    <>
      {" "}
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="flex my-5 gap-x-4">
            {roundsList && (
              <Autocomplete
                sx={{
                  "& .MuiAutocomplete-clearIndicator": { display: "none" },
                }}
                id="combo-box-demo"
                onChange={(e, value) => {
                  setRound(value);
                  setRoundId(value?.value);
                }}
                value={round}
                options={roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))}
                defaultValue={{
                  label: roundsList?.data?.data?.map((_e: any, i: any) => ({
                    label: _e?.roundName,
                    value: _e?._id,
                  }))?.[0]?.label,
                  value: roundsList?.data?.data?.map((_e: any, i: any) => ({
                    label: _e?.roundName,
                    value: _e?._id,
                  }))?.[0]?.value,
                }}
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value?.value
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...params}
                    label="Select Round"
                    size="small"
                    defaultValue={round?.value}
                    variant="outlined"
                  />
                )}
              />
            )}

            <DatePicker
              format="DD MMM YYYY"
              defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
              label="Select Date"
              // value={date}
              onChange={(newValue: any) => setDate(newValue)}
              slotProps={{ textField: { size: "small", color: "error" },   field: {
                        readOnly: true
                    } }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
          </div>

          <BetHeadCard round={round} date={date} />
          <Bet2DList round={round} date={date} />
        </>
      )}
    </>
  );
};

export default TwoDList;
