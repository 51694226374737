import React, { useState } from "react";
import { CONTENT } from "../../content";
import { useAtom } from "jotai";
import { userDetailAtom } from "../../store";
import { useGetBetDetail } from "../../api/funs/rounds";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { timeDateMMFun } from "../../util/funs/timeDateMMFun";

const BetthreeDDetailTable = () => {
  const [userDetailData] = useAtom(userDetailAtom);
  const { id } = useParams();


  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: betDetail } = useGetBetDetail(
    id as string,
    pagination,
    "three-d"
  );



  return (
    <table className="w-full my-4 table-auto">
      <tbody className="text-center border border-steam">
        {CONTENT.bet_list.array_3d.map((_d, i) => (
          <tr key={i}>
            <td className="py-3 border border-steam">{_d}</td>
            <td className="py-3 font-semibold capitalize border border-steam">
            {i === 0
                    ?timeDateMMFun(betDetail?.data?.data?.transactionData?.createdAt).format( "DD MMM YYYY hh:mm:ss A")
                    : i === 1
                    ? betDetail?.data?.data?.roundData?.gameType
                    : i === 2
                    ? userDetailData?.agentId?.userCode
                    : i === 3
                    ? betDetail?.data?.data?.userData?.phone
                    : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BetthreeDDetailTable;
