import React from "react";
import { CONTENT } from "../../../content";

const ReportUserOverViewCard = ({ reportOverview }: any) => {
  return (
    <div className="px-3 bg-white rounded-[10px] mt-3 py-3 md:w-[70%]">
      <span className="font-bold text-dynamic_black text-[12px] mb-4 block">
        User Report
      </span>
      <div className="flex justify-between pr-5">
        {CONTENT.report.userOverview.map((_: any, index: number) => (
          <div key={index}>
            <span className="text-[12px] block">{_}</span>
            <span
              className={`block text-[36px] font-semibold ${
                index === 1
                  ? "text-vermilion_bird"
                  : index === 2
                  ? "text-grass_green"
                  : ""
              }`}
            >
              {reportOverview
                ? index === 0
                  ? reportOverview?.data?.data?.totalUsers?.toLocaleString()
                  : index === 1
                  ? reportOverview?.data?.data?.last7dayUsers?.toLocaleString()
                  : ""
                : "--"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportUserOverViewCard;
