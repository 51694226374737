import React, {  useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AddCircle1Icon } from "../../pages/common/Icon";

import CustomizeTable from "../../table/CustomizeTable";
import { agentTableCol } from "../../table/columns/agent";
import { useGetAgentList } from "../../api/funs/agent";
import { RouteName } from "../../app/constants/route-constant";
import { useAtom } from "jotai";
import { filterAgentAtom, paginationAgentAtom } from "../../store";
import { CONTENT } from "../../content";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";

const AgentList: React.FC = () => {
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useAtom(filterAgentAtom);

  const [pagination, setPagination] = useAtom(paginationAgentAtom);
  const { data: profileInfo } = useProfileInfo();

  const {
    data: agentLists,
    isLoading,
    error,
  } = useGetAgentList(pagination, globalFilter);
  useEffect(() => {
    setGlobalFilter("")
    setPagination({
      pageIndex:0,
      pageSize:10
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const agentMemorized = useMemo(() => {
    return agentLists?.data?.data?.map((d: any, i: number) => ({
      _id: d?._id,
      id: i,
      p_round: true,
      agent_id: d?.userCode,
      agent: d?.name,
      status: d?.status,
      est_live_date: d?.createdAt || new Date(),
      domain: d?.domainName,
      email: d?.email,
      ip_address: d?.loginIpAddresses,
      receiving_mail: d?.receivingMail,
      is2DAvailable: d?.is2DAvailable,
      isRound1Available: d?.isRound1Available,
      isRound2Available: d?.isRound2Available,
      isRound3Available: d?.isRound3Available,
      isRound4Available: d?.isRound4Available,
    }));
  }, [agentLists]);
  return (
    <>
      <div className="inline-block mb-3 gap-x-4">
        {permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.AGENT_CREATE
        ) && (
          <div
            onClick={() => {
              navigate(RouteName.agents_create);
            }}
            className="flex items-center px-2 py-1 text-white rounded-md md:py-2 bg-vermilion_bird"
          >
            <AddCircle1Icon width={18} height={18} />
            <span className="font-bold p-l-5">Create</span>
          </div>
        )}
      </div>
      <CustomizeTable
        column={agentTableCol}
        data={agentMemorized}
        totalCount={agentLists?.data?.totalCounts}
        isError={error}
        isRefetching={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        showGlobalFilter={true}
      />
    </>
  );
};

export default AgentList;
