import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FileUploadIcon } from "../common/Icon";
import { CircularProgress, TextField } from "@mui/material";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { FiX } from "react-icons/fi";
import { DevTool } from "@hookform/devtools";

import CustomizeTable from "../../table/CustomizeTable";

import { useCreateAppAds } from "../../api/mutation/app-ads";
import { useGetAppAdsList } from "../../api/funs/app-ads";
import { appAdsCol } from "../../table/columns/app-ads";

const CreateAppAdsPage = () => {
  const { data: appAds, isLoading, error } = useGetAppAdsList();
  const [imageFile, setImageFile] = useState<File[] | null>(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const { trigger, isMutating } = useCreateAppAds();
  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (imageFile) {
      if (imageSize.width > imageSize.height) {
        formData.append("adLink", data.link ?? "");
        formData.append("ad_image", imageFile[0]);
        trigger(formData as any);
      } else if (imageSize.width < imageSize.height) {
        toast.error("Portrait Image is not valid for banner");
        return;
      } else {
        toast.error("Square Image is not valid for banner");
        return;
      }
      // * CALL API HERE
      reset();
      setImageFile(null);
    } else toast.error("Please choose an image");
  };

  const { control, handleSubmit, formState, reset } = useForm<{
    link: string;
  }>({
    values: {
      link: "",
    },
    defaultValues: {
      link: "",
    },
  });
  const { errors } = formState;

  const checkFilSize = (file: File) => {
    if (file.size > 1024 * 1024) {
      toast.error("file size is too large");
      return {
        code: "file size is too large",
        message: "file size should be less than 25 MB",
      };
    }
    return null;
  };

  function validateImageOrientation(image: any) {
    const file = image;
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const img: any = new Image();
        img.src = e.target.result;
        img.onload = function () {
          setImageSize({ width: img.width, height: img.height });
        };
      };
      reader.readAsDataURL(file);
    }
  }
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {
    if (fileRejections.length) {
      toast.error(fileRejections[0].errors[0].code);
      return;
    }
    validateImageOrientation(acceptedFiles[0]);
    setImageFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    validator: checkFilSize,
    onDrop,
  });
  const appAdsMemozied = useMemo(() => {
    return appAds?.data?.data?.map((d: any, i: number) => ({
      _id: d?._id,
      image: d?.adImageUrl,
      redirect_link: d?.adLink,
      uploaded_date: d?.createdAt,
    }));
  }, [appAds]);
  const [, setProgress] = useState(0);

  function uploadFile(
    file: File | any,
    onProgress: (percentage: number) => void
  ) {
    const url = "https://api.cloudinary.com/v1_1/demo/image/upload";
    const key = "docs_upload_example_us_preset";

    if (file) {
      return new Promise<string>((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.onload = () => {
          const resp = JSON.parse(xhr.responseText);
          res(resp.secure_url);
        };
        xhr.onerror = (evt) => rej(evt);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentage = (event.loaded / event.total) * 100;
            onProgress(Math.round(percentage));
          }
        };

        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", key);

        xhr.send(formData);
      });
    }
  }

  useEffect(() => {
    async function upload() {
      await uploadFile(imageFile, setProgress);
    }

    upload();
  }, [imageFile]);
  return (
    <>
      <div className="w-full px-4 bg-white">
        <span className="block mb-2 font-semibold text-dynamic_black">
          Upload App Open AD
        </span>

        {/* {imageFile && <LinearProgress variant="determinate" value={progress} />} */}
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-x-5">
            {imageFile ? (
              <div className="relative w-[350px] h-[300px]">
                <FiX
                  className="absolute top-3 right-3 text-vermilion_bird"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setImageFile(null);
                  }}
                />
                <img
                  src={imageFile?.[0] && URL.createObjectURL(imageFile?.[0])}
                  alt=""
                  className="w-[350px] h-[300px] rounded-md "
                />
              </div>
            ) : (
              <div
                className="flex items-center justify-center w-[350px] h-[300px] p-3 rounded-[10px]  bg-pink_lemonade"
                {...getRootProps()}
              >
                {imageFile ? (
                  <div className="relative flex-1 w-full">
                    <FiX
                      className="absolute top-3 right-3 text-vermilion_bird"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setImageFile(null);
                      }}
                    />
                    <img
                      src={
                        imageFile?.[0] && URL.createObjectURL(imageFile?.[0])
                      }
                      alt=""
                      className="w-full rounded-md "
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center flex-1 h-full ">
                    <FileUploadIcon />

                    <input type="file" {...getInputProps()} />
                    <span className="block text-dynamic_black text-[14px] mt-3 mb-1 text-center w-[80%] font-semibold">
                      Drag and drop your image or{" "}
                      <span className="text-vermilion_bird">browse</span> from
                      file explorer
                    </span>
                    <span className="block text-dim_grey text-[14px]">
                      1080 x 2340 , up to 25 MB
                    </span>
                  </div>
                )}
              </div>
            )}

            <div className="w-full">
              <div className="col-span-2">
                <Controller
                  control={control}
                  name="link"
                  rules={{
                    validate: {
                      required: (value: any) => {
                        if (!value) {
                          return "Link is required.";
                        }
                        // if (
                        //   /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)*[:]\d{1,5}\/.*#?$/.test(
                        //     value
                        //   )
                        // ) {
                        //   return undefined;
                        // }
                        // return "Link format is invalid.";
                      },
                    },
                  }}
                  render={({ field, formState, fieldState }) => {
                    return (
                      <TextField
                        autoComplete="off"
                        color="error"
                        size="small"
                        fullWidth
                        {...field}
                        placeholder="Redirect Link"
                        error={!!errors?.link}
                        label="Redirect Link"
                        sx={{
                          marginBottom: "1.5rem",
                        }}
                        helperText={
                          !!errors?.link?.message && errors?.link?.message
                        }
                      />
                    );
                  }}
                />
              </div>
              <div className="flex justify-between">
                <button
                  className={`cursor-pointer  flex items-center justify-center  py-2 px-3 font-semibold  rounded-md  gap-x-2 bg-lynx_white text-dim_grey `}
                  onClick={() => {
                    reset();
                    setImageFile(null);
                  }}
                >
                  Reset
                </button>
                <button
                  className={`cursor-pointer  flex items-center justify-center  py-2 px-3 font-semibold  rounded-md  gap-x-2 ${
                    isMutating
                      ? "text-vermilion_bird bg-pink_lemonade "
                      : "bg-vermilion_bird text-white"
                  } ${isMutating ? "opacity-50 cursor-not-allowed " : ""}`}
                  disabled={isMutating}
                >
                  {isMutating ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#F44336",
                      }}
                    />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </div>

          <DevTool control={control} />
        </form>
      </div>
      <div className="px-4 mt-4">
        <CustomizeTable
          column={appAdsCol}
          data={appAdsMemozied || []}
          totalCount={2}
          isError={error}
          isRefetching={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          showGlobalFilter={false}
          isShowAction={"inactive"}
        />
      </div>
    </>
  );
};

export default CreateAppAdsPage;
