import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getFaqListApi = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  const axios_url = new URL(`${url}${API_NAME.faq_list_api}`);
  axios_url.searchParams.set("language", "");
  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());
  axios_url.searchParams.set("sort", "desc");

  return axiosInstance.get(axios_url);
};

export const createFaqApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      faqArg: {
        question: string;
        answer: string;
        language: string;
      }[];
    };
  }
) => {
  // const axios_url = new URL(`${url}${agUrl}`);

  arg?.faqArg?.map((_e) => {
    return axiosInstance.post(`${url}${agUrl}`, _e);
  });
};
export const updateFaqApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      question: string;
      answer: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
export const deleteFaqOneApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      id: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}/${arg.id}`);

  return axiosInstance.delete(`${axios_url}`);
};

export const getFaqByIdApi = async (id?: string) => {
  const axios_url = new URL(`${url}/api/admins/faqs/${id}`);

  return axiosInstance.get(axios_url);
};
