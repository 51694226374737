import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { betListCol } from "../../../table/columns/bet/betList";

import { useGetBetList } from "../../../api/funs/rounds";
import moment from 'moment-timezone';
import dayjs from "dayjs";

const Bet2DList = ({
  round,
  date,
}: {
  round: {
    label?: string;
    value?: string;
  };
  date: string | null;
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const {
    data: betList,
    isLoading,
    error,
  } = useGetBetList(
    "two-d",
    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    date ? dayjs(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    pagination.pageIndex,
    pagination.pageSize,
    round?.value as string
  );


  const betListData = useMemo(() => {
    
    return betList?.data?.data?.map((d: any) => ({
      _id: d?._id,
      _userId: d?.userId,

      agent_code: d?.agent?.userCode,
      name: d?.user?.name,
      phone: d?.user?.phone,
      bet_date: d?.betDate || new Date(),
      round:
        d?.roundId === "5d7a514b5d2c12c7449be010"
          ? "Round 1"
          : d?.roundId === "5d7a514b5d2c12c7449be011"
          ? "Round 2"
          : d?.roundId === "5d7a514b5d2c12c7449be012"
          ? "Round 3"
          : d?.roundId === "5d7a514b5d2c12c7449be013"
          ? "Round 4"
          : "Round 2",
      bet_numbers: d?.betNumbers?.map((_: any) => _?.number),
      total_no_of_bet: d?.totalNumbersOfBets,
      bet_amount: d?.total,
    }));
  }, [betList]);



  return (
    <CustomizeTable
      column={betListCol}
      data={betListData}
      totalCount={betList?.data?.totalCounts}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

export default Bet2DList;
