import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { bet2DHistoryCol } from "../../../table/columns/user/2dBetHistory";
import { useParams } from "react-router-dom";
import { useGetUserByBetHistory } from "../../../api/funs/user";
import { useAtom } from "jotai";
import { paginationBetHistoryTwoDAtom } from "../../../store";

const Bet2DHistory = ({ betTabIndex }: { betTabIndex?: number }) => {
  const { id } = useParams();
  const [globalFilter, setGlobalFilter] = useState("");

  const [pagination, setPagination] = useAtom(paginationBetHistoryTwoDAtom);

  const {
    data: transactionBetTwoDHistory,
    error,
    isLoading,
  } = useGetUserByBetHistory(
    id || "",
    pagination,
    "twod-bet-history",
    globalFilter
  );
  const historyData = useMemo(() => {
    return transactionBetTwoDHistory?.data?.data?.map((d: any) => ({
      _id: d?._id,
      _userId: d?.userId,
      bet_id: d?.transactionId,
      bet_date: d?.betDate,
      round: d?.round?.roundName,
      bet_numbers: d?.betNumbers?.map((e: any) => e?.number),
      total_no_of_bet: d?.totalNumbersOfBets,
      bet_amount: d?.total,
      profile_time: `x${d?.odds}`,
    }));
  }, [transactionBetTwoDHistory]);
  return (
    <CustomizeTable
      column={bet2DHistoryCol}
      data={historyData}
      totalCount={transactionBetTwoDHistory?.data?.totalCounts}
      isError={error}
      isRefetching={isLoading}
      pagination={pagination}
      setPagination={setPagination}
      menuType={"Bet2DHistory"}
      betTabIndex={betTabIndex}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
      showGlobalFilter={true}
    />
  );
};

export default Bet2DHistory;
