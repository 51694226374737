import React, { useState } from "react";
import { ExportIcon } from "../../../pages/common/Icon";
import XLSX from "sheetjs-style";
// import XLSX from 'xlsx-js-style';
import { saveAs } from "file-saver";
import { CircularProgress } from "@mui/material";

const ExportButton = ({
  exportdata,
  fileName,
  firstTotalValue,
  secondTotalValue,
  thirdTotalValue,
  fouthTotalValue,
  type,
  date,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);

    if (type === "3DmonthlyReport") {
      try {
        const worksheet = XLSX.utils.json_to_sheet(exportdata);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

        XLSX.utils.sheet_add_aoa(
          worksheet,
          [[{ v: "Date", t: "s", s: { alignment: { horizontal: "right" } } }]],
          { origin: `A${exportdata?.length + 2}` }
        );

        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              {
                v: date,
                t: "s",
                s: { alignment: { horizontal: "right" } },
              },
            ],
          ],
          { origin: `B${exportdata?.length + 2}` }
        );

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const excelBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(excelBlob, fileName);
      } catch (error) {
        console.error("Export error:", error);
        // Handle export errors (display notification, etc.)
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const worksheet = XLSX.utils.json_to_sheet(exportdata);
        // worksheet["A1"].s = {
        //   // set the style for target cell
        //   font: {
        //     name: "宋体",
        //     sz: 24,
        //     bold: true,
        //     color: {
        //       rgb: "FFFFAA00",
        //     },
        //   },
        // };
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

        XLSX.utils.sheet_add_aoa(
          worksheet,
          [[{ v: "Total", t: "s", s: { alignment: { horizontal: "right" } } }]],
          { origin: `A${exportdata?.length + 2}` }
        );
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              {
                v: firstTotalValue?.toString(),
                t: "s",
                s: { alignment: { horizontal: "right" } },
              },
            ],
          ],
          { origin: `C${exportdata?.length + 2}` }
        );
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              {
                v: secondTotalValue?.toString(),
                t: "s",
                s: { alignment: { horizontal: "right" } },
              },
            ],
          ],
          { origin: `D${exportdata?.length + 2}` }
        );
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              {
                v: thirdTotalValue?.toString(),
                t: "s",
                s: { alignment: { horizontal: "right" } },
              },
            ],
          ],
          { origin: `E${exportdata?.length + 2}` }
        );
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [
            [
              {
                v: fouthTotalValue?.toString(),
                t: "s",
                s: { alignment: { horizontal: "right" } },
              },
            ],
          ],
          { origin: `F${exportdata?.length + 2}` }
        );

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const excelBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(excelBlob, fileName);
      } catch (error) {
        console.error("Export error:", error);
        // Handle export errors (display notification, etc.)
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div
      className="flex items-center justify-center h-10 px-3 bg-white border rounded-md cursor-pointer gap-x-2 border-dim_grey"
      onClick={handleExport}
    >
      <ExportIcon />{" "}
      <span className="text-[12px]">
        {isLoading ? (
          <CircularProgress
            size={20}
            sx={{
              color: "#F44336",
            }}
          />
        ) : (
          "Export"
        )}
      </span>
    </div>
  );
};

export default ExportButton;
