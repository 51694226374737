import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AddCircle1Icon } from "../../pages/common/Icon";
import { useGetAdminList } from "../../api/funs/admin";

import { adminTableCol } from "../../table/columns/admin";
import CustomizeTable from "../../table/CustomizeTable";
import { RouteName } from "../../app/constants/route-constant";
import { useAtom } from "jotai";
import { filterAdminAtom, paginationAdminAtom } from "../../store";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const AdminList: React.FC = () => {
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useAtom(filterAdminAtom);  const { data: profileInfo } = useProfileInfo();
  const [pagination, setPagination] = useAtom(paginationAdminAtom);

  const {
    data: adminLists,
    isLoading,
    error,
  } = useGetAdminList(pagination, globalFilter);
  useEffect(() => {
    setGlobalFilter("")
    setPagination({
      pageIndex:0,
      pageSize:10
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const userMemozied = useMemo(() => {
    return adminLists?.data?.data?.map((d: any, i: number) => ({
      _id: d?._id,
      user_code: d?.userCode,
      admin: d?.name,
      status: d?.status,
      user_role: d?.roleId?.name,
      created_date: d?.createdAt,
      create_by: d?.createdBy?.name || "---",
    }));
  }, [adminLists]);

  return (
    <div>
      <div className="inline-block mb-3 gap-x-4">
        {permissionValidate(
          profileInfo?.data?.data?.roleId?.permissionIds,
          CONTENT.permission_const.ADMIN_CREATE
        ) && (
          <div
            onClick={() => {
              navigate(RouteName.admins_create);
            }}
            className="flex items-center px-2 py-1 text-white rounded-md md:py-2 bg-vermilion_bird"

       
          >
            <AddCircle1Icon width={18} height={18} />
            <span className="font-bold p-l-5">Create</span>
          </div>
        )}
      </div>

      <CustomizeTable
        column={adminTableCol}
        data={userMemozied}
        totalCount={adminLists?.data?.totalCounts}
        isError={error}
        isRefetching={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        showGlobalFilter={true}
      />
    </div>
  );
};

export default AdminList;
