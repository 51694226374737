import { ActiveBlockCell } from "../../cell/ActiveBlockCell";
import { TimeFormatCell } from "../../cell/TimeFormatCell";

export const adminTableCol = [
  {
    accessorKey: "user_code", //simple recommended way to define a column
    header: "User Code",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "admin", //simple recommended way to define a column
    header: "Admin",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "status", //simple recommended way to define a column
    header: "Status",
    Cell: ActiveBlockCell,
    filterVariant: "checkbox",
    accessorFn: (originalRow: any) => (originalRow.status ? "true" : "false"), //must be strings
    id: "status",
  },
  {
    accessorKey: "user_role", //simple recommended way to define a column
    header: "User Role",
    filterVariant: "multi-select",
    filterSelectOptions: [
      "SUPERADMIN	",
      "AGENT",
      "DEVELOPER",
      "MEMBER",
      "DATA_ENTRY",
    ],

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },

  {
    accessorFn: (originalRow: any) => new Date(originalRow?.created_date),

    accessorKey: "created_date", //simple recommended way to define a column
    header: " Created Date",
    filterVariant: "date-range",

    Cell: TimeFormatCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "create_by", //simple recommended way to define a column
    header: "Created By",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  // {
  //   // accessorFn: (originalRow) => parseInt(originalRow?.age), //alternate way
  //   id: "age", //id required if you use accessorFn instead of accessorKey
  //   header: "Age",
  //   Header: <i style={{ color: "red" }}>Age</i>, //optional custom markup
  //   // Cell: ({ cell }) => <i>{cell.getValue<number>().toLocaleString()}</i>, //optional custom cell render
  // },
];
