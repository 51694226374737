import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as DashboardActive } from "../../assets/icons/dashboardactive.svg";
import { ReactComponent as LogoAIO } from "../../assets/icons/LOGOAIO.svg";
import { ReactComponent as AccountCircle } from "../../assets/icons/accountcircle.svg";
import { ReactComponent as Key } from "../../assets/icons/key.svg";
import { ReactComponent as Visibility } from "../../assets/icons/visibility.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrowdown.svg";
import { ReactComponent as ArrowDownActive } from "../../assets/icons/arrowdownactive.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as UserMenu } from "../../assets/icons/usermenu.svg";
import { ReactComponent as UserMenuActive } from "../../assets/icons/usermenuActive.svg";
import { ReactComponent as TwoD } from "../../assets/icons/twoD.svg";
import { ReactComponent as TwoDActive } from "../../assets/icons/twoDActive.svg";
import { ReactComponent as Admin } from "../../assets/icons/admin.svg";
import { ReactComponent as AdminActive } from "../../assets/icons/adminactive.svg";
import { ReactComponent as AddCircle } from "../../assets/icons/addcircle.svg";
import { ReactComponent as AddCircle1 } from "../../assets/icons/addcircle1.svg";
import { ReactComponent as SearchNormal } from "../../assets/icons/searchnormal.svg";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { ReactComponent as AirrowLeft } from "../../assets/icons/arrowleft.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as Agent } from "../../assets/icons/agent.svg";
import { ReactComponent as AgentActive } from "../../assets/icons/agentActive.svg";
import { ReactComponent as Person } from "../../assets/icons/person.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/check_circle.svg";
import { ReactComponent as UnCheckCircle } from "../../assets/icons/uncheck_circle.svg";
import { ReactComponent as Notification } from "../../assets/icons/notification.svg";
import { ReactComponent as NotificationActive } from "../../assets/icons/notificationActive.svg";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as ReportActive } from "../../assets/icons/reportActive.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import { ReactComponent as SettingActive } from "../../assets/icons/settingActive.svg";
import { ReactComponent as PercentInvoice } from "../../assets/icons/invoicepercent.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrowright.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { ReactComponent as Block } from "../../assets/icons/block.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowSwapHorizontal } from "../../assets/icons/arrowSwapHorizontal.svg";
import { ReactComponent as Answer } from "../../assets/icons/answer.svg";
import { ReactComponent as InfoBlue } from "../../assets/icons/infoBlue.svg";
import { ReactComponent as EyeSlash } from "../../assets/icons/eyeSlash.svg";
import { ReactComponent as Percentage } from "../../assets/icons/percentage.svg";
import { ReactComponent as BlockCircleModal } from "../../assets/icons/blockCircleModal.svg";
import { ReactComponent as CheckCircleModal } from "../../assets/icons/checkCircleModal.svg";
import { ReactComponent as CheckCircleBrown } from "../../assets/icons/checkCircleBrown.svg";
import { ReactComponent as TwoDType } from "../../assets/icons/twoDType.svg";
import { ReactComponent as ThreeDType } from "../../assets/icons/ThreeDType.svg";
import { ReactComponent as TimeClock } from "../../assets/icons/timeClock.svg";
import { ReactComponent as TurnOnWhite } from "../../assets/icons/turnOnWhite.svg";
import { ReactComponent as TurnOffRed } from "../../assets/icons/turnOffRed.svg";
import { ReactComponent as SettingWhite } from "../../assets/icons/settingWhite.svg";
import { ReactComponent as ArrowUpWhite } from "../../assets/icons/arrowUpWhite.svg";
import { ReactComponent as ReleaseAlert } from "../../assets/icons/releaseAlert.svg";
import { ReactComponent as FileUpload } from "../../assets/icons/fileUpload.svg";
import { ReactComponent as DocumentUploadGrey } from "../../assets/icons/documentUploadGrey.svg";
import { ReactComponent as DocumentUploadWhite } from "../../assets/icons/documentUploadWhite.svg";

// export icon list
export const LogoIcon = (props: any) => <Logo {...props} />;
export const LogoAIOIcon = (props: any) => <LogoAIO {...props} />;
export const DashboardIcon = (props: any) => <Dashboard {...props} />;
export const DashboardActiveIcon = (props: any) => (
  <DashboardActive {...props} />
);
export const AccountCircleIcon = (props: any) => <AccountCircle {...props} />;
export const KeyIcon = (props: any) => <Key {...props} />;
export const VisibilityIcon = (props: any) => <Visibility {...props} />;
export const ArrowDownIcon = (props: any) => <ArrowDown {...props} />;
export const ArrowDownActiveIcon = (props: any) => (
  <ArrowDownActive {...props} />
);
export const EditIcon = (props: any) => <Edit {...props} />;
export const LogoutIcon = (props: any) => <Logout {...props} />;
export const UserMenuIcon = (props: any) => <UserMenu {...props} />;
export const UserMenuActiveIcon = (props: any) => <UserMenuActive {...props} />;
export const TwoDIcon = (props: any) => <TwoD {...props} />;
export const TwoDActiveIcon = (props: any) => <TwoDActive {...props} />;
export const AdminIcon = (props: any) => <Admin {...props} />;
export const AdminActiveIcon = (props: any) => <AdminActive {...props} />;
export const AddCircleIcon = (props: any) => (
  <AddCircle
    className={`icon ${props.className || ""} ${props.active ? "active" : ""}`}
    {...props}
  />
);
export const AddCircle1Icon = (props: any) => (
  <AddCircle1
    className={`icon ${props.className || ""} ${props.active ? "active" : ""}`}
    {...props}
  />
);
export const SearchNormalIcon = (props: any) => (
  <SearchNormal
    className={`icon ${props.className || ""} ${props.active ? "active" : ""}`}
    {...props}
  />
);
export const ExportIcon = (props: any) => (
  <Export
    className={`icon ${props.className || ""} ${props.active ? "active" : ""}`}
    {...props}
  />
);
export const AirrowLeftIcon = (props: any) => (
  <AirrowLeft
    className={`icon ${props.className || ""} ${props.active ? "active" : ""}`}
    {...props}
  />
);
export const EyeIcon = (props: any) => <Eye {...props} />;
export const AgentIcon = (props: any) => <Agent {...props} />;
export const AgentActiveIcon = (props: any) => <AgentActive {...props} />;
export const PersonIcon = (props: any) => <Person {...props} />;
export const CheckCircleIcon = (props: any) => <CheckCircle {...props} />;
export const UnCheckCircleIcon = (props: any) => <UnCheckCircle {...props} />;
export const NotificationIcon = (props: any) => <Notification {...props} />;
export const NotificationActiveIcon = (props: any) => (
  <NotificationActive {...props} />
);
export const ReportIcon = (props: any) => <Report {...props} />;
export const ReportActiveIcon = (props: any) => <ReportActive {...props} />;
export const SettingIcon = (props: any) => <Setting {...props} />;
export const SettingActiveIcon = (props: any) => <SettingActive {...props} />;
export const PercentInvocieIcon = (props: any) => <PercentInvoice {...props} />;
export const CalendarIcon = (props: any) => <Calendar {...props} />;
export const ArrowRightIcon = (props: any) => <ArrowRight {...props} />;
export const TrashIcon = (props: any) => <Trash {...props} />;
export const QuestionIcon = (props: any) => <Question {...props} />;
export const BlockIcon = (props: any) => <Block {...props} />;
export const CloseIcon = (props: any) => <Close {...props} />;
export const ArrowSwapHorizontalIcon = (props: any) => (
  <ArrowSwapHorizontal {...props} />
);
export const AnswerIcon = (props: any) => <Answer {...props} />;
export const InfoBlueIcon = (props: any) => <InfoBlue {...props} />;
export const EyeSlashIcon = (props: any) => <EyeSlash {...props} />;
export const PercentageIcon = (props: any) => <Percentage {...props} />;
export const BlockCircleModalIcon = (props: any) => (
  <BlockCircleModal {...props} />
);
export const CheckCircleModalIcon = (props: any) => (
  <CheckCircleModal {...props} />
);
export const CheckCircleBrownIcon = (props: any) => (
  <CheckCircleBrown {...props} />
);
export const TwoDTypeIcon = (props: any) => <TwoDType {...props} />;
export const ThreeDTypeIcon = (props: any) => <ThreeDType {...props} />;
export const TimeClockIcon = (props: any) => <TimeClock {...props} />;
export const TurnOnWhiteIcon = (props: any) => <TurnOnWhite {...props} />;
export const TurnOffRedIcon = (props: any) => <TurnOffRed {...props} />;
export const SettingWhiteIcon = (props: any) => <SettingWhite {...props} />;
export const ArrowUpWhiteIcon = (props: any) => <ArrowUpWhite {...props} />;
export const ReleaseAlertIcon = (props: any) => <ReleaseAlert {...props} />;
export const FileUploadIcon = (props: any) => <FileUpload {...props} />;
export const DocumentUploadGreyIcon = (props: any) => <DocumentUploadGrey {...props} />;
export const DocumentUploadWhiteIcon = (props: any) => <DocumentUploadWhite {...props} />;
