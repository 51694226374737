import useSWR from "swr";
import { API_NAME } from "../../constant";
import { getFaqByIdApi, getFaqListApi } from "../../faq";

export const useGetFaqList = ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  return useSWR(
    [API_NAME.faq_list_api, pageIndex, pageSize],
    () => getFaqListApi({ pageIndex, pageSize }),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetFaqById= (id?:string) => {
  return useSWR(
    id ? [ API_NAME.faq_id_api,id]:null,
    ()=>getFaqByIdApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
