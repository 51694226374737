/* eslint-disable import/named */
import axios from "axios";
import { getAccessToken } from "./storage";
import { API_DOMAIN } from "../config";
// eslint-disable-next-line no-undef
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;

class AxiosService {
  axiosInstance = {};

  constructor() {
    this.initInstance();
  }

  initInstance() {
    this.axiosInstance = axios.create({
      baseURL: URL,
      withCredentials: false,
    });

    this.axiosInstance.interceptors?.request.use((config) => {
      // get token to set 'Authorization' header for send back to server

      // eslint-disable-next-line no-param-reassign

      if (getAccessToken()) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      return config;
    });

    this.axiosInstance.interceptors?.response.use(
      (response) => response,
      (error) => {
        // if (error.response?.status === 401) {
        //   localStorage.setItem(
        //     "token_expired",
        //     JSON.stringify({
        //       token_expired: true,
        //     })
        //   );
        // }

        return Promise.reject(error);
      }
    );

    return this.axiosInstance;
  }

  getInstance() {
    return this.axiosInstance || this.initInstance();
  }
}
const AxiosServiceD = new AxiosService();
export default AxiosServiceD;
