import React from "react";
import roundSvg from "../../assets/images/round.svg";
import { ArrowRightIcon } from "../common/Icon";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useGetPreviousWinnersList } from "../../api/funs/rounds";
import { setPreviousResult } from "../../services/storage";
import { CircularProgress } from "@mui/material";
const PreviousResultCard = ({
  winningNumber,
  set,
  value,
  resultDate,
  roundType,
  resultId,
  announceTime,
}: {
  winningNumber?: string;
  set?: string;
  value?: string;
  resultDate?: string;
  roundType?: string;
  resultId?: string;
  announceTime?: string;
}) => {
  const navigate = useNavigate();

  const { data: PreviousWinnings, isLoading } = useGetPreviousWinnersList(
    "two-d",
    resultId as string,
    0,
    3
  );

  return (
    <div className="bg-white rounded-[10px] px-2 py-1 md:px-3 md:py-2 mt-3">
      <div className="flex items-start justify-between mb-3 md:items-center">
        <div className="md:flex gap-x-2">
          <img src={roundSvg} className="w-10 h-10" alt="placeholder" />
          <div>
            <span className="font-bold text-[10px] md:text-[16px] block">
              {moment(resultDate).format("DD MMMM YYYY")}
            </span>
            <span className="font-bold text-vermilion_bird text-[8px] md:text-[12px] -mt-[3px] block">
              {roundType}
            </span>
          </div>
        </div>

        <div className="flex items-center md:gap-x-3">
          <span className="text-[8px] md:text-[14px]  ">
            Result announced:
            <span className="font-semibold">
              {moment(announceTime, "HH:mm").format("hh:mm:ss a")} at{" "}
              {moment(resultDate).format("DD MMMM YYYY")},{" "}
              {moment(resultDate).format("ddd")}
            </span>
          </span>
          <ArrowRightIcon />
        </div>
      </div>
      <div className="md:flex gap-x-10">
        <div className="py-3 md:py-0 md:w-[299px] md:h-[259px] bg-center bg-no-repeat bg-cover bg-round_image rounded-[10px] flex flex-col justify-center items-center">
          <span className="text-[12px]">Result</span>
          <span className="text-[50px] font-bold -mt-3 mb-2">
            {winningNumber}
          </span>

          <div className="flex gap-x-10">
            <div className="text-center">
              <span className="text-[12px] font-normal">Set</span>
              <br />
              <span className="text-[16px] font-bold">{set}</span>
            </div>
            <div className="text-center">
              <span className="text-[12px] font-normal">Value</span>
              <br />
              <span className="text-[16px] font-bold">{value}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between flex-auto mt-4 md:mt-0">
          <span className="block text-[12px] md:text-base">
            Total of {PreviousWinnings?.data?.totalCounts} Winners
          </span>
          {isLoading ? (
            <div className="flex justify-center w-full mt-3">
              {" "}
              <CircularProgress
                size={20}
                sx={{
                  color: "#F44336",
                }}
              />
            </div>
          ) : (
            <table className="w-full text-dim_grey">
              <tbody>
                {PreviousWinnings?.data?.data?.length === 0 ? (
                  <div className="flex items-center justify-center">
                    No Record ...
                  </div>
                ) : (
                  <>
                    {PreviousWinnings?.data?.data?.twodWinnerLists?.map(
                      (_d: any, i: any) => (
                        <tr
                          className="border-dashed cursor-pointer border-steam border-y"
                          onClick={() => {
                            navigate({
                              pathname: `/users/detail/${_d?.userId?._id}/winning/${_d?.twodResultId}`,
                              search: "?type=twod",
                            });
                          }}
                          key={i}
                        >
                          <td className="py-3">
                            <span className="px-1 md:px-2 md:py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                              {i + 1}
                            </span>
                          </td>
                          <td>
                            {" "}
                            <div className="flex items-center">
                              {/* <img
                        className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}

                              <div>
                                <div className=" text-[10px] md:text-[14px] font-medium  text-dynamic_black">
                                  {_d?.userId?.name}
                                </div>
                                <div className="text-[8px] md:text-[12px] text-dim_grey">
                                  {_d?.userId?.phone}{" "}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Agent Code <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.agentCode}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Bet <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.totalBetAmount?.toLocaleString()}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px] block">Won</span>
                            <span className="text-[12px] md:text-[16px] text-grass_green">
                              {_d?.totalWinningAmount?.toLocaleString()}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-[12px] block">Status</span>
                            {_d?.status === "UNCLAIMED" ? (
                              <div className="flex items-center">
                                <CheckCircleBrownIcon />
                                <span className=" text-[14px] ml-1 text-dim_grey">
                                  UnClaimed
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <CheckCircleIcon />
                                <span className=" text-[14px] ml-1 text-grass_green">
                                  Claimed
                                </span>
                              </div>
                            )}
                          </td> */}
                          <td>
                            <ArrowRightIcon />
                          </td>
                        </tr>
                      )
                    )}
                  </>
                )}
              </tbody>
            </table>
          )}

          <div
            onClick={() => {
              setPreviousResult(
                JSON.stringify({
                  winningNumber: winningNumber,
                  set: set,
                  value: value,
                  resultDate: resultDate,
                  roundType: roundType,
                  resultId: resultId,
                })
              );
              navigate(RouteName.twod_previousresults_winnerlist);
            }}
            className="flex items-center justify-center w-full py-2 mt-2 text-xs cursor-pointer md:text-base bg-pink_lemonade text-vermilion_bird"
          >
            View All Winners
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousResultCard;
