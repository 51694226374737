import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getNotificationListApi = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  const axios_url = new URL(`${url}${API_NAME.notification_lists_api}`);
  // type=&pageNumber=1&pageSize=20&sort=desc

  axios_url.searchParams.set("type", "");
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());
  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("sort", "desc");
  // axios_url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  // axios_url.searchParams.set('globalFilter', globalFilter ?? '');
  // axios_url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
  return axiosInstance.get(axios_url);
};
export const getNotificationByIdApi = async (id: string | null) => {
  if (id) {
    return axiosInstance.get(`${url}${API_NAME.notifications_by_id_api}/${id}`);
  }
};
export const getNotificationForEmailApi = async () => {
  return axiosInstance.get(`${url}${API_NAME.notifications_for_email_api}`);
};

export const createNotificationTelegramApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      text: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};

export const createNotificationInAppApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      title: string;
      text: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
export const createNotificationEmailApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      emails: string[];
      subject: string;
      text: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};
