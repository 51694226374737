import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getRoleListApi = async () => {
  const axios_url = new URL(`${url}${API_NAME.role_list_api}`);

  return axiosInstance.get(axios_url);
};
export const getPermissionListApi = async () => {
  const axios_url = new URL(`${url}${API_NAME.permission_list_api}`);

  return axiosInstance.get(axios_url);
};

export const createRoleApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      name: string;
      permissionIds: string[];
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.post(`${axios_url}`, arg);
};

export const getRoleByIdApi = async (id: string) => {
  const axios_url = new URL(`${url}/api/admins/roles/${id}`);

  return axiosInstance.get(axios_url);
};

export const updateRoleApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      name: string;
      permissionIds: string[];
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
