function InfoCard({
  label,
  info,
  className,
}: {
  label: string;
  info: string | number | React.ReactNode;
  className?: string;
}) {
  return (
    <div className={"flex w-full overflow-hidden border border-b-gray-500"}>
      <div className="w-[60%] bg-gray-150/80  px-4 py-2 font-semibold whitespace-nowrap	">{label}</div>
      <div className="w-[40%] bg-white px-4 py-2 whitespace-nowrap	">{info}</div>
    </div>
  );
}

export default InfoCard;
