import { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
type Props = {
  auth: boolean;
  children?: JSX.Element;
};

export function RouteGuard(props: Props) {
  const { children } = props;
  const location = useLocation();
  const { authenticated } = useContext(AuthContext);
  useEffect(() => {}, []);
  if (authenticated) {
    return <>{children}</>;
  }
  return (
    <Navigate
      to="/login"
      state={{
        from: location,
      }}
      replace
    />
  );
}
