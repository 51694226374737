import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import { useParams } from "react-router-dom";
import Modal from "../common/modal";
import Loading from "../common/loading";

const GameTypeEdit: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [name, setName] = useState("MM2D");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/gametypes/" + id,
      data: {
        name: name,
        time: time,
      },
      method: "put",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setMessage(res.data.message);
          setIsModalOpen(true);
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchgametypesData = async () => {
    fetchData({
      url: "api/gametypes/" + id,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setName(res.data.data?.name);
          setTime(res.data.data?.time);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchgametypesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setName(event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="container">
                {message ? (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                ) : (
                  <></>
                )}
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="name">Group Type Name :</label>
                      <select
                        className="form-input-select"
                        onChange={handleSelectChange}
                        aria-label="Default select example"
                      >
                        <option value="MM2D">MM2D</option>
                        <option value="3D">3D</option>
                        <option value="TH2D">TH2D</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="time">Time :</label>
                      <input
                        type="text"
                        className="form-control"
                        id="time"
                        placeholder="Enter time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                    <div className="col-3">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        Edit
                      </button>
                    </div>
                    <div className="col-3">
                      <Link to="/gametypes/list" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Game Type Edit" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/gametypes/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GameTypeEdit;
