import React from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";

import { useGetAgentDetail } from "../../api/funs/agent";
import AgentInformationOverview from "../../components/ui/agent/AgentInformationOverview";
import DetailAgentTable from "../../table/other/DetailAgentTable";
import AgentDetailMenu from "../../components/ui/menus/AgentDetailMenu";
import { useAtom } from "jotai";
import { menuAtom } from "../../store";
import AgentInvoice from "../../api/funs/agent/AgentInvoice";
import AgentInvitedUser from "../../api/funs/agent/AgentInvitedUser";
import AgentDetailOverview from "../../api/funs/agent/AgentDetailOverview";
import SpinnerLoading from "../common/SpinnerLoading";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const AgentDetail: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data: agentDetail } = useGetAgentDetail(id as string);
  const [menuIndex] = useAtom(menuAtom);
  const { data: profileInfo } = useProfileInfo();

  return (
    <>
      {agentDetail ? (
        <>
          {" "}
          <div>
            <div className="pl-5 mt-10 md:mt-0">
              <BackArrowButton
                handleClick={() => navigate(RouteName.agents_list)}
              />
            </div>
          </div>
          <div className="pl-5">
            {agentDetail && (
              <>
                <AgentInformationOverview agentDetail={agentDetail} />
                <AgentDetailMenu />

                {menuIndex === 0 &&
                permissionValidate(
                  profileInfo?.data?.data?.roleId?.permissionIds,
                  CONTENT.permission_const.AGENT_DETAIL_INFORMATION
                ) ? (
                  <DetailAgentTable agentDetail={agentDetail} />
                ) : menuIndex === 1 &&
                  permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.AGENT_DETAIL_INVOICE
                  ) ? (
                  <AgentInvoice />
                ) : menuIndex === 2 &&
                  permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.AGENT_DETAIL_INVITED_USER
                  ) ? (
                  <AgentInvitedUser />
                ) : menuIndex === 3 &&
                  permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.AGENT_DETAIL_OVERVIEW
                  ) ? (
                  <AgentDetailOverview />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

export default AgentDetail;
