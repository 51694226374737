import { CheckCircleIcon, UnCheckCircleIcon } from "../../../pages/common/Icon";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {  userDetailAtom } from "../../../store";
import IOSSwitchAction from "../../../table/actions/IOSSwitchAction";
import { useProfileInfo } from "../../../api/funs/profile";
import { permissionValidate } from "../../../util/funs";
import { CONTENT } from "../../../content";

const UserInformationOverview = () => {
  const { id, userId } = useParams();
  const { pathname } = useLocation();
  const { data: profileInfo } = useProfileInfo();

  const [userDetailData] = useAtom(userDetailAtom);

 

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <span className="text-[14px] md:text-[18px] font-bold">{userDetailData?.name}</span>
        </div>
        {[
          `/twod/betlist/user/${userId}/${id}`,
          `/threed/betlist/user/${userId}/${id}`,
        ].includes(pathname) ? null : (
          <div className="flex items-center">
            {userDetailData?.status ? (
              <span className="flex items-center p-r-10 gap-x-1">
                <CheckCircleIcon /> <span className="text-sm md:text-base">Verified</span>
              </span>
            ) : (
              <span className="flex items-center p-r-10 gap-x-1">
                <UnCheckCircleIcon /> <span className="text-sm md:text-base">Blocked</span> 
              </span>
            )}

            {}

            {permissionValidate(
              profileInfo?.data?.data?.roleId?.permissionIds,
              CONTENT.permission_const.USER_TOGGLE_ACTIVE_BLOCK
            ) && <IOSSwitchAction detail={userDetailData} />}
          </div>
        )}
      </div>
      <div className="my-2 d-flex">
        <span className="font-semibold text-[12px] md:text-[16px]">
          {userDetailData?.userCode}
        </span>
      </div>
      <div className="d-flex">
      <span className="font-semibold text-[12px] md:text-[16px]">
          {userDetailData?.phone}
        </span>
      </div>
    </>
  );
};

export default UserInformationOverview;
