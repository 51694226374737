import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { invoiceAgentCol } from "../../../table/columns/agent";
import moment from "moment";
import BetTab from "../../../components/ui/tabs/BetTab";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetAgentInvoice } from ".";
import dayjs from "dayjs";
import { CalendarIcon } from "../../../pages/common/Icon";
import { useAtom } from "jotai";
import {
  paginatioAgentBetTabDAtom,
  paginatioAgentInvoiceDAtom,
} from "../../../store";

const AgentInvoice = () => {
  const [betTabIndex, setBetTabIndex] = useAtom(paginatioAgentBetTabDAtom);
  const [globalFilter] = useState("");
  const [pagination, setPagination] = useAtom(paginatioAgentInvoiceDAtom);
  const [date, setDate] = useState(new Date());

  const { id } = useParams();

  const {
    data: agentInvoices,
    error,
    isLoading,
  } = useGetAgentInvoice(
    pagination,
    globalFilter,
    betTabIndex === 0 ? "2d-invoice" : "3d-invoice",
    id as string,
    date ? `${dayjs(date).format("MMMM")}` : `${moment().format("MMMM")}`
  );

  const invoiceAgentMemorized = useMemo(() => {
    return agentInvoices?.data?.data?.map((d: any, i: number) => ({
      invoice_month: d?.month,
      _id: d?._id,
      // sr_no: `${pagination.pageIndex}${i}`,
      invoice_type: betTabIndex === 0 ? "2D" : "3D",
      total_bet_amount: d?.totalBetAmount,
      percentage: `${d?.invoicePercentage}%`,
      invoice_status: d?.status,
      total_invoice_amount: d?.totalInvoiceAmount,
    }));
  }, [agentInvoices?.data?.data, betTabIndex]);
  return (
    <>
      <div className="flex mb-4 gap-x-10">
        <BetTab betTabIndex={betTabIndex} setBetTabIndex={setBetTabIndex} />
        <DatePicker
          views={["month"]}
          format="MMM YYYY"
          defaultValue={dayjs(moment().format("YYYY-MM"))}
          label="Date"
          // value={date}
          onChange={(newValue: any) => setDate(newValue)}
          slotProps={{ textField: { size: "small", color: "error" }, field: {
                        readOnly: true
                    } }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
        />
      </div>

      <CustomizeTable
        column={invoiceAgentCol}
        data={invoiceAgentMemorized || []}
        totalCount={agentInvoices?.data?.totalCounts}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </>
  );
};

export default AgentInvoice;
