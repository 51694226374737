import React from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import DetailTable from "../../table/other/DetailTable";
import { useNotificationById } from "../../api/funs/notification";
import DetailMessage from "../../components/ui/DetailMessage";
import SpinnerLoading from "../common/SpinnerLoading";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import DetailTitle from "../../components/ui/DetailTitle";

const NotificationDetail: React.FC = () => {
  const { id } = useParams();

  // const [admin, setAdmin] = useState<NotificationDetailPops>();
  const navigate = useNavigate();

  const { data: detailNoti, isLoading } = useNotificationById(id || null);
  // const fetchadminData = async () => {
  //     fetchData({
  //       url: "api/admins/"+id,
  //       method: "get",
  //       })
  //       .then((res) => {
  //         if(res.data.isSuccess){
  //             setAdmin(res.data.data);
  //         }
  //       })
  //       .catch((err) => {
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   };
  //   useEffect(() => {
  //     fetchadminData();
  //   },[])

  return (
    <div className="mt-10 md:mt-0">
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <BackArrowButton
            handleClick={() => navigate("/notifications/list")}
          />
          <DetailTable notiData={detailNoti} />

          {detailNoti?.data?.data?.type === "email" && (
            <span className="px-3 py-1 inline-block mt-4 bg-pink_lemonade text-vermilion_bird rounded-[10px]">
              {detailNoti?.data?.data?.to}
            </span>
          )}

          <DetailTitle message={detailNoti?.data?.data?.title} />
          <DetailMessage message={detailNoti?.data?.data?.text} />
        </>
      )}
    </div>
  );
};

export default NotificationDetail;
