export function WinningNumberCell({ renderedCellValue, row }: any) {
  return (
    <div>
      {row?.original?.tootNumber && row?.original?.winningType === "Toot" ? (
        <div className="flex flex-wrap gap-x-1">
          {row?.original?.tootNumber?.map((_d: any, i: number) => (
            <div
              className="inline-block p-2 rounded-lg text-dynamic_black bg-goldenrod"
              key={i}
            >
              {_d}
            </div>
          ))}
        </div>
      ) : (
        <div className="inline-block p-2 rounded-lg text-dynamic_black bg-goldenrod">
          {renderedCellValue}
        </div>
      )}
    </div>
  );
}
