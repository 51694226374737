import { CircularProgress } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { CalendarIcon } from '../common/Icon';
import { useCreateGenerateInvoice } from '../../api/mutation/generate-invoice';

const ThreeDGenerateInvoicePage = () => {

  const { control, handleSubmit, formState } = useForm<{
    fromDate: Dayjs | null;
 
    toDate: Dayjs | null;
  
  }>({
    defaultValues: {
      fromDate: null,
      toDate:null
    }
  });
  const { errors } = formState;
  const { trigger, isMutating } = useCreateGenerateInvoice();


  const onSubmit = (data: any) => {

    trigger({
startDate:dayjs(data.fromDate).format("YYYY-MM-DD"),
endDate:dayjs(data.toDate).format("YYYY-MM-DD"),
    })
  
  };
  return (
    <div className="mt-10 md:pl-4 bg-white md:mt-0 md:w-[70%] ">
      
      <p className="text-sm font-bold text-black md:text-md">Please Select Date to Export Invoice</p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
    <div className="md:grid md:grid-cols-2 gap-x-4">
          <div className='w-full mb-2 md:mb-0'>
          <span className="block mb-2 font-bold">From</span> 
      <Controller
        control={control}
        name="fromDate"
        rules={{
          required: {
            value: true,
            message: "Date is required",
          },
        }}
        render={({ field, formState, fieldState }) => {
          return (
            <DesktopDatePicker
              label="Select Date"
              {...field}
              slotProps={{
                textField: {
                  size: "small",
                  color: "error",
                  fullWidth:true,
                  helperText:
                    !!errors?.fromDate?.message &&
                    errors?.fromDate?.message,
                  error: !!errors?.fromDate?.message,
                },
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
              format="DD MMM YYYY"
            />
          );
        }}
          />
   </div>
          <div className='w-full mb-2'>
            
          <span className="block mb-2 font-bold">To</span> 
      <Controller
        control={control}
        name="toDate"
        rules={{
          required: {
            value: true,
            message: "Date is required",
          },
        }}
        render={({ field, formState, fieldState }) => {
          return (
            <DesktopDatePicker
              label="Select Date"
              {...field}
              slotProps={{
                textField: {
                  size: "small",
                  color: "error",
                  fullWidth:true,

                  helperText:
                    !!errors?.toDate?.message &&
                    errors?.toDate?.message,
                  error: !!errors?.toDate?.message,
                },
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
              format="DD MMM YYYY"
            />
          );
        }}
      />
       </div>
   
    
     
       <div className="flex justify-between flex-1 col-span-2">            <div></div>
        <button
              type="submit"
              className={`btn-create-active ${
                isMutating ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={isMutating}
            >
              {isMutating ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "Export"
              )}
            </button>
  </div>
    </div>
  </form>
    </div>
    

  

  )
}

export default ThreeDGenerateInvoicePage