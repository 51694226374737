import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "../../../pages/common/Icon";
import { sliceStarPhoneFun } from "../../../util/funs/sliceStarFun";

const ShowHideSecret = ({ code }: { code: string }) => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = () => {
    setIsShow(!isShow);
  };
  return (
    <div className="flex justify-center items-centers gap-x-2">
      {!isShow ? sliceStarPhoneFun(code, code?.length) : code}{" "}
      {!isShow ? (
        <EyeSlashIcon onClick={handleClick} />
      ) : (
        <EyeIcon onClick={handleClick} />
      )}{" "}
    </div>
  );
};

export default ShowHideSecret;
