import { MdCheckCircle, MdDoDisturbOn } from "react-icons/md";

export function ActiveBlockCell({ renderedCellValue }: any) {
  return (
    <>
      {renderedCellValue ==="true" ? (
        <div className="flex items-center">
          <MdCheckCircle className="text-green-500" />
          <span className="text-dynamic_black text-[14px] ml-1 font-semibold">
            Active
          </span>
        </div>
      ) : (
        <div className="flex items-center">
          <MdDoDisturbOn className="text-red-500" />
          <span className="text-dynamic_black text-[14px] ml-1 font-semibold">
            Blocked
          </span>
        </div>
      )}
    </>
  );
}
