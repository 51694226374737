import { useLocation } from "react-router-dom";

export function AvatarCell({ renderedCellValue, row }: any) {
  const { pathname } = useLocation();
  return (
    <div className="flex items-center gap-x-2">
      {pathname === "/users/userlist" && (

        <>
        {
            row?.original?.imageUrl ? <img
            className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
            src={  row?.original?.imageUrl }
            alt=""
          /> : <img
            className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
            src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        }
        </>
       
      )}

      <div>
        <div className="text-[14px] font-medium  text-dynamic_black">
          {row?.original?.name}
        </div>
        <div className="text-[12px] text-dim_grey">{row?.original?.phone}</div>
      </div>
    </div>
  );
}
