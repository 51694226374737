import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import { RouteName } from "../../app/constants/route-constant";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import Menu from "../../components/ui/menus/Menu";
import { useAtom } from "jotai";
import { userDetailAtom } from "../../store";
import UserInformationOverview from "../../components/ui/users/UserInformationOverview";
import UserDetailMenuItem from "../../components/ui/users/UserDetailMenuItem";
import { useGetUserById } from "../../api/funs/user";
import SpinnerLoading from "../common/SpinnerLoading";

const UserDetail: React.FC = () => {
  const { id } = useParams();
  const [, setUserDetailData] = useAtom(userDetailAtom);
  const navigate = useNavigate();

  const { data: userDetail, isLoading } = useGetUserById(id || "0");

  useMemo(() => {
    if (userDetail?.data?.data) {
      setUserDetailData(userDetail?.data?.data);
    }

    return () => {};
  }, [userDetail,setUserDetailData]);

  return (
    <>
      {" "}
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="">
            <div className="">
              <BackArrowButton
                handleClick={() => navigate(RouteName.users_userlist)}
              />
            </div>
            <div className="">
              <UserInformationOverview />

              <Menu />
              <UserDetailMenuItem />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserDetail;
