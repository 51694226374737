import { useUpdateAgentInvoiceStatus } from "../../api/mutation/agent";

export function InvoiceButtonCell({ renderedCellValue, row }: any) {
  const { trigger } = useUpdateAgentInvoiceStatus(row?.original?._id as string);
  return (
    <>
      {renderedCellValue ? (
        <div className="px-3 py-2 ">
          <span
            className=" text-[14px] ml-1 text-grass_green"
            // onClick={() => {
            //   trigger({
            //     status: false,
            //   });
            // }}
          >
            Paid
          </span>
        </div>
      ) : (
        <span
          className="px-3 py-2 rounded-md cursor-pointer bg-autumn_glory"
          onClick={() => {
            trigger({
              status: true,
            });
          }}
        >
          <span className=" text-[14px] ml-1 text-white">Unpaid</span>
        </span>
      )}
    </>
  );
}
