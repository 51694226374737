import useSWRMutation from "swr/mutation";

import { toast } from "react-toastify";
import { deleteAdsBannerOneApi } from "../../ads-banner";
import { useGetAdsBannerList } from "../../funs/ads-banner";
import { createAppAdsApi } from "../../app-ads";
import { useGetAppAdsList } from "../../funs/app-ads";

export function useCreateAppAds() {
  // const navigate = useNavigate();
  // const [pagination] = useState({
  //   pageIndex: 0,
  //   pageSize: 10, //customize the default page size
  // });

  const { mutate } = useGetAppAdsList();

  return useSWRMutation("/api/admins/ads/create", createAppAdsApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}

export function useDeleteAdsBannerOne() {
  const { mutate } = useGetAdsBannerList();

  return useSWRMutation(`/api/admins/banners`, deleteAdsBannerOneApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Deleted.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}
