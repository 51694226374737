import AgentDashboard from "../assets/images/agentdashboard.png";
import UserDashboard from "../assets/images/userdashboard.png";
import BetDashboard from "../assets/images/betdashboard.png";
import TodayBetDashboard from "../assets/images/todaybetdashboard.png";

export const CONTENT = {
  notification: {
    detail: {
      table: ["Notification Type", "Created Date"],
    },
  },
  user_detail: {
    menu: ["Information", "Bet History", "Winning History", "Overview"],
    detail: {
      table: ["Name", "Phone Number", "Status"],
      table2: [
        "Registered Date",
        "Last Login Time",
        // "IP Address",
        // "Device Info",
        "Registration Agent Code",
      ],
      winningHistoryCard: ["User", "Game", "Total Bet", "Total Win Amount"],
    },
    betTab: {
      array: ["2D", "3D"],
    },
    overview: {
      array2: ["Total Betting", "Total Lost"],
      array: [
        "Total Bet Times",
        "Total Won Times",
        // "Total Deposit",
        // "Total Withdraw",
      ],
    },
  },
  bet_list: {
    array: ["Bet Placed on", "Game", "Round", "Agent Code", "Ph.No"],
    array_3d: ["Bet Placed on", "Game", "Agent Code", "Ph.No"],
    overview: {
      array2: ["Total Betting", "Total Amount Earned by Users"],

      array: ["Total Round", "Active Date"],
      array3: [
        {
          title: "Top Winner of All Time",
          desc: "Bet placed on ",
          userInfo: {
            name: "May Thant Zin",
            phone: "09382882389",
          },
          betInfo: ["Bet", "Won"],
        },
        {
          title: "Top Betting of All Time",
          desc: "Bet placed on ",
          userInfo: {
            name: "May Thant Zin",
            phone: "09382882389",
          },
          betInfo: ["Bet", "Won"],
        },
      ],
    },
  },
  report: {
    userOverview: ["Total Users", "New Users (Last 7 Days)"],
  },
  admin_detail: {
    array: [
      "User Role",
      "Login Code",
      "Phone Number",
      "Created Date",
      "Created by",
      "Status",
    ],
    array2: ["Password", "IP Address"],
  },
  agent_detail: {
    array: [
      "Agent Name",
      "Agent Code",
      "Secret Code",
      "Game Type",
      "2D Rounds",
      "Invoice Percentage",
      "Phone Number",
      "Email Address",
      "Receiving Email",
      "Live Date",
      "Status",
      "Registered Date",
    ],
    array2: ["Password", "IP Address", "Domain"],
    menu: ["Information", "Invoice", "Invited Users", "Overview"],
    overview: ["Total Users", "Total User Bettings"],
  },
  dashboard: {
    cardA: [
      {
        img: UserDashboard,
        text: "Total Users",
      },
      {
        img: AgentDashboard,
        text: "Total Agents",
      },
      {
        img: BetDashboard,
        text: "Bet List",
      },
      {
        img: TodayBetDashboard,
        text: "Today Bet List",
      },
    ],
    betTypeA: [
      {
        label: "2D",
        value: "2d",
      },
      {
        label: "3D",
        value: "3d",
      },
    ],
    tootDaeTypeA: [
      {
        label: "Dae",
        value: "straight-winners",
      },
      {
        label: "Toot",
        value: "box-winners",
      },
    ],
  },
  role: {
    names: [
      "SUPER_ADMIN",
      "NORMAL_ADMIN",
      "OPERATION_ADMIN",
      "AGENT",
      "MEMBER",
    ],
  },
  permission_const: {
    DASHBOARD_MENU_VIEW: "DASHBOARD_MENU_VIEW",
    USER_MENU_VIEW: "USER_MENU_VIEW",
    USER_DETAIL: "USER_DETAIL",
    USER_TOGGLE_ACTIVE_BLOCK: "USER_TOGGLE_ACTIVE_BLOCK",
    USER_DETAIL_INFORMATION: "USER_DETAIL_INFORMATION",
    USER_DETAIL_BET_HISTORY: "USER_DETAIL_BET_HISTORY",
    USER_DETAIL_WINNING_HISTORY: "USER_DETAIL_WINNING_HISTORY",
    USER_DETAIL_OVERVIEW: "USER_DETAIL_OVERVIEW",
    twoD_MENU_BET_LIST: "2D_MENU_BET_LIST",
    twoD_MENU_PREVIOUS_RESULTS: "2D_MENU_PREVIOUS_RESULTS",
    twoD_MENU_ROUND_SETTING: "2D_MENU_ROUND_SETTING",
    twoD_MENU_SUMMARY: "2D_MENU_SUMMARY",
    threeD_MENU_BET_LIST: "3D_MENU_BET_LIST",
    threeD_MENU_PREVIOUS_RESULTS: "3D_MENU_PREVIOUS_RESULTS",
    threeD_MENU_ROUND_SETTING: "3D_MENU_ROUND_SETTING",
    threeD_MENU_SUMMARY: "3D_MENU_SUMMARY",
    ADMIN_MENU_VIEW: "ADMIN_MENU_VIEW",
    ADMIN_DETAIL: "ADMIN_DETAIL",
    ADMIN_CREATE: "ADMIN_CREATE",
    ADMIN_EDIT: "ADMIN_EDIT",
    ADMIN_ROLE_PERMISSION: "ADMIN_ROLE_PERMISSION",

    ADMIN_ACTIVE_BLOCK_TOGGLE: "ADMIN_ACTIVE_BLOCK_TOGGLE",
    AGENT_MENU_VIEW: "AGENT_MENU_VIEW",
    AGENT_DETAIL: "AGENT_DETAIL",
    AGENT_CREATE: "AGENT_CREATE",
    AGENT_EDIT: "AGENT_EDIT",
    AGENT_ACTIVE_BLOCK_TOGGLE: "AGENT_ACTIVE_BLOCK_TOGGLE",
    AGENT_DETAIL_INFORMATION: "AGENT_DETAIL_INFORMATION",
    AGENT_DETAIL_INVOICE: "AGENT_DETAIL_INVOICE",
    AGENT_DETAIL_INVITED_USER: "AGENT_DETAIL_INVITED_USER",
    AGENT_DETAIL_OVERVIEW: "AGENT_DETAIL_OVERVIEW",
    NOTIFICATION_MENU_VIEW: "NOTIFICATION_MENU_VIEW",
    NOTIFICATION_DETAIL: "NOTIFICATION_DETAIL",
    NOTIFICATION_CREATE: "NOTIFICATION_CREATE",
    REPORT_MENU_OVERALL: "REPORT_MENU_OVERALL",
    REPORT_MENU_2D_DAILY: "REPORT_MENU_2D_DAILY",
    REPORT_MENU_2D_MONTHLY: "REPORT_MENU_2D_MONTHLY",
    REPORT_MENU_2D_ANNUAL: "REPORT_MENU_2D_ANNUAL",
    REPORT_MENU_3D_MONTHLY: "REPORT_MENU_3D_MONTHLY",
    REPORT_MENU_3D_ANNUAL: "REPORT_MENU_3D_ANNUAL",
    SETTING_MENU_CLOSE_DAYS: "SETTING_MENU_CLOSE_DAYS",
    SETTING_CLOSE_DAYS_CREATE: "SETTING_CLOSE_DAYS_CREATE",
    SETTING_CLOSE_DAYS_EDIT: "SETTING_CLOSE_DAYS_EDIT",
    SETTING_CLOSE_DAYS_DELETE: "SETTING_CLOSE_DAYS_DELETE",
    SETTING_MENU_MAINTENANCE: "SETTING_MENU_MAINTENANCE",
    SETTING_MENU_TERMS_CONDITION: "SETTING_MENU_TERMS_CONDITION",
    SETTING_TERMS_CONDITION_EDIT: "SETTING_TERMS_CONDITION_EDIT",
    SETTING_MENU_POLICY: "SETTING_MENU_POLICY",
    SETTING_POLICY_EDIT: "SETTING_POLICY_EDIT",
    SETTING_MENU_FAQS: "SETTING_MENU_FAQS",
    SETTING_FAQS_EDIT: "SETTING_FAQS_EDIT",
    SETTING_FAQS_CREATE: "SETTING_FAQS_CREATE",
    SETTING_FAQS_DELETE: "SETTING_FAQS_DELETE",
  },
  round: [
    { label: "Round1 (2D)", value: "Round1_2D" },
    { label: "Round2 (2D)", value: "Round2_2D" },
    { label: "Round3 (2D)", value: "Round3_2D" },
    { label: "Round4 (2D)", value: "Round4_2D" },
  ],
};
