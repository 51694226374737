
import useSWRMutation from "swr/mutation";

import { useProfileById, useProfileInfo } from "../../funs/profile";
import { updateProfileApi } from "../../profile";
import { toast } from "react-toastify";

export function useUpdateProfile(id?: string) {
  const { mutate: ProfileByIdMutate } = useProfileById(id as string);

  const { mutate } = useProfileInfo();

  return useSWRMutation(id ? `/api/admins/${id}` : null, updateProfileApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      ProfileByIdMutate();
      mutate();
    },
  });
}
