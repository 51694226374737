import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";

import {
  EyeIcon,
  CalendarIcon,
  EyeSlashIcon,
  PercentageIcon,
  AddCircleIcon,
  TrashIcon,
} from "../../pages/common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DevTool } from "@hookform/devtools";
import { useUpdateAgent } from "../../api/mutation/agent";
import { useGetAgentDetail } from "../../api/funs/agent";
import { decryptHashedData } from "../../util/funs";
import SpinnerLoading from "../common/SpinnerLoading";
import IOSSwitch from "../../table/actions/IOSSwitch";

const AgentUpdate: React.FC = () => {
  const { id } = useParams();

  const { data: agentDetail } = useGetAgentDetail(id as string);

  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const { trigger, isMutating,error:errorAgent } = useUpdateAgent(id as string);
  const [is2D_S, setIs2D_S] = useState(false);
  const [is3D_S, setIs3D_S] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (setIs2D_S) {
      setIs2D_S(agentDetail?.data?.data?.is2DAvailable);
      setIs3D_S(agentDetail?.data?.data?.is3DAvailable);
    }
  }, [agentDetail]);

  const { control, handleSubmit, formState, getValues, setValue,reset } = useForm<{
    agent_name: string;
    phone: string;
    ipAddressA: { text: string }[];

    domain: string;
    email: string;
    receive_email: string;
    invoice_percentage: number;
    live_date: Dayjs | null;
    password: string;
    confirmPassword: string;
    round_name: string[];
    isRound1Available: boolean;
    isRound2Available: boolean;
    isRound3Available: boolean;
    isRound4Available: boolean;
  }>({
    values: {
      agent_name: agentDetail?.data?.data?.name,
      phone: agentDetail?.data?.data?.phone,
      ipAddressA: agentDetail?.data?.data?.loginIpAddresses?.map((_: any) => ({
        text: _,
      })),
      domain: agentDetail?.data?.data?.domainName,
      email: agentDetail?.data?.data?.email,
      receive_email: agentDetail?.data?.data?.receivingMail,
      invoice_percentage: agentDetail?.data?.data?.invoicePercentage,
      live_date:
        agentDetail?.data?.data?.liveDate &&
        dayjs(agentDetail?.data?.data?.liveDate),

      password:
        agentDetail?.data?.data?.encryptPassword &&
        decryptHashedData(
          agentDetail?.data?.data?.encryptPassword?.toString(),
          "AIO_PASSWORD_ENCRYPT"
        ),
      confirmPassword:
        agentDetail?.data?.data?.encryptPassword &&
        decryptHashedData(
          agentDetail?.data?.data?.encryptPassword?.toString(),
          "AIO_PASSWORD_ENCRYPT"
        ),
      round_name: [],
      isRound1Available: agentDetail?.data?.data?.isRound1Available,
      isRound2Available: agentDetail?.data?.data?.isRound2Available,
      isRound3Available: agentDetail?.data?.data?.isRound3Available,
      isRound4Available: agentDetail?.data?.data?.isRound4Available,
    },

    defaultValues: {
      agent_name: "",
      phone: "",
      ipAddressA: agentDetail?.data?.data?.loginIpAddresses?.map((_: any) => ({
        text: _,
      })),
      domain: "",
      email: "",
      receive_email: "",
      invoice_percentage: 0,
      live_date: null,
      password: "",
      confirmPassword: "",
      round_name: [],
      isRound1Available: agentDetail?.data?.data?.isRound1Available,
      isRound2Available: agentDetail?.data?.data?.isRound2Available,
      isRound3Available: agentDetail?.data?.data?.isRound3Available,
      isRound4Available: agentDetail?.data?.data?.isRound4Available,
    },
  });
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "ipAddressA",
    control,
  });

  const onSubmit = (data: any) => {
    if (is2D_S === false && is3D_S === false) {
      setError(true);
    } else {
      trigger({
        is2DAvailable: is2D_S === true ? 1 : 0,
        is3DAvailable: is3D_S === true ? 1 : 0,
        liveDate: data?.live_date,
        domain: data?.domain,
        receivingMail: data?.receive_email,
        phone: data?.phone,
        ipAddress: data?.ipAddressA?.map((_: any) => _?.text),
        email: data?.email,
        name: data?.agent_name,
        password: data?.confirmPassword,
        invoicePercentage: data?.invoice_percentage,
        isRound1Available: data?.isRound1Available ? 1 : 0,
        isRound2Available: data?.isRound2Available ? 1 : 0,
        isRound3Available: data?.isRound3Available ? 1 : 0,
        isRound4Available: data?.isRound4Available ? 1 : 0,
      });

      if(errorAgent){

      }else{
        reset();

      }
    }
  };
  return (
    <div className="md:w-[60%]">
      {agentDetail ? (
        <>
          <div className="md:pl-5">
            <BackArrowButton
              handleClick={() => navigate(RouteName.agents_list)}
            />
          </div>
          <form className="md:pl-5" noValidate onSubmit={handleSubmit(onSubmit)}>
            <p>Please select game type for this agent</p>
            <div className="flex gap-x-10">
              <div className="flex items-center gap-x-2">
                <IOSSwitch
                  value={is2D_S}
                  handleClick={() => {
                    setIs2D_S(!is2D_S);
                  }}
                />
                <span className="text-dynamic_black text-[18px] font-semibold">
                  2D
                </span>
              </div>
              <div className="flex items-center gap-x-2">
                <IOSSwitch
                  value={is3D_S}
                  handleClick={() => {
                    setIs3D_S(!is3D_S);
                  }}
                />
                <span className="text-dynamic_black text-[18px] font-semibold">
                  3D
                </span>
              </div>
            </div>
            {error && (
              <p className="mt-2 text-vermilion_bird font-bold text-[12px]">
                Please choose game type.
              </p>
            )}

            <div
              className={`grid md:grid-cols-3 my-4 ${
                !is2D_S ? "pointer-events-none	opacity-50" : ""
              }`}
            >
              <Controller
                control={control}
                name="isRound1Available"
                // rules={{
                //   validate: {
                //     required: (value: any) => {
                //       if (value.length < 1) {
                //         return "Round is required.";
                //       }
                //     },
                //   },
                // }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#F44336",
                            },
                          }}
                          checked={
                            getValues("isRound1Available") ? true : false
                          }
                          value={getValues("isRound1Available") ? true : false}
                          onChange={(e: any) => {
                            if (getValues("isRound1Available")) {
                              setValue("isRound1Available", false, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            } else {
                              setValue("isRound1Available", true, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            }
                          }}
                        />
                      }
                      label={"Round1 (2D)"}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="isRound2Available"
                // rules={{
                //   validate: {
                //     required: (value: any) => {
                //       if (value.length < 1) {
                //         return "Round is required.";
                //       }
                //     },
                //   },
                // }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#F44336",
                            },
                          }}
                          checked={
                            getValues("isRound2Available") ? true : false
                          }
                          value={getValues("isRound2Available") ? true : false}
                          onChange={(e: any) => {
                            if (getValues("isRound2Available")) {
                              setValue("isRound2Available", false, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            } else {
                              setValue("isRound2Available", true, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            }
                          }}
                        />
                      }
                      label={"Round2 (2D)"}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="isRound3Available"
                // rules={{
                //   validate: {
                //     required: (value: any) => {
                //       if (value.length < 1) {
                //         return "Round is required.";
                //       }
                //     },
                //   },
                // }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#F44336",
                            },
                          }}
                          checked={
                            getValues("isRound3Available") ? true : false
                          }
                          value={getValues("isRound3Available") ? true : false}
                          onChange={(e: any) => {
                            if (getValues("isRound3Available")) {
                              setValue("isRound3Available", false, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            } else {
                              setValue("isRound3Available", true, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            }
                          }}
                        />
                      }
                      label={"Round3 (2D)"}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="isRound4Available"
                render={({ field, formState, fieldState }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#F44336",
                            },
                          }}
                          checked={
                            getValues("isRound4Available") ? true : false
                          }
                          value={getValues("isRound4Available") ? true : false}
                          onChange={(e: any) => {
                            if (getValues("isRound4Available")) {
                              setValue("isRound4Available", false, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            } else {
                              setValue("isRound4Available", true, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            }
                          }}
                        />
                      }
                      label={"Round4 (2D)"}
                    />
                  );
                }}
              />
            </div>
            <div className="grid mt-first-line:4 md:grid-cols-2 gap-x-10 gap-y-1">
              <Controller
                control={control}
                name="agent_name"
                rules={{
                  required: {
                    value: true,
                    message: "Agent name is required.",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      placeholder="Agent Name (e.g.Ko Soe)"
                      error={!!errors?.agent_name}
                      label="Agent Name (e.g.Ko Soe)"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.agent_name?.message &&
                        errors?.agent_name?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: "Phone is required.",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      placeholder="Phone Number"
                      error={!!errors?.phone}
                      label="Phone Number"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.phone?.message && errors?.phone?.message
                      }
                    />
                  );
                }}
              />
              <div>
                {fields.map((field, index) => (
                  <div
                    className="flex justify-center mb-3 gap-x-4"
                    key={field.text}
                  >
                    <Controller
                      control={control}
                      name={`ipAddressA.${index}.text`}
                      rules={{
                        validate: (v: any) => {
                          if (v?.length === 0) {
                            return "Ip Address is required.";
                          } else if (/^([0-9]{1,3}\.){3}[0-9]{1,3}$/.test(v)) {
                            return undefined;
                          }

                          return "Invalid Ip Address";
                        },
                      }}
                      render={({ field, formState, fieldState }) => {
                        return (
                          <TextField
                            autoComplete="off"
                            color="error"
                            fullWidth
                            size="small"
                            {...field}
                            placeholder="Ip Address"
                            label="Ip Address"
                            error={!!errors.ipAddressA?.[index]?.text}
                            helperText={
                              errors.ipAddressA?.[index]?.text?.message
                            }
                          />
                        );
                      }}
                    />

                    {fields?.length !== 1 && (
                      <div className="mt-2 cursor-pointer">
                        <TrashIcon onClick={() => remove(index)} />
                      </div>
                    )}
                  </div>
                ))}
                <div className="mb-3">
                  <span
                    className="flex justify-center items-center w-[160px] h-[40px] gap-x-2 bg-pink_lemonade rounded-md cursor-pointer"
                    onClick={() => append({ text: " " })}
                  >
                    <AddCircleIcon className="w-5 h-5" />
                    <span className="text-vermilion_bird text-[12px] font-bold">
                      Add IP address
                    </span>
                  </span>
                </div>
              </div>
              <Controller
                control={control}
                name="domain"
                rules={{
                  required: {
                    value: true,
                    message: "Domain is required.",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      placeholder="Domain"
                      error={!!errors?.domain}
                      label="Domain"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.domain?.message && errors?.domain?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="email"
                rules={{
                  validate: {
                    required: (value: any) => {
                      if (!value) {
                        return "Email is required.";
                      }
                      if (value && !validator.isEmail(value)) {
                        return "Email is not format.";
                      }
                    },
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      placeholder="Email"
                      error={!!errors?.email}
                      label="Email"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.email?.message && errors?.email?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="receive_email"
                rules={{
                  required: {
                    value: true,
                    message: "Receive email is required.",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      placeholder="Receiving Email (example@gmail.com)"
                      error={!!errors?.receive_email}
                      label="Receiving Email (example@gmail.com)"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.receive_email?.message &&
                        errors?.receive_email?.message
                      }
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="invoice_percentage"
                rules={{
                  validate: {
                    required: (value: any) => {
                      if (value?.toString().startsWith("0") && value?.toString()?.length > 1 ) {
                        return "Invoice percentage is only number";
                      }
                     
                      if (!(/^\d{1,2}\.\d{2}$/.test(value))) {
                    
                        return "Please enter two decimal places"
                      }
                      if (!(+value >= 0 && +value <= 100)) {
                        return "Invoice percentage is required.";
                      }
                    },
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setIsShowConfirmPassword(!isShowConfirmPassword);
                            }}
                          >
                            <PercentageIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                      placeholder="Invoice Percentage"
                      error={!!errors?.invoice_percentage}
                      label="Invoice Percentage"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.invoice_percentage?.message &&
                        errors?.invoice_percentage?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="live_date"
                rules={{
                  required: {
                    value: true,
                    message: "Live Date is required",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <DesktopDatePicker
                      label="Select Date"
                      {...field}
                      slotProps={{
                        textField: {
                          size: "small",
                          color: "error",
                          helperText:
                            !!errors?.live_date?.message &&
                            errors?.live_date?.message,
                          error: !!errors?.live_date?.message,
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      format="YYYY-MM-DD"
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="password"
                rules={{
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },

                  validate: (value: string) => {
                    if (!value) {
                      return "Not a Number";
                    }
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      type={isShowPassword ? "text" : "password"}
                      placeholder="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            {isShowPassword ? (
                              <EyeSlashIcon className="w-6 h-6" />
                            ) : (
                              <EyeIcon className="w-6 h-6" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors?.password}
                      label="Password"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.password?.message && errors?.password?.message
                      }
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },

                  validate: (value: string) => {
                    if (!value) {
                      return "Not a Number";
                    }
                    if (value !== getValues("password")) {
                      return "Do not match.";
                    }
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      size="small"
                      {...field}
                      type={isShowConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setIsShowConfirmPassword(!isShowConfirmPassword);
                            }}
                          >
                            {isShowPassword ? (
                              <EyeSlashIcon className="w-6 h-6" />
                            ) : (
                              <EyeIcon className="w-6 h-6" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Confirm Password"
                      error={!!errors?.confirmPassword}
                      label="Confirm Password"
                      sx={{
                        marginBottom: "1.5rem",
                      }}
                      helperText={
                        !!errors?.confirmPassword?.message &&
                        errors?.confirmPassword?.message
                      }
                    />
                  );
                }}
              />
            </div>

            <div className="flex items-center justify-between w-full">
              <div></div>
              <button
                type="submit"
                className={`btn-create-active ${
                  isMutating ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isMutating}
              >
                {isMutating ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
            <DevTool control={control} />
          </form>
        </>
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

export default AgentUpdate;
