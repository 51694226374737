import useSWR from "swr";

import { getSystemMaintenanceApi } from "../../maintence";

export const useGetSystemMaintenance = () => {
  return useSWR(
    ["get_system_maintenance"],
    getSystemMaintenanceApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
