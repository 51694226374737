import React from "react";
import BackArrowButton from "../../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import { DevTool } from "@hookform/devtools";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useGetPermissionList } from "../../../api/funs/role";
import SpinnerLoading from "../../common/SpinnerLoading";
import { useCreateRole } from "../../../api/mutation/role";
import { CONTENT } from "../../../content";

const RoleCreatePage = () => {
  const navigate = useNavigate();

  const { data: permissionList, isLoading } = useGetPermissionList();
  const { trigger, isMutating } = useCreateRole();

  const { control, handleSubmit, formState, reset, getValues, setValue } =
    useForm<{
      role_name: string;
      role_value: string[];
    }>({
      defaultValues: {
        role_name: "",
        role_value: [],
      },
    });
  const { errors } = formState;
  const role_name = getValues("role_name");
  const role_values = getValues("role_value");

  const role_all_values =
    permissionList && permissionList?.data?.data?.map((e: any) => e._id);

  const onSubmit = (data: any) => {
    trigger({
      name: data?.role_name,
      permissionIds: data?.role_value,
    });
    reset();
  };
  return (
    <>
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="md:pl-5">
            <BackArrowButton
              handleClick={() => navigate(RouteName.role_list)}
            />
          </div>
          <div className="md:pl-5">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <label>Select Role Name</label>
              <br />
              {!!errors?.role_name?.message && (
                <span className="text-red-500 text-[12px]">
                  {errors?.role_name?.message}
                </span>
              )}

              <div className="grid mb-3 md:grid-cols-3">
                {CONTENT.role.names.map((_, i) => (
                  <Controller
                    key={i}
                    control={control}
                    name="role_name"
                    rules={{
                      validate: {
                        required: (value: any) => {
                          if (value.length === 0) {
                            return "Role Name is required.";
                          }
                        },
                      },
                    }}
                    render={({ field, formState, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Radio
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#F44336",
                                },
                              }}
                              checked={role_name === _}
                              onChange={(e: any) => {
                                if (!(role_name === _)) {
                                  setValue("role_name", _, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  });
                                } else {
                                  setValue("role_name", "", {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  });
                                }
                              }}
                            />
                          }
                          label={_}
                        />
                      );
                    }}
                  />
                ))}
              </div>
              <div className="flex items-center justify-between">
                <label>Select Permissions</label>
                <div className="md:mr-[30%]">
                  <Controller
                    control={control}
                    name="role_value"
                    render={({ field, formState, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#F44336",
                                },
                              }}
                              checked={
                                role_all_values?.length === role_values?.length
                              }
                              onChange={(e: any) => {
                                if (!e.target.checked) {
                                  setValue("role_value", [], {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  });
                                } else {
                                  setValue(
                                    "role_value",
                                    role_all_values && role_all_values,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                      shouldTouch: true,
                                    }
                                  );
                                }
                              }}
                            />
                          }
                          label={"All"}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {!!errors?.role_value?.message && (
                <span className="text-red-500 text-[12px]">
                  {errors?.role_value?.message}
                </span>
              )}
              <div className="grid md:grid-cols-3">
                {permissionList?.data?.data?.map((_t: any, i: any) => (
                  <Controller
                    control={control}
                    name="role_value"
                    rules={{
                      validate: {
                        required: (value: any) => {
                          if (value.length < 1) {
                            return "Permission is required.";
                          }
                        },
                      },
                    }}
                    render={({ field, formState, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "&, &.Mui-checked": {
                                  color: "#F44336",
                                },
                              }}
                              checked={role_values.includes(_t._id)}
                              value={_t?._id}
                              onChange={(e: any) => {
                                if (!role_values?.includes(_t?._id)) {
                                  setValue(
                                    "role_value",
                                    [...role_values, _t?._id],
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                      shouldTouch: true,
                                    }
                                  );
                                } else {
                                  setValue(
                                    "role_value",
                                    role_values.filter((_e) => _e !== _t?._id),
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                      shouldTouch: true,
                                    }
                                  );
                                }
                              }}
                            />
                          }
                          label={_t?.name}
                        />
                      );
                    }}
                  />
                ))}
              </div>
              <div className="flex items-center justify-between w-full">
                <div></div>
                <button
                  type="submit"
                  className={`btn-create-active ${
                    isMutating ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  disabled={isMutating}
                >
                  {isMutating ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>

              <DevTool control={control} />
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default RoleCreatePage;
