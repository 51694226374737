import { CircularProgress, TextField } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { InfoBlueIcon } from "../../../../pages/common/Icon";
import { FiX } from "react-icons/fi";
import { useAtom } from "jotai";
import { modalAtom, roundInfoAtom } from "../../../../store";
import { useCreateAnnounced } from "../../../../api/mutation/rounds";
import moment from "moment";

const AnnounceResultModalContent = () => {
  const [, setIsOpenModal] = useAtom(modalAtom);
  const [roundId] = useAtom(roundInfoAtom);

  const { control, handleSubmit, formState } = useForm<{
    winningNumber: string;
    set: string;
    value: string;
  }>({
    defaultValues: {
      winningNumber: "",
      set: "",
      value: "",
    },
  });
  const { errors } = formState;

  const { trigger, isMutating } = useCreateAnnounced(
    "two-d",
    roundId?.value as string
  );

  const handleClose = () => {
    setIsOpenModal({ isOpen: false });
  };
  const onSubmit = (data: any) => {
    trigger(data);
    handleClose();
  };
  return (
    <div className="relative flex flex-col px-3 py-3 bg-white rounded-md">
      <form
        className="flex flex-col "
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FiX className="absolute right-5 top-5" onClick={handleClose} />

        <p className="text-[1rem] text-dynamic_black font-semibold">
          Announce Result
        </p>
        <p className="text-[0.8rem] text-vermilion_bird">
          2D ({roundId?.label}){" "}
          <span className="text-dynamic_black">Result for</span>{" "}
          {moment().format("DD MM YYYY")}
        </p>

        <Controller
          control={control}
          name="winningNumber"
          rules={{
            validate: {
              required: (value: any) => {
                if (value === "00") {
                  return undefined;
                }
                if (
                  !(value?.toString()?.length === 2) ||
                  !(+value > 0 && +value < 101)
                ) {
                  return "Please enter only two numbers.";
                }
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                inputProps={{ inputMode: "decimal" }}
                error={!!errors?.winningNumber}
                label="Result (e.g. 29)"
                placeholder="Result (e.g. 29)"
                sx={{
                  width: "20rem",
                  marginBottom: "1.5rem",
                  ".MuiInputBase-input": {
                    fontSize: "1.5rem",
                    color: "#F44336",
                  },
                }}
                helperText={
                  !!errors?.winningNumber?.message &&
                  errors?.winningNumber?.message
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name="set"
          rules={{
            validate: {
              required: (value: any) => {
                if (!value) {
                  return "Set is required.";
                }
                if (/^\d+(?:\.\d+)?$/.test(value) && value.includes(".")) {
                  return undefined;
                }

                return "Please enter float numbers.";
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Set (e.g. 1299.39)"
                error={!!errors?.set}
                label="Set"
                sx={{
                  width: "20rem",
                  marginBottom: "1.5rem",
                }}
                inputProps={{ inputMode: "decimal" }}
                helperText={!!errors?.set?.message && errors?.set?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="value"
          rules={{
            validate: {
              required: (value: any) => {
                if (!value) {
                  return "Value is required.";
                }
                if (/^\d+(?:\.\d+)?$/.test(value) && value.includes(".")) {
                  return undefined;
                }

                return "Please enter float numbers.";
              },
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                size="small"
                {...field}
                placeholder="Value (e.g. 38992.99)"
                error={!!errors?.value}
                label="Value"
                sx={{
                  width: "20rem",
                  marginBottom: "1.5rem",
                }}
                helperText={!!errors?.value?.message && errors?.value?.message}
                inputProps={{ inputMode: "decimal" }}
              />
            );
          }}
        />

        <div className="flex items-start gap-x-3 mb-[1.5rem]">
          <InfoBlueIcon className="mt-1" />
          <span className=" w-[18rem] text-blizzy_blueberry text-[0.8rem]">
            Please enter the correct result and make sure you have double
            checked before confirming it. This action can not be edited later.
          </span>
        </div>
        <div className="grid flex-1 grid-cols-2 gap-x-5">
          <button
            className="flex items-center justify-center w-full py-2 font-semibold bg-lynx_white text-dim_grey rounded-[12px]"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className={`flex items-center justify-center w-full py-2 font-semibold text-white bg-vermilion_bird rounded-[12px] ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {" "}
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AnnounceResultModalContent;
