import React, { useState } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import Modal from "../common/modal";

const GameTypeCreate: React.FC = () => {
  const [name, setName] = useState("MM2D");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/gametypes",
      data: {
        name: name,
        time: time,
      },
      method: "post",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setMessage(res.data.message);
          setIsModalOpen(true);
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              {message ? (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              ) : (
                <></>
              )}
              <div className="container">
                <div className="row g-3">
                  <div className="col-12">
                    <label htmlFor="name">Group Type Name :</label>
                    <select
                      className="form-input-select"
                      onChange={(e) => setName(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="MM2D" selected>
                        MM2D
                      </option>
                      <option value="3D">3D</option>
                      <option value="TH2D">TH2D</option>
                    </select>
                  </div>

                  <div className="col-12">
                    <label htmlFor="time">Time :</label>
                    <input
                      type="text"
                      className="form-control"
                      id="time"
                      placeholder="Enter time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      Create
                    </button>
                  </div>
                  <div className="col-3">
                    <Link to="/gametypes/list" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Game Type Create" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/gametypes/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GameTypeCreate;
