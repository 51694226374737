import useSWR from "swr";
import { API_NAME } from "../../constant";

import {
  getBetListApi,
  getDSummaryApi,
  getPreviousResultApi,
  getPreviousWinningApi,
  getPreviousWinningThreeApi,
  getRoundsApi,
  getRoundsByIdApi,
  getTwoDBetDetailApi,
} from "../../rounds";

export const useGetRoundList = (type: string) => {
  return useSWR(
    [API_NAME.round_list_api, type],
    () => getRoundsApi(type),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetPreviousResultList = (
  type: string,
  startDate?: string,
  endDate?: string
) => {
  return useSWR(
    [API_NAME.round_list_api, type, startDate, endDate],
    () => getPreviousResultApi(type, startDate, endDate),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetBetList = (
  type: string,
  startDate: string,
  endDate: string,
  pageNumber: number,
  pageSize: number,
  roundId: string
) => {
  return useSWR(
    roundId
      ? [
          API_NAME.round_list_api,
          type,
          startDate,
          endDate,
          pageNumber,
          pageSize,
          roundId,
        ]
      : null,
    () =>
      getBetListApi(type, startDate, endDate, pageNumber, pageSize, roundId),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetDSummary = (type: string) => {
  return useSWR(
    [`/api/admins/${type}/summary`],
    () => getDSummaryApi(type),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetBetDetail = (
  id: string,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string
) => {
  return useSWR(
    id ? [`/api/admins/${type}/${id}/bet-lists`, pageIndex, pageSize] : null,
    () => getTwoDBetDetailApi(id, { pageIndex, pageSize }, type),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};

export const useGetPreviousWinnersList = (
  type: string,
  resultId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useSWR(
    resultId
      ? [API_NAME.round_list_api, type, resultId, pageNumber, pageSize]
      : null,
    () => getPreviousWinningApi(type, resultId, pageNumber, pageSize),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetPreviousWinnersThreeDList = (
  type: string,
  resultId: string,
  pageNumber: number,
  pageSize: number,
  tootDaeType: string
) => {
  return useSWR(
    resultId
      ? [
          API_NAME.round_list_api,
          type,
          resultId,
          pageNumber,
          pageSize,
          tootDaeType,
        ]
      : null,
    () =>
      getPreviousWinningThreeApi(
        type,
        resultId,
        pageNumber,
        pageSize,
        tootDaeType
      ),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};

export const useGetRoundById = (
  id: string
) => {
  return useSWR(
    id ? [`round-detail`, id] : null,
    () => getRoundsByIdApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
