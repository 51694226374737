import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getUserListApi = async (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter: string
) => {
  const axios_url = new URL(`${url}${API_NAME.user_list_api}`);
  axios_url.searchParams.set("keyword", globalFilter ?? "");
  axios_url.searchParams.set("sortReg", "desc");
  axios_url.searchParams.set("sortLog", "desc");
  axios_url.searchParams.set("pageSize", pageSize.toString());
  axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());

  return axiosInstance.get(axios_url);
};

export const getUserDetailApi = async (id: string | null) => {
  if (id) {
    const axios_url = new URL(`${url}/api/admins/users/${id}/details`);

    return axiosInstance.get(axios_url);
  }
};
export const getUserByTransactionHistory = async (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  }
) => {
  if (id) {
    const axios_url = new URL(
      `${url}/api/admins/users/${id}/transaction-history`
    );
    axios_url.searchParams.set("pageSize", pageSize.toString());
    axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());

    return axiosInstance.get(axios_url);
  }
};
export const getUserByWinningHistory = async (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string
) => {
  if (id) {
    const axios_url = new URL(
      `${url}/api/admins/users/${id}/${type}-winning-history`
    );
    axios_url.searchParams.set("pageSize", pageSize.toString());
    axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());

    return axiosInstance.get(axios_url);
  }
};
export const getUserByBetHistory = async (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string,
  globalFilter: string
) => {
  // twod-bet-history
  if (id) {
    const axios_url = new URL(`${url}/api/admins/users/${id}/${type}`);
    axios_url.searchParams.set("keyword", globalFilter ?? "");
    axios_url.searchParams.set("pageSize", pageSize.toString());
    axios_url.searchParams.set("pageNumber", (pageIndex + 1).toString());

    return axiosInstance.get(axios_url);
  }
};

export const getUserDetailOverviewApi = async (id?: string) => {
  if (id) {
    const axios_url = new URL(`${url}/api/admins/users/${id}/overview`);

    return axiosInstance.get(axios_url);
  }
};
export const getUserDetailWinningApi = async (
  userId?: string,
  resultId?: string,
  type?: string,
  tdType?: string
) => {
  if (resultId) {
    const axios_url = tdType
      ? new URL(
          `${url}/api/admins/users/${userId}/${resultId}/${type}-winning-details?type=${tdType}`
        )
      : new URL(
          `${url}/api/admins/users/${userId}/${resultId}/${type}-winning-details`
        );

    return axiosInstance.get(axios_url);
  }
};

export const sendRequest = (url: string, args: any) => {
  return null;
};
