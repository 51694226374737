import React from "react";

import {
  useGetPreviousResultList,
  useGetRoundList,
} from "../../../api/funs/rounds";
import LatestResultWinning3DCard from "./LatestResultWinning3DCard";
import LatestResultWinningDetail3DCard from "./LatestResultWinningDetail3DCard";

const LatestBet3DCard = () => {
  const { data: roundsList } = useGetRoundList("3d");

  const { data: previousResult } = useGetPreviousResultList("three-d");

  return (
    <div className="md:flex justify-between items-center w-full h-full  py-4 md:py-0 md:h-[364px]  pr-5 mt-3 bg-white bg-center bg-no-repeat bg-cover rounded-md bg-dashboard_latest_result">
      <LatestResultWinning3DCard
        winningResult={previousResult?.data?.data?.[0]}
        round={roundsList}
      />

      {previousResult?.data?.data?.length > 0 && (
        <LatestResultWinningDetail3DCard
          resultId={previousResult?.data?.data?.[0]?._id}
          winningNumber={previousResult?.data?.data?.[0]?.winningNumber}
          resultDate={previousResult?.data?.data?.[0]?.resultDate}
          roundType={roundsList?.data?.data?.[0]?.roundName}
        />
      )}
    </div>
  );
};

export default LatestBet3DCard;
