import { MdCheckCircle, MdDoDisturbOn } from "react-icons/md";
import { CheckCircleIcon } from "../../pages/common/Icon";

export function VerifiedCell({ renderedCellValue }: any) {
  return (
    <>
      {renderedCellValue ? (
        <div className="flex items-center">
          <CheckCircleIcon className="text-grass_green" />
          <span className="text-dynamic_black text-[14px] ml-1 font-semibold">
            Verified
          </span>
        </div>
      ) : (
        <div className="flex items-center">
          <MdDoDisturbOn className="text-red-500" />
          <span className="text-dynamic_black text-[14px] ml-1 font-semibold">
            Blocked
          </span>
        </div>
      )}
    </>
  );
}
