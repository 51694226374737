import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useGetPoliciesByLang } from "../api/funs/polices";
import { useUpdatePolicies } from "../api/mutation/policies/indext";
import { CircularProgress } from "@mui/material";
const EditPolicesForm = () => {
    const { lang } = useParams()
    const { data: policyDetail } = useGetPoliciesByLang(lang);

    const {trigger,isMutating}=useUpdatePolicies(lang)



    const { control, handleSubmit, formState, reset } = useForm<{
      policy:string
    }>({
        values: {
          policy:policyDetail?.data?.data?.policy?.policy
      },
    defaultValues: {
      policy:""
    },
    });
    const { errors } = formState;

  const onSubmit = (data: any) => {
      trigger({
        policy:data?.policy
      })
   
    reset()
  };
  return (
    <div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>

              <p className="">Language : { policyDetail?.data?.data?.policy?.language ==="en"?"English":policyDetail?.data?.data?.policy?.language ==="mm"?"Myanmar":"Chinese"}</p>
              <label className="text-[14px]">Privacy Policy:</label>
        <Controller
          control={control}
          name="policy"
          rules={{
              validate: {
                required:(value:any) => {
                    if (["<p><br></p>","<h1><br></h1>"].includes(value)) {
                        return "Policy is required."
                    }
                      if(!value)                     return  "Terms is required."

            },
        
            },
            minLength: 11
          }}
          render={({ field, formState, fieldState }) => {
            return (
                <ReactQuill
                {...field}
                placeholder={"Write Description"}
                onChange={(text) => {
                  field.onChange(text);
                }}
              />
            );
          }}
              />
              <span className="text-[14px] text-red-600">{errors?.policy?.message}</span>
               <div className="flex items-center justify-between w-full mt-2">
          <div></div>
          <button
              type="submit"
              className={`btn-create-active ${
                isMutating ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={isMutating}
            >
              {isMutating ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "Update"
              )}
            </button>
              </div>
              <DevTool control={control} />

      </form>
    </div>
  );
};

export default EditPolicesForm;
