import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const url = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getTermsConditionApi = async () => {
  const axios_url = new URL(`${url}${API_NAME.terms_api}`);


  return axiosInstance.get(axios_url);
};

// export const createFaqApi = async (
//   agUrl: string,
//   {
//     arg,
//   }: {
//     arg: {
//       faqArg: {
//         question: string;
//         answer: string;
//         language: string;
//       }[];
//     };
//   }
// ) => {
//   // const axios_url = new URL(`${url}${agUrl}`);

//   arg?.faqArg?.map((_e) => {
//     return axiosInstance.post(`${url}${agUrl}`, _e);
//   });
// };
export const updateFaqApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      question: string;
      answer: string;
    };
  }
) => {
  const axios_url = new URL(`${url}${agUrl}`);

  return axiosInstance.put(`${axios_url}`, arg);
};
// export const deleteFaqOneApi = async (
//   agUrl: string,
//   {
//     arg,
//   }: {
//     arg: {
//       id: string;
//     };
//   }
// ) => {
//   const axios_url = new URL(`${url}${agUrl}/${arg.id}`);

//   return axiosInstance.delete(`${axios_url}`);
// };

export const getTermsConditionByLangApi = async (lang?: string) => {
  const axios_url = new URL(`${url}/api/admins/terms/${lang}`);

  return axiosInstance.get(`${axios_url}`);
};
export const updateTermsConditionApi = async (
  agUrl: string,
  {
    arg,
  }: {
    arg: {
      terms: string;
    };
  }) => {

  return axiosInstance.put(`${agUrl}`,arg);
};
