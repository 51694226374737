import { useNavigate } from "react-router-dom";

import useSWRMutation from "swr/mutation";

import { RouteName } from "../../../app/constants/route-constant";

import { useGetFaqList } from "../../funs/faq";
import { useState } from "react";
import { createFaqApi, deleteFaqOneApi, updateFaqApi } from "../../faq";
import { toast } from "react-toastify";

export function useCreateFaq() {
  const navigate = useNavigate();
  const [pagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useGetFaqList(pagination);

  return useSWRMutation("/api/admins/faqs/create", createFaqApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
      navigate(RouteName.setting_faqs_list);
    },
  });
}
export function useUpdateFaq(id?: string) {
  const navigate = useNavigate();
  const [pagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useGetFaqList(pagination);

  return useSWRMutation(
    id ? `/api/admins/faqs/update/${id}` : null,
    updateFaqApi,
    {
      throwOnError: false,
      onError: () => {
        toast.error("Something wrong.", {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.setting_faqs_list);
      },
    }
  );
}
export function useDeleteFaqOne() {
  const [pagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { mutate } = useGetFaqList(pagination);

  return useSWRMutation(`/api/admins/faqs/delete`, deleteFaqOneApi, {
    throwOnError: false,
    onError: () => {
      toast.error("Something wrong.", {
        position: "bottom-right",
      });
    },
    onSuccess: (d) => {
      toast.success("Successfully Created.", {
        position: "bottom-right",
      });
      mutate();
    },
  });
}
