import React, { useMemo, useState } from "react";
import CustomizeTable from "../../../table/CustomizeTable";
import { betsCol } from "../../../table/columns/bet/bets";
import { useGetBetDetail } from "../../../api/funs/rounds";
import { useParams } from "react-router-dom";

const Bets = () => {
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const {
    data: betDetail,
    isLoading,
    error,
  } = useGetBetDetail(id as string, pagination, "two-d");

  const betsMemorized = useMemo(() => {
    return betDetail?.data?.data?.betData?.map((d: any) => ({
      bet_number: d?.betNumber,
      bet_amount: d?.amount,
      profile_time: `x${d?.odds}`,
    }));
  }, [betDetail]);
  return (
    <>
      <CustomizeTable
        column={betsCol}
        data={betsMemorized}
        totalCount={betDetail?.data?.totalCounts}
        isError={error}
        isRefetching={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        isShowAction={"inactive"}
        total={betDetail?.data?.data?.transactionData?.amount}
      />
    </>
  );
};

export default Bets;
