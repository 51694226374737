import { TimeFormatCell } from "../../cell/TimeFormatCell";

export const notificationTableCol = [
  // {
  //   accessorKey: "sr_no", //simple recommended way to define a column
  //   header: "Sr No",
  // },
  {
    accessorKey: "type", //simple recommended way to define a column
    header: "Type",
    filterVariant: "multi-select",
    filterSelectOptions: ["telegram", "email", "inApp"],
  },
  {
    accessorKey: "title", //simple recommended way to define a column
    header: "Title",
  },
  {
    accessorKey: "message", //simple recommended way to define a column
    header: "Message",
  },
  {
    accessorKey: "created_at", //simple recommended way to define a column
    header: " Created Date",
    Cell: TimeFormatCell,
  },
];
