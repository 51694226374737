import React from "react";
import { useLocation, useParams } from "react-router-dom";

const TotalBetAmountMMK = ({total}:{total?:number}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  return (
    <>
      { [`/twod/betlist/user/${id}`,`/threed/betlist/user/${id}`].includes(pathname) ? (
        <div className="pl-10">
          <span className="text-12 text-dim_grey">Total Bet</span>
          <br />
          <span className="text-[24px] font-bold">
            {
              total?.toLocaleString()
            }
            <span className="text-[12px] text-dim_gray font-normal"></span>
          </span>
        </div>
      ):<div></div>}
    </>
  );
};

export default TotalBetAmountMMK;
