import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AnswerIcon,
  ArrowSwapHorizontalIcon,
  QuestionIcon,
} from "../../pages/common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { Controller, useForm } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";
import { DevTool } from "@hookform/devtools";
import { useCreateFaq } from "../../api/mutation/faq";

const FaqCreate: React.FC = () => {
  const navigate = useNavigate();

  const { trigger } = useCreateFaq();
  const { control, handleSubmit, formState, reset } = useForm<{
    en_q: string;
    en_a: string;
    mm_q: string;
    mm_a: string;
    zh_q: string;
    zh_a: string;
  }>({
    defaultValues: {
      en_q: "",
      en_a: "",
      mm_q: "",
      mm_a: "",
      zh_q: "",
      zh_a: "",
    },
  });
  const { errors } = formState;
  const onSubmit = (data: any) => {
    const createFaqArg = [
      {
        question: data?.mm_q,
        answer: data?.mm_a,
        language: "mm",
      },
      {
        question: data?.en_q,
        answer: data?.en_a,
        language: "en",
      },
      {
        question: data?.zh_q,
        answer: data?.zh_a,
        language: "zh",
      },
    ];

    trigger({
      faqArg: createFaqArg,
    });
    reset();
  };
  return (
    <>
      <div className="pl-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.setting_faqs_list)}
        />
      </div>
      <form className="pl-5" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div>
          {" "}
          <label className="mb-3 text-[12px] font-semibold">
            Language Myanmar
          </label>
          <div className="flex items-center gap-x-5">
            <Controller
              control={control}
              name="mm_q"
              rules={{
                required: {
                  value: true,
                  message: "Question is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <QuestionIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.mm_q}
                    label="Question"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.mm_q?.message && errors?.mm_q?.message}
                      </span>
                    }
                  />
                );
              }}
            />
            <ArrowSwapHorizontalIcon className="w-12 h-12" />
            <Controller
              control={control}
              name="mm_a"
              rules={{
                required: {
                  value: true,
                  message: "Answer is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AnswerIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.en_a}
                    label="Answer"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.mm_a?.message && errors?.mm_a?.message}
                      </span>
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="my-2">
          {" "}
          <label className="mb-3 text-[12px] font-semibold">
            Language English
          </label>
          <div className="flex items-center gap-x-5">
            <Controller
              control={control}
              name="en_q"
              rules={{
                required: {
                  value: true,
                  message: "Question is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <QuestionIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.en_q}
                    label="Question"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.en_q?.message && errors?.en_q?.message}
                      </span>
                    }
                  />
                );
              }}
            />
            <ArrowSwapHorizontalIcon className="w-12 h-12" />
            <Controller
              control={control}
              name="en_a"
              rules={{
                required: {
                  value: true,
                  message: "Answer is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AnswerIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.en_a}
                    label="Answer"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.en_a?.message && errors?.en_a?.message}
                      </span>
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          {" "}
          <label className="mb-3 text-[12px] font-semibold">
            Language Chinese
          </label>
          <div className="flex items-center gap-x-5">
            <Controller
              control={control}
              name="zh_q"
              rules={{
                required: {
                  value: true,
                  message: "Question is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <QuestionIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.zh_q}
                    label="Question"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.zh_q?.message && errors?.zh_q?.message}
                      </span>
                    }
                  />
                );
              }}
            />
            <ArrowSwapHorizontalIcon className="w-12 h-12" />
            <Controller
              control={control}
              name="zh_a"
              rules={{
                required: {
                  value: true,
                  message: "Answer is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AnswerIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.zh_a}
                    label="Answer"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.zh_a?.message && errors?.zh_a?.message}
                      </span>
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div></div>
          <button className=" btn-create-active">Create</button>
        </div>
        <DevTool control={control} />
      </form>
    </>
  );
};

export default FaqCreate;
