import moment from 'moment-timezone';
import { timeDateMMFun } from '../../util/funs/timeDateMMFun';


export function TimeFormatCell({ renderedCellValue, row }: any) {

  // Parse the UTC datetime string
  const yangonTimezone = "Asia/Yangon";

const utcDatetime = moment.utc(renderedCellValue);

// Convert to Yangon time zone
const yangonDatetime = utcDatetime.clone().tz(yangonTimezone);


  return (
    <>
          {
              renderedCellValue && <> <span className="text-[14px] font-medium  text-dynamic_black">{timeDateMMFun(renderedCellValue).format( "DD MMM yyyy")}</span><br/>
              <span className="text-[12px] text-dim_grey">{timeDateMMFun(renderedCellValue).format( "hh:mm:ss a")} </span></>
    }

    
     
    </>
  );
}
