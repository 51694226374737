export const roleCol = [
  {
    accessorKey: "user_role", //simple recommended way to define a column
    header: "User Role",
    filterVariant: "multi-select",
    filterSelectOptions: [
      "SUPERADMIN	",
      "AGENT",
      "DEVELOPER",
      "MEMBER",
      "DATA_ENTRY",
    ],
  },
  //   {
  //       accessorFn: (originalRow: any) => new Date(originalRow?.created_at),

  //   accessorKey: "created_at", //simple recommended way to define a column
  //     header: "Created Date",

  //   filterVariant: "date-range",
  //   Cell: TimeFormatCell,

  // },
];
