import React from "react";
import { useGetPreviousWinnersList } from "../../../api/funs/rounds";
import { setPreviousResult } from "../../../services/storage";
import { RouteName } from "../../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const LatestResultWinningDetailCard = ({
  resultId,
  winningNumber,
  set,
  value,
  resultDate,
  roundType,
}: any) => {
  const { data: PreviousWinnings, isLoading } = useGetPreviousWinnersList(
    "two-d",
    resultId as string,
    0,
    1000
  );
  const navigate = useNavigate();


  return (
    <div className="ml-2 md:ml-0 md:flex md:flex-col items-start justify-between  w-full md:w-[55%] py-3 px-3  bg-white rounded-md border border-steam">
      <div className="flex justify-between w-full mb-2 md:mb-4">
        <span className="block text-xs md:text-md">
          Total of{" "}
          {PreviousWinnings?.data?.data?.twodWinnerLists?.length ||
            PreviousWinnings?.data?.data?.length}{" "}
          Winners
        </span>
      </div>

      {isLoading ? (
        <div className="flex justify-center w-full my-3">
          {" "}
          <CircularProgress
            size={20}
            sx={{
              color: "#F44336",
            }}
          />
        </div>
      ) : (
        <table className="w-full text-dim_grey">
          <tbody>
            {PreviousWinnings?.data?.data?.length === 0 ? (
              <div className="flex items-center justify-center my-5">
                No Record ...
              </div>
            ) : (
              <>
                {" "}
                {PreviousWinnings?.data?.data?.twodWinnerLists
                  ?.slice(0, 3)
                  ?.map((_d: any, i: any) => (
                    <tr
                      className="border-dashed border-steam border-y "
                      key={i}
                    >
                      <td className="py-3">
                        <span className="px-2 py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                          {i + 1}
                        </span>
                      </td>
                      <td className="py-1">
                        {" "}
                        <div className="flex items-center">
                          <div>
                            <div className="text-[12px] md:text-[14px] font-medium  text-dynamic_black">
                              {_d?.userId?.name}
                            </div>
                            <div className="text-[10px] md:text-[12px] text-dim_grey">
                              {_d?.userId?.phone}{" "}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-1 ">
                        {" "}
                        <div className="">
                          <>
                            <span className="block text-[10px] md:text-[10px] text-dim_grey">
                              Agent Code
                            </span>
                            <span className="text-[12px]">{_d?.agentCode}</span>
                          </>
                        </div>
                      </td>
                      <td className="py-1 ">
                        {" "}
                        <div className="">
                          <>
                            <span className="block text-[10px] text-dim_grey">
                              Bet
                            </span>
                            <span className="text-[12px]">
                              {_d?.totalBetAmount?.toLocaleString()}
                            </span>
                          </>
                        </div>
                      </td>
                      <td className="py-1 ">
                        {" "}
                        <div className="">
                          <>
                            <span className="block text-[10px] text-dim_grey">
                              Won
                            </span>
                            <span className="text-[12px] text-grass_green">
                              {_d?.totalWinningAmount?.toLocaleString()}
                            </span>
                          </>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      )}

      <div
        onClick={() => {
          setPreviousResult(
            JSON.stringify({
              winningNumber: winningNumber,
              set: set,
              value: value,
              resultDate: resultDate,
              roundType: roundType,
              resultId: resultId,
            })
          );
          navigate(RouteName.twod_previousresults_winnerlist);
        }}
        className="flex cursor-pointer items-center justify-center w-full py-2 bg-pink_lemonade text-vermilion_bird text-[12px]  mt-3"
      >
        View More
      </div>
    </div>
  );
};

export default LatestResultWinningDetailCard;
