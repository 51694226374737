import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRoundById } from "../../api/funs/rounds";
import { Controller, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import SpinnerLoading from "../common/SpinnerLoading";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { CalendarIcon, TimeClockIcon } from "../common/Icon";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useUpdateRound } from "../../api/mutation/rounds";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";

const ThreeDEditRoundSettingPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: roundDetail, isLoading } = useGetRoundById(id as string);

  const { trigger, isMutating } = useUpdateRound(id as string);

  const { control, handleSubmit, formState } = useForm<{
    endDate: Dayjs | null;
    // openTime: Dayjs | null;
    closeTime: Dayjs | null;
    r_a_time: Dayjs | null;
    e_r_a_time: Dayjs | null;
  }>({
    values: {
      endDate:
        roundDetail?.data?.data?.endDate &&
        dayjs(roundDetail?.data?.data?.endDate),
      // openTime:
      //   roundDetail?.data?.data?.openTime &&
      //   dayjs(
      //     moment(roundDetail?.data?.data?.openTime, "HH:mm").format(
      //       "YYYY-MM-DD hh:mm:ss A"
      //     )
      //   ),
      closeTime:
        roundDetail?.data?.data?.endTime &&
        dayjs(
          moment(roundDetail?.data?.data?.endTime, "HH:mm").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
      r_a_time:
        roundDetail?.data?.data?.announceTime &&
        dayjs(
          moment(roundDetail?.data?.data?.announceTime, "HH:mm").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
      e_r_a_time:
        roundDetail?.data?.data?.untilEndTime &&
        dayjs(
          moment(roundDetail?.data?.data?.untilEndTime, "HH:mm").format(
            "YYYY-MM-DD hh:mm:ss A"
          )
        ),
    },
    defaultValues: {
      endDate: undefined,
      closeTime: undefined,
      r_a_time: undefined,
      e_r_a_time: null,
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    const passData = {
      endDate: dayjs(data?.endDate).format("YYYY-MM-DD"),
      openTime: roundDetail?.data?.data?.openTime,
      endTime: moment(
        dayjs(data?.closeTime).format("YYYY-MM-DD hh:mm:ss A")
      ).format("HH:mm"),
      announceTime: moment(
        dayjs(data?.r_a_time).format("YYYY-MM-DD hh:mm:ss A")
      ).format("HH:mm"),
      untilEndTime: moment(
        dayjs(data?.e_r_a_time).format("YYYY-MM-DD hh:mm:ss A")
      ).format("HH:mm"),
      status: true,
    };
   

    trigger(passData);
  };
  return (
    <>
      <div className="pl-5 mb-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.three_roundsetting)}
        />
      </div>
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="pl-5 w-[60%] flex flex-col gap-y-6 bg-white">
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: {
                  value: true,
                  message: "Date is required",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <DesktopDatePicker
                    label="Select End Date"
                    {...field}
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",
                        fullWidth: true,
                        helperText:
                          !!errors?.endDate?.message &&
                          errors?.endDate?.message,
                        error: !!errors?.endDate?.message,
                      },
                      field: {
                        readOnly: true
                    }
                    }}
                    slots={{
                      openPickerIcon: CalendarIcon,
                    }}
                    format="DD MMM YYYY"
                  />
                );
              }}
            />
            {/* <Controller
              control={control}
              name="openTime"
              rules={{
                required: {
                  value: true,
                  message: "Time is required",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <DesktopTimePicker
                    label="Select Close Time"
                    {...field}
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",
                        fullWidth: true,
                        helperText:
                          !!errors?.openTime?.message &&
                          errors?.openTime?.message,
                        error: !!errors?.openTime?.message,
                      },
                    }}
                    slots={{
                      openPickerIcon: TimeClockIcon,
                    }}
                    format="hh:mm:ss A"
                  />
                );
              }}
            /> */}
            <Controller
              control={control}
              name="closeTime"
              rules={{
                required: {
                  value: true,
                  message: "Time is required",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <DesktopTimePicker
                    label="Select Close Time"
                    {...field}
                   
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",
                        fullWidth: true,

                        helperText:
                          !!errors?.closeTime?.message &&
                          errors?.closeTime?.message,
                        error: !!errors?.closeTime?.message,
                      },
                      field: {
                        readOnly: true
                    }
                      
                    }}
                    slots={{
                      openPickerIcon: TimeClockIcon,
                    }}
                    format="hh:mm:ss A"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="r_a_time"
              rules={{
                required: {
                  value: true,
                  message: "Time is required",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <DesktopTimePicker
                    label="Select R.A Time"
                    {...field}
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",
                        fullWidth: true,
                        helperText:
                          !!errors?.r_a_time?.message &&
                          errors?.r_a_time?.message,
                        error: !!errors?.r_a_time?.message,
                      },
                      field: {
                        readOnly: true
                    }
                    }}
                    slots={{
                      openPickerIcon: TimeClockIcon,
                    }}
                    format="hh:mm:ss A"
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="e_r_a_time"
              rules={{
                required: {
                  value: true,
                  message: "Time is required",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <DesktopTimePicker
                    label="Select Extra R.A Time"
                    {...field}
                    slotProps={{
                      textField: {
                        size: "small",
                        color: "error",
                        fullWidth: true,
                        helperText:
                          !!errors?.e_r_a_time?.message &&
                          errors?.e_r_a_time?.message,
                        error: !!errors?.e_r_a_time?.message,
                      },
                      field: {
                        readOnly: true
                    }
                    }}
                    slots={{
                      openPickerIcon: TimeClockIcon,
                    }}
                    format="hh:mm:ss A"
                  />
                );
              }}
            />

            <div className="col-span-2">
              <button
                className={`cursor-pointer flex items-center justify-center w-full py-3 font-semibold  rounded-md  gap-x-2 ${"bg-vermilion_bird text-white"} ${
                  isMutating ? "opacity-50 cursor-not-allowed " : ""
                }`}
                disabled={isMutating}
              >
                {isMutating ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  <>Update</>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ThreeDEditRoundSettingPage;
