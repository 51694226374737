import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getDashboardOverviewListApi = async (data: any) => {
  return axiosInstance.get(`${URL}${API_NAME.dashboard_counts_list_api}`);
};
export const getTopAgentListApi = async (type?: string) => {
  return axiosInstance.get(
    `${URL}/api/admins/dashboard/top-agents/${type}?showPerPage=10`
  );
};
