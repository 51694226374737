export const API_NAME = {
  dashboard_counts_list_api: "/api/admins/general/dashboard/counts",
  top_agent_api: "/api/admins/dashboard/top-agent",
  user_list_api: "/api/admins/users/lists",
  noti_user_lists_api: "/api/admins/users/lists",
  notification_lists_api: "/api/admins/notifications/lists",
  notifications_by_id_api: "/api/admins/notifications",
  notifications_for_email_api: "/api/admins/notifications/agent-emails",
  profile_info_api: "/api/admins/info",
  profile_by_id_api: "/api/admins/:id",
  admins_list_api: "/api/admins/lists",
  admins_edit_id_api: "/api/admins/:id",
  admins_agents_list_api: "/api/admins/agents/lists",
  user_overview_api: "/api/admins/users/:userId/overview",
  user_by_overview_api: "/api/admins/users/by/:userId/overview",
  user_transaction_api: "/api/admins/users/:id/transaction-history",
  user_winning_transaction_api: "/api/admins/users/:id/twod-winning-history",
  user_twd_bet_history_api: "/api/admins/users/:id/twod-bet-history",
  holidays_lists_api: "/api/admins/holidays/lists",
  holidays_lists_id_api: "/api/admins/holidays/:id",
  faq_id_api: "/api/admins/faq/lists/:id",
  faq_list_api: "/api/admins/faqs/list",
  terms_api: "/api/admins/terms/lists",
  terms_condition_by_lang_api: "/api/admins/terms/lists/:lang",
  polices_api: "/api/admins/policies/lists",
  polices_condition_by_lang_api: "/api/admins/policies/lists/:lang",
  role_list_api: "/api/admins/roles/lists",
  role_detail_api: "/api/admins/roles/edit/:id",
  permission_list_api: "/api/admins/permissions/lists",
  round_list_api: "/api/admins/rounds/lists",
  agent_edit_id_api: "/api/agent/:id",
  agent_invited_user__id_api: "/api/admins/agents/:agenttId/invited-users",
  agent__overview_detail_id_api: "/api/admins/agents/:agenttId/overview",
  agent_code_list: "/api/admins/agents/code-lists",
  report_overview_list: "/api/admin/reports/overview",
  report_list: "/api/admin/reports",
};
