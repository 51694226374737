import { TimeFormatCell } from "../../cell/TimeFormatCell";
import { AmountMMKCell } from "../../cell/AmountMMKCell";
import BetNumbersCell from "../../cell/BetNumbersCell";

export const bet3DHistoryCol = [
  // {
  //   accessorKey: "bet_id", //simple recommended way to define a column
  //   header: "Bet ID",
  // },
  {
    accessorFn: (originalRow: any) => new Date(originalRow?.bet_date),
    accessorKey: "bet_date", //simple recommended way to define a column
    header: "Bet Date",
    filterVariant: "date-range",
    Cell: TimeFormatCell,
  },

  {
    accessorKey: "bet_numbers", //simple recommended way to define a column
    header: "Bet Numbers",
    Cell: BetNumbersCell,
  },
  {
    accessorKey: "total_no_of_bet", //simple recommended way to define a column
    header: "Total No. of Bet",
  },
  {
    accessorKey: "bet_amount", //simple recommended way to define a column
    header: "Bet Amount",
    Cell: AmountMMKCell,
  },
  {
    accessorKey: "profile_time_twt", //simple recommended way to define a column
    header: "Profit Tim (Twt)",
  },
  {
    accessorKey: "profile_time", //simple recommended way to define a column
    header: "Profit Time",
  },
];
