import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";
import { useGetTermsCondition } from "../../funs/terms&condition";
import { updateTermsConditionApi } from "../../terms&condition";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";

export const useUpdateTermsCondition = (lang?: string) => {
  const navigate = useNavigate();

  const { mutate } = useGetTermsCondition();

  return useSWRMutation(
    lang ? `/api/admins/terms/update/${lang}` : null,
    updateTermsConditionApi,
    {
      throwOnError: false,
      onError: () => {toast.error("Something wrong.", {
        position: "bottom-right",
      });},
      onSuccess: (d) => {
        toast.success("Successfully Created.", {
          position: "bottom-right",
        });
        mutate();
        navigate(RouteName.setting_termconditions_list);
      },
    }
  );
};
