import React, { useState } from "react";
import roundSvg from "../../assets/images/round.svg";
import { ArrowRightIcon } from "../common/Icon";
import { RouteName } from "../../app/constants/route-constant";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useGetPreviousWinnersThreeDList } from "../../api/funs/rounds";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { CONTENT } from "../../content";
import { setPreviousResult } from "../../services/storage";
const ThreedPreviousResultCard = ({
  winningNumber,
  resultDate,
  roundType,
  resultId,
  tootNumbers,
  resultTime,
}: {
  winningNumber?: string;
  resultDate?: string;
  resultTime?: string;
  roundType?: string;
  resultId?: string;
  tootNumbers?: string[];
}) => {
  const navigate = useNavigate();

  const [tootDaeType, setTootDaeType] = useState<{
    label: string;
    value: string;
  } | null>({
    label: "Toot",
    value: "box-winners",
  });

  const { data: PreviousWinnings, isLoading } = useGetPreviousWinnersThreeDList(
    "three-d",
    resultId as string,
    0,
    3,
    tootDaeType?.value as string
  );

  return (
    <div className="bg-white rounded-[10px] px-1 md:px-3 py-2 mt-3">
       <div className="flex items-start justify-between mb-2 md:mb-0 md:items-center">
        <div className="md:flex gap-x-2">
          <img src={roundSvg} className="w-10 h-10" alt="placeholder" />
          <div>
            <span className="font-bold text-[10px] md:text-[16px] block">
              {moment(resultDate).format("DD MMMM YYYY")}
            </span>
            <span className="font-bold text-vermilion_bird text-[8px] md:text-[12px] -mt-[3px] block">
              {roundType}
            </span>
          </div>
        </div>

        <div className="flex items-center md:gap-x-3">
          <span className="text-[8px] md:text-[14px]  ">
            Result announced:
            <span className="font-semibold">
              {moment(resultTime,"HH:mm").format("hh:mm:ss a")} at{" "}
              {moment(resultDate).format("DD MMMM YYYY")},{" "}
              {moment(resultDate).format("ddd")}
            </span>
          </span>
          <ArrowRightIcon />
        </div>
      </div>
      <div className="md:flex gap-x-10">
        <div className="md:w-[299px] py-3  bg-center bg-no-repeat bg-cover bg-round_image rounded-[10px] flex flex-col justify-center items-center">
          <span className="text-[12px]">Result</span>
          <span className="text-[50px] font-bold -mt-3 mb-2">
            {winningNumber}
          </span>

          <span className="block">
            {tootNumbers?.map((_r, i) => (
              <span className="text-[30px] font-bold -mt-3 mb-2 block" key={i}>
                {_r}
              </span>
            ))}
          </span>
        </div>
        <div className="flex flex-col items-start justify-between flex-auto mt-3 md:mt-0">
          <div className="flex items-center justify-between w-full mb-4">
            <span className="block">
              Total of {PreviousWinnings?.data?.totalCounts} Winners
            </span>

            <Autocomplete
              sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
              id="combo-box-demo"
              onChange={(e, value) => {
                setTootDaeType(value as any);
              }}
              value={tootDaeType}
              options={CONTENT?.dashboard?.tootDaeTypeA}
              defaultValue={{
                label: CONTENT?.dashboard?.tootDaeTypeA?.[0]?.label,
                value: CONTENT?.dashboard?.tootDaeTypeA?.[0]?.value,
              }}
              getOptionLabel={(option) => option?.label}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              style={{ width: 130 }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  color="error"
                  {...params}
                  label="Select Type"
                  size="small"
                  defaultValue={tootDaeType?.value}
                  variant="outlined"
                />
              )}
            />
          </div>

          {isLoading ? (
            <div className="flex justify-center w-full mt-3">
              {" "}
              <CircularProgress
                size={20}
                sx={{
                  color: "#F44336",
                }}
              />
            </div>
          ) : (
            <table className="w-full text-dim_grey">
              <tbody>
                {PreviousWinnings?.data?.data?.threedWinnerLists?.map(
                  (_d: any, i: number) => (
                    <tr
                      className="border-dashed cursor-pointer border-steam border-y"
                      key={i}
                      onClick={() => {
                        navigate({
                          pathname: `/users/detail/${_d?.userId?._id}/winning/${_d?.threedResultId}`,
                          search: `?type=threed&tdType=${
                            tootDaeType?.value === "box-winners"
                              ? "box"
                              : "straight"
                          }`,
                        });
                      }}
                    >
                        <td className="py-3">
                            <span className="px-1 md:px-2 md:py-[0.6px] text-white bg-vermilion_bird text-[12px] rounded-[10px]">
                              {i + 1}
                            </span>
                          </td>
                          <td>
                            {" "}
                            <div className="flex items-center">
                              {/* <img
                        className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> */}

                              <div>
                                <div className=" w-16 text-[10px] md:text-[14px] font-medium  text-dynamic_black">
                                  {_d?.userId?.name}
                                </div>
                                <div className="text-[8px] md:text-[12px] text-dim_grey">
                                  {_d?.userId?.phone}{" "}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Agent Code <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.agentCode}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px]">
                              Bet <br />
                              <span className="text-[12px] md:text-[16px]">
                                {_d?.totalBetAmount?.toLocaleString()}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className="text-[10px] md:text-[12px] block">Won</span>
                            <span className="text-[12px] md:text-[16px] text-grass_green">
                              {_d?.totalWinningAmount?.toLocaleString()}
                            </span>
                          </td>
                      {/* <td>
                        <span className="text-[12px] block">Status</span>
                        {_d?.status === "UNCLAIMED" ? (
                          <div className="flex items-center">
                            <CheckCircleBrownIcon />
                            <span className=" text-[14px] ml-1 text-dim_grey">
                              UnClaimed
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <CheckCircleIcon />
                            <span className=" text-[14px] ml-1 text-grass_green">
                              Claimed
                            </span>
                          </div>
                        )}
                      </td> */}
                      <td>
                        <ArrowRightIcon />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}

          <div
            onClick={() => {
              setPreviousResult(
                JSON.stringify({
                  winningNumber: winningNumber,
                  set: "",
                  value: "",
                  resultDate: resultDate,
                  roundType: roundType,
                  resultId: resultId,
                  tootDaeType: tootDaeType?.value,
                  tootNumbers: tootNumbers,
                })
              );

              navigate(RouteName.threed_previousresults_winnerlist);
            }}
            className="flex items-center justify-center w-full py-2 mt-3 text-xs cursor-pointer md:text-base bg-pink_lemonade text-vermilion_bird"
          >
            View All Winners
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreedPreviousResultCard;
