import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import Modal from "../common/modal";
import RichTextEditor from "../common/richTextEditor";
import { AirrowLeftIcon } from "../../pages/common/Icon";

const TermCreate: React.FC = () => {
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [richText, setRichText] = useState<string>("");
  const [language, setLanguage] = useState<string>("en");
  const navigate = useNavigate();
  const [errorArray, setErrorArray] = useState<any[]>([]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/admins/terms/create",
      data: {
        language: language,
        terms: richText,
      },
      method: "post",
    })
      .then((res) => {
        if (res.data.status === "success") {
          setMessage(res.data.message);
          navigate("/setting/termconditions/list");
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setErrorArray(err.response.data.errors);
      })
      .finally(() => {});
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="flex col">
                <AirrowLeftIcon
                  onClick={() => navigate("/setting/termconditions/list")}
                />{" "}
                <span
                  className="p-l-5"
                  onClick={() => navigate("/setting/termconditions/list")}
                >
                  Back
                </span>
              </div>
            </div>
            <form>
              <div className="container">
                {errorArray ? (
                  errorArray.map((error, i) => (
                    <div className="row p-t-10 p-b-10 alert alert-danger">
                      {error.msg}
                    </div>
                  ))
                ) : (
                  <></>
                )}
                <div className="row g-3 p-t-10 p-b-10">
                  <div className="col-12 d-flex align-items-center">
                    <select
                      className="form-input-select"
                      onChange={(e) => setLanguage(e.target.value)}
                      aria-label="Default Select Role"
                    >
                      <option value="en">English</option>
                      <option value="mm">Myanmar</option>
                      <option value="zh">Chinese</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <label htmlFor="Terms And Conditions">
                      Terms And Conditions
                    </label>
                    <RichTextEditor value={richText} onChange={setRichText} />
                  </div>
                </div>
                <div className="row ">
                  <div className="flex-row-reverse col-12 d-flex">
                    {richText ? (
                      <div>
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn-create-active"
                        >
                          Create
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button type="submit" className="btn-create-unactive">
                          Create
                        </button>
                      </div>
                    )}
                    <div
                      onClick={() => navigate("/setting/termconditions/list")}
                      className="btn-cancel"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal title="Holiday Create" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/holiday/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermCreate;
