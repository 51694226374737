import React from "react";
import { CONTENT } from "../../content";
import { useGetDSummary } from "../../api/funs/rounds";
import moment from "moment";

const Summary: React.FC = () => {
  const { data: dSummary } = useGetDSummary("three-d");
  return (
    <>
      <div className="grid mb-3 md:grid-cols-2 gap-x-4 gap-y-3 ">
        {CONTENT.bet_list.overview.array.map((_d, i) => (
          <div key={i} className="bg-lynx_white rounded-[10px] px-4 py-2">
            <span className=" text-[12px] text-dynamic_black mb-2 font-semibold">
              {_d}
            </span>

            <p className={`text-dynamic_black text-[18px] font-semibold`}>
              {i === 0
                ? (+(
                    dSummary?.data?.data?.total3DRounds || 0
                  ))?.toLocaleString()
                : i === 1
                ? `${moment(
                    dSummary?.data?.data?.activeDate?.resultDate
                  ).format("DD MMMM YYYY")} - Present`
                : ""}
            </p>
          </div>
        ))}
      </div>
      <div>
        <div className="grid mb-4 md:grid-cols-2 gap-x-4 gap-y-3">
          <div className="bg-grass_green rounded-[10px] px-4 py-2 flex flex-col justify-center">
            <div>
              <span className="text-white text-[10px]">Total Profit</span>
              <p className="text-white text-[28px] font-semibold">
                {(dSummary?.data?.data?.totalProfit || 0)?.toLocaleString()}{" "}
                <span className="text-[14px] font-normal"></span>
              </p>
            </div>

            {/* <span className="text-[14px] font-normal text-white mb-3">
              Last won in{" "}
              {`${format(new Date(new Date()), "dd MMM  yyyy")} at ${format(
                new Date(new Date()),
                "hh:mm:ss a"
              )}`}
            </span> */}
          </div>

          <div className="flex flex-col gap-y-5">
            {CONTENT.bet_list.overview.array2.map((_y, i) => (
              <div className="bg-lynx_white rounded-[10px] px-4 py-2" key={i}>
                <span className=" text-[12px] text-dynamic_black mb-2 font-semibold">
                  {_y}
                </span>

                <p
                  className={`${
                    i === 0 ? "text-dynamic_black" : "text-grass_green"
                  } text-[16px] font-semibold`}
                >
                  {(i === 0
                    ? dSummary?.data?.data?.totalBetAmount
                    : dSummary?.data?.data?.totalAmountEarnByUser || 0
                  )?.toLocaleString()}{" "}
                  <span className="text-[14px] font-normal"></span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="grid mb-2 md:grid-cols-2 gap-x-4 gap-y-3">
        {CONTENT.bet_list.overview.array3.map((_d, i) => (
          <div className="bg-lynx_white rounded-[10px] px-3 py-2" key={i}>
            <span className="block mb-1 font-semibold text-[12px]">
              {_d.title}
            </span>
            <span className="text-dim_grey text-[13px] block mb-3">
              {`${_d.desc} ${moment(dSummary?.data?.data?.activeDate).format(
                "DD MMMM YYYY"
              )} at ${moment(dSummary?.data?.data?.activeDate).format(
                "hh:mm:ss a"
              )}`}
            </span>
            <div className="grid grid-cols-3 gap-x-2">
              <div className="flex items-center">
                <div>
                  <div className="text-[14px] font-medium  text-dynamic_black">
                  {i === 0
                      ? dSummary?.data?.data?.topWinningUser?.userInfo?.[0]
                          ?.name || "--"
                      : dSummary?.data?.data?.topBetUser?.userInfo?.[0]?.name ||
                        "--"}
                  </div>
                  <div className="text-[12px] text-dim_grey">
                  {i === 0
                      ? dSummary?.data?.data?.topWinningUser?.userInfo?.[0]
                          ?.phone || "--"
                      : dSummary?.data?.data?.topBetUser?.userInfo?.[0]
                          ?.phone || "--"}
                  </div>
                </div>
              </div>
              {_d?.betInfo?.map((_, ii) => (
                <div key={ii}>
                  <span className="block text-[14px] ">{_}</span>
                  <span
                    className={`font-semibold ${
                      i === 0 && ii === 1 ? "text-grass_green" : ""
                    }`}
                  >
                    {ii === 0 && i === 0
                      ? dSummary?.data?.data?.topWinningUser?.totalBetAmount
                        ? dSummary?.data?.data?.topWinningUser?.totalBetAmount?.toLocaleString()
                        : "---"
                      : ii === 1 && i === 0
                      ? dSummary?.data?.data?.topWinningUser?.totalWinningAmount
                        ? dSummary?.data?.data?.topWinningUser?.totalWinningAmount?.toLocaleString()
                        : "--"
                      : ii === 0 && i === 1
                      ? dSummary?.data?.data?.topBetUser?.totalBetAmount
                        ? dSummary?.data?.data?.topBetUser?.totalBetAmount?.toLocaleString()
                        : "--"
                      : ii === 1 && i === 1
                      ? dSummary?.data?.data?.topBetUser?.totalWinningAmount
                        ? dSummary?.data?.data?.topBetUser?.totalWinningAmount?.toLocaleString()
                        : "--"
                      : ""}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Summary;
