import React, { useState } from "react";
import DateFilter from "../../components/ui/bet/DateFilter";
import ThreedPreviousResultCard from "./ThreedPreviousResultCard";
import { useGetPreviousResultList } from "../../api/funs/rounds";
import dayjs from "dayjs";
import moment from "moment";
import SpinnerLoading from "../common/SpinnerLoading";

const ThreeDPreviousresultPage: React.FC = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data: previousResults, isLoading } = useGetPreviousResultList(
    "three-d",
    startDate
      ? dayjs(startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    endDate
      ? dayjs(endDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );
  return (
    <div className="mt-3">
      {" "}
      <DateFilter setStartDateP={setStartDate} setEndDateP={setEndDate} />
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          {previousResults?.data?.data?.length === 0 ? (
            <div className="flex flex-col items-center justify-center flex-1 w-full h-[50vh]">
              <div className="block text-2xl font-bold text-dim_grey">
                No Record...
              </div>
              <div className="block text-dim_grey text-[12px] mt-1">
                There is no “3D” for this day. Please try to change the date to
                see results.
              </div>
            </div>
          ) : (
            <>
              {previousResults?.data?.data?.map((_: any, i: number) => (
                <ThreedPreviousResultCard
                  key={i}
                  winningNumber={_?.winningNumber}
                  resultDate={_?.resultDate}
                  roundType={_?.roundId?.roundName}
                  resultId={_?._id}
                  tootNumbers={_?.tootNumbers}
                  resultTime={_?.announceTime}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ThreeDPreviousresultPage;
