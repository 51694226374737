import React, { useMemo, useState } from "react";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CONTENT } from "../../content";
import CustomizeTable from "../../table/CustomizeTable";
import { useGetUserDetailWinning } from "../../api/funs/user";
import moment from "moment";
import { winningDetailCol } from "../../table/columns/user/winningDetail";
import SpinnerLoading from "../common/SpinnerLoading";
import { ThreeDTypeIcon, TwoDTypeIcon } from "../common/Icon";

const WinningUserDetailPage = () => {
  const { id, resultId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100, //customize the default page size
  });
  const {
    data: detail,
    error,
    isLoading,
  } = useGetUserDetailWinning(
    id,
    resultId,
    searchParams.get("type") as string,
    searchParams.get("tdType") as string
  );
  const historyData = useMemo(() => {
    return detail?.data?.data?.betData?.map((d: any) => ({
      number:
        (searchParams.get("type") as string) === "twod"
          ? detail?.data?.data?.gameData?.winningNumber
          : d?.winningNumber,
      winningType: detail?.data?.data?.gameData?.gameType,
      bet_amount: d?.betAmount,
      bet_date: d?.betDate,
      status: d?.status,
      winning_amount: d?.winningAmount,
    }));
  }, [detail, searchParams]);
  return (
    <>
      {" "}
      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div className="">
            <BackArrowButton
              handleClick={() => navigate(`/users/detail/${id}`)}
            />

            <div className="flex justify-between">
              <p>{detail?.data?.data?.userData?.userCode}</p>
              <p>
                Bet Placed on :{" "}
                {moment(detail?.data?.data?.betData[0]?.betDate).format(
                  "hh:mm:ss a"
                )}{" "}
                at{" "}
                {moment(detail?.data?.data?.betData[0]?.betDate).format(
                  "YYYY MMM DD"
                )}
                ,{" "}
                {moment(detail?.data?.data?.betData[0]?.betDate).format("ddd")}
              </p>
            </div>
            <div className="grid grid-cols-4 mb-5 gap-x-5">
              {CONTENT.user_detail.detail.winningHistoryCard.map((_, i) => (
                <div
                  key={i}
                  className="py-3 pl-4 border rounded-lg border-steam bg-lynx_white"
                >
                  <span className="block mb-2 font-bold text-[12px]">{_}</span>

                  {i === 0 ? (
                    <div className="flex gap-x-2">
                      <img
                        className="inline-block w-6 h-6 rounded-full ring-2 ring-white"
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />

                      <div>
                        <span className="block text-[16px] text-vermilion_bird">
                          {detail?.data?.data?.userData?.userName}
                        </span>
                        <span className="text-[13px]  font-semibold">
                          {detail?.data?.data?.userData?.phone}
                        </span>
                      </div>
                    </div>
                  ) : i === 1 ? (
                    <div className="flex items-center gap-x-2">
                      {(searchParams.get("type") as string) === "twod" ? (
                        <TwoDTypeIcon />
                      ) : (
                        <ThreeDTypeIcon />
                      )}

                      <div>
                        <span className="block font-semibold text-autumn_glory ">
                          {detail?.data?.data?.gameData?.gameType}{" "}
                          {(searchParams.get("type") as string) === "twod" &&
                            `(${detail?.data?.data?.gameData?.roundName})`}
                        </span>

                        <span className="text-[12px] text-dynamic_black">
                          {moment(
                            detail?.data?.data?.gameData?.resultDate
                          ).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span
                        className={`${
                          i === 2 ? "text-vermilion_bird" : "text-grass_green"
                        } text-[20px] font-semibold`}
                      >
                        {i === 2
                          ? detail?.data?.data?.gameData?.totalBettingAmount?.toLocaleString()
                          : "+" +
                            detail?.data?.data?.gameData?.totalWinAmount?.toLocaleString()}{" "}
                        <span className="text-[10px]"></span>
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <CustomizeTable
            column={winningDetailCol}
            data={historyData}
            totalCount={detail?.data?.data?.betData?.length}
            isError={error}
            isRefetching={isLoading}
            pagination={pagination}
            setPagination={setPagination}
            menuType={"Bet2DHistory"}
            isShowAction={"inactive"}
          />
        </>
      )}
    </>
  );
};

export default WinningUserDetailPage;
