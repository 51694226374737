export function ImageCell({ renderedCellValue, row }: any) {
  return (
    <div className="flex items-center">
      <img
        className="inline-block w-10 h-10 rounded-md ring-2 ring-white"
        src={renderedCellValue}
        alt=""
      />
    </div>
  );
}
