import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import { RoundDetailPops } from "../../components/DataDeclare";
import { useParams } from "react-router-dom";
import Loading from "../common/loading";

const RoundDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [round, setRound] = useState<RoundDetailPops>();
  const fetchroundData = async () => {
    fetchData({
      url: "api/admins/rounds/" + id,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setRound(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchroundData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {" "}
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="container">
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="time">Date :</label>
                      {round?.date}
                    </div>
                    <div className="col-12">
                      <label htmlFor="name">End Time :</label>
                      {round?.endTime}
                    </div>
                    <div className="col-12">
                      <label htmlFor="name">Winning Number :</label>
                      {round?.winningNumber}
                    </div>
                    <div className="col-12">
                      <label htmlFor="name">Toot Number :</label>
                      {round?.tootNumbers}
                    </div>
                    <div className="col-12">
                      <label htmlFor="name">Game Type:</label>
                      {round?.gameTypeId?.name}-{round?.gameTypeId?.roundName}
                    </div>

                    <div className="col-3">
                      <Link to="/round/list" className="btn btn-secondary">
                        Back
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoundDetail;
