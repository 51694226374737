import React, { useState } from "react";

import "../style/login.scss";
import Backgrund from "../assets/images/backgroundlogin.png";
import {
  LogoAIOIcon,
  AccountCircleIcon,
  EyeSlashIcon,
  EyeIcon,
} from "./common/Icon";
import { useLogin } from "../api/mutation/auth";
import { Controller, useForm } from "react-hook-form";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useGetIpAddress } from "../api/funs/user";

const Login: React.FC = () => {
  const { data: ipaddress } = useGetIpAddress();
  const { trigger, isMutating } = useLogin();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const { control, handleSubmit, formState } = useForm<{
    userCode: string;
    password: string;
  }>({
    defaultValues: {
      userCode: "",
      password: "",
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    trigger({
      userCode: data?.userCode,
      password: data?.password,
      ipAddress: ipaddress?.data?.ip,
    });
  };

  return (
    <div
      className="flex items-center justify-center login-form"
      style={{ backgroundImage: `url(${Backgrund})` }}
    >
      <div className="flex px-4 py-3 bg-white rounded-lg">
        <div className="">
          <div className="">
            <div className="flex justify-center col-12">
              <LogoAIOIcon width={80} height={80} />
            </div>
          </div>
          <div className="mt-2 mb-10 font-bold row text-md md:text-xl">
            <div className="text-center col-12">Login In To Your Account</div>
          </div>

          <div className="flex justify-center">
            <form
              className="w-[80%]"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Controller
                control={control}
                name="userCode"
                rules={{
                  required: {
                    value: true,
                    message: "User code is required.",
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      {...field}
                      size="small"
                      error={!!errors?.userCode}
                      label="UserCode"
                      fullWidth
                      className="customTextField"
                      color="error"
                      sx={{
                        marginBottom: "20px",
                      }}
                      helperText={
                        <span className="whitespace-nowrap">
                          {!!errors?.userCode?.message &&
                            errors?.userCode?.message}
                        </span>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AccountCircleIcon className="w-6 h-6" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="password"
                rules={{
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                  validate: (value: string) => {
                    if (!value) {
                      return "Password is required.";
                    }
                  },
                }}
                render={({ field, formState, fieldState }) => {
                  return (
                    <TextField
                      autoComplete="off"
                      color="error"
                      {...field}
                      size="small"
                      error={!!errors?.password}
                      type={isShowPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      sx={{
                        marginBottom: "20px",
                      }}
                      helperText={
                        <span className="whitespace-nowrap">
                          {!!errors?.password?.message &&
                            errors?.password?.message}
                        </span>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            {isShowPassword ? (
                              <EyeSlashIcon className="w-6 h-6" />
                            ) : (
                              <EyeIcon className="w-6 h-6" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                }}
              />

              <button
                className={`button-primary py-2 w-full flex justify-center items-center bg-vermilion_bird text-white rounded-md ${
                  isMutating && "opacity-50"
                } `}
              >
                {isMutating ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <div className="container justify-content-center">
          <div className="row">
            <span>Hotline: +959 372 837 893, +959 348 727 270</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
