import { useAtom } from "jotai";
import { modalAtom } from "../../../../store";
import { FiX } from "react-icons/fi";

import AgentCredentialShowCard from "../../agent/AgentCredentialShowCard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Margin, usePDF } from "react-to-pdf";
import { decryptHashedData } from "../../../../util/funs";
import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../../app/constants/route-constant";

const CredentialModalContent = () => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalAtom);
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: `${isOpenModal?.showData?.data?.agent?.name}-credentials`,
    page: {
      margin: Margin.MEDIUM,
      format: "A4",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
  });
  const navigate =useNavigate()

  const handleCloseX = () => {
    setIsOpenModal({ isOpen: false });
    navigate(RouteName.agents_list)
  };
  

  return (
    <>
      {isOpenModal?.showData?.data && (
        <>
          <div className="relative px-3 pt-8 pb-3 bg-white rounded-md ">
            <FiX className="absolute right-3 top-3" onClick={handleCloseX} />
            <AgentCredentialShowCard
              agentInfo={isOpenModal?.showData?.data?.agent}
            />
            <div className="flex justify-end gap-4 mt-6">
              <div
                onClick={() => {
                  toPDF();
                  // handleClose();
                  // navigate(RouteName.agents_list);
                }}
                className="px-2 py-1 mx-0 bg-white border rounded-lg cursor-pointer w-fit border-red text-vermilion_bird"
              >
                Download
              </div>
              <CopyToClipboard
                text={`agentcode: ${
                  isOpenModal?.showData?.data?.agent?.userCode
                },
        password:${
          isOpenModal?.showData?.data?.agent?.encryptPassword &&
          decryptHashedData(
            isOpenModal?.showData?.data?.agent?.encryptPassword,
            "AIO_PASSWORD_ENCRYPT"
          )
        },
        secretcode: ${isOpenModal?.showData?.data?.agent?.secretCode},
        username: ${isOpenModal?.showData?.data?.agent?.name},
      `}
              >
                <div
                  onClick={() => {
                    toast.info("Copied agent credentials", {
                      position: "bottom-right",
                    });
                    // handleClose();
                    // navigate(RouteName.agents_list);
                  }}
                  className="px-2 py-1 mx-0 bg-white border rounded-lg cursor-pointer w-fit border-red text-vermilion_bird"
                >
                  CopyToClipboard
                </div>
              </CopyToClipboard>
            </div>
            <div
              ref={targetRef}
              className="absolute -left-[9999px] -top-[9999px] w-full"
            >
              <AgentCredentialShowCard
                agentInfo={isOpenModal?.showData?.data?.agent}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CredentialModalContent;
