import React from "react";
import { CONTENT } from "../../../content";
import { useGetAgentDetailOverview } from ".";
import { useParams } from "react-router-dom";

const AgentDetailOverview = () => {
    const {id}=useParams()

    const {data:agentOverviewDetail}=useGetAgentDetailOverview(id as string)
  return (
    <div>
      <div className="w-full px-3 py-2 bg-grass_green rounded-[10px] mb-3">
        <span className="block text-white">
          Total Percentage Collected from Invoice
        </span>
        <span className="text-white text-[2rem]">
                  { agentOverviewDetail?.data?.data?.totalPercentFromInvoice?.toLocaleString()} <span className="text-[12px]"></span>
        </span>
      </div>
      <div className="grid grid-cols-2 gap-x-5">

        {
                  CONTENT.agent_detail.overview.map((_e: any, i: number) => (
                      <div key={i} className="rounded-[10px] bg-lynx_white  px-3 py-2">
                          <span className="block  text-[12px]">
                              {_e}
                          </span>
                          <span className="text-[18px] font-bold">{ i===0? agentOverviewDetail?.data?.data?.totalUsers:i===1?agentOverviewDetail?.data?.data?.totalUserBetTime:""}</span>
                    </div>

            ))
        }
      </div>
    </div>
  );
};

export default AgentDetailOverview;
