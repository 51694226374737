import React from "react";
import { AirrowLeftIcon } from "../../../pages/common/Icon";

const BackArrowButton = ({ handleClick }: {
  handleClick:()=> void
}) => {
  return (
    <div className="flex items-center mb-4 cursor-pointer" onClick={handleClick}>
      <AirrowLeftIcon className="w-6 h-6 md:w-6 md:h-6" /> <span className="pl-2 text-sm md:text-base">Back</span>
    </div>
  );
};

export default BackArrowButton;
