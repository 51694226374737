import { DevTool } from "@hookform/devtools";
import TextField from "@mui/material/TextField";

import { Controller, useForm } from "react-hook-form";
import { useCreateEmailApp } from "../api/mutation/notification";
import { CircularProgress } from "@mui/material";
import Select from "react-select";

import { useNotificationForEmail } from "../api/funs/notification";

const EmailNotiForm = () => {
  const { data: emailLists } = useNotificationForEmail();
  const { trigger, isMutating } = useCreateEmailApp();

  const emailOptions = emailLists?.data?.data?.map((_: any) => ({
    label: _?.receivingMail,
    value: _?.receivingMail,
  }));

  const { control, handleSubmit, formState, reset, setValue } = useForm<{
    email?: { value: string; label: string }[];
    title: string;
    message: string;
  }>({
    defaultValues: {
      email: [],
      title: "",
      message: "",
    },
  });
  const { errors } = formState;

  const onSubmit = (data: any) => {
    trigger({
      emails: data?.email && data?.email?.map((_d: any) => _d?.value),
      subject: data?.title,
      text: data?.message,
    });
    reset();
    setValue("email", []);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name="title"
          rules={{
            required: {
              value: true,
              message: "Title is required.",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                {...field}
                error={!!errors?.title}
                label="Title"
                fullWidth
                sx={{
                  marginBottom: "15px",
                }}
                helperText={!!errors?.title?.message && errors?.title?.message}
                size="small"
              />
            );
          }}
        />
        <Controller
          control={control}
          name="message"
          rules={{
            required: {
              value: true,
              message: "Message is required",
            },
          }}
          render={({ field, formState, fieldState }) => {
            return (
              <TextField
                autoComplete="off"
                color="error"
                {...field}
                error={!!errors?.message?.message}
                label="Message"
                multiline
                fullWidth
                minRows={2}
                maxRows={4}
                sx={{
                  marginBottom: "15px",
                }}
                helperText={
                  !!errors?.message?.message && errors?.message?.message
                }
                size="small"
              />
            );
          }}
        />

        <div className="mb-3">
          {emailOptions && (
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Email is required.",
                },
              }}
              render={({ field: { onChange, value, ...field } }) => {
                return (
                  <div className="z-[50]">
                    {/* <FormControl
                  fullWidth
                  sx={{
                    marginBottom: "15px",
                  }}
                  error={!!formState.errors?.email}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Email
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Email"
                    multiple
                    renderValue={(selected: any) => {
                      return (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected &&
                            selected?.map((value: any) => (
                              <Chip key={value} label={value} />
                            ))}
                        </Box>
                      );
                    }}
                  >
                    {emailLists &&
                      emailLists?.data?.data?.map((_: any, i: any) => (
                        <MenuItem key={i} value={_?.receivingMail}>
                      
                          <ListItemText primary={_?.receivingMail} />
                        </MenuItem>
                      ))}
                  </Select>
                  {errors?.email?.message && (
                    <FormHelperText>{errors?.email?.message}</FormHelperText>
                  )}
                </FormControl> */}

                    {emailOptions && (
                      <Select
                        options={emailOptions && emailOptions}
                        isMulti // Enable multi-selection
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        {...field} // Optional ref for programmatic access (if needed)
                        // Customize other props as desired (e.g., placeholder, styles)

                        menuPlacement={"bottom"}
                        closeMenuOnSelect={true}
                      />
                    )}
                  </div>
                );
              }}
            />
          )}

          {!!errors?.email?.message && errors?.email?.message && (
            <span className="block mt-2 text-red-500 text-[10px]">
              {errors?.email?.message}
            </span>
          )}
        </div>

        <div className="flex items-center justify-between w-full">
          <div></div>
          <button
            type="submit"
            className={`btn-create-active ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Create"
            )}
          </button>
        </div>

        <DevTool control={control} />
      </form>
    </div>
  );
};

export default EmailNotiForm;
