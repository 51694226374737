import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import { GameTypeDetailPops } from "../../components/DataDeclare";
import { useParams } from "react-router-dom";
import Loading from "../common/loading";

const GameTypeDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [gametype, setGameType] = useState<GameTypeDetailPops>();
  const fetchgametypesData = async () => {
    fetchData({
      url: "api/gametypes/" + id,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setGameType(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchgametypesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="container">
                {isLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="name">Group Type Name :</label>
                      {gametype?.name}
                    </div>

                    <div className="col-12">
                      <label htmlFor="time">Time :</label>
                      {gametype?.time}
                    </div>

                    <div className="col-3">
                      <Link to="/gametypes/list" className="btn btn-secondary">
                        Back
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameTypeDetail;
