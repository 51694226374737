import { WinningNumberCell } from "../../cell/WinningNumberCell";
import { AmountMMKCell } from "../../cell/AmountMMKCell";
import DateFormatCell from "../../cell/DateFormatCell";
import { AmountGreenMMKCell } from "../../cell/AmountGreenMMKCell";
import IsClaimedCell from "../../cell/IsClaimedCell";

export const winningDetailCol = [
  {
    accessorKey: "number", //simple recommended way to define a column
    header: "Winning Number",
    Cell: WinningNumberCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "winningType", //simple recommended way to define a column
    header: "Winning Type",

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "bet_amount", //simple recommended way to define a column
    header: "Bet Amount",
    Cell: AmountMMKCell,

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "bet_date", //simple recommended way to define a column
    header: "Bet Date",
    Cell: DateFormatCell,
    filterVariant: "date-range",
    accessorFn: (originalRow: any) => new Date(originalRow?.bet_date),

    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "status", //simple recommended way to define a column
    header: "Status",
    // accessorFn: (originalRow: any) => (originalRow.status ? "true" : "false"), //must be strings
    id: "status",
    Cell: IsClaimedCell,
    // filterVariant: "checkbox",
    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
  {
    accessorKey: "winning_amount", //simple recommended way to define a column
    header: "Winning Amount",
    Cell: AmountGreenMMKCell,
    // muiTableHeadCellProps: { style: { color: "green" } }, //custom props
    // enableHiding: false, //disable a feature for this column
  },
];
