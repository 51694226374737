import React from "react";
import { CONTENT } from "../../content";
import { format } from "date-fns";
import { userDetailAtom } from "../../store";
import { useAtom } from "jotai";
import { CheckCircleIcon, UnCheckCircleIcon } from "../../pages/common/Icon";

const UserDetailOverViewTable = () => {
  const [userDetailData] = useAtom(userDetailAtom);

  return (
    <>
      <table className="w-full mb-5 table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.user_detail.detail.table.map((_d, i) => (
            <tr key={i}>
              <td className="py-3 text-sm border border-steam md:text-base">{_d}</td>
              <td className="py-3 text-sm font-semibold capitalize border border-steam md:text-base">
                {i === 0 ? (
                  userDetailData?.name
                ) : i === 1 ? (
                  userDetailData?.phone
                ) : i === 2 ? (
                  userDetailData?.status ? (
                    <span className="flex items-center justify-center gap-x-1">
                      <CheckCircleIcon />{" "}
                      <span className="font-normal">Verified</span>
                    </span>
                  ) : (
                    <span className="flex items-center justify-center gap-x-1">
                      <UnCheckCircleIcon />{" "}
                      <span className="font-normal">Blocked</span>
                    </span>
                  )
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="w-full table-auto">
        <tbody className="text-center border border-steam">
          {CONTENT.user_detail.detail.table2.map((_d, i) => (
            <tr key={i}>
              <td className="py-3 text-sm border border-steam md:text-base">{_d}</td>
              <td
                className={`py-3 ${
                  [0, 1].includes(i) ? "font-normal " : "font-semibold "
                } text-xs md:text-base capitalize border border-steam`}
              >
                {i === 0
                  ? userDetailData?.createdAt
                    ? `${format(
                        new Date(userDetailData?.createdAt || new Date()),
                        "dd MMM  yyyy"
                      )} at ${format(
                        new Date(userDetailData?.createdAt || new Date()),
                        "hh:mm:ss a"
                      )}`
                    : "---"
                  : i === 1
                  ? userDetailData?.lastLogin
                    ? `${format(
                        new Date(userDetailData?.lastLogin || new Date()),
                        "dd MMM  yyyy"
                      )} at ${format(
                        new Date(userDetailData?.lastLogin || new Date()),
                        "hh:mm:ss a"
                      )}`
                    : "---"
                  : i === 2
                  ? userDetailData?.agentId?.userCode ?? "--"
                  : "---"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UserDetailOverViewTable;
