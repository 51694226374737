import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import Modal from "../common/modal";

const RoundCreate: React.FC = () => {
  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [announceTime, setAnnounceTime] = useState("");
  const [message, setMessage] = useState("");
  const [gameTypeId, setGameTypeId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [, setIsLoading] = useState<boolean>(true);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };
  const [gameType, setGameType] = useState<any[]>([]);

  const fetchgametypesData = async () => {
    fetchData({
      url: "api/gametypes",

      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setGameType(res.data.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchgametypesData();
  }, []);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData({
      url: "api/admins/rounds",
      data: {
        date: selectedDate,
        endTime: endTime,
        announceTime: announceTime,
        openTime: time,
        gameTypeId: gameTypeId,
      },
      method: "post",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setMessage(res.data.message);
          setIsModalOpen(true);
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="container">
              {message ? (
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              ) : (
                <></>
              )}
              <div className="container">
                <div className="row g-3">
                  <div className="col-12 form-group">
                    <label
                      htmlFor="dateInput"
                      className="control-label required"
                    >
                      Select Date:
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      id="dateInput"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="name" className="control-label required">
                      Group Type Name :
                    </label>
                    <select
                      className="form-input-selectt"
                      onChange={(e) => setGameTypeId(e.target.value)}
                      aria-label="Default select Game Type"
                    >
                      <option selected>Choose Game Type</option>
                      {gameType.map((game, i) => (
                        <option value={game?._id}>
                          {game?.name} - {game?.roundName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12">
                    <label htmlFor="time" className="control-label required">
                      Time :
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      placeholder="Enter time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="time" className="control-label required">
                      Time :
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      placeholder="Enter time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="time" className="control-label required">
                      Time :
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      placeholder="Announce time"
                      value={announceTime}
                      onChange={(e) => setAnnounceTime(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      Create
                    </button>
                  </div>
                  <div className="col-3">
                    <Link to="/round/list" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Round Create" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <Link to="/round/list" className="btn btn-secondary">
                Ok
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RoundCreate;
