import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Gridtable from "../common/gridtable";
import { fetchData } from "../../services/fetch";
import Modal from "../common/modal";
import Loading from "../common/loading";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/authContext";

const RoundList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectCount, setSelectCount] = useState<number>(0);
  const { permissionIds } = useContext(AuthContext);
  const headerdata = [
    {
      name: "Date",
    },
    {
      name: "End Time",
    },

    {
      name: "Game Type",
    },
    {
      name: "Winning Number",
    },
    {
      name: "Toot Numbers",
    },
    {
      name: "Action",
    },
  ];
  const [bodydata, setBodydata] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setMessage("");
    setId("");
  };

  const fetchroundData = async (count: number) => {
    count = count + 1;
    let filter = "?";
    let pagenumber = count ? filter + "pageNumber=" + count : filter;
    if (startDate) {
      let startdate = "from=" + format(startDate, "yyyy-MM-dd");
      if (pagenumber === "?") {
        pagenumber = pagenumber + startdate;
      } else {
        pagenumber = pagenumber + "&" + startdate;
      }
    }
    if (endDate) {
      let enddate = "to=" + format(endDate, "yyyy-MM-dd");
      if (pagenumber === "?") {
        pagenumber = pagenumber + enddate;
      } else {
        pagenumber = pagenumber + "&" + enddate;
      }
    }
    let url = "api/admins/rounds/lists" + pagenumber;
    fetchData({
      url: url,
      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setBodydata(res.data.data);
          setPageCount(res.data.totalPages);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchroundData(selectCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteConfirm = (
    id: string,
    gametypename: string,
    gametime: string
  ) => {
    setId(id);
    setMessage(
      "Are you sure you want to delete " + gametypename + "-" + gametime + "?"
    );
    setIsModalOpen(true);
  };
  const Search = () => {
    fetchroundData(selectCount);
  };

  const clearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectCount(0);
    fetchroundData(0);
  };

  const deleteClick = () => {
    fetchData({
      url: "api/admins/rounds/" + id,
      method: "delete",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setId("");
          setMessage(res.data.message);
          setIsModalOpen(false);
        } else {
          setId("");
          setMessage(res.data.message);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  // Invoke when user click to request another page.
  const handlePageClick = (selectedItem: { selected: number }) => {
    setIsLoading(true);
    setSelectCount(selectedItem.selected);
    fetchroundData(selectedItem.selected);
  };
  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Round List</h5>
            {permissionIds?.includes("CREATE_ROUND") ? (
              <Link to="/round/create" className="btn btn-primary">
                Create
              </Link>
            ) : (
              <></>
            )}
            {!id && message ? (
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            ) : (
              <></>
            )}
            <div className="container p-t-10 p-b-10">
              <div className="row p-t-10 p-b-10">
                <div className="col">
                  <label htmlFor="startdate" className="control-label p-r-10">
                    Start Date
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="yyyy-MM-dd"
                    className="form-control fs-16 w-140"
                  />
                </div>
                <div className="col">
                  <label htmlFor="enddate" className="control-label p-r-10">
                    End Date
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="yyyy-MM-dd"
                    className="form-control fs-16 w-140"
                  />
                </div>
              </div>
              <div className="row p-t-10 p-b-10">
                <div className="col-3">
                  <button onClick={() => Search()} className="btn btn-success">
                    Search
                  </button>
                </div>
                <div className="col-3">
                  <button
                    onClick={() => clearSearch()}
                    className="btn btn-secondary"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <Gridtable headerdata={headerdata} customClass="">
              {isLoading ? (
                <Loading></Loading>
              ) : (
                bodydata.map((body, i) => (
                  <tr key={i}>
                    <td>{body?.date}</td>
                    <td>{body?.endTime}</td>
                    <td>
                      {body?.gameTypeId?.name}-{body?.gameTypeId?.roundName}
                    </td>
                    <td>{body?.winningNumber}</td>
                    <td>{body?.tootNumbers}</td>
                    <td>
                      <div>
                        {permissionIds?.includes("EDIT_ROUND") ? (
                          <Link to={`/round/edit/${body._id}`} className="px-2">
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        ) : (
                          <></>
                        )}
                        {permissionIds?.includes("VIEW_ROUND") ? (
                          <Link to={`/round/detail/${body._id}`}>
                            <i className="bi bi-info-square"></i>
                          </Link>
                        ) : (
                          <></>
                        )}
                        {permissionIds?.includes("DELETE_ROUND") ? (
                          <div
                            className="px-2"
                            onClick={() =>
                              deleteConfirm(
                                body?._id,
                                body?.gameTypeId?.name,
                                body?.gameTypeId?.time
                              )
                            }
                          >
                            <i className="bi bi-trash"></i>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </Gridtable>
            {pageCount > 1 ? (
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal title="Round Delete" isOpen={isModalOpen} onClose={closeModal}>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-12">{message}</div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-3">
              <div onClick={() => deleteClick()} className="btn btn-primary">
                Ok
              </div>
            </div>
            <div className="col-3">
              <div onClick={() => closeModal()} className="btn btn-secondary">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RoundList;
