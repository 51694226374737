import useSWR from "swr";
import { API_NAME } from "../../constant";
import {
  getPermissionListApi,
  getRoleByIdApi,
  getRoleListApi,
} from "../../role";

export const useGetRoleList = () => {
  return useSWR(
    [API_NAME.role_list_api],
    () => getRoleListApi(),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetRoleById = (id: string) => {
  return useSWR(
    id ? [API_NAME.role_detail_api, id] : null,
    () => getRoleByIdApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetPermissionList = () => {
  return useSWR(
    [API_NAME.permission_list_api],
    () => getPermissionListApi(),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
