import React from "react";
import {  useNavigate } from "react-router-dom";

import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import EditTermConditionForm from "../../forms/EditTermConditionForm";

const TermsEdit: React.FC = () => {


  const navigate = useNavigate();

  return (
    <>
      <div className="pl-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.setting_termconditions_list)}
        />
      </div>
      <div className="pl-5">
        <EditTermConditionForm />
      </div>
    </>
  );
};

export default TermsEdit;
