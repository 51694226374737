import React, { useState } from "react";
import { CONTENT } from "../../content";
import { useGetBetDetail } from "../../api/funs/rounds";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userDetailAtom } from "../../store";
import dayjs from "dayjs";
import { timeDateMMFun } from "../../util/funs/timeDateMMFun";

const BetDetailTable = () => {
  const { id } = useParams();
  
  const [userDetailData, ] = useAtom(userDetailAtom);

  const [pagination, ] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: betDetail } = useGetBetDetail(
    id as string,
    pagination,
    "two-d"
  );

  // console.log("GGG",dayjs(betDetail?.data?.data?.transactionData?.createdAt).format( "DD MMM YYYY hh:mm:ss A"))

  // console.log("GGG",.tz('Asia/Yangon'))

  return (
    <>
      {betDetail && (
        <table className="w-full my-4 table-auto">
          <tbody className="text-center border border-steam">
            {CONTENT.bet_list.array.map((_d, i) => (
              <tr key={i}>
                <td className="py-3 border border-steam">{_d}</td>
                <td className="py-3 font-semibold capitalize border border-steam">
                  {i === 0
                    ? timeDateMMFun(betDetail?.data?.data?.transactionData?.createdAt).format( "DD MMM YYYY hh:mm:ss A")
                    : i === 1
                    ? betDetail?.data?.data?.roundData?.gameType
                    : i === 2
                    ? betDetail?.data?.data?.roundData?.roundName
                    : i === 3
                    ? userDetailData?.agentId?.userCode
                    : i === 4
                    ? betDetail?.data?.data?.userData?.phone
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default BetDetailTable;
