import useSWRMutation from "swr/mutation";

import {
  createAnnouncedAfterApi,
  createAnnouncedApi,
  patchStatusRoundApi,
  updateRoundsByIdApi,
} from "../../rounds";
import { useGetPreviousResultList, useGetRoundList } from "../../funs/rounds";
import moment from "moment";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { modalAtom } from "../../../store";
import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";

export function useCreateAnnounced(type: string, roundId: string) {
  // const { mutate } = useGetFaqList(pagination);
  const { mutate } = useGetPreviousResultList(
    type,
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  );
  return useSWRMutation(
    `/api/admins/winning/${type}/add/${roundId}`,
    createAnnouncedApi,
    {
      throwOnError: false,
      onError: () => {
        toast.error("Something wrong.", {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {

        if (d?.data?.isSuccess) {
          toast.success("Successfully Created.", {
            position: "bottom-right",
          });
        }else{
          toast.info(d?.data?.message, {
            position: "bottom-right",
          });
        }
      
        mutate();
        // navigate(RouteName.setting_faqs_list);
      },
    }
  );
}
export function useCreateAnnouncedAfter(type: string, roundId: string) {
  // const { mutate } = useGetFaqList(pagination);
  // const { mutate } = useGetPreviousResultList(
  //   "two-d",
  //   moment().format("YYYY-MM-DD"),
  //   moment().format("YYYY-MM-DD")
  // );
  return useSWRMutation(
    `/api/admins/winning/${type}/announce/after/${roundId}`,
    createAnnouncedAfterApi,
    {
      throwOnError: false,
      onError: () => {
        toast.error("Something wrong.", {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
       

        if (d.data.isSuccess) {
          // mutate();

        }
        // navigate(RouteName.setting_faqs_list);
      },
    }
  );
}

export function usePatchRoundStatus(id?: string) {
  const [, setIsOpenModal] = useAtom(modalAtom);

  const { mutate } = useGetRoundList("2d");

  return useSWRMutation(
    id ? `/api/admins/rounds/${id}/status-change` : null,
    patchStatusRoundApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Updated.", {
          position: "bottom-right",
        });

        mutate();

        setIsOpenModal({ isOpen: false });
      },
    }
  );
}
export function useUpdateRound(id: string) {
  const { mutate } = useGetRoundList("3d");
  const navigate=useNavigate()

  return useSWRMutation(
    id ? `/api/admins/rounds/${id}` : null,
    updateRoundsByIdApi,
    {
      throwOnError: false,
      onError: (e) => {
        toast.error(e?.response?.data?.msg || e?.response?.data?.message, {
          position: "bottom-right",
        });
      },
      onSuccess: (d) => {
        toast.success("Successfully Updated.", {
          position: "bottom-right",
        });

        mutate();
        navigate(RouteName.three_roundsetting)
      },
    }
  );
}
