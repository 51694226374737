export function getUser() {
    return localStorage.getItem('user')?localStorage.getItem('user'):undefined;
}


export async function setUser(value:any) {
    await localStorage.setItem("user", value);
}

export function getAccessToken() {
    return localStorage.getItem('access_token')?localStorage.getItem('access_token'):undefined;
}


export async function setAccessToken(value:any) {
    await localStorage.setItem("access_token", value);
}
export function getPermissionIds() {
    return localStorage.getItem('permission')?localStorage.getItem('permission'):undefined;
}


export async function setPermissionIds(value:any) {
    await localStorage.setItem("permission", value);
}

export function getSuperAdmin() {
    return localStorage.getItem('super_admin')?localStorage.getItem('super_admin'):undefined;
}

export async function setSuperAdmin(value:any) {
    await localStorage.setItem("super_admin", value);
}


export async function setPreviousResult(value:any) {
    await localStorage.setItem("previous-result", value);
}
export function getPreviousResult() {
    return localStorage.getItem('previous-result')?localStorage.getItem('previous-result'):{};
}
