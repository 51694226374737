import React from "react";

import { useDashboardCounts } from "../../../api/funs/dashoard";
import { CONTENT } from "../../../content";
import { kmbconvertFun } from "../../../util/funs/kmbconvertFun";
import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";
const Card = () => {
  const { data: count } = useDashboardCounts();
  const navigate =useNavigate()

  return (
    <div className="grid my-3 md:grid-cols-4 gap-x-5 gap-y-2 md:gap-y-0">
      {CONTENT.dashboard.cardA.map((_, i) => (
        <div
          key={i}
          className={`flex items-center w-full px-4 py-4 bg-white gap-x-5 rounded-xl ${[0,1].includes(i)? "cursor-pointer": ""}`}

          onClick={() => {
            if (i===0) {
              navigate(RouteName.users_userlist)
            } else if (i===1) {
              navigate(RouteName.agents_list)

            } else {
              
            }
          }}
        >
          <img src={_?.img} alt={_?.text} />
          <div>
            <span className="block mb-2">{_?.text}</span>
            <span className="block text-2xl font-semibold">
              {count
                ? i === 0
                  ? kmbconvertFun(count?.data?.data?.totalUsers)
                  : // ? count?.data?.data?.totalUsers
                  i === 1
                  ? kmbconvertFun(count?.data?.data?.totalAgents)
                  : i === 2
                  ? kmbconvertFun(count?.data?.data?.betLists)
                  : i === 3
                  ? kmbconvertFun(count?.data?.data?.todayBetLists)
                  : ""
                : "--"}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
