import { AmountMMKCell } from "../../cell/AmountMMKCell";

import BetOneNumberCell from "../../cell/BetOneNumberCell";

export const betsCol = [
  {
    accessorKey: "bet_number", //simple recommended way to define a column
    header: "Number",
    Cell: BetOneNumberCell,
  },

  {
    accessorKey: "bet_amount", //simple recommended way to define a column
    header: "Bet Amount",
    Cell: AmountMMKCell,
  },
  {
    accessorKey: "profile_time", //simple recommended way to define a column
    header: "Profit Time",
  },
];
