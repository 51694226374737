import useSWR from "swr";
import {
  getDashboardOverviewListApi,
  getTopAgentListApi,
} from "../../dashboard";
import { API_NAME } from "../../constant";

export const useDashboardCounts = () => {
  return useSWR(
    API_NAME.dashboard_counts_list_api,
    getDashboardOverviewListApi
   
  );
};
export const useGetTopAgentList = (type?: string) => {
  return useSWR(
    type ? ["top-agent", type] : null,
    () => getTopAgentListApi(type),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
