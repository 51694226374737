import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";
import {
  useGetPreviousResultList,
  useGetRoundList,
} from "../../../api/funs/rounds";
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";

import { CONTENT } from "../../../content";

const BetWinningCard = () => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const [round, setRound] = useState({
    label: "",
    value: "",
  });
  const [betType, setBetType] = useState<{
    label: string;
    value: string;
  }>({
    label: "2D",
    value: "2d",
  });

  const { data: roundsList } = useGetRoundList(betType?.value as string);
  useEffect(() => {
    if (roundsList) {
      setRound({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
    }
  }, [roundsList]);

  const { data: previousResults } = useGetPreviousResultList(
    betType?.value === "2d" ? "two-d" : "three-d",

    // moment().format("2024-04-27"),
    // moment().format("2024-04-27")
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  );

  const result = previousResults?.data?.data?.find(
    (_e: any) => _e?.roundId?.roundName === round?.label
  );

  const selectRound =
    betType?.value === "2d"
      ? roundsList?.data?.data?.find(
          (_t: any) => _t?.roundName === round?.label
        )
      : roundsList?.data?.data?.[0];

  return (
    <div className="w-full px-3 py-3 bg-white rounded-md">
      <div className="flex justify-between">
        <div className="text-xs md:text-[14px] font-bold">
          <span className="block">Today 2D Result</span>
          <span>
            {moment().format("DD MMM YYYY")}, {round?.label}
          </span>
        </div>
        <div className="flex flex-col items-end justify-end gap-y-3 md:gap-y-0 md:flex-row md:gap-x-2">
          {roundsList && betType?.value === "2d" && (
            <Autocomplete
              id="combo-box-demo"
              onChange={(e, value) => {
                setRound(value as any);
              }}
              value={round}
              sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
              options={roundsList?.data?.data?.map((_e: any, i: any) => ({
                label: _e?.roundName,
                value: _e?._id,
              }))}
              defaultValue={{
                label: roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))?.[0]?.label,
                value: roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))?.[0]?.value,
              }}
              getOptionLabel={(option) => option?.label}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }

              style={{ width: isSmallScreen? 100:160, }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  color="error"
                  {...params}
                  label="Select Round"
                  size="small"
                  defaultValue={round?.value}
                  variant="outlined"
                />
              )}
            />
          )}{" "}
          <Autocomplete
            id="combo-box-demo"
            onChange={(e, value) => {
              setBetType(value as any);
            }}
            sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
            value={betType}
            options={CONTENT.dashboard.betTypeA}
            defaultValue={{
              label: CONTENT.dashboard.betTypeA?.[0]?.label,
              value: CONTENT.dashboard.betTypeA?.[0]?.value,
            }}
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            style={{ width: 100 }}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                color="error"
                {...params}
                label="Select Round"
                size="small"
                defaultValue={betType?.value}
                variant="outlined"

            
             
              />
            )}
          />
        </div>
      </div>
      {!result && (
        <p className=" mt-4 text-dim_grey text-[12px]">Coming soon!</p>
      )}
      <div className="grid items-center w-full grid-cols-2 mt-10 mb-5 ">
        {betType?.value === "2d" ? (
          <div className="flex gap-x-2">
            <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
              {result?.winningNumber?.[0] || "-"}
            </div>
            <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
              {result?.winningNumber?.[1] || "-"}
            </div>
          </div>
        ) : (
          <div className="flex gap-x-2">
            <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
              {result?.winningNumber?.[0] || "-"}
            </div>
            <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
              {result?.winningNumber?.[1] || "-"}
            </div>
            <div className="flex justify-center items-center w-[40px] h-[50px] bg-vermilion_bird rounded-[10px] text-white text-[45px] font-semibold">
              {result?.winningNumber?.[2] || "-"}
            </div>
          </div>
        )}

        {betType?.value === "2d" && (
          <div className="flex gap-x-[30%]">
            <div>
              <span className="text-dim_gray text-[12px]">Set</span>
              <br />
              <span className="font-bold">{result?.set || "--"}</span>
            </div>
            <div>
              <span className="text-dim_gray text-[12px]">Value</span>
              <br />
              <span className="font-bold">{result?.value || "--"}</span>
            </div>
          </div>
        )}
      </div>
      <div>
        {result ? (
          <>
            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-[10.5px] md:text-[14px]">
                Announce Result opened at{" "}
                {moment(result?.resultDate).format("DD MMM YYYY")} at{" "}
                {moment(selectRound?.announceTime, "HH:mm")?.format(
                  "hh:mm:ss a"
                )}
              </span>
            </span>
            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-[10.5px] md:text-[14px]">
                Bet opened at{" "}
                {["ROUND 1", "ROUND 2"].includes(selectRound?.roundName)
                  ? moment(selectRound?.resultDate)
                      .subtract(1, "days")
                      .format("DD MMM YYYY")
                  : moment(selectRound?.resultDate).format("DD MMM YYYY")}{" "}
                at{" "}
                {moment(selectRound?.openTime, "HH:mm")?.format("hh:mm:ss a")}
              </span>
            </span>
            <span className={`flex items-center mb-2 gap-x-1 `}>
              <MdCheckCircle className="text-green-500 text-[12px]" />
              <span className="text-[10.5px] md:text-[14px]">
                Bet closed at {moment(result?.resultDate).format("DD MMM YYYY")}{" "}
                at {moment(selectRound?.endTime, "HH:mm")?.format("hh:mm:ss a")}
              </span>
            </span>
          </>
        ) : (
          <>
            {betType?.value === "2d" &&
            moment().subtract(1, "d") &&
            moment(selectRound?.openTime, "HH:mm")?.format("hh:mm:ss a") <
              selectRound?.endTime &&
            ["ROUND 1", "ROUND 2"].includes(selectRound?.roundName) ? (
              <>
                <span className="flex items-center gap-x-1">
                  <MdCheckCircle className="text-green-500 text-[12px]" />
                  <span className="text-[10.5px] md:text-[14px]">
                    Bet will be opened at {moment().format("DD MMM YYYY")} at{" "}
                    {moment(selectRound?.openTime, "HH:mm")?.format(
                      "hh:mm:ss a"
                    )}
                  </span>
                </span>
              </>
            ) : (
              <span className="flex items-center gap-x-1">
                <AiFillInfoCircle className="text-dim_grey text-[12px]" />
                <span className="text-[10.5px] md:text-[14px]">
                  Bet will be opened at {moment().format("DD MMM YYYY")} at{" "}
                  {moment(selectRound?.openTime, "HH:mm")?.format("hh:mm:ss a")}
                </span>
              </span>
            )}

            <span className="flex items-center gap-x-1">
              <AiFillInfoCircle className="text-dim_grey text-[12px]" />
              <span className="text-[10.5px] md:text-[14px]">
                Bet will be closed at{" "}
                {betType?.value === "2d"
                  ? moment().format("DD MMM YYYY")
                  : moment(selectRound?.endDate).format("DD MMM YYYY")}{" "}
                at {moment(selectRound?.endTime, "HH:mm")?.format("hh:mm:ss a")}
              </span>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default BetWinningCard;
