import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouteObject } from "react-router-dom";
import Login from "../pages/login";

import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/pagenotfound";

import AdminList from "../pages/admin/adminlist";
import AdminCreate from "../pages/admin/create";
import AdminDetail from "../pages/admin/detail";
import AdminEdit from "../pages/admin/edit";

import AgentList from "../pages/agent/agentlist";
import AgentCreate from "../pages/agent/create";

import HolidayList from "../pages/holiday/holidaylist";
import HolidayCreate from "../pages/holiday/create";
import HolidayEdit from "../pages/holiday/edit";

import GameTypesList from "../pages/gametypes/gametypeslist";
import GameTypesEdit from "../pages/gametypes/edit";
import GameTypesCreate from "../pages/gametypes/create";
import GameTypesDetail from "../pages/gametypes/detail";

import RoundList from "../pages/round/roundlist";
import RoundCreate from "../pages/round/create";
import RoundEdit from "../pages/round/edit";
import RoundDetail from "../pages/round/detail";

import { RouteGuard } from "../components/RouteGuard";
import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

import TwoDList from "../pages/twod/twodlist";
import TwoDPreviousResult from "../pages/twod/previousresult";
import TwoDRoundSetting from "../pages/twod/roundsetting";
import TwoDSummary from "../pages/twod/summary";

import ThreeDList from "../pages/threed/threedlist";

import ThreeDSummary from "../pages/threed/summary";

import UserList from "../pages/user/userlist";
import UserDetail from "../pages/user/detail";

import NotificationList from "../pages/notifications/notificationlist";
import NotificationCreate from "../pages/notifications/create";

import TermList from "../pages/termsandcondition/list";
import TermCreate from "../pages/termsandcondition/create";

import PrivacyPolicyList from "../pages/privacypolicy/list";
import PrivacyPolicyCreate from "../pages/privacypolicy/create";
import PrivacyPolicyEdit from "../pages/privacypolicy/edit";

import FAQsList from "../pages/faqs/list";
import FAQsCreate from "../pages/faqs/create";
import FAQsEdit from "../pages/faqs/edit";
import NotificationDetail from "../pages/notifications/NotificationDetail";
import ProfilePage from "../pages/ProfilePage";
import TwoDBetDetailPage from "../pages/twod/TwoDBetDetailPage";
import TwodPreviousWinnerListPage from "../pages/twod/TwodPreviousWinnerListPage";
import ThreeDBetDetailPage from "../pages/twod/ThreeDBetDetailPage";
import ThreeDPreviousresultPage from "../pages/twod/ThreeDPreviousresultPage";
import ThreedPreviousWinnerListPage from "../pages/twod/ThreedPreviousWinnerListPage";
import ThreeDRoundSettingPage from "../pages/twod/ThreeDRoundSettingPage";
import ReportPage from "../pages/report/ReportPage";
import ReportTwoDDailyPage from "../pages/report/ReportTwoDDailyPage";
import ReportTwoDMonthlyPage from "../pages/report/ReportTwoDMonthlyPage";
import ReportTwoDAnnualPage from "../pages/report/ReportTwoDAnnualPage";
import ReportThreeDMonthlyPage from "../pages/report/ReportThreeDMonthlyPage";
import ReportThreeDAnnualPage from "../pages/report/ReportThreeDAnnualPage";
import TermsEdit from "../pages/termsandcondition/edit";
import RoleListsPage from "../pages/admin/role/RoleListsPage";
import RoleCreatePage from "../pages/admin/role/RoleCreatePage";
import RoleUpdatePage from "../pages/admin/role/RoleUpdatePage";
import AgentDetail from "../pages/agent/detail";
import AgentUpdate from "../pages/agent/update";
import CustomizeModal from "../components/ui/modal/CustomizeModal";
import EditProfilePage from "../pages/EditProfilePage";
import WinningUserDetailPage from "../pages/user/WinningUserDetailPage";
import PageLayout from "../layout/mainlayouts/PageLayout";
import CreateMaintenancePage from "../pages/maintenance/CreateMaintenancePage";
import ThreeDEditRoundSettingPage from "../pages/twod/ThreeDEditRoundSettingPage";
import CreateAdsBannerPage from "../pages/ads-banner/CreateAdsBannerPage";
import CreateAppAdsPage from "../pages/app-ads/CreateAppAdsPage";
import ThreeDGenerateInvoicePage from "../pages/threed/GenerateInvoicePage";

export const RootRouter = () => {
  const { authenticated } = useContext(AuthContext);

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <Dashboard />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "dashboard",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <Dashboard />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        // {
        //   path: "setting/termconditions/list",
        //   element: (
        //     <RouteGuard auth={authenticated}>
        //       <TermList />
        //     </RouteGuard>
        //   ),
        // },
        {
          path: "setting/termconditions/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TermCreate />

                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/termconditions/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TermList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/termconditions/:lang",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TermsEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/privacypolicy/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <PrivacyPolicyList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/privacypolicy/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <PrivacyPolicyCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/privacypolicy/edit/:lang",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <PrivacyPolicyEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/faqs/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <FAQsList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/faqs/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <FAQsCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/faqs/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <FAQsEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "profile",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ProfilePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "profile/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <EditProfilePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AdminList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AdminCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AdminDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AdminEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "agents/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AgentList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "agent/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AgentDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/role/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoleListsPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/role/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoleCreatePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "admins/role/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoleUpdatePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "agents/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AgentCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "agents/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <AgentUpdate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "gametypes/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <GameTypesList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "gametypes/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <GameTypesCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "gametypes/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <GameTypesDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "gametypes/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <GameTypesEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/closedays/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <HolidayList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/closedays/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <HolidayCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/closedays/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <HolidayCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "setting/closedays/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <HolidayEdit />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "round/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoundList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "round/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoundCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "round/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoundDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "round/edit/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <RoundEdit initialNumbers={[]} />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/betlist/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwoDList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/betlist/user/:userId/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwoDBetDetailPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/previousresults/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDPreviousresultPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/previousresults/winnerlist",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwodPreviousWinnerListPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/roundsettings/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwoDRoundSetting />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/summary/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwoDSummary />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/betlist/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/betlist/user/:userId/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDBetDetailPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "twod/previousresults/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <TwoDPreviousResult />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/previousresults/winnerlist",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreedPreviousWinnerListPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "users/userlist/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <UserList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "users/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <UserDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "users/detail/:id/winning/:resultId",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <WinningUserDetailPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },

        {
          path: "threed/roundsettings/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDRoundSettingPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/roundsettings/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDRoundSettingPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/roundsettings/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDEditRoundSettingPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "threed/summary/",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDSummary />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "notifications/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <NotificationList />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "notifications/create",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <NotificationCreate />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },

        {
          path: "notifications/detail/:id",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <NotificationDetail />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },

        {
          path: "report/list",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "report/two_d/daily_report",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportTwoDDailyPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "report/two_d/monthly_report",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportTwoDMonthlyPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "report/two_d/annual_report",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportTwoDAnnualPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "report/three_d/monthly_report",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportThreeDMonthlyPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "report/three_d/annual_report",
          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ReportThreeDAnnualPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/setting/maintenance",

          element: (
            <RouteGuard auth={authenticated}>
              <>
                <CreateMaintenancePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "/setting/app-ad",

          element: (
            <RouteGuard auth={authenticated}>
              <>
                <CreateAppAdsPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "/setting/ads-banner",

          element: (
            <RouteGuard auth={authenticated}>
              <>
                <CreateAdsBannerPage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        {
          path: "/threed/generate-invoice",

          element: (
            <RouteGuard auth={authenticated}>
              <>
                <ThreeDGenerateInvoicePage />
                <CustomizeModal />
              </>
            </RouteGuard>
          ),
        },
        { path: "*", element: <PageNotFound /> },
      ],
    },
    // {
    //   loader: async () => {
    //       if (authenticated) {
    //           return redirect("/dashboard");
    //       }
    //       return null;
    //   },
    //   path: "/login",
    //   element: <Login />
    // },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default RootRouter;
