import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  AnswerIcon,
  ArrowSwapHorizontalIcon,
  QuestionIcon,
} from "../../pages/common/Icon";
import BackArrowButton from "../../components/ui/buttons/BackArrowButton";
import { RouteName } from "../../app/constants/route-constant";
import { Controller, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useGetFaqById } from "../../api/funs/faq";
import { useUpdateFaq } from "../../api/mutation/faq";

const Edit: React.FC = () => {
  const { id } = useParams();
  const { data: faqDetail } = useGetFaqById(id);

  const navigate = useNavigate();

  const { control, handleSubmit, formState, reset } = useForm<{
    question: string;
    answer: string;
  }>({
    values: {
      question: faqDetail?.data?.data?.faq?.question,
      answer: faqDetail?.data?.data?.faq?.answer,
    },
    defaultValues: {
      question: "",
      answer: "",
    },
  });
  const { errors } = formState;
  const { trigger, isMutating } = useUpdateFaq(id);
  const onSubmit = (data: any) => {
    trigger(data);
    reset();
  };
  return (
    <>
      <div className="pl-5">
        <BackArrowButton
          handleClick={() => navigate(RouteName.setting_faqs_list)}
        />
      </div>
      <form className="pl-5" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {" "}
          <label className="mb-3 text-[12px] font-semibold">
            Language{" "}
            {faqDetail?.data?.data?.faq?.language === "en"
              ? "English"
              : faqDetail?.data?.data?.faq?.language === "zh"
              ? "Chinese"
              : "Myanmar"}
          </label>
          <div className="flex items-center gap-x-5">
            <Controller
              control={control}
              name="question"
              rules={{
                required: {
                  value: true,
                  message: "Question is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <QuestionIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.question}
                    label="Question"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.question?.message &&
                          errors?.question?.message}
                      </span>
                    }
                  />
                );
              }}
            />
            <ArrowSwapHorizontalIcon className="w-12 h-12" />
            <Controller
              control={control}
              name="answer"
              rules={{
                required: {
                  value: true,
                  message: "Answer is required.",
                },
              }}
              render={({ field, formState, fieldState }) => {
                return (
                  <TextField
                    autoComplete="off"
                    color="error"
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AnswerIcon className="w-6 h-6" />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors?.answer}
                    label="Answer"
                    fullWidth
                    sx={{
                      marginBottom: "10px",
                    }}
                    helperText={
                      <span className="whitespace-nowrap">
                        {!!errors?.answer?.message && errors?.answer?.message}
                      </span>
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div></div>
          <button
            type="submit"
            className={`btn-create-active ${
              isMutating ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={isMutating}
          >
            {isMutating ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Update"
            )}
          </button>
        </div>
        <DevTool control={control} />
      </form>
    </>
  );
};

export default Edit;
