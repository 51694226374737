import React from 'react'
import { format } from "date-fns";

const DateFormatCell = ({renderedCellValue,row}:any) => {
  return (
      <>
                {
              renderedCellValue && <> <span className={`text-[14px] font-medium ${row?.original?.dateColor?"text-vermilion_bird" : "text-dynamic_black"} `}> {format( new Date(renderedCellValue), "dd MMM  yyyy")}</span>
             </>
    }

      </>
  )
}

export default DateFormatCell