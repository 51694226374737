import CryptoJS from "crypto-js";

export const decryptHashedData = (hashData: string, key: string) => {
  const decryptData = hashData && CryptoJS.AES.decrypt(hashData, key);
  return JSON.parse(decryptData?.toString(CryptoJS.enc.Utf8));
};

export const permissionValidate = (
  permissionA?: {
    label: string;
    value: string;
    _id: string;
  }[],
  permissionName?: string
) => {
  const permissionSA = permissionA
    ?.map((_) => _?.value)
    ?.includes(permissionName as string);

  return permissionSA;
};
