import useSWR from "swr";
import { API_NAME } from "../../constant";
import {
  getUserListApi,
  getUserDetailApi,
  getUserByTransactionHistory,
  getUserDetailOverviewApi,
  getUserByBetHistory,
  getUserByWinningHistory,
  getUserDetailWinningApi,
} from "../../user";
import { getIpAddressApi } from "../../auth";

export const useGetUserList = (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  globalFilter: string
) => {
  return useSWR(
    [API_NAME.user_list_api, pageIndex, pageSize, globalFilter],
    () => getUserListApi({ pageIndex, pageSize }, globalFilter),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetUserById = (id: string | null) => {
  return useSWR(
    [API_NAME.user_overview_api, id],
    () => getUserDetailApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetUserByTransactionHistory = (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  }
) => {
  return useSWR(
    [API_NAME.user_transaction_api, id, pageIndex, pageSize],
    () => getUserByTransactionHistory(id, { pageIndex, pageSize }),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetUserByWinningHistory = (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string
) => {
  return useSWR(
    [API_NAME.user_winning_transaction_api, id, pageIndex, pageSize, type],
    () => getUserByWinningHistory(id, { pageIndex, pageSize }, type),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetUserByBetHistory = (
  id: string | null,
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  },
  type: string,
  globalFilter: string
) => {
  return useSWR(
    [
      API_NAME.user_twd_bet_history_api,
      id,
      pageIndex,
      pageSize,
      type,
      globalFilter,
    ],
    () => getUserByBetHistory(id, { pageIndex, pageSize }, type, globalFilter),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};

export const useGetUserByOverview = (id?: string) => {
  return useSWR(
    id ? [API_NAME.user_by_overview_api, id] : null,
    () => getUserDetailOverviewApi(id),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetUserDetailWinning = (
  userId?: string,
  resultId?: string,
  type?: string,
  tdType?: string
) => {
  return useSWR(
    userId && resultId
      ? [API_NAME.user_by_overview_api, userId, resultId, type, tdType]
      : null,
    () => getUserDetailWinningApi(userId, resultId, type, tdType),
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
export const useGetIpAddress = () => {
  return useSWR(
    ["get_ip_address"],
    getIpAddressApi,
    // getDashboardOverviewListApi,
    {
      revalidateOnFocus: false,
    }
  );
};
