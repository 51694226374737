import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./style/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { AuthContextProvider } from "./contexts/authContext";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Provider as JotaiProvider, createStore } from "jotai";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Toast from "@radix-ui/react-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const customStore = createStore();

root.render(
  <Toast.Provider swipeDirection="up">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <JotaiProvider store={customStore}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
        <ToastContainer />
        {/* <JotailDevtool store={customStore} /> */}
      </JotaiProvider>
    </LocalizationProvider>
  </Toast.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
