import React from "react";

const IOSSwitch = ({
  value,
  handleClick,
}: {
  value: boolean;
  handleClick: () => void;
}) => {
  return (
    <div
      onClick={handleClick}
      className="relative inline-block w-10 align-middle transition duration-200 ease-in select-none md:w-11"
    >
      <div
        className={` ${
          !value ? "border-gray-400" : "right-0 border-grass_green"
        } absolute block h-6 w-6 ${
          false ? "cursor-not-allowed" : "cursor-pointer"
        } appearance-none rounded-full border-4 bg-white outline-none`}
      />
      <div
        className={`${!value ? "bg-gray-400" : "bg-grass_green"} block h-6 ${
          false ? "cursor-not-allowed" : "cursor-pointer"
        } overflow-hidden rounded-full `}
      ></div>
    </div>
  );
};

export default IOSSwitch;
