"use client";
import React, { useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Stack } from "@mui/material";

// import Image from "next/image";
// import { Icons } from "../ui/Icons";
// import Link from "next/link";
// import { usePathname, useRouter } from "next/navigation";
// import { cn } from "@/utils/cn";
// import Breadcrumb from "../ui/Breadcrumb";
// import { useSessionLogout } from "@/lib/session";
// import { Button } from "../ui/Button";
// import { BUTTON_TYPE } from "@/utils/enums";
// import Dialog from "../ui/Dialog";
// import { menuList } from "@/constant/menuList";
// import { getAgentData } from "@/lib/getToken";
// import { UserInfoData } from "@/types/auth";
import { menuList } from "./MenuList";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowDownActiveIcon,
  ArrowDownIcon,
  ArrowUpWhiteIcon,
  LogoIcon,
} from "../../pages/common/Icon";
import { RouteName } from "../../app/constants/route-constant";
import DrawHeaderC from "./DrawHeaderC";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "white",
  boxShadow: "none",
  alignItems: "start",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  padding: "0 20px",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "variant" && prop !== "sx" && prop !== "open",
  name: "MyThemeComponent",
  slot: "Root",
  // We are specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "primary" && styles.primary,
    props.color === "secondary" && styles.secondary,
  ],
})<AppBarProps>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // height: 100,
  position: "relative",
  backgroundColor: open ? "#F44336" : "",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
 
  width: drawerWidth,
 
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Drawer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  
  const { data: profileInfo } = useProfileInfo();

  const [openDrawer, setDrawerOpen] = useState(false);

  const [openItems, setOpenItems] = useState<number[]>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  permissionValidate(
    profileInfo?.data?.data?.roleId?.permissionIds,
    CONTENT.permission_const.DASHBOARD_MENU_VIEW
  );

  const handleClick = (index: number) => {
    const isChild = menuList.some(
      (menu) =>
        menu.children &&
        menu.children.some((child) => child.link === menuList[index].link)
    );

    if (isChild) {
      setOpenItems((prevOpenItems) => {
        const currentIndex = prevOpenItems.indexOf(index);
        if (currentIndex !== -1) {
          // If the child is already open, close it
          return [
            ...prevOpenItems.slice(0, currentIndex),
            ...prevOpenItems.slice(currentIndex + 1),
          ];
        } else {
          // If the child is not open, close other children and open this one
          const parentIndex = menuList.findIndex(
            (menu) =>
              menu.children &&
              menu.children.some((child) => child.link === menuList[index].link)
          );
          return [parentIndex, index];
        }
      });
    } else {
      setOpenItems((prevOpenItems) =>
        prevOpenItems.includes(index) ? [] : [index]
      );
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        className="w-screen shadow-none bg-gray-50"
        position="fixed"
        open={openDrawer}
      >
        <div className="flex w-full gap-x-4">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: 1,
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon className="!text-black" />
          </IconButton>
          <DrawHeaderC />
        </div>
      </AppBar>

      <DrawerStyled variant="permanent" open={openDrawer}>
        <DrawerHeader open={openDrawer}>
          <Box
            sx={{
              width: "fit-content",

              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            <div className="flex items-center ml-2 text-white gap-x-2">
              <LogoIcon></LogoIcon> AIO Admin
            </div>
          </Box>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className="text-white" />
            ) : (
              <ChevronLeftIcon className="text-white" />
            )}
          </IconButton>
        </DrawerHeader>

        <Stack className="flex-col justify-between h-full">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {menuList.map((menu, key) => (
              <>
                {(key === 0 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.DASHBOARD_MENU_VIEW
                  )) ||
                (key === 1 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.USER_MENU_VIEW
                  )) ||
                (key === 2 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.twoD_MENU_BET_LIST
                  )) ||
                (key === 3 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.threeD_MENU_BET_LIST
                  )) ||
                (key === 4 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.ADMIN_MENU_VIEW
                  )) ||
                (key === 5 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.AGENT_MENU_VIEW
                  )) ||
                (key === 6 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.NOTIFICATION_MENU_VIEW
                  )) ||
                (key === 7 &&
                  !permissionValidate(
                    profileInfo?.data?.data?.roleId?.permissionIds,
                    CONTENT.permission_const.REPORT_MENU_OVERALL
                  )) ? (
                  <></>
                ) : (
                  <React.Fragment key={key}>
                    <ListItemButton
                      sx={{ mb: 1, borderRadius: "10px" }}
                      selected={
                        pathname === menu?.link || openItems.includes(key)
                      }
                      className={` flex justify-center
                   ${
                     (pathname === menu?.link || openItems.includes(key)) &&
                     "!bg-vermilion_bird text-white"
                   }`}
                      onClick={() => {
                        navigate(menu.link);
                        handleClick(key);
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "fit-content", pr: openDrawer ? 2 : 0 }}
                      >
                        {pathname === menu?.link || openItems.includes(key)
                          ? menu?.activeIcon
                          : menu.icon}
                      </ListItemIcon>
                      {openDrawer && (
                        <>
                          <ListItemText primary={menu.label} />
                          {menu?.children?.length > 0 &&
                            (openItems.includes(key) ? (
                              <ArrowUpWhiteIcon fontSize={"large"} />
                            ) : (
                              <>
                                {pathname === menu?.link ||
                                openItems.includes(key) ? (
                                  <ArrowDownActiveIcon />
                                ) : (
                                  <ArrowDownIcon />
                                )}
                              </>
                            ))}
                        </>
                      )}
                    </ListItemButton>
                    {menu?.children?.length > 0 && (
                      <Collapse
                        in={openItems.includes(key)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {menu?.children?.map((child, i) => (
                            <>
                              {(key === 2 &&
                                i === 0 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.twoD_MENU_BET_LIST
                                )) ||
                              (key === 2 &&
                                i === 1 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .twoD_MENU_PREVIOUS_RESULTS
                                )) ||
                              (key === 2 &&
                                i === 2 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .twoD_MENU_ROUND_SETTING
                                )) ||
                              (key === 2 &&
                                i === 3 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.twoD_MENU_SUMMARY
                                )) ||
                              (key === 3 &&
                                i === 0 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.threeD_MENU_BET_LIST
                                )) ||
                              (key === 3 &&
                                i === 1 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .threeD_MENU_PREVIOUS_RESULTS
                                )) ||
                              (key === 3 &&
                                i === 2 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .threeD_MENU_ROUND_SETTING
                                )) ||
                              (key === 3 &&
                                i === 3 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.threeD_MENU_SUMMARY
                                )) ||
                              (key === 4 &&
                                i === 1 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.ADMIN_ROLE_PERMISSION
                                )) ||
                              (key === 7 &&
                                i === 0 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.REPORT_MENU_OVERALL
                                )) ||
                              (key === 7 &&
                                i === 1 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.REPORT_MENU_2D_DAILY
                                )) ||
                              (key === 7 &&
                                i === 2 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .REPORT_MENU_2D_MONTHLY
                                )) ||
                              (key === 7 &&
                                i === 3 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.REPORT_MENU_2D_ANNUAL
                                )) ||
                              (key === 7 &&
                                i === 4 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .REPORT_MENU_3D_MONTHLY
                                )) ||
                              (key === 7 &&
                                i === 5 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.REPORT_MENU_3D_ANNUAL
                                )) ||
                              (key === 8 &&
                                i === 0 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .SETTING_MENU_CLOSE_DAYS
                                )) ||
                              (key === 8 &&
                                i === 1 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .SETTING_MENU_MAINTENANCE
                                )) ||
                              (key === 8 &&
                                i === 2 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const
                                    .SETTING_MENU_TERMS_CONDITION
                                )) ||
                              (key === 8 &&
                                i === 3 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.SETTING_MENU_POLICY
                                )) ||
                              (key === 8 &&
                                i === 4 &&
                                !permissionValidate(
                                  profileInfo?.data?.data?.roleId
                                    ?.permissionIds,
                                  CONTENT.permission_const.SETTING_MENU_FAQS
                                )) ? (
                                <></>
                              ) : (
                                <ListItemButton
                                  key={i}
                                  sx={{ ml: 5, mb: 1, borderRadius: "10px" }}
                                  className={`flex text-dynamic_black justify-start items-center align-middle hover:bg-pink_lemonade rounded-lg ${
                                    pathname === child?.link &&
                                    "!bg-pink_lemonade !hover:bg-pink_lemonade hover:text-vermilion_bird !text-vermilion_bird font-semibold !border-r-orange-700 rounded-lg "
                                  }`}
                                  onClick={() => {
                                    navigate(child.link);
                                  }}
                                >
                                  <ListItemText primary={child.label} />
                                </ListItemButton>
                              )}
                            </>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                )}
              </>
            ))}
          </List>
        </Stack>
      </DrawerStyled>
      <div
        className={`w-screen min-h-full overflow-x-hidden py-16 md:py-[80px] px-2 md:px-[10px] md:pl-5    ${
          [
            "/twod/previousresults",
            "/twod/betlist",
            RouteName.report_list,
            RouteName.dashboard,
            "/",
          ].includes(pathname)
            ? "bg-lynx_white"
            : "bg-white"
        } `}
      >
        {children}
      </div>
    </Box>
  );
};

export default Drawer;
