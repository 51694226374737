import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AddCircle1Icon } from "../common/Icon";
import CustomizeTable from "../../table/CustomizeTable";
import { closeDayCol } from "../../table/columns/settings/closeDayCol";
import { useGetHolidayList } from "../../api/funs/holidays";
import { RouteName } from "../../app/constants/route-constant";
import { useProfileInfo } from "../../api/funs/profile";
import { permissionValidate } from "../../util/funs";
import { CONTENT } from "../../content";

const HolidayList: React.FC = () => {

  const { data: profileInfo } = useProfileInfo();

  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const { data: holidays, isLoading, error } = useGetHolidayList();

  const closeDayMemorizedData = useMemo(() => {
    return holidays?.data?.data?.map((d: any, i: number) => ({
      sr_no: i + 1,
      _id: d?._id,
      // sr_no: `${pagination.pageIndex}${i}`,
      date: d?.date,
      dateColor: true,
      description: d?.holidayName,
    }));
  }, [holidays]);

  return (
    <>
      <div className="inline-block mt-10 mb-3 md:mt-0 gap-x-4">

        {
          permissionValidate(
            profileInfo?.data?.data?.roleId?.permissionIds,
            CONTENT.permission_const.SETTING_CLOSE_DAYS_CREATE
          ) && (  <div
            onClick={() => {
              navigate(RouteName.setting_closedays_create);
            }}
            className="flex items-center px-2 py-1 text-white rounded-md md:py-2 bg-vermilion_bird"          >
            <AddCircle1Icon width={18} height={18} />
            <span className="font-bold p-l-5">Create</span>
          </div>)
        }
      
      </div>
      <CustomizeTable
        column={closeDayCol}
        data={closeDayMemorizedData || []}
        totalCount={closeDayMemorizedData?.length}
        pagination={pagination}
        setPagination={setPagination}
        error={error}
        isRefetching={isLoading}
      />
    </>
  );
};

export default HolidayList;
