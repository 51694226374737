import moment from "moment";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import { useGetBetList, useGetRoundList } from "../../../api/funs/rounds";

import { Autocomplete, CircularProgress, TextField, useMediaQuery } from "@mui/material";
import { CONTENT } from "../../../content";

import { useNavigate } from "react-router-dom";
import { RouteName } from "../../../app/constants/route-constant";

const TodayBetListCard = () => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const navigate = useNavigate();
  const [round, setRound] = useState({
    label: "",
    value: "",
  });

  const [betType, setBetType] = useState<{
    label: string;
    value: string;
  } | null>({
    label: "2D",
    value: "2d",
  });

  const { data: betList, isLoading } = useGetBetList(
    betType?.value === "2d" ? "two-d" : "three-d",
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
    0,
    3,
    round?.value as string
  );
  const { data: roundsList } = useGetRoundList(betType?.value as string);
  useEffect(() => {
    if (roundsList) {
      setRound({
        label: roundsList?.data?.data?.[0]?.roundName,
        value: roundsList?.data?.data?.[0]?._id,
      });
    }
  }, [roundsList]);

  return (
    <div className="flex flex-col items-start justify-between w-full px-3 py-3 bg-white rounded-md">
      <div className="justify-between w-full md:flex">
        <span className="block text-[14px] mb-3 md:mb-0">Today Bet List</span>
        <div className="flex justify-between mb-2 gap-x-2">
          {roundsList && betType?.value === "2d" && (
            <Autocomplete
              sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
              id="combo-box-demo"
              onChange={(e, value) => {
                setRound(value as any);
              }}
              value={round}
              options={roundsList?.data?.data?.map((_e: any, i: any) => ({
                label: _e?.roundName,
                value: _e?._id,
              }))}
              defaultValue={{
                label: roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))?.[0]?.label,
                value: roundsList?.data?.data?.map((_e: any, i: any) => ({
                  label: _e?.roundName,
                  value: _e?._id,
                }))?.[0]?.value,
              }}
              getOptionLabel={(option) => option?.label}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              style={{ width:isSmallScreen?100 : 160 }}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  color="error"
                  {...params}
                  label="Select Round"
                  size="small"
                  defaultValue={round?.value}
                  variant="outlined"
                />
              )}
            />
          )}{" "}
          <Autocomplete
            sx={{ "& .MuiAutocomplete-clearIndicator": { display: "none" } }}
            id="combo-box-demo"
            onChange={(e, value) => {
              setBetType(value as any);
            }}
            value={betType}
            options={CONTENT?.dashboard?.betTypeA}
            defaultValue={{
              label: CONTENT?.dashboard?.betTypeA?.[0]?.label,
              value: CONTENT?.dashboard?.betTypeA?.[0]?.value,
            }}
            getOptionLabel={(option) => option?.label}
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            style={{ width: 100 }}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                color="error"
                {...params}
                label="Select Round"
                size="small"
                defaultValue={betType?.value}
                variant="outlined"
              />
            )}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center w-full my-3">
          {" "}
          <CircularProgress
            size={20}
            sx={{
              color: "#F44336",
            }}
          />
        </div>
      ) : (
        <table className="w-full text-dim_grey">
          <tbody>
            {betList?.data?.data?.length === 0 && (
              <tr>
                <td>
                  <div className="flex items-center justify-center my-10 md:my-0">
                    No record...
                  </div>
                </td>
              </tr>
            )}
            {betList?.data?.data?.map((_d: any, i: any) => (
              <tr className="border-dashed border-steam border-y ">
                <td className="py-1">
                  {" "}
                  <div className="flex items-center">
                    <div>
                      <div className="text-[14px] font-medium  text-dynamic_black">
                        {_d?.user?.name}
                      </div>
                      <div className="text-[12px] text-dim_grey">
                        {_d?.user?.phone}{" "}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="flex justify-end py-1">
                  {" "}
                  <div className="">
                    <>
                      <span className="text-[14px] font-medium  text-dynamic_black block">
                        {format(new Date(_d?.betDate), "dd MMM  yyyy")}
                      </span>

                      <span className="text-[12px] text-dim_grey">
                        {format(new Date(_d?.betDate), "hh:mm:ss a")}
                      </span>
                    </>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div
        onClick={() => {
          // setPreviousResult(
          //   JSON.stringify({
          //     winningNumber: winningNumber,
          //     set: set,
          //     value: value,
          //     resultDate: resultDate,
          //     roundType: roundType,
          //     resultId: resultId,
          //   })
          // );
          // navigate(RouteName.twod_previousresults_winnerlist);
        }}
        className="flex items-center justify-center w-full py-2 bg-pink_lemonade text-vermilion_bird text-[12px]  mt-3 cursor-pointer"
        onClickCapture={() => {
          navigate(
            betType?.value === "2d"
              ? RouteName.twod_betlist
              : RouteName.threed_betlist
          );
        }}
      >
        View More
      </div>
    </div>
  );
};

export default TodayBetListCard;
